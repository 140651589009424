import assert from "assert"
import { ForwardedRef, forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MfaResponse } from "api"
import { validateMessageTarget } from "validation"

import { ErrorText, Form, Button, Flex,
         MessageTargetInput, OnMessageTargetInputChange } from "ui/ui"

import { MessageTargetType } from "model"

export interface MessageTargetFormProps {
    performMfa: MessageTargetFormPerformMfa
    onMessageTargetChange?: OnMessageTargetFormMessageTargetChange
    onVerificationNeeded?: OnMessageTargetFormVerificationNeeded
    onSuccess?: OnMessageTargetFormSuccess

    placeholder?: string

    width?: string
}

export type MessageTargetFormPerformMfa = (
    messageTarget: string,
    messageTargetType: MessageTargetType,
) => Promise<MfaResponse>

export type OnMessageTargetFormMessageTargetChange = OnMessageTargetInputChange

export type OnMessageTargetFormVerificationNeeded = (
    messageTarget: string,
    messageTargetType: MessageTargetType,
) => void

export type OnMessageTargetFormSuccess = OnMessageTargetFormVerificationNeeded

const MessageTargetForm = forwardRef((
    {
        performMfa, onMessageTargetChange, onVerificationNeeded, onSuccess,
        placeholder,
        width,
    }: Readonly<MessageTargetFormProps>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    const [t] = useTranslation()

    // State

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as any)

    const [messageTarget, setMessageTarget] = useState(undefined as string | undefined)
    const [messageTargetType, setMessageTargetType] = useState(null as MessageTargetType | null)

    const messageTargetInputInvalid =
        messageTarget != null &&
        validateMessageTarget(messageTarget) != null

    const messageTargetInvalid =
        messageTarget == null ||
        messageTargetInputInvalid

    // Render

    return <Form onSubmit={onSubmit}
                 loading={loading}
                 width={width}
                 ref={ref}>
        <Flex align="start">
            <MessageTargetInput onChange={onInnerMessageTargetChange}
                                value={messageTarget}

                                placeholder={placeholder}

                                invalid={messageTargetInputInvalid}
                                loading={loading}/>

            <Button text={renderSubmitButtonText()}

                    disabled={messageTargetInvalid}
                    loading={loading}

                    type="submit"/>

            <ErrorText
                textAlign="left"

                error={error}

                apiErrorMessageMapping={{
                    404: t("messageTargets.messages.errors.notFound.messageTarget"),
                    409: t("messageTargets.messages.errors.occupied.messageTarget"),
                }}
            />
        </Flex>
    </Form>

    function renderSubmitButtonText(): string {
        switch (messageTargetType) {
            case "phone":
                return t("misc.buttons.sendSms")

            case "email":
                return t("misc.buttons.sendEmail")

            default:
                return t("misc.buttons.send")
        }
    }

    // Events

    async function onSubmit() {
        assert(messageTarget != null && messageTargetType != null)

        setLoading(true)

        try {
            const response = await performMfa(messageTarget, messageTargetType)

            switch (response.status) {
                case "success":
                    onSuccess?.(messageTarget, messageTargetType)
                    break

                case "verification-needed":
                    onVerificationNeeded?.(messageTarget, messageTargetType)
                    break
            }

            setError(undefined)
        } catch (error) {
            setLoading(false)
            setError(error)
        }
    }

    function onInnerMessageTargetChange(
        newMessageTarget: string,
        newMessageTargetType: MessageTargetType | null,
    ) {
        setMessageTarget(newMessageTarget)
        setMessageTargetType(newMessageTargetType)

        onMessageTargetChange?.(newMessageTarget, newMessageTargetType)
    }
})

MessageTargetForm.displayName = "PhoneForm"

export default MessageTargetForm
