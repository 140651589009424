import { z } from "zod"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"

export const DocumentResponseSchema = ModelObjectResponseSchema.extend({
    name: z.string(),
    mimeType: z.string(),
    size: z.number(),
    creatorId: UuidSchema.nullish(),
})

export type DocumentResponse = z.infer<typeof DocumentResponseSchema>
