import assert from "assert"
import { ForwardedRef, forwardRef, ReactNode, useContext } from "react"
import { useTranslation } from "react-i18next"
import { areDatesWithSameDay, dateToDateString, dateToTimeString, DeepReadonly } from "my-util"
import { UserContext } from "ui/context"
import { Name, NotificationCount } from "ui/ui"
import ChatMessageTicks from "../ChatMessageTicks"
import { UiChat } from "../types"
import style from "./style.module.css"

export interface ChatCardProps {
    onClick?: (chat: UiChat) => void
    chat: UiChat
    selected?: boolean

    formal?: boolean

    width?: string
}

const ChatCard = forwardRef((
    {
        onClick, chat, selected,
        formal,
        width,
    }: DeepReadonly<ChatCardProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const { user, messages, unreadCount } = chat

    const lastMessage = messages.length > 0
        ? messages[messages.length - 1]
        : null

    const now = new Date()

    return <div className={selected ? style.SelectedChatCard : style.ChatCard}
                onClick={() => onClick?.({ ...chat } as UiChat)}
                style={{ width }}
                ref={ref}>
        {renderChatTitle()}
        {renderLastMessageMoment()}
        {renderLastMessagePreview()}
        {renderUnreadCount()}
    </div>

    function renderChatTitle(): ReactNode {
        return <div className={style.title}>
            {localUser?.id === user.id
                ? t("chat.labels.selfChat")

                : <Name formal={formal}
                        value={user}/>
            }
        </div>
    }

    function renderLastMessageMoment(): ReactNode {
        return lastMessage != null &&
            <div className={style.lastMessageMoment}>
                {areDatesWithSameDay(now, lastMessage.date)
                    ? dateToTimeString(lastMessage.date)
                    : dateToDateString(lastMessage.date)
                }
            </div>
    }

    function renderLastMessagePreview(): ReactNode {
        return lastMessage != null &&
            <div className={style.lastMessagePreview}>
                {renderStatus()}
                {renderSender()}
                {renderPayload()}
            </div>

        function renderStatus(): ReactNode {
            assert(lastMessage != null)

            return lastMessage.local &&
                <ChatMessageTicks status={lastMessage.status ?? "sent"}/>
        }

        function renderSender(): ReactNode {
            assert(lastMessage != null)

            return lastMessage.local &&
                <span className={style.sender}>
                    {t("misc.words.you")}
                    {":"}
                </span>
        }

        function renderPayload(): ReactNode {
            assert(lastMessage != null)

            if (lastMessage.text != null)
                return lastMessage.text

            if (lastMessage.documents?.length)
                return <span className={style.document}>
                    {t("misc.words.document")}
                </span>
        }
    }

    function renderUnreadCount(): ReactNode {
        return Boolean(unreadCount) &&
            <div className={style.unreadCount}>
                <NotificationCount count={unreadCount!}
                                   max={99}/>
            </div>
    }
})

ChatCard.displayName = "ChatCard"

export default ChatCard
