import { CurrencyRateMessage } from "api"
import { CurrencyRate, Money } from "model"
import { DeepReadonly } from "my-util"
import { FieldDocument, copyUiDocument } from "ui/component/document"

export default interface CurrencyRateFields {
    moment: Date
    money: Money,
    evidence: FieldDocument | null
    evidenceLink: string |  null
}

export function copyCurrencyRateFields(fields: DeepReadonly<CurrencyRateFields>): CurrencyRateFields {
    return {
        moment: new Date(fields.moment.getTime()),
        money: fields.money.copy(),

        evidence: fields.evidence != null
            ? copyUiDocument(fields.evidence)
            : null,

        evidenceLink: fields.evidenceLink,
    }
}

export function currencyRateFromFields(fields: DeepReadonly<CurrencyRateFields>): CurrencyRate {
    return new CurrencyRate({
        moment: fields.moment,
        money: fields.money,
        evidenceId: fields.evidence?.document?.id ?? fields.evidence?.id,
        evidenceLink: fields.evidenceLink,
    })
}

export function currencyRateToFields(rate: CurrencyRate): CurrencyRateFields {
    return {
        moment: new Date(rate.moment.getTime()),
        money: rate.money,

        evidence: rate.evidenceId != null
            ? {
                id: rate.evidenceId,
                status: "loading",
            }
            : null,

        evidenceLink: rate.evidenceLink,
    }
}

export function currencyRateFieldsToMessage(fields: DeepReadonly<CurrencyRateFields>): CurrencyRateMessage {
    return {
        moment: new Date(fields.moment.getTime()),

        money: {
            currency: fields.money.currency,
            amount: fields.money.amount.toString(),
        },

        evidenceId: fields.evidence?.document?.id ?? fields.evidence?.id,
        evidenceLink: fields.evidenceLink,
    }
}
