import { ForwardedRef, forwardRef, Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { linkIconUrl, trashCanIconUrl } from "image"
import { Invite } from "model"

import { dateToDateString, dateToDateTimeString,
         dateWithTimeNulled, DeepReadonly, group } from "my-util"

import { useRerender } from "ui/hook"
import { createRegistrationPagePath } from "ui/page/RegistrationPage/path"
import { createInvitePagePath } from "ui/page/sections/clients/invites/InvitePage/path"

import { Button, Clickable, Copy, UserRoleAbbr, Timer,
         InviteStatusColorLegend, MessageTarget, Flex } from "ui/ui"

import style from "./style.module.css"

export interface InviteTableProps {
    onExpired?: (invite: Invite) => void
    onDelete?: (invite: Invite) => void

    invites: Iterable<Invite>

    showStatuses?: boolean

    loading?: boolean
    disabled?: boolean

    width?: string
}

const InviteTable = forwardRef((
    {
        onExpired, onDelete,
        invites,
        showStatuses,
        loading, disabled,
        width,
    }: DeepReadonly<InviteTableProps>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const [t] = useTranslation()

    const navigate = useNavigate()
    const rerender = useRerender()

    // State

    const innerInvitesByTimestamps = useMemo(
        () =>
            [
                ...group(
                    invites,
                    ({ createdAt }) => dateWithTimeNulled(createdAt).getTime(),
                ).entries()
            ].sort(([lhs], [rhs]) => rhs - lhs),
        [invites],
    )

    // Render

    return <table className={style.table}
                  width={width}
                  ref={ref}>
        <tbody>
            {innerInvitesByTimestamps.map(([timestamp, invites]) =>
                <Fragment key={timestamp}>
                    {invites.map((invite, i) => {
                        const onClick = () => onInviteClick(invite)

                        return <Fragment key={invite.id}>
                            {/* First row */}

                            <tr className={style.firstRow}
                                onClick={onClick}>
                                {/* Date */}

                                {i === 0 &&
                                    <td className={style.dateWrapper}
                                        rowSpan={4 * invites.length}>
                                        <div className={style.date}>
                                            {dateToDateString(new Date(timestamp))}
                                        </div>
                                    </td>
                                }

                                {/* Status */}

                                {showStatuses &&
                                    <td>
                                        <Flex width="32px">
                                            <InviteStatusColorLegend status={invite.status}/>
                                        </Flex>
                                    </td>
                                }

                                {/* Client row header */}

                                <td>{t("misc.labels.client")}</td>

                                {/* Expiration row header */}

                                <td>{t("domain.invites.labels.expiresAt.moment")}</td>

                                {/* Gap */}

                                <td/>
                            </tr>

                            {/* Second row */}

                            <tr className={style.secondRow}
                                onClick={onClick}>
                                {/* Role abbr */}

                                <td className={style.bigText + " " + style.client}
                                    rowSpan={3}>
                                    <Flex>
                                        <UserRoleAbbr role={invite.role}/>
                                    </Flex>
                                </td>

                                {/* Message target */}

                                <td className={style.client}>
                                    <MessageTarget messageTarget={invite.messageTarget}
                                                   stopLinkClickPropagation/>
                                </td>

                                {/* Expiration date */}

                                <td className={style.expiration}>
                                    {dateToDateTimeString(invite.expiresAt)}
                                </td>

                                {/* Actions */}

                                <td>
                                    <Clickable stopPropagation>
                                        <Button onClick={() => onDelete?.(invite)}

                                                iconSrc={trashCanIconUrl}
                                                iconAlt="Trash can icon"

                                                loading={loading}
                                                disabled={disabled || invite.isExpired}

                                                buttonStyle="text"
                                                critical/>
                                    </Clickable>
                                </td>
                            </tr>

                            {/* Third row */}

                            <tr className={style.thirdRow}
                                onClick={onClick}>
                                {/* Name */}

                                <td className={style.client}>
                                    {invite.name ?? t("domain.invites.messages.nameNotSet")}
                                </td>

                                {/* Expiration timer */}

                                <td className={style.expiration}>
                                    <Timer onExpired={() => onInviteExpired(invite)}
                                           expiresAt={invite.expiresAt}/>
                                </td>

                                {/* Actions */}

                                <td>
                                    <Flex>
                                        <Clickable stopPropagation>
                                            <Copy value={inviteToRegistrationLink(invite)}

                                                  copiedText={t("misc.messages.linkCopied")}
                                                  text=""

                                                  iconSrc={linkIconUrl}
                                                  iconAlt="Link icon"
                                                  iconFilter="brightness(.4)"/>
                                        </Clickable>
                                    </Flex>
                                </td>
                            </tr>

                            {/* Fourth row */}

                            <tr className={style.fourthRow}
                                onClick={onClick}>
                                {/* Expiration timer */}

                                <td className={style.client}>
                                    {invite.company ?? t("domain.company.messages.notSet")}
                                </td>

                                {/* Gap */}

                                <td className={style.expiration}/>
                                <td/>
                            </tr>
                        </Fragment>
                    })}

                    <tr className={style.gap}/>
                </Fragment>
            )}
        </tbody>
    </table>

    // Events

    function onInviteClick(invite: Invite) {
        if (!invite.isExpired)
            navigate(createInvitePagePath(invite.id))
    }

    function onInviteExpired(invite: Invite) {
        rerender()
        onExpired?.(invite)
    }

    // Util

    function inviteToRegistrationLink(invite: Invite): string {
        return window.location.origin + createRegistrationPagePath(invite.id)
    }
})

InviteTable.displayName = "InviteTable"

export default InviteTable
