import { ForwardedRef, forwardRef } from "react"
import { Flex, FlexProps } from "ui/ui/layout"
import { UserStatus } from "model"
import UserStatusColorLegend from "../UserStatusColorLegend"

export interface UserStatusColorLegendListProps extends Omit<FlexProps, "children"> {
    onClick?: (status: UserStatus) => void

    hideActive?: boolean
    hideUnverified?: boolean
    hideBlocked?: boolean

    showLegend?: boolean

    fontSize?: string
}

const UserStatusColorLegendList = forwardRef((
    props: Readonly<UserStatusColorLegendListProps>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const {
        onClick,
        hideActive, hideUnverified, hideBlocked,
        showLegend,
        fontSize,
    } = props

    return <Flex direction="horizontal"
                 width="fit-content"
                 { ...props }
                 ref={ref}>
        {!hideActive &&
            <UserStatusColorLegend showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}
                                   status="active"/>
        }

        {!hideUnverified &&
            <UserStatusColorLegend showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}
                                   status="unverified"/>
        }

        {!hideBlocked &&
            <UserStatusColorLegend showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}
                                   status="blocked"/>
        }
    </Flex>
})

UserStatusColorLegendList.displayName = "UserStatusColorLegendList"

export default UserStatusColorLegendList
