import { joinSubpaths } from "my-util"
import { MAIN_PAGE_PATH } from "ui/page/sections/MainPage/path"

export const MESSENGER_PAGE_PATH = joinSubpaths(MAIN_PAGE_PATH, "/messenger")

export const MESSENGER_PAGE_USER_ID_SEARCH_PARAM = "user-id"

export function createMessengerPagePath(userId?: string | null): string {
    return userId != null
        ? `${MESSENGER_PAGE_PATH}?${MESSENGER_PAGE_USER_ID_SEARCH_PARAM}=${encodeURIComponent(userId)}`
        : MESSENGER_PAGE_PATH
}
