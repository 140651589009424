import { WithNullableCreatorId } from "model/interfaces"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, Nullish, tryNormalizeUuid } from "my-util"

export interface DocumentCreationOptions extends AbstractModelObjectCreationOptions {
    name: string
    mimeType: string
    size: number
    blob?: Blob | null
    creatorId?: string | null
}

export interface DocumentCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<DocumentCreationOptions>
{}

export default class Document
    extends
        AbstractModelObject

    implements
        Readonly<WithNullableCreatorId>
{
    static createOrPass(arg: Document | DeepReadonly<DocumentCreationOptions>): Document {
        return arg instanceof Document
            ? arg
            : new Document(arg)
    }

    readonly name: string
    readonly mimeType: string
    readonly size: number
    readonly blob: Blob | null
    readonly creatorId: string | null

    constructor(options: DeepReadonly<DocumentCreationOptions>) {
        super(options)

        this.name = options.name
        this.mimeType = options.mimeType
        this.size = options.size
        this.blob = options.blob ?? null

        this.creatorId = options.creatorId != null
            ? tryNormalizeUuid(options.creatorId)
            : null
    }

    getCreatorIdColor(opacity?: number | null): string {
        return AbstractModelObject.getIdColor(this.creatorId ?? "", opacity)
    }

    override copyOrPass(
        arg?: Document
            | DeepReadonly<DocumentCopyOptions>
            | null,
    ): Document {
        if (arg == null)
            return this

        if (arg instanceof Document)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<DocumentCopyOptions> = {}): Document {
        return new Document({
            name: options.name ?? this.name,
            mimeType: options.mimeType ?? this.mimeType,
            size: options.size ?? this.size,

            blob: options.blob !== undefined
                ? options.blob
                : this.blob,

            creatorId: options.creatorId !== undefined
                ? options.creatorId
                : this.creatorId,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
