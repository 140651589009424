// Interface

import { HOUR_MINUTES, MINUTE_SECONDS, SECOND_MILLIS,
         DAY_HOURS, MINUTE_MILLIS, DAY_MILLIS, HOUR_MILLIS } from "./consts"

export default interface Duration {
    days: number
    hours: number
    minutes: number
    seconds: number
    millis: number
}

// Consts

export const ZERO_DURATION: Readonly<Duration> = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    millis: 0,
}

// Math

// :round

export function roundedDuration(duration: Readonly<Duration>): Duration {
    return mapDuration(duration, Math.round)
}

export function roundDuration(duration: Duration): Duration {
    return applyToDuration(duration, Math.round)
}

// :floor

export function flooredDuration(duration: Readonly<Duration>): Duration {
    return mapDuration(duration, Math.floor)
}

export function floorDuration(duration: Duration): Duration {
    return applyToDuration(duration, Math.floor)
}

// :ceil

export function ceiledDuration(duration: Readonly<Duration>): Duration {
    return mapDuration(duration, Math.ceil)
}

export function ceilDuration(duration: Duration): Duration {
    return applyToDuration(duration, Math.ceil)
}

// :trunc

export function truncedDuration(duration: Readonly<Duration>): Duration {
    return mapDuration(duration, Math.trunc)
}

export function truncDuration(duration: Duration): Duration {
    return applyToDuration(duration, Math.trunc)
}

// Map/apply

export function mapDuration(duration: Readonly<Duration>, map: (part: number) => number): Duration {
    return applyToDuration(copyDuration(duration), map)
}

export function applyToDuration(duration: Duration, apply: (part: number) => number): Duration {
    duration.days = apply(duration.days)
    duration.hours = apply(duration.hours)
    duration.minutes = apply(duration.minutes)
    duration.seconds = apply(duration.seconds)
    duration.millis = apply(duration.millis)

    return duration
}

// Normalize

export function normalizeDuration(duration: Duration): Duration {
    assignDuration(duration, normalizedDuration(duration))
    return duration
}

export function normalizedDuration(duration: Readonly<Partial<Duration>>): Duration {
    const fullDuration = assignDuration(ZERO_DURATION, duration)
    const millis = durationToMillis(fullDuration)
    const normalizedDuration = millisToDuration(millis)
    
    return normalizedDuration
}

// Assign

export function assignedDuration(to: Duration, from: Readonly<Partial<Duration>>): Duration {
    return assignDuration(copyDuration(to), from)
}

export function assignDuration(to: Duration, from: Readonly<Partial<Duration>>): Duration {
    if (from.days != null)
        to.days = from.days

    if (from.hours != null)
        to.hours = from.hours

    if (from.minutes != null)
        to.minutes = from.minutes

    if (from.seconds != null)
        to.seconds = from.seconds

    if (from.millis != null)
        to.millis = from.millis

    return to
}

// Copy

export function copyDuration(
    {
        days,
        hours,
        minutes,
        seconds,
        millis,
    }: Readonly<Duration>,
): Duration {
    return {
        days,
        hours,
        minutes,
        seconds,
        millis
    }
}

// Conversion

export function millisToDuration(totalMillis: number): Duration {
    const totalSeconds = totalMillis / SECOND_MILLIS
    const totalMinutes = totalSeconds / MINUTE_SECONDS
    const totalHours = totalMinutes / HOUR_MINUTES
    const totalDays = totalHours / DAY_HOURS

    const millis = totalMillis % SECOND_MILLIS
    const seconds = totalSeconds % MINUTE_SECONDS
    const minutes = totalMinutes % HOUR_MINUTES
    const hours = totalHours % DAY_HOURS
    const days = totalDays

    return {
        millis,
        seconds,
        minutes,
        hours,
        days,
    }
}

export function durationToMillis(
    {
        days,
        hours,
        minutes,
        seconds,
        millis,
    }: Readonly<Duration>,
): number {
    const daysMillis = days * DAY_MILLIS
    const hoursMillis = hours * HOUR_MILLIS
    const minutesMillis = minutes * MINUTE_MILLIS
    const secondsMillis = seconds * SECOND_MILLIS

    return daysMillis
         + hoursMillis
         + minutesMillis
         + secondsMillis
         + millis
}

