import { ReadonlyDate } from "my-util/type"

export function dateWithDateSet(date: ReadonlyDate, dateDate: ReadonlyDate): Date {
    return setDateDate(new Date(date.getTime()), dateDate)
}

export function setDateDate(date: Date, dateDate: ReadonlyDate): Date {
    date.setFullYear(dateDate.getFullYear())
    date.setMonth(dateDate.getMonth())
    date.setDate(dateDate.getDate())

    return date
}

export function dateWithTimeSet(date: ReadonlyDate, timeDate: ReadonlyDate): Date {
    return setDateTime(new Date(date.getTime()), timeDate)
}

export function setDateTime(date: Date, timeDate: ReadonlyDate): Date {
    date.setHours(timeDate.getHours())
    date.setMinutes(timeDate.getMinutes())
    date.setSeconds(timeDate.getSeconds())
    date.setMilliseconds(timeDate.getMilliseconds())

    return date
}

export function dateWithTimeNulled(date: ReadonlyDate): Date {
    return nullDateTime(new Date(date.getTime()))
}

export function nullDateTime(date: Date): Date {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
}
