import { determineMessageTargetType } from "model"
import { IS_ALLOWED_BY_EVERY_MESSAGE_TARGET_TYPE, IsAllowedByMessageTargetType } from "my-util"
import ViolationType from "./ViolationType"
import { validatePhone } from "./phone"
import { validateEmail } from "./email"

export type MessageTargetViolationType =
    | ViolationType
    | "phone-forbidden"
    | "email-forbidden"

export function validateMessageTarget(
    target: string,
    config: Readonly<IsAllowedByMessageTargetType> = IS_ALLOWED_BY_EVERY_MESSAGE_TARGET_TYPE,
) {
    switch (determineMessageTargetType(target)) {
        case "phone":
            return config.isAllowedByPhone
                 ? validatePhone(target)
                 : "phone-forbidden"

        case "email":
            return config.isAllowedByEmail
                 ? validateEmail(target)
                 : "email-forbidden"
    }
}
