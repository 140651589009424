import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { sticklessArrowLeftIconUrl, sticklessArrowRightIconUrl } from "image"
import { getAllMyTransfersInWork, getAllTransfersInWork, getUserById } from "api"
import { Transfer, User } from "model"

import { DECEMBER_INDEX, JANUARY_INDEX, getMonthDayCount,
         getMonthDayName, getMonthName, weekToString,
         getNextMonthIndex, MonthIndex, getMonthWeeks,
         uniqueArray, getPrevMonthIndex, normalizeMonthIndex } from "my-util"

import { useTransfers, useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { createTransferPagePath } from "ui/page/sections/transfers/TransferPage/path"
import { Page } from "ui/component"

import { Tab, TabbedView, Loading, ErrorDisplay, TabbedViewChild, Flex,
         DayCalendar, WeekCalendar, MonthCalendar, YearCalendar, Button } from "ui/ui"

// UI

// - Consts

const TAB_WIDTH = "224px"

// Scope

// - Consts

export const CALENDAR_PAGE_SCOPE_SEARCH_PARAM = "scope"
export const CALENDAR_PAGE_YEAR_SEARCH_PARAM = "year"
export const CALENDAR_PAGE_MONTH_SEARCH_PARAM = "month"
export const CALENDAR_PAGE_DAY_SEARCH_PARAM = "day"

// - Types

export type Scope =
    | "year"
    | "month"
    | "week"
    | "day"

// Tab index

// - Consts

const YEAR_TAB_INDEX: YearTabIndex = 0
const MONTH_TAB_INDEX: MonthTabIndex = 1
const WEEK_TAB_INDEX: WeekTabIndex = 2
const DAY_TAB_INDEX: DayTabIndex = 3

// - Types

export type YearTabIndex = 0
export type MonthTabIndex = 1
export type WeekTabIndex = 2
export type DayTabIndex = 3

export type TabIndex =
    | YearTabIndex
    | MonthTabIndex
    | WeekTabIndex
    | DayTabIndex

// Component

export default function CalendarPage() {
    const [t] = useTranslation()
    const [localUser] = useContext(UserContext)
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    // Storages

    const storedUsers = useUsers()
    const storedTransfers = useTransfers()

    // Scope

    const scopeSearchParam = searchParams.get(CALENDAR_PAGE_SCOPE_SEARCH_PARAM)

    const tabIndex = scopeSearchParam != null
        ? scopeToTabIndex(scopeSearchParam)
        : YEAR_TAB_INDEX

    // Date

    const now = new Date()

    // - Year

    const yearSearchParam = searchParams.get(CALENDAR_PAGE_YEAR_SEARCH_PARAM)

    const year = yearSearchParam != null
        ? Number(yearSearchParam)
        : now.getFullYear()

    // - Month

    const monthSearchParam = searchParams.get(CALENDAR_PAGE_MONTH_SEARCH_PARAM)

    const monthIndex = monthSearchParam != null
        ? normalizeMonthIndex(Number(monthSearchParam) - 1)
        : now.getMonth() as MonthIndex

    // - Day

    const daySearchParam = searchParams.get(CALENDAR_PAGE_DAY_SEARCH_PARAM)

    const dayIndex = daySearchParam != null
        ? Number(daySearchParam) - 1
        : now.getDate() - 1

    // - Weeks

    const monthWeeks = useMemo(
        () => getMonthWeeks(year, monthIndex),
        [year, monthIndex],
    )

    const currentMonthWeekIndex = useMemo(
        () => {
            for (let i = 0; i < monthWeeks.length; ++i) {
                const { end } = monthWeeks[i]

                if (end.monthIndex === monthIndex) {
                    if (dayIndex <= end.dayIndex)
                        return i
                } else
                    return monthWeeks.length - 1
            }

            return 0
        },

        [monthWeeks, monthIndex, dayIndex]
    )

    const currentMonthWeek = useMemo(
        () => monthWeeks[currentMonthWeekIndex],
        [currentMonthWeekIndex, monthWeeks],
    )

    // Transfers

    const [transfers, setTransfers] = useState(new Array<Transfer>())
    const [loadingTransfers, setLoadingTransfers] = useState(localUser != null)

    // Users

    const [users, setUsers] = useState(new Map<string, User>())
    const [loadingUsers, setLoadingUsers] = useState(false)

    // Loading

    const [error, setError] = useState(undefined as unknown)

    const loading =
        loadingTransfers ||
        loadingUsers

    // Effects

    // - Transfers loading

    useEffect(() => {
        if (!loadingTransfers)
            return

        const controller = new AbortController()

        const get = localUser?.isAdmin
            ? getAllTransfersInWork
            : getAllMyTransfersInWork

        get(controller.signal)
            .then(transfers => {
                storedTransfers.addAll(transfers)
                setTransfers(transfers)
                setLoadingUsers(true)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingTransfers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingTransfers])

    // - Users loading

    useEffect(() => {
        if (!loadingUsers)
            return

        const controller = new AbortController()
        const creatorIds = uniqueArray(transfers.map(({ creatorId }) => creatorId))
        const requests = creatorIds.map(id => getUserById(id, controller.signal))
        const promise = Promise.all(requests)

        promise
            .then(users => {
                storedUsers.addAll(users)
                setUsers(User.groupById(users))
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingUsers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page title={t("sections.calendar.header").toUpperCase()}
                 type="main">
        {renderContent()}
    </Page>

    function renderContent() {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        return <TabbedView onSelect={onTabIndexSelect}
                           selected={tabIndex}
                           right={renderScrollButtons()}>
            {renderYearTab()}
            {renderMonthTab()}
            {renderWeekTab()}
            {renderDayTab()}
        </TabbedView>
    }

    // - Tabs

    function renderYearTab(): TabbedViewChild {
        const name = tabIndex === YEAR_TAB_INDEX
            ? year.toString()
            : t("sections.calendar.tabNames.year")

        return <Tab index={YEAR_TAB_INDEX}
                    width={TAB_WIDTH}
                    name={name}>
            <YearCalendar transfers={transfers}
                          onDateClick={onDateClick}
                          year={year}

                          direction="horizontal"
                          gap="32px"
                          wrap/>
        </Tab>
    }

    function renderMonthTab(): TabbedViewChild {
        const name = tabIndex === MONTH_TAB_INDEX
            ? getMonthName(monthIndex)
            : t("sections.calendar.tabNames.month")

        return <Tab index={MONTH_TAB_INDEX}
                    width={TAB_WIDTH}
                    name={name}>
            <MonthCalendar onTransferClick={onTransferClick}
                           transfers={transfers}
                           users={users}
                           monthIndex={monthIndex}
                           year={year}/>
        </Tab>
    }

    function renderWeekTab(): TabbedViewChild {
        const name = tabIndex === WEEK_TAB_INDEX
            ? weekToString(currentMonthWeek)
            : t("sections.calendar.tabNames.week")

        return <Tab index={WEEK_TAB_INDEX}
                    width={TAB_WIDTH}
                    name={name}>
            <WeekCalendar onTransferClick={onTransferClick}
                          transfers={transfers}
                          users={users}
                          dayIndex={dayIndex}
                          monthIndex={monthIndex}
                          year={year}/>
        </Tab>
    }

    function renderDayTab(): TabbedViewChild {
        const name = tabIndex === DAY_TAB_INDEX
            ? getMonthDayName(monthIndex, dayIndex + 1)
            : t("sections.calendar.tabNames.day")

        return <Tab index={DAY_TAB_INDEX}
                    width={TAB_WIDTH}
                    name={name}>
            <DayCalendar onTransferClick={onTransferClick}
                         transfers={transfers}
                         users={users}
                         dayIndex={dayIndex}
                         monthIndex={monthIndex}
                         year={year}/>
        </Tab>
    }

    // - Scroll buttons

    function renderScrollButtons(): ReactNode {
        return <Flex direction="horizontal"
                     gap="8px">
            <Button onClick={onClickLeft}

                    buttonStyle="text"
                    width="fit-content"

                    iconSrc={sticklessArrowLeftIconUrl}
                    iconAlt="Arrow left"/>

            <Button onClick={onClickRight}

                    buttonStyle="text"
                    width="fit-content"

                    iconSrc={sticklessArrowRightIconUrl}
                    iconAlt="Arrow right"/>
        </Flex>

        function onClickLeft() {
            switch (tabIndex) {
                case YEAR_TAB_INDEX:
                    onYearSelect(year - 1)
                    break

                case MONTH_TAB_INDEX: {
                    const newMonthIndex = getPrevMonthIndex(monthIndex)

                    if (newMonthIndex !== DECEMBER_INDEX)
                        onMonthIndexSelect(newMonthIndex)
                    else
                        onDateSelect(year - 1, newMonthIndex, dayIndex)

                    break
                }

                case WEEK_TAB_INDEX: {
                    if (currentMonthWeekIndex > 0) {
                        const {
                            start: {
                                monthIndex: newMonthIndex,
                                dayIndex: newDayIndex,
                            },
                        } = monthWeeks[currentMonthWeekIndex - 1]

                        const newYear =
                            newMonthIndex !== monthIndex &&
                            newMonthIndex === DECEMBER_INDEX
                                ? year - 1
                                : year

                        onDateSelect(newYear, newMonthIndex, newDayIndex)

                        break
                    }

                    const newMonthIndex = getPrevMonthIndex(monthIndex)

                    const newYear = newMonthIndex === DECEMBER_INDEX
                        ? year - 1
                        : year

                    const newDayIndex = getMonthDayCount(newMonthIndex, newYear) - 1

                    onDateSelect(newYear, newMonthIndex, newDayIndex)

                    break
                }

                case DAY_TAB_INDEX: {
                    if (dayIndex > 0) {
                        onDayIndexSelect(dayIndex - 1)
                        break
                    }

                    const newMonthIndex = getPrevMonthIndex(monthIndex)

                    const newYear = newMonthIndex === DECEMBER_INDEX
                        ? year - 1
                        : year

                    const newDayIndex = getMonthDayCount(newMonthIndex, newYear) - 1

                    onDateSelect(newYear, newMonthIndex, newDayIndex)

                    break
                }
            }
        }

        function onClickRight() {
            switch (tabIndex) {
                case YEAR_TAB_INDEX:
                    onYearSelect(year + 1)
                    break

                case MONTH_TAB_INDEX: {
                    const newMonthIndex = getNextMonthIndex(monthIndex)

                    if (newMonthIndex !== JANUARY_INDEX)
                        onMonthIndexSelect(newMonthIndex)
                    else
                        onDateSelect(year + 1, newMonthIndex, dayIndex)

                    break
                }

                case WEEK_TAB_INDEX: {
                    if (currentMonthWeekIndex < monthWeeks.length - 1) {
                        const {
                            start: {
                                monthIndex: newMonthIndex,
                                dayIndex: newDayIndex,
                            },
                        } = monthWeeks[currentMonthWeekIndex + 1]

                        onDateSelect(year, newMonthIndex, newDayIndex)

                        break
                    }

                    const newMonthIndex = getNextMonthIndex(monthIndex)

                    const newYear = newMonthIndex === JANUARY_INDEX
                        ? year + 1
                        : year

                    const newDayIndex =
                        currentMonthWeek.end.dayIndex + 1 < getMonthDayCount(newMonthIndex, newYear) - 1
                            ? currentMonthWeek.end.dayIndex + 1
                            : 0

                    onDateSelect(newYear, newMonthIndex, newDayIndex)

                    break
                }

                case DAY_TAB_INDEX: {
                    if (dayIndex + 1 < getMonthDayCount(monthIndex, year)) {
                        onDayIndexSelect(dayIndex + 1)
                        break
                    }

                    const newDayIndex = 0
                    const newMonthIndex = getNextMonthIndex(monthIndex)

                    const newYear = newMonthIndex === JANUARY_INDEX
                        ? year + 1
                        : year

                    onDateSelect(newYear, newMonthIndex, newDayIndex)

                    break
                }
            }
        }
    }

    // Events

    // - Date selection

    function onDateClick(newDate: Date) {
        setSearchParams(oldSearchParams => {
            const newSearchParams = new URLSearchParams(oldSearchParams)

            newSearchParams.set(CALENDAR_PAGE_SCOPE_SEARCH_PARAM, "day" satisfies Scope)
            newSearchParams.set(CALENDAR_PAGE_YEAR_SEARCH_PARAM, newDate.getFullYear().toString())
            newSearchParams.set(CALENDAR_PAGE_MONTH_SEARCH_PARAM, (newDate.getMonth() + 1).toString())
            newSearchParams.set(CALENDAR_PAGE_DAY_SEARCH_PARAM, newDate.getDate().toString())

            return newSearchParams
        })
    }

    function onDateSelect(newYear: number, newMonthIndex: number, newDayIndex: number) {
        setSearchParams(oldSearchParams => {
            const newSearchParams = new URLSearchParams(oldSearchParams)

            newSearchParams.set(CALENDAR_PAGE_YEAR_SEARCH_PARAM, newYear.toString())
            newSearchParams.set(CALENDAR_PAGE_MONTH_SEARCH_PARAM, (newMonthIndex + 1).toString())
            newSearchParams.set(CALENDAR_PAGE_DAY_SEARCH_PARAM, (newDayIndex + 1).toString())

            return newSearchParams
        })
    }

    function onYearSelect(newYear: number) {
        setSearchParams(oldSearchParams => {
            const newSearchParams = new URLSearchParams(oldSearchParams)

            newSearchParams.set(CALENDAR_PAGE_YEAR_SEARCH_PARAM, newYear.toString())

            return newSearchParams
        })
    }

    function onMonthIndexSelect(newMonthIndex: number) {
        setSearchParams(oldSearchParams => {
            const newSearchParams = new URLSearchParams(oldSearchParams)

            newSearchParams.set(CALENDAR_PAGE_MONTH_SEARCH_PARAM, (newMonthIndex + 1).toString())

            return newSearchParams
        })
    }

    function onDayIndexSelect(newDayIndex: number) {
        setSearchParams(oldSearchParams => {
            const newSearchParams = new URLSearchParams(oldSearchParams)

            newSearchParams.set(CALENDAR_PAGE_DAY_SEARCH_PARAM, (newDayIndex + 1).toString())

            return newSearchParams
        })
    }

    // - Tab index selection

    function onTabIndexSelect(newTabIndex: number) {
        setSearchParams(oldSearchParams => {
            const newSearchParams = new URLSearchParams(oldSearchParams)
            const scope = tabIndexToScope(newTabIndex)

            newSearchParams.set(CALENDAR_PAGE_SCOPE_SEARCH_PARAM, scope)

            return newSearchParams
        })
    }

    // - Transfer clicking

    function onTransferClick(transfer: Transfer) {
        navigate(createTransferPagePath(transfer.id))
    }

    // Util

    function tabIndexToScope(index: number): Scope {
        switch (index) {
            default:
            case YEAR_TAB_INDEX:
                return "year"

            case MONTH_TAB_INDEX:
                return "month"

            case WEEK_TAB_INDEX:
                return "week"

            case DAY_TAB_INDEX:
                return "day"
        }
    }

    function scopeToTabIndex(scope: string): TabIndex {
        switch (scope.toLowerCase()) {
            default:
            case "year" satisfies Scope:
                return YEAR_TAB_INDEX

            case "month" satisfies Scope:
                return MONTH_TAB_INDEX

            case "week" satisfies Scope:
                return WEEK_TAB_INDEX

            case "day" satisfies Scope:
                return DAY_TAB_INDEX
        }
    }
}
