import { forwardRef, useState, ReactNode, useRef,
         useLayoutEffect, ForwardedRef, useReducer } from "react"

import { getLang } from "i18n"
import { DeepReadonly } from "my-util"
import { useWindowSize } from "ui/hook"
import { Flex, FlexItem } from "ui/ui"
import Header from "../Header"
import Footer from "../Footer"
import Menu, { MenuRef } from "../Menu"
import style from "./style.module.css"

let lastShortMenu = false

export type PageType =
    | "auth"
    | "main"

export interface PageProps {
    type?: PageType

    compactTitle?: ReactNode | ((compact: boolean) => ReactNode)
    title?: ReactNode

    autoMoveCompactTitle?: boolean

    children?: ReactNode
}

const Page = forwardRef((
    {
        type,
        compactTitle, title,
        autoMoveCompactTitle,
        children,
    }: DeepReadonly<PageProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { width: windowWidth } = useWindowSize()
    const mobileMenu = windowWidth <= 500

    // Refs

    const firstRenderRef = useRef(true)
    const menuRef = useRef(null as MenuRef | null)

    // State

    const [shortMenu, setShortMenu] = useReducer(
        (oldShortMenu: boolean, newShortMenu: boolean) => {
            firstRenderRef.current = false
            return lastShortMenu = newShortMenu
        },

        lastShortMenu,
    )

    const [estimatingCompactTitle, setEstimatingCompactTitle] = useState(false)
    const [compactTitleDoesFitMenu, setCompactTitleDoesFitMenu] = useState(true)

    // Effects

    useLayoutEffect(() => {
        if (!autoMoveCompactTitle || shortMenu)
            return

        setCompactTitleDoesFitMenu(true)
        setEstimatingCompactTitle(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoMoveCompactTitle, shortMenu, compactTitle, getLang()])

    useLayoutEffect(() => {
        if (!estimatingCompactTitle)
            return

        const menuElement = menuRef.current?.component?.current
        const compactTitleElement = menuRef.current?.title?.current

        if (menuElement == null || compactTitleElement == null)
            return

        const menuRect = menuElement.getBoundingClientRect()
        const compactTitleRect = compactTitleElement.getBoundingClientRect()

        setCompactTitleDoesFitMenu(menuRect.right >= compactTitleRect.right)
        setEstimatingCompactTitle(false)
    }, [estimatingCompactTitle])

    // Render

    const main = type === "main"

    const showCompactTitleAtMenu =
        !shortMenu &&
        (!autoMoveCompactTitle || compactTitleDoesFitMenu)

    return <div className={style.Page}
                ref={ref}>
        <div className={style.header}>
            <Header type={type}/>
        </div>

        {main &&
            <div className={style.menu}>
                <Menu firstRender={firstRenderRef.current}

                      onShortChange={setShortMenu}
                      short={shortMenu}

                      title={showCompactTitleAtMenu ? renderCompactTitle(true) : undefined}

                      mobile={mobileMenu}

                      ref={menuRef}/>
            </div>
        }

        <main className={main ? style.main : style.authMain}>
            {(main || (shortMenu && compactTitle) || title) &&
                <div className={style.title}>
                    <Flex direction="horizontal"
                          height="100%"
                          gap="32px">
                        {!showCompactTitleAtMenu && compactTitle &&
                            <div>{renderCompactTitle(false)}</div>
                        }

                        {title &&
                            <FlexItem grow={1}>
                                {title}
                            </FlexItem>
                        }
                    </Flex>
                </div>
            }

            <div className={style.content}>
                {children}
            </div>
        </main>

        <div className={style.footer}>
            <Footer type={type}/>
        </div>
    </div>

    function renderCompactTitle(compact: boolean): ReactNode {
        return typeof compactTitle === "function"
            ? compactTitle(compact)
            : compactTitle
    }
})

Page.displayName = "Page"

export default Page
