import Decimal from "decimal.js"

export const ZERO_DECIMAL = new Decimal(0)

export function createOrPassDecimal(
    value?:
        | Decimal
        | number
        | string
        | undefined
        | null,

    defaultValue?:
        | Decimal
        | number
        | string
        | undefined
): Decimal {
    if (value == null)
        return createOrPassDecimal(defaultValue, ZERO_DECIMAL)

    if (value instanceof Decimal)
        return value

    return new Decimal(value)
}

export function createOrPassNullableDecimal(
    value?:
        | Decimal
        | number
        | string
        | undefined
        | null,
): Decimal | null {
    if (value == null)
        return null

    if (value instanceof Decimal)
        return value

    return new Decimal(value)
}
