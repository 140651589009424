import { ForwardedRef, forwardRef, useMemo } from "react"
import { Money, MoneyCreationOptions } from "model"
import { Flex } from "ui/ui/layout"
import { DecimalOutput } from "ui/ui/numeric"
import Label from "ui/ui/Label"
import Information from "ui/ui/Information"
import Output from "ui/ui/Output"

export interface MoneyOutputProps {
    value: Money | MoneyCreationOptions | null
    precision?: number

    width?: string
    currencyWidth?: string
    amountWidth?: string
    gap?: string

    label?: string
    information?: string
}

const MoneyOutput = forwardRef((
    {
        value, precision,
        width, currencyWidth, amountWidth, gap,
        label, information,
    }: Readonly<MoneyOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {

    const innerValue = useMemo(() => Money.createOrPass(value), [value])

    // Render

    return <Flex width={width}
                 gap="4px"
                 ref={ref}>
        <Label text={label}/>

        <Flex direction="horizontal"
              gap={gap ?? "8px"}>
            <Output width={currencyWidth ?? "fit-content"}>
                {innerValue.currency}
            </Output>

            <DecimalOutput value={innerValue.amount}
                           precision={precision}
                           width={amountWidth}/>
        </Flex>

        <Information text={information}/>
    </Flex>
})

MoneyOutput.displayName = "MoneyOutput"

export default MoneyOutput
