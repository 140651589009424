import { useTranslation } from "react-i18next"
import { linkIconUrl } from "image"
import { InviteStatus } from "model"
import { createRegistrationPagePath } from "ui/page/RegistrationPage/path"
import { Flex, Copy, InviteStatusBadge } from "ui/ui"

export interface InviteHeaderProps {
    text: string
    status?: InviteStatus
    inviteId: string
}

export default function InviteHeader({ text, status, inviteId }: Readonly<InviteHeaderProps>) {
    const [t] = useTranslation()

    const registrationLink = window.location.origin + createRegistrationPagePath(inviteId)

    return <Flex direction="horizontal"
                 justify="space-between">
        <Flex direction="horizontal"
              width="fit-content">
            {text}

            {status != null &&
                <InviteStatusBadge status={status}/>
            }
        </Flex>

        <Copy text={t("misc.buttons.copyLink")}
              copiedText={t("misc.messages.linkCopied")}

              fontSize="16px"

              value={registrationLink}

              iconSrc={linkIconUrl}
              iconAlt="Link icon"
              iconFilter="brightness(0.4)"/>
    </Flex>
}
