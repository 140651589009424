import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export interface LoadingIndicatorProps {
    size?: string
}

const LoadingIndicator = forwardRef((
    { size }: Readonly<LoadingIndicatorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div className={style.LoadingIndicator}
         style={{ fontSize: size }}
         ref={ref}>
        <div/>
        <div/>
        <div/>
    </div>
)

LoadingIndicator.displayName = "LoadingIndicator"

export default LoadingIndicator
