import { useEffect, useState } from "react"

import { NotFoundApiError, NotificationSource,
         clearMyNotifications, deleteNotificationById } from "api"

import { Notification } from "model"

// Globals

// - Source

const globalSource = new NotificationSource({ url: "/api/notifications/my/stream" })

function openGlobalSource() {
    globalSource.on("error", error => console.error(error || "Something went wrong"))

    globalSource.on("old", addAllGlobalNotifications)
    globalSource.on("new", addGlobalNotification)

    globalSource.on("delete", deleteGlobalNotificationById)
    globalSource.on("clear", clearGlobalNotifications)

    globalSource.open()
}

// - Notifications

const globalNotifications = new Array<Notification>()

// -- Add

function addAllGlobalNotifications(notifications: readonly Notification[]) {
    for (const notification of notifications)
        addGlobalNotification(notification)
}

function addGlobalNotification(notification: Notification) {
    const oldNotificationIndex = globalNotifications.findIndex(({ id }) => id === notification.id)

    if (oldNotificationIndex >= 0)
        globalNotifications.splice(oldNotificationIndex, 1)

    globalNotifications.push(notification)

    globalNotifications.sort((lhs, rhs) => rhs.modifiedAt.getTime() - lhs.modifiedAt.getTime())
}

// -- Delete

function deleteGlobalNotificationById(notificationId: string) {
    const notificationIndex = globalNotifications.findIndex(({ id }) => id === notificationId)

    if (notificationIndex >= 0)
        globalNotifications.splice(notificationIndex, 1)
}

function clearGlobalNotifications() {
    globalNotifications.length = 0
}

// Hook

export type NotificationsHook = [Notification[], NotificationsHookActions]

export interface NotificationsHookActions {
    deleteByValue(notification: Notification): void
    deleteById(notificationId: string): void
    deleteByIndex(notificationIndex: number): void
    clear(): void
}

const ACTIONS: NotificationsHookActions = {
    deleteByIndex(notificationIndex) {
        const notification = globalNotifications[notificationIndex] as Notification | undefined

        if (notification != null)
            ACTIONS.deleteByValue(notification)
    },

    deleteByValue(notification) {
        ACTIONS.deleteById(notification.id)
    },

    async deleteById(notificationId) {
        try {
            await deleteNotificationById(notificationId)
        } catch (error) {
            if (error instanceof NotFoundApiError) {
                console.warn(`Notification with id ${notificationId} not found`)
                deleteGlobalNotificationById(notificationId)
                return
            }

            console.error(error)
        }
    },

    async clear() {
        try {
            await clearMyNotifications()
        } catch (error) {
            console.error(error)
        }
    },
}

export default function useNotifications(): NotificationsHook {
    const [notifications, setNotifications] = useState([...globalNotifications])

    useEffect(() => {
        if (globalSource.readyState === "closed")
            openGlobalSource()

        const updateEventNames = ["old", "new", "delete", "clear"] as const

        for (const eventName of updateEventNames)
            globalSource.on(eventName, setNotificationsFromGlobal)

        return () => {
            for (const eventName of updateEventNames)
                globalSource.off(eventName, setNotificationsFromGlobal)
        }

        function setNotificationsFromGlobal() {
            setNotifications([...globalNotifications])
        }
    }, [])

    return [notifications, ACTIONS]
}
