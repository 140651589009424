import { t } from "i18next"
import { MONTH_COUNT, WEEK_DAYS } from "./consts"
import { getWeekDayIndex, Week, WeekEdge } from "./week"
import { isLeapYear } from "./year"

export const JANUARY_INDEX = 0
export const FEBRUARY_INDEX = 1
export const MARCH_INDEX = 2
export const APRIL_INDEX = 3
export const MAY_INDEX = 4
export const JUNE_INDEX = 5
export const JULY_INDEX = 6
export const AUGUST_INDEX = 7
export const SEPTEMBER_INDEX = 8
export const OCTOBER_INDEX = 9
export const NOVEMBER_INDEX = 10
export const DECEMBER_INDEX = 11

export const MONTH_INDEXES = [
    JANUARY_INDEX,
    FEBRUARY_INDEX,
    MARCH_INDEX,
    APRIL_INDEX,
    MAY_INDEX,
    JUNE_INDEX,
    JULY_INDEX,
    AUGUST_INDEX,
    SEPTEMBER_INDEX,
    OCTOBER_INDEX,
    NOVEMBER_INDEX,
    DECEMBER_INDEX,
] as const

export type MonthIndex =
    | typeof JANUARY_INDEX
    | typeof FEBRUARY_INDEX
    | typeof MARCH_INDEX
    | typeof APRIL_INDEX
    | typeof MAY_INDEX
    | typeof JUNE_INDEX
    | typeof JULY_INDEX
    | typeof AUGUST_INDEX
    | typeof SEPTEMBER_INDEX
    | typeof OCTOBER_INDEX
    | typeof NOVEMBER_INDEX
    | typeof DECEMBER_INDEX

export function isMonthIndex(n: number): n is MonthIndex {
    return Number.isInteger(n)
        && JANUARY_INDEX <= n
        && DECEMBER_INDEX >= n
}

export function normalizeMonthIndexOrGetCurrent(
    monthIndex?: number | null,
    date: Date = new Date(),
): MonthIndex {
    return monthIndex != null
        ? normalizeMonthIndex(monthIndex)
        : date.getMonth() as MonthIndex
}

export function normalizeMonthIndex(monthIndex: number): MonthIndex {
    return (MONTH_COUNT + (monthIndex | 0) % MONTH_COUNT) % MONTH_COUNT as MonthIndex
}

export function getAllMonthNames(short: boolean = false): string[] {
    return MONTH_INDEXES.map(monthIndex => getMonthName(monthIndex, short))
}

export function getMonthName(monthIndex: MonthIndex, short: boolean = false): string {
    switch (monthIndex) {
        case JANUARY_INDEX:
            return short
                ? t("datetime.months.january.short")
                : t("datetime.months.january.full")

        case FEBRUARY_INDEX:
            return short
                ? t("datetime.months.february.short")
                : t("datetime.months.february.full")

        case MARCH_INDEX:
            return short
                ? t("datetime.months.march.short")
                : t("datetime.months.march.full")

        case APRIL_INDEX:
            return short
                ? t("datetime.months.april.short")
                : t("datetime.months.april.full")

        case MAY_INDEX:
            return short
                ? t("datetime.months.may.short")
                : t("datetime.months.may.full")

        case JUNE_INDEX:
            return short
                ? t("datetime.months.june.short")
                : t("datetime.months.june.full")

        case JULY_INDEX:
            return short
                ? t("datetime.months.july.short")
                : t("datetime.months.july.full")

        case AUGUST_INDEX:
            return short
                ? t("datetime.months.august.short")
                : t("datetime.months.august.full")

        case SEPTEMBER_INDEX:
            return short
                ? t("datetime.months.september.short")
                : t("datetime.months.september.full")

        case OCTOBER_INDEX:
            return short
                ? t("datetime.months.october.short")
                : t("datetime.months.october.full")

        case NOVEMBER_INDEX:
            return short
                ? t("datetime.months.november.short")
                : t("datetime.months.november.full")

        case DECEMBER_INDEX:
            return short
                ? t("datetime.months.december.short")
                : t("datetime.months.december.full")

        default:
            return t("datetime.messages.errors.invalidMonthIndex")
    }
}

export function getMonthDayCount(
    monthIndex: MonthIndex,
    year: number = new Date().getFullYear(),
): number {
    switch (monthIndex) {
        default:
        case JANUARY_INDEX:
        case MARCH_INDEX:
        case MAY_INDEX:
        case JULY_INDEX:
        case AUGUST_INDEX:
        case OCTOBER_INDEX:
        case DECEMBER_INDEX:
            return 31

        case APRIL_INDEX:
        case JUNE_INDEX:
        case SEPTEMBER_INDEX:
        case NOVEMBER_INDEX:
            return 30

        case FEBRUARY_INDEX:
            return isLeapYear(year) ? 29 : 28
    }
}

export interface CalendarMonthDay {
    type: CalendarMonthDayType
    index: number
}

export type CalendarMonthDayType =
    | "prev"
    | "cur"
    | "next"

export function getMonthCalendarDays(
    monthIndex: MonthIndex,
    year: number = new Date().getFullYear(),
): CalendarMonthDay[] {
    // Common

    const days = new Array<CalendarMonthDay>()
    const monthDayCount = getMonthDayCount(monthIndex, year)

    // Prev month

    const firstWeekDayIndex = getWeekDayIndex(year, monthIndex, 0)
    const daysToPrepend = firstWeekDayIndex

    const {
        monthIndex: prevMonthIndex,
        year: prevYear,
    } = getPrevMonthIndexAndYear(monthIndex, year)

    const prevMonthDayCount = getMonthDayCount(prevMonthIndex, prevYear)

    for (let i = daysToPrepend; i > 0; --i)
        days.push({
            type: "prev",
            index: prevMonthDayCount - i,
        })

    // Current month

    for (let i = 0; i < monthDayCount; ++i)
        days.push({
            type: "cur",
            index: i,
        })

    // Next month

    const daysToAppend = (6 * 7) - monthDayCount - daysToPrepend

    for (let i = 0; i < daysToAppend; ++i)
        days.push({
            type: "next",
            index: i,
        })

    return days
}

export function getAllMonthDayNames(
    monthIndex: MonthIndex,
    year: number = new Date().getFullYear(),
    short: boolean = false,
): string[] {
    return new Array(getMonthDayCount(monthIndex, year))
        .fill(0)
        .map((_, i) => i + 1)
        .map(day => getMonthDayName(monthIndex, day, short))
}

export function getMonthDayName(
    monthIndex: MonthIndex,
    day: number,
    short: boolean = false,
): string {
    switch (monthIndex) {
        case JANUARY_INDEX:
            return short
                ? t("datetime.months.january.shortWithCount", { count: day })
                : t("datetime.months.january.fullWithCount", { count: day })

        case FEBRUARY_INDEX:
            return short
                ? t("datetime.months.february.shortWithCount", { count: day })
                : t("datetime.months.february.fullWithCount", { count: day })

        case MARCH_INDEX:
            return short
                ? t("datetime.months.march.shortWithCount", { count: day })
                : t("datetime.months.march.fullWithCount", { count: day })

        case APRIL_INDEX:
            return short
                ? t("datetime.months.april.shortWithCount", { count: day })
                : t("datetime.months.april.fullWithCount", { count: day })

        case MAY_INDEX:
            return short
                ? t("datetime.months.may.shortWithCount", { count: day })
                : t("datetime.months.may.fullWithCount", { count: day })

        case JUNE_INDEX:
            return short
                ? t("datetime.months.june.shortWithCount", { count: day })
                : t("datetime.months.june.fullWithCount", { count: day })

        case JULY_INDEX:
            return short
                ? t("datetime.months.july.shortWithCount", { count: day })
                : t("datetime.months.july.fullWithCount", { count: day })

        case AUGUST_INDEX:
            return short
                ? t("datetime.months.august.shortWithCount", { count: day })
                : t("datetime.months.august.fullWithCount", { count: day })

        case SEPTEMBER_INDEX:
            return short
                ? t("datetime.months.september.shortWithCount", { count: day })
                : t("datetime.months.september.fullWithCount", { count: day })

        case OCTOBER_INDEX:
            return short
                ? t("datetime.months.october.shortWithCount", { count: day })
                : t("datetime.months.october.fullWithCount", { count: day })

        case NOVEMBER_INDEX:
            return short
                ? t("datetime.months.november.shortWithCount", { count: day })
                : t("datetime.months.november.fullWithCount", { count: day })

        case DECEMBER_INDEX:
            return short
                ? t("datetime.months.december.shortWithCount", { count: day })
                : t("datetime.months.december.fullWithCount", { count: day })

        default:
            return t("datetime.messages.errors.invalidMonthIndex")
    }
}

export function getMonthWeeks(year: number, monthIndex: MonthIndex): Week[] {
    const weeks = new Array<Week>()

    // First week

    const firstWeekDayIndex = getWeekDayIndex(year, monthIndex, 0)

    let firstWeekStart: WeekEdge

    if (firstWeekDayIndex > 0) {
        const prevMonthIndex = getPrevMonthIndex(monthIndex)
        const prevMonthDayCount = getMonthDayCount(prevMonthIndex)
        const prevMonthFirstWeekFirstDayIndex = prevMonthDayCount - firstWeekDayIndex

        firstWeekStart = {
            monthIndex: prevMonthIndex,
            dayIndex: prevMonthFirstWeekFirstDayIndex,
        }
    } else
        firstWeekStart = {
            dayIndex: 0,
            monthIndex,
        }

    const firstWeekEnd: WeekEdge = {
        dayIndex: 6 - firstWeekDayIndex,
        monthIndex,
    }

    const firstWeek: Week = {
        start: firstWeekStart,
        end: firstWeekEnd,
    }

    weeks.push(firstWeek)
    
    // Middle weeks

    let dayIndex = firstWeekEnd.dayIndex + 1
    let nextDayIndex = dayIndex + WEEK_DAYS

    const monthDayCount = getMonthDayCount(monthIndex, year)

    while (nextDayIndex < monthDayCount) {
        const week: Week = {
            start: {
                dayIndex: dayIndex,
                monthIndex,
            },

            end: {
                dayIndex: nextDayIndex - 1,
                monthIndex,
            },
        }

        weeks.push(week)

        dayIndex = nextDayIndex
        nextDayIndex += WEEK_DAYS
    }

    // Last week

    const lastWeekStart: WeekEdge = {
        dayIndex: dayIndex,
        monthIndex,
    }

    let lastWeekEnd: WeekEdge

    if (nextDayIndex > monthDayCount) {
        const nextMonthIndex = getNextMonthIndex(monthIndex)

        lastWeekEnd = {
            dayIndex: nextDayIndex - monthDayCount - 1,
            monthIndex: nextMonthIndex,
        }
    } else
        lastWeekEnd = {
            dayIndex: nextDayIndex - 1,
            monthIndex,
        }

    const lastWeek: Week = {
        start: lastWeekStart,
        end: lastWeekEnd,
    }

    weeks.push(lastWeek)

    return weeks
}

export interface MonthIndexAndYear {
    monthIndex: MonthIndex
    year: number
}

export function getPrevMonthIndexAndYear(monthIndex: MonthIndex, year: number): MonthIndexAndYear {
    return monthIndex === JANUARY_INDEX
        ? { year: year - 1, monthIndex: DECEMBER_INDEX }
        : { year, monthIndex: monthIndex - 1 as MonthIndex }
}

export function getPrevMonthIndex(monthIndex: MonthIndex): MonthIndex {
    return monthIndex === JANUARY_INDEX
        ? DECEMBER_INDEX
        : monthIndex - 1 as MonthIndex
}

export function getNextMonthIndexAndYear(monthIndex: MonthIndex, year: number): MonthIndexAndYear {
    return monthIndex === DECEMBER_INDEX
        ? { year: year + 1, monthIndex: JANUARY_INDEX }
        : { year, monthIndex: monthIndex + 1 as MonthIndex }
}

export function getNextMonthIndex(monthIndex: MonthIndex): MonthIndex {
    return monthIndex === DECEMBER_INDEX
        ? JANUARY_INDEX
        : monthIndex + 1 as MonthIndex
}
