import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { MAIN_PAGE_PATH } from "ui/page/sections/MainPage/path"
import { Link } from "ui/ui"

const BackToTheMainPageLink = forwardRef((_, ref: ForwardedRef<HTMLAnchorElement>) => {
    const [t] = useTranslation()

    return <Link text={t("sections.main.backLink")}
                 to={MAIN_PAGE_PATH}
                 ref={ref}/>
})

BackToTheMainPageLink.displayName = "BackToTheMainPageLink"

export default BackToTheMainPageLink
