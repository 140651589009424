import { ForwardedRef, forwardRef, HTMLInputAutoCompleteAttribute, useState } from "react"
import { formatPhoneAsYouType } from "my-util"
import Input from "ui/ui/Input"

const DEFAULT_COUNTRY_CODE = "7"
const DEFAULT_COUNTRY = "RU"

export interface PhoneInputProps {
    onChange?: (phone: string) => void
    value?: string

    width?: string
    height?: string

    invalid?: boolean
    disabled?: boolean
    readonly?: boolean
    loading?: boolean

    autoFocus?: boolean
    autoComplete?: HTMLInputAutoCompleteAttribute

    information?: string
    placeholder?: string
    label?: string

    iconSrc?: string
    iconAlt?: string
    iconFilter?: string
}

const PhoneInput = forwardRef((
    {
        onChange, value,
        width, height,
        invalid, disabled, readonly, loading,
        autoFocus, autoComplete,
        information, placeholder, label,
        iconSrc, iconAlt, iconFilter,
    }: Readonly<PhoneInputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const [entering, setEntering] = useState(
        value != null &&
        value.replaceAll(/\D/g, "") !== DEFAULT_COUNTRY_CODE
    )

    // Render

    return <Input onChange={onInnerChange}
                  value={getValue()}

                  postProcess={postProcess}
                  regex={/^[\d\s]*$/g}

                  left={"+"}

                  width={width}
                  height={height}

                  invalid={invalid}
                  disabled={disabled}
                  readonly={readonly}
                  loading={loading}

                  onFocus={onFocus}
                  autoFocus={autoFocus}
                  autoComplete={autoComplete}

                  label={label}
                  placeholder={getPlaceholder()}
                  information={information}

                  iconSrc={iconSrc}
                  iconAlt={iconAlt}
                  iconFilter={iconFilter}

                  ref={ref}/>

    // Events

    function onInnerChange(value: string) {
        if (onChange == null)
            return

        const cleanValue = value.replaceAll(/\s+/g, "")

        const internationalValue = cleanValue.length !== 0
            ? "+" + cleanValue
            : ""

        onChange(internationalValue)
    }

    function onFocus() {
        setEntering(true)
    }

    // Util

    function getValue(): string | undefined {
        return entering
            ? (value != null ? postProcess(value) : DEFAULT_COUNTRY_CODE)
            : undefined
    }

    function postProcess(value: string): string {
        return formatPhoneAsYouType(value, DEFAULT_COUNTRY)
    }

    function getPlaceholder(): string | undefined {
        if (entering)
            return placeholder

        let newPlaceholder = `${DEFAULT_COUNTRY_CODE}...`

        if (placeholder)
            newPlaceholder += ` ${placeholder}`

        return newPlaceholder
    }
})

PhoneInput.displayName = "PhoneInput"

export default PhoneInput
