import { ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { deleteInviteApplicationById, getAllInviteApplications } from "api"
import { InviteApplication } from "model"
import { useInviteApplications } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { ALL_INVITES_PAGE_PATH } from "ui/page/sections/clients/invites/AllInvitesPage/path"
import { InviteApplicationTable, Page } from "ui/component"
import { Center, Flex, Loading, ErrorDisplay, Link, ActionModal } from "ui/ui"

export default function AllInviteApplicationsPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedApplications = useInviteApplications()

    // State

    const [applications, setApplications] = useState(new Array<InviteApplication>())
    const [loading, setLoading] = useState(localUser?.isManager ?? false)
    const [error, setError] = useState(undefined as unknown)

    const [deletingId, setDeletingId] = useState(undefined as string | undefined)

    // Effects

    useEffect(() => {
        if (!loading)
            return

        const controller = new AbortController()

        getAllInviteApplications(controller.signal)
            .then(applications => {
                storedApplications.addAll(applications)
                setApplications(applications)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isManager)
        return <Error403Page/>

    return <Page title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderTitle(): ReactNode {
        return <Flex justify="space-between"
                     direction="horizontal"
                     gap="32px">
            {t("sections.clients.invites.applications.header")}

            <Link text={t("sections.clients.invites.link")}
                  to={ALL_INVITES_PAGE_PATH}
                  whiteSpace="nowrap"
                  fontSize="16px"/>
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        if (applications.length === 0)
            return <Center>
                {t("sections.clients.invites.applications.messages.no")}
            </Center>

        return <>
            <InviteApplicationTable onDelete={({ id }) => setDeletingId(id)}
                                    applications={applications}/>

            {deletingId != null &&
                <ActionModal onNo={() => setDeletingId(undefined)}
                             onYes={onDelete}
                             closeOnSuccess
                             critical>
                    {t("domain.inviteApplications.messages.warnings.deletion")}
                </ActionModal>
            }
        </>
    }

    async function onDelete() {
        if (deletingId == null)
            return

        await deleteInviteApplicationById(deletingId)

        setApplications(oldApplications => oldApplications.filter(({ id }) => id !== deletingId))
    }
}
