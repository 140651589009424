import { z } from "zod"
import { UuidSchema } from "my-util"

export const LegalDocumentMessageSchema = z.object({
    number: z.string(),
    date: z.coerce.date(),
    documentId: UuidSchema.nullish(),
})

export type LegalDocumentMessage = z.infer<typeof LegalDocumentMessageSchema>
