import { countEntities, createApiError, isEntityExists,
         BASE_URL, throwIfResponseNotOk, get, del, tryDeleteEntity } from "api/http/util"

import { DocumentResponseSchema } from "api/response"
import { Document } from "model"

export const DOCUMENTS_SUBPATH = "/documents"

export async function countDocuments(signal?: AbortSignal | null): Promise<number> {
    return countEntities(DOCUMENTS_SUBPATH, signal)
}

export async function countMyDocuments(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${DOCUMENTS_SUBPATH}/my`, signal)
}

export async function isDocumentExistsById(id: string, signal?: AbortSignal | null) {
    return isEntityExists(`${DOCUMENTS_SUBPATH}/${id}`, signal)
}

export async function getDocumentById(id: string, signal?: AbortSignal | null): Promise<Document> {
    return new Document(await get({
        subpath: `${DOCUMENTS_SUBPATH}/${id}`,
        schema: DocumentResponseSchema,
        signal,
    }))
}

export async function getDocumentDataById(id: string, signal?: AbortSignal | null): Promise<Blob> {
    const url = createDocumentDownloadUrlById(id)
    const response = await fetch(url, { signal })

    await throwIfResponseNotOk(response)

    return response.blob()
}

export function downloadDocumentById(id: string, filename: string = "unknown") {
    const a = document.createElement("a")
    const url = createDocumentDownloadUrlById(id)

    a.href = url
    a.download = filename

    a.click()
    a.remove()
}

export function createDocumentDownloadUrlById(id: string): string {
    return `${BASE_URL}${DOCUMENTS_SUBPATH}/${id}/data`
}

export async function getAllDocuments(signal?: AbortSignal | null): Promise<Document[]> {
    return (await get({
        subpath: DOCUMENTS_SUBPATH,
        schema: DocumentResponseSchema.array(),
        signal,
    })).map(response => new Document(response))
}

export async function getAllMyDocuments(signal?: AbortSignal | null): Promise<Document[]> {
    return (await get({
        subpath: `${DOCUMENTS_SUBPATH}/my`,
        schema: DocumentResponseSchema.array(),
        signal,
    })).map(response => new Document(response))
}

export async function clearDocuments(signal?: AbortSignal | null) {
    return del({
        subpath: DOCUMENTS_SUBPATH,
        signal,
    })
}

export async function clearMyDocuments(signal?: AbortSignal | null) {
    return del({
        subpath: `${DOCUMENTS_SUBPATH}/my`,
        signal,
    })
}

export async function deleteDocumentById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: `${DOCUMENTS_SUBPATH}/${id}`,
        signal,
    })
}

export async function tryDeleteDocumentById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return tryDeleteEntity(`${DOCUMENTS_SUBPATH}/${id}`, signal)
}

export interface UploadDocumentsOptions {
    onProgress?: (loaded: number, total: number) => void
    onAbort?: () => void
    onError?: (error: unknown) => void
    onSuccess?: (documents: Document[]) => void
}

export interface UploadDocumentsResult {
    response: Promise<Document[] | null>
    abort: () => void
}

export function uploadDocuments(
    files: File[],
    options: Readonly<UploadDocumentsOptions> = {},
): UploadDocumentsResult {
    const formData = new FormData()

    for (const file of files)
        formData.append("files", file, file.name)

    const request = new XMLHttpRequest()

    request.responseType = "text"

    request.open("POST", BASE_URL + DOCUMENTS_SUBPATH)

    const { onProgress, onAbort, onError, onSuccess } = options

    if (onProgress)
        request.upload.onprogress = event => {
            if (event.lengthComputable)
                onProgress(event.loaded, event.total)
        }

    return {
        abort() { request.abort() },

        response: new Promise((resolve, reject) => {
            const notifyError = (error: unknown) => {
                onError?.(error)
                reject(error)
            }

            request.onerror = () => {
                const error = createApiError(
                    request.status,
                    request.statusText,
                    "Failed to perform a request"
                )

                notifyError(error)
            }

            request.onabort = () => {
                onAbort?.()
                resolve(null)
            }

            request.onload = () => {
                try {
                    if (request.status < 200 || request.status >= 300)
                        throw createApiError(
                            request.status,
                            request.statusText,
                            request.responseText,
                        )

                    const json = JSON.parse(request.responseText)
                    const allParsedDocuments = DocumentResponseSchema.array().parse(json)
                    const documents = allParsedDocuments.map(parsedDocument => new Document(parsedDocument))

                    onSuccess?.(documents)
                    resolve(documents)
                } catch (error) {
                    notifyError(error)
                }
            }

            request.send(formData)
        }),
    }
}
