import { ForwardedRef, forwardRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getMfaOtp, getMfaOtpEndpointStatus } from "api"
import style from "./style.module.css"

const Otp = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
    const [t] = useTranslation()

    // State

    const [show, setShow] = useState(false)
    const [otp, setOtp] = useState(null as string | null)

    // Effects

    // - Endpoint status checking

    useEffect(() => {
        const controller = new AbortController()

        getMfaOtpEndpointStatus(controller.signal)
            .then(status => setShow(status.isEnabled))
            .catch(error => {
                if (!controller.signal.aborted)
                    console.error(error)
            })

        return () => controller.abort()
    }, [])

    // - Code fetching

    useEffect(() => {
        if (!show)
            return

        const controller = new AbortController()

        getMfaOtp(controller.signal)
            .then(setOtp)
            .catch(error => {
                if (!controller.signal.aborted)
                    console.error(error)
            })

        return () => controller.abort()
    }, [show])

    // Render

    if (!show || otp == null)
        return null

    return <div className={style.otp}
                ref={ref}>
        <p>{t("test.messages.warnings.test")}</p>
        <p>{t("auth.mfa.messages.yourOtp", { otp })}</p>
    </div>
})

Otp.displayName = "Otp"

export default Otp
