import { t } from "i18next"
import { Transfer, TransfersComparator, TransferStatus } from "model"
import { map } from "my-util/iterable"

// Group

export type TransferGroup =
    | "new"
    | "in-work"
    | "today"
    | "done"
    | "rejected"

export function getTransferGroup(transfer: Transfer): TransferGroup {
    if (transfer.isToday)
        return "today"

    switch (transfer.status) {
        case "new":
            return "new"

        case "waiting-moment":
        case "waiting-payment":
        case "paid":
            return "in-work"

        case "done":
            return "done"

        case "payment-expired":
        case "rejected":
            return "rejected"
    }
}

export function transferGroupToString(group: TransferGroup): string {
    return t(`domain.transfers.groups.${group}`)
}

export function isTransferGroup(s: string): s is TransferGroup {
    switch (s) {
        case "new":
        case "in-work":
        case "today":
        case "done":
        case "rejected":
            s satisfies TransferGroup
            return true

        default:
            return false
    }
}

// Presence

export interface TransferGroupPresence {
    newPresent: boolean
    inWorkPresent: boolean
    todayPresent: boolean
    donePresent: boolean
    rejectedPresent: boolean
}

export function checkTransferGroupPresence(transfers: Iterable<Transfer>): TransferGroupPresence {
    const groups = map(transfers, getTransferGroup)

    return {
        newPresent: groups.some(group => group === "new"),
        inWorkPresent: groups.some(group => group === "in-work"),
        todayPresent: groups.some(group => group === "today"),
        donePresent: groups.some(group => group === "done"),
        rejectedPresent: groups.some(group => group === "rejected"),
    }
}

// Colors

export interface TransferGroupColors {
    backgroundColor: string
    color: string
}

export const NEW_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#FFF0F7",
    color: "orange",
}

export const IN_WORK_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#FEEAE9",
    color: "blue",
}

export const TODAY_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#E5F9DE",
    color: "red",
}

export const DONE_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#D2EBFF",
    color: "lime",
}

export const REJECTED_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "white",
    color: "gray",
}

export function getTransferGroupColorsByTransfer(transfer: Transfer): TransferGroupColors {
    return getTransferGroupColors(getTransferGroup(transfer))
}

export function getTransferGroupColors(group: TransferGroup): TransferGroupColors {
    switch (group) {
        case "new":
            return NEW_TRANSFER_GROUP_COLORS

        case "in-work":
            return IN_WORK_TRANSFER_GROUP_COLORS

        case "today":
            return TODAY_TRANSFER_GROUP_COLORS

        case "done":
            return DONE_TRANSFER_GROUP_COLORS

        case "rejected":
            return REJECTED_TRANSFER_GROUP_COLORS

        default:
            return group satisfies never
    }
}

// Sorting order

export type TransfersByGroupSortingOrder = TransferStatus[]

export const NEW_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    "new",
    "waiting-moment",
    "waiting-payment",
    "paid",
    "done",
    "rejected",
    "payment-expired",
]

export const IN_WORK_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    "waiting-moment",
    "waiting-payment",
    "paid",
    "new",
    "done",
    "rejected",
    "payment-expired",
]

export const TODAY_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = IN_WORK_TRANSFERS_BY_GROUP_SORTING_ORDER

export const DONE_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    "done",
    "new",
    "waiting-moment",
    "waiting-payment",
    "paid",
    "rejected",
    "payment-expired",
]

export const REJECTED_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    "rejected",
    "payment-expired",
    "new",
    "waiting-moment",
    "waiting-payment",
    "paid",
    "done",
]

export function getTransfersByGroupSortingOrderComparator(
    groupOrOrder: TransferGroup | Readonly<TransfersByGroupSortingOrder>,
): TransfersComparator {
    const order = typeof groupOrOrder === "string"
        ? getTransfersByGroupSortingOrder(groupOrOrder)
        : groupOrOrder

    return groupOrOrder === "today"
        ? todayComparator
        : defaultComparator

    function todayComparator(lhs: Transfer, rhs: Transfer): number {
        const lhsToday = lhs.isToday
        const rhsToday = rhs.isToday

        if (lhsToday === rhsToday)
            return defaultComparator(lhs, rhs)

        if (lhsToday)
            return -1

        if (rhsToday)
            return 1

        return 0
    }

    function defaultComparator(lhs: Transfer, rhs: Transfer): number {
        return Math.sign(order.indexOf(lhs.status) - order.indexOf(rhs.status))
    }
}

export function getTransfersByGroupSortingOrder(group: TransferGroup) {
    switch (group) {
        case "new":
            return NEW_TRANSFERS_BY_GROUP_SORTING_ORDER

        case "in-work":
            return IN_WORK_TRANSFERS_BY_GROUP_SORTING_ORDER

        case "today":
            return TODAY_TRANSFERS_BY_GROUP_SORTING_ORDER

        case "done":
            return DONE_TRANSFERS_BY_GROUP_SORTING_ORDER

        case "rejected":
            return REJECTED_TRANSFERS_BY_GROUP_SORTING_ORDER

        default:
            return group satisfies never
    }
}
