import { ForwardedRef, forwardRef, ReactNode } from "react"
import { crossIconUrl } from "image"
import { DeepReadonly } from "my-util"
import { Flex, Center } from "ui/ui/layout"
import Button, { ButtonProps } from "ui/ui/Button"
import { Form } from "ui/ui/form"
import Dim from "ui/ui/Dim"
import style from "./style.module.css"

export interface ModalProps {
    onClose?: () => void

    header?: string
    children?: ReactNode
    buttons?: ModalButton[]

    loading?: boolean
    disabled?: boolean

    opacity?: number
    width?: string
}

export interface ModalButton extends ButtonProps {}

const Modal = forwardRef((
    {
        onClose,
        header, children, buttons,
        loading, disabled,
        opacity, width
    }: DeepReadonly<ModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <div className={style.Modal}
                ref={ref}>
        <Dim onClick={onInnerClose}
             opacity={opacity}/>

        <Center type="absolute">
            <div className={style.container}
                 style={{ width }}>
                <Form loading={loading}>
                    {renderHeader()}
                    {renderContent()}
                    {renderButtons()}
                </Form>
            </div>
        </Center>
    </div>

    function renderHeader(): ReactNode {
        return <div className={style.header}>
            <Flex direction="horizontal"
                  justify="space-between">
                <div className={style.headerText}>
                    {header}
                </div>

                <Button buttonStyle="text"
                        width="fit-content"
                        loading={loading}
                        disabled={disabled}
                        iconSrc={crossIconUrl}
                        iconAlt="Cross icon"
                        onClick={onInnerClose}/>
            </Flex>
        </div>
    }

    function renderContent(): ReactNode {
        return <div className={style.content}>
            {children}
        </div>
    }

    function renderButtons(): ReactNode {
        if (!buttons?.length)
            return null

        return <div className={style.buttons}>
            <Flex direction="horizontal"
                  justify="end"
                  gap="8px">
                {buttons.map((button, i) =>
                    <Button width="fit-content"
                            loading={loading}
                            disabled={disabled}
                            {...button}
                            key={i}/>
                )}
            </Flex>
        </div>
    }

    // Events

    function onInnerClose() {
        if (!loading && !disabled)
            onClose?.()
    }
})

Modal.displayName = "Modal"

export default Modal
