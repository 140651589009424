import { ForwardedRef, forwardRef, useMemo } from "react"
import { sandClockIconUrl, tickIconUrl } from "image"
import { getLang } from "i18n"
import { TransferStatus, transferStatusToString } from "model"
import { DeepReadonly } from "my-util"
import { FlexDirection } from "ui/ui/layout"

import PhaseIndicator,
       { Phase, PhaseIcon, PhaseSize, PhaseStateStyles, PhaseType } from "ui/ui/PhaseIndicator"

export interface TransferPhaseIndicatorProps {
    status: TransferStatus

    indicatorStyle?: TransferPhaseIndicatorStyle
    mobile?: boolean

    size?: PhaseSize
    sepLength?: string

    direction?: FlexDirection

    label?: string
    labelFontSize?: string
}

export type TransferPhaseIndicatorStyle =
    | "mini"
    | "full"

const TransferPhaseIndicator = forwardRef((
    props: Readonly<TransferPhaseIndicatorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        status,
        indicatorStyle,
        size, sepLength,
    } = props

    // State

    const miniStyle = useMemo<PhaseStateStyles>(() => ({
        fallback: {
            backgroundColor: "#97C776",
            borderColor: "#97C776",
            color: "white",
            fontSize: "16px",
            size,
        },

        future: {
            backgroundColor: "#F84235",
            borderColor: "#F84235",
        },
    }), [size])

    const fullStyle = useMemo<PhaseStateStyles>(() => ({
        fallback: {
            fontSize: "16px",
            size,
        },

        current: {
            fontSize: "18px",
        },
    }), [size])

    const phases = useMemo<DeepReadonly<Phase[]>>(
        () => indicatorStyle === "mini"
            ? [
                {
                    label: transferStatusToString("new"),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },

                {
                    label: transferStatusToString("waiting-moment"),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },

                {
                    label: transferStatusToString("waiting-payment"),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },

                {
                    label: transferStatusToString("paid"),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },

                {
                    label: transferStatusToString("done"),
                    icon: renderMiniIcon,
                    style: miniStyle,
                    text: "",
                },
            ]

            : [
                {
                    text: transferStatusToString("new"),
                    style: fullStyle,
                },

                {
                    text: transferStatusToString("waiting-moment"),
                    style: fullStyle,
                },

                {
                    text: transferStatusToString("waiting-payment"),
                    style: fullStyle,
                },

                {
                    text: transferStatusToString("paid"),
                    style: fullStyle,
                },

                {
                    text: transferStatusToString("done"),
                    style: fullStyle,
                },
            ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fullStyle, indicatorStyle, miniStyle, getLang()],
    )

    // Render

    return <PhaseIndicator { ...props }
                           phases={phases}
                           current={getCurrent()}
                           sepLength={sepLength}
                           ref={ref}/>

    function renderMiniIcon(type: PhaseType): PhaseIcon {
        return type === "future"
            ? {
                src: sandClockIconUrl,
                alt: "Sand clock icon",
            }

            : {
                src: tickIconUrl,
                alt: "Tick icon",
            }
    }

    // Util

    function getCurrent(): number {
        switch (status) {
            case "new":
                return 0

            case "waiting-moment":
                return 1

            case "waiting-payment":
                return 2

            case "paid":
                return 3

            case "done":
                return 4

            case "payment-expired":
                return -1

            case "rejected":
                return -1
        }
    }
})

TransferPhaseIndicator.displayName = "TransferPhaseIndicator"

export default TransferPhaseIndicator
