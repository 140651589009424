import { z } from "zod"
import { AnonymousChatMessageResponseSchema } from "./anonymous-message"
import { DeleteChatMessagesResponseSchema } from "./delete-messages"
import { ChatInfoResponseSchema } from "./info"
import { MarkChatMessagesReadResponseSchema } from "./mark-messages-read"
import { ChatMessageListResponseSchema } from "./message-list"
import { ChatMessageResponseSchema } from "./message"
import { ChatRequestVerificationResponseSchema } from "./request-verification"

export const ChatResponseSchema = ChatMessageListResponseSchema
    .or(AnonymousChatMessageResponseSchema)
    .or(DeleteChatMessagesResponseSchema)
    .or(ChatMessageResponseSchema)
    .or(ChatRequestVerificationResponseSchema)
    .or(MarkChatMessagesReadResponseSchema)
    .or(ChatInfoResponseSchema)

export type ChatResponse = z.infer<typeof ChatResponseSchema>
