import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

const Copyright = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) =>
    <div className={style.Copyright}
         ref={ref}>
        ManyAir © 2024
    </div>
)

Copyright.displayName = "Copyright"

export default Copyright
