import { ForwardedRef, forwardRef, useState } from "react"
import Lang from "i18n/Lang"
import style from "./style.module.css"

export interface LangSelectorProps {
    onSelect?: (lang: Lang) => void
    lang?: Lang
}

const LangSelector = forwardRef((
    {
        onSelect,
        lang,
    }: Readonly<LangSelectorProps>,
    ref: ForwardedRef<HTMLButtonElement>,
) => {
    const [innerLang, setInnerLang] = useState(lang ?? "ru")

    return <button className={style.LangSelector}
                   onClick={onClick}
                   ref={ref}>
        {getText()}
    </button>

    function onClick() {
        const newInnerLang = innerLang === "ru" ? "en" : "ru"

        setInnerLang(newInnerLang)
        onSelect?.(newInnerLang)
    }

    function getText() {
        switch (innerLang) {
            case "ru":
                return "En"

            case "en":
                return "Ру"

            default:
                return innerLang satisfies never
        }
    }
})

LangSelector.displayName = "LangSelector"

export default LangSelector
