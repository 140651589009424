export function isDigits(s: string): boolean {
    for (const c of s)
        if (!isDigit(c))
            return false

    return true
}

export function isDigit(s: string): boolean {
    return "0" <= s && s <= "9"
}

export function isNullOrBlank(s: string | undefined | null): boolean {
    return s == null || isBlank(s)
}

export function isBlank(s: string): boolean {
    return s.trim().length === 0
}
