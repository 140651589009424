import { ReactElement, ForwardedRef, forwardRef } from "react"
import { formatPhone, hideLastNDigits } from "my-util"
import { Link } from "ui/ui/link"
import style from "./style.module.css"

const TO_HIDE = 6

export interface PhoneProps {
    phone: string

    noFormat?: boolean
    noLink?: boolean
    hide?: boolean
    hidePlus?: boolean

    stopLinkClickPropagation?: boolean
}

const Phone = forwardRef((
    {
        phone,
        noFormat, noLink, hide, hidePlus,
        stopLinkClickPropagation,
    }: Readonly<PhoneProps>,
    ref: ForwardedRef<HTMLElement>,
) => {
    // Render

    return noLink
        ? renderSpan()
        : renderLink()

    function renderSpan(): ReactElement {
        return <span className={style.Phone}
                     ref={ref}>
            {preparePhone(phone)}
        </span>
    }

    function renderLink(): ReactElement {
        return <Link stopClickPropagation={stopLinkClickPropagation}
                     to={`tel:${phone}`}
                     text={preparePhone(phone)}
                     ref={ref as any}/>
    }

    // Util

    function preparePhone(phone: string): string {
        phone = formatIfNeeded(phone)
        phone = hideIfNeeded(phone)
        phone = hidePlusIfNeeded(phone)

        return phone
    }

    function formatIfNeeded(phone: string): string {
        return noFormat
            ? phone
            : formatPhone(phone)
    }

    function hideIfNeeded(phone: string): string {
        return hide
            ? hideLastNDigits(phone, TO_HIDE)
            : phone
    }

    function hidePlusIfNeeded(phone: string): string {
        return hidePlus
            ? phone.replace("+", "")
            : phone
    }
})

Phone.displayName = "Phone"

export default Phone
