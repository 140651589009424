import { DAY_HOURS } from "./consts"

export const HOURS: readonly number[] = new Array(DAY_HOURS)
    .fill(0)
    .map((_, i) => i + 1)

export const HOUR_STRINGS: readonly string[] = HOURS.map(hour => {
    let hourString = `${hour}:00`

    if (hourString.length === 4)
        hourString = "0" + hourString

    return hourString
})
