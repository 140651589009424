import { ForwardedRef, forwardRef } from "react"
import { clockIconUrl } from "image"
import { dateToTimeString, DeepReadonly } from "my-util"
import Output, { OutputProps } from "ui/ui/Output"

export interface TimeOutputProps extends Omit<OutputProps, "children"> {
    date: Date
}

const TimeOutput = forwardRef((
    props: DeepReadonly<TimeOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output iconSrc={clockIconUrl}
            iconAlt="Clock icon"
            iconFilter="brightness(0) saturate(100%) invert(71%) sepia(5%) saturate(5137%) hue-rotate(183deg) brightness(68%) contrast(104%)"
            overflow="hidden"
            height="32px"
            {...props as TimeOutputProps}
            ref={ref}>
        {dateToTimeString(props.date)}
    </Output>
)

TimeOutput.displayName = "TimeOutput"

export default TimeOutput
