import { Named, WithNamedCreator } from "model/interfaces"
import { NamedMixin, NamedMixinCopyOptions, NamedMixinCreationOptions } from "model/mixins"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, map, Nullish, tryNormalizeUuid } from "my-util"

export interface AnonymousChatMessageCreationOptions extends AbstractModelObjectCreationOptions {
    creator: NamedMixin | NamedMixinCreationOptions
    text?: string | null
    documentIds?: string[] | null
    readByIds?: Iterable<string> | null
    edited?: boolean | null
}

export interface AnonymousChatMessageCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<Omit<AnonymousChatMessageCreationOptions, "creator">>
{
    creator?: NamedMixin | NamedMixinCopyOptions | null
}

export default class AnonymousChatMessage
    extends
        AbstractModelObject

    implements
        DeepReadonly<WithNamedCreator>
{
    static createOrPass(
        arg: AnonymousChatMessage | Readonly<AnonymousChatMessageCreationOptions>,
    ): AnonymousChatMessage {
        return arg instanceof AnonymousChatMessage
            ? arg
            : new AnonymousChatMessage(arg)
    }

    readonly text: string | null
    readonly documentIds: readonly string[]
    readonly readByIds: ReadonlySet<string>
    readonly edited: boolean

    private readonly creatorMixin: NamedMixin

    constructor(options: DeepReadonly<AnonymousChatMessageCreationOptions>) {
        super(options)

        this.text = options.text ?? null
        this.documentIds = options.documentIds?.map(tryNormalizeUuid) ?? []
        this.readByIds = new Set(map(options.readByIds ?? [], tryNormalizeUuid))
        this.edited = options.edited ?? false

        this.creatorMixin = NamedMixin.createOrPass(options.creator)
    }

    get creator(): Readonly<Named> {
        return this.creatorMixin
    }

    override copyOrPass(
        arg?: AnonymousChatMessage
            | Readonly<AnonymousChatMessageCopyOptions>
            | null,
    ): AnonymousChatMessage {
        if (arg == null)
            return this

        if (arg instanceof AnonymousChatMessage)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<AnonymousChatMessageCopyOptions> = {}): AnonymousChatMessage {
        return new AnonymousChatMessage({
            creator: this.creatorMixin.copyOrPass(options.creator),

            text: options.text !== undefined
                ? options.text
                : this.text,

            documentIds: options.documentIds ?? this.documentIds,
            readByIds: options.readByIds ?? this.readByIds,
            edited: options.edited ?? false,

            // ModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
