import { getLang } from "i18n"

export interface BankCreationOptions {
    ruName?: string | null
    enName?: string | null

    ruAddress?: string | null
    enAddress?: string | null

    swift?: string | null
    itn?: string | null
    bic?: string | null
}

export interface BankCopyOptions extends BankCreationOptions {}

export default class Bank {
    static createOrPass(arg?: Bank | Readonly<BankCreationOptions> | null): Bank {
        if (arg == null)
            return new Bank()

        if (arg instanceof Bank)
            return arg

        return new Bank(arg)
    }

    readonly itn: string | null

    readonly ruName: string | null
    readonly enName: string | null

    readonly ruAddress: string | null
    readonly enAddress: string | null

    readonly swift: string | null
    readonly bic: string | null

    constructor(options: Readonly<BankCreationOptions> = {}) {
        this.itn = options.itn ?? null

        this.ruName = options.ruName ?? null
        this.enName = options.enName ?? null

        this.ruAddress = options.ruAddress ?? null
        this.enAddress = options.enAddress ?? null

        this.swift = options.swift ?? null
        this.bic = options.bic ?? null
    }

    // Name

    get anyName(): string | null {
        return getLang() === "ru"
            ? this.ruName ?? this.enName
            : this.enName ?? this.ruName
    }

    get name(): string | null {
        return getLang() === "ru"
            ? this.ruName
            : this.enName
    }

    // Address

    get anyAddress(): string | null {
        return getLang() === "ru"
            ? this.ruAddress ?? this.enAddress
            : this.enAddress ?? this.ruAddress
    }

    get address(): string | null {
        return getLang() === "ru"
            ? this.ruAddress
            : this.enAddress
    }

    // Copy

    copyOrPass(arg?: Bank | Readonly<BankCopyOptions> | null): Bank {
        if (arg == null)
            return this

        if (arg instanceof Bank)
            return arg

        return this.copy(arg)
    }

    copy(options: Readonly<BankCopyOptions> = {}): Bank {
        return new Bank({
            itn: options.itn !== undefined
                ? options.itn
                : this.itn,

            ruName: options.ruName !== undefined
                ? options.ruName
                : this.ruName,

            enName: options.enName !== undefined
                ? options.enName
                : this.enName,

            ruAddress: options.ruAddress !== undefined
                ? options.ruAddress
                : this.ruAddress,

            enAddress: options.enAddress !== undefined
                ? options.enAddress
                : this.enAddress,

            swift: options.swift !== undefined
                ? options.swift
                : this.swift,

            bic: options.bic !== undefined
                ? options.bic
                : this.bic,
        })
    }
}
