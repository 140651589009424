import { getLang } from "i18n"
import { DeepReadonly } from "my-util"
import Bank, { BankCopyOptions, BankCreationOptions } from "./Bank"

export interface CompanyCreationOptions {
    itn?: string | null

    ruName?: string | null
    enName?: string | null

    ruAddress?: string | null
    enAddress?: string | null

    bank?: Bank | BankCreationOptions | null

    correspondentAccount?: string | null
    paymentAccount?: string | null
}

export interface CompanyCopyOptions extends Omit<CompanyCreationOptions, "bank"> {
    bank?: Bank | BankCopyOptions | null
}

export default class Company {
    static createOrPass(arg?: Company | DeepReadonly<CompanyCreationOptions> | null): Company {
        if (arg == null)
            return new Company()

        if (arg instanceof Company)
            return arg

        return new Company(arg)
    }

    readonly itn: string | null

    readonly ruName: string | null
    readonly enName: string | null

    readonly ruAddress: string | null
    readonly enAddress: string | null

    readonly bank: Bank

    readonly correspondentAccount: string | null
    readonly paymentAccount: string | null

    constructor(options: DeepReadonly<CompanyCreationOptions> = {}) {
        this.itn = options.itn ?? null

        this.ruName = options.ruName ?? null
        this.enName = options.enName ?? null

        this.ruAddress = options.ruAddress ?? null
        this.enAddress = options.enAddress ?? null

        this.bank = Bank.createOrPass(options.bank)

        this.correspondentAccount = options.correspondentAccount ?? null
        this.paymentAccount = options.paymentAccount ?? null
    }

    // Name

    get anyName(): string | null {
        return getLang() === "ru"
            ? this.ruName ?? this.enName
            : this.enName ?? this.ruName
    }

    get name(): string | null {
        return getLang() === "ru"
            ? this.ruName
            : this.enName
    }

    // Address

    get anyAddress(): string | null {
        return getLang() === "ru"
            ? this.ruAddress ?? this.enAddress
            : this.enAddress ?? this.ruAddress
    }

    get address(): string | null {
        return getLang() === "ru"
            ? this.ruAddress
            : this.enAddress
    }

    // Copy

    copyOrPass(arg?: Company | DeepReadonly<CompanyCopyOptions> | null): Company {
        if (arg == null)
            return this

        if (arg instanceof Company)
            return arg

        return this.copy(arg)
    }

    copy(options: DeepReadonly<CompanyCopyOptions> = {}): Company {
        return new Company({
            itn: options.itn !== undefined
                ? options.itn
                : this.itn,

            ruName: options.ruName !== undefined
                ? options.ruName
                : this.ruName,

            enName: options.enName !== undefined
                ? options.enName
                : this.enName,

            ruAddress: options.ruAddress !== undefined
                ? options.ruAddress
                : this.ruAddress,

            enAddress: options.enAddress !== undefined
                ? options.enAddress
                : this.enAddress,

            bank: this.bank.copyOrPass(options.bank),

            correspondentAccount: options.correspondentAccount !== undefined
                ? options.correspondentAccount
                : this.correspondentAccount,

            paymentAccount: options.paymentAccount !== undefined
                ? options.paymentAccount
                : this.paymentAccount,
        })
    }
}
