export const UUID_REGEX = /^([0-9a-f]{1,8})-([0-9a-f]{1,4})-([0-9a-f]{1,4})-([0-9a-f]{1,4})-([0-9a-f]{1,12})$/i
export const EMAIL_REGEX = /^[\w\-.]+@[\w\-.]+$/g

export const RU_NAME_INPUT_REGEX = /^[а-я\s]*$/iug
export const EN_NAME_INPUT_REGEX = /^[a-z\s]*$/iug
export const DECIMAL_INPUT_REGEX = /^[\d\s]*(\d\s*[,.][\d\s]*)?$/g
export const TIME_INPUT_REGEX = /^\s*\d?(\d(\s*:\s*\d{0,2})?)?\s*$/g
export const DATE_INPUT_REGEX = /^\s*\d?(\d(\s*\.\s*\d?(\d\s*(\.\s*\d{0,4})?)?)?)?\s*$/g
export const SHORT_DATE_INPUT_REGEX = /^\s*\d?(\d(\s*\.\s*\d?(\d\s*(\.\s*\d{0,2})?)?)?)?\s*$/g
export const COMMA_WITH_WHITESPACE_REGEX = /\s*,\s*/
export const LEGAL_ITN_INPUT_REGEX = /^\d{0,10}$/ig
export const PHYSICAL_ITN_INPUT_REGEX = /^\d{0,12}$/ig
export const SWIFT_INPUT_REGEX = /^([A-Z]{0,5}|[A-Z]{6}[A-Z0-9]{0,5})$/ig
export const BIC_INPUT_REGEX = /^\d{0,9}$/ig
export const IBAN_INPUT_REGEX = /^\w{0,35}$/ig
export const CORRESPONDENT_ACCOUNT_INPUT_REGEX = /^\d{0,20}$/ig
export const PAYMENT_ACCOUNT_INPUT_REGEX = /^\w{0,20}$/ig
