import { CSSProperties, ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { ApiError, NetworkError } from "api"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"


export interface ErrorTextProps {
    error?: unknown

    textAlign?: CSSProperties["textAlign"]
    fontSize?: string
    fontWeight?: string

    apiErrorMessageMapping?: ErrorTextApiErrorMessageMapping
    doNotUseDefaultApiErrorMessageMapping?: boolean
}

export interface ErrorTextApiErrorMessageMapping {
    [statusCode: number]: string | undefined
}

const ErrorText = forwardRef((
    {
        error,
        textAlign, fontSize, fontWeight,
        apiErrorMessageMapping, doNotUseDefaultApiErrorMessageMapping,
    }: DeepReadonly<ErrorTextProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    if (error instanceof ApiError)
        error = apiErrorMessageMapping?.[error.statusCode]
            ?? defaultApiErrorMessageMapping(error.statusCode)
            ?? error

    if (error instanceof NetworkError)
        error = t("errors.networkError")

    const display = error
        ? undefined
        : "none"

    const message = error instanceof Error
        ? error.message
        : String(error)

    return <span className={style.ErrorText}
                 style={{ display, textAlign, fontSize, fontWeight }}
                 ref={ref}>
        {message}
    </span>

    function defaultApiErrorMessageMapping(statusCode: number): string | undefined {
        return doNotUseDefaultApiErrorMessageMapping
            ? undefined
            : t([`errors.${statusCode}` as any, "errors.unspecific"])
    }
})

ErrorText.displayName = "ErrorText"

export default ErrorText
