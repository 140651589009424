import { ForwardedRef, forwardRef, ReactNode, useEffect, useRef } from "react"
import { plusIconUrl, trashCanIconUrl } from "image"
import { DeepReadonly, splicedArray } from "my-util"
import { copyRoutePointFields, RoutePointFields } from "ui/fields"
import { useStateWithDeps } from "ui/hook"
import { Button, Flex } from "ui/ui"
import RoutePointEditor from "../RoutePointEditor"
import style from "./style.module.css"

export interface RoutePointListEditorProps {
    onChange?: (routePoints: RoutePointFields[]) => void
    routePoints?: RoutePointFields[]

    validateCountry?: (country: string, entering: boolean) => boolean

    noDocumentDelete?: boolean

    loading?: boolean
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    output?: boolean

    width?: string
}

const RoutePointListEditor = forwardRef((
    {
        onChange, routePoints,
        validateCountry,
        noDocumentDelete,
        loading, disabled, readonly, required, output,
        width,
    }: DeepReadonly<RoutePointListEditorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const changedRef = useRef(false)

    const [innerRoutePoints, setInnerRoutePoints] = useStateWithDeps(
        (): RoutePointFields[] => {
            if (routePoints == null || routePoints.length === 0) {
                changedRef.current = true
                return [createEmptyRoutePoint()]
            }

            return routePoints.map(copyRoutePointFields)
        },
        [routePoints],
    )

    // Changes propagation

    useEffect(() => {
        if (onChange != null && changedRef.current) {
            onChange(innerRoutePoints.map(copyRoutePointFields))
            changedRef.current = false
        }
    }, [onChange, innerRoutePoints])

    // Render

    return <Flex width={width}
                 ref={ref}>
        {renderAllRoutePoints()}
    </Flex>

    function renderAllRoutePoints(): ReactNode {
        return output || readonly
            ? renderImmutableRoutePoints()
            : renderMutableRoutePoints()
    }

    function renderImmutableRoutePoints(): ReactNode {
        return innerRoutePoints.map(renderRoutePoint)
    }

    function renderMutableRoutePoints(): ReactNode {
        return innerRoutePoints.map((routePoint, i) =>
            <div className={style.routePoint}
                 key={i}>
                {renderRoutePoint(routePoint, i)}
                {renderDeleteButton(i)}

                {i === innerRoutePoints.length - 1 &&
                    renderAddButton()
                }
            </div>
        )
    }

    function renderRoutePoint(routePoint: RoutePointFields, routePointIndex: number): ReactNode {
        return <RoutePointEditor
            onChange={newRoutePoint => onRoutePointChange(newRoutePoint, routePointIndex)}
            routePoint={routePoint}

            validateCountry={validateCountry}

            noDocumentDelete={noDocumentDelete}

            loading={loading}
            disabled={disabled}
            readonly={readonly}
            required={required}
            output={output}

            key={routePointIndex}
        />
    }

    function renderDeleteButton(routePointIndex: number): ReactNode {
        return <div className={style.delete}>
            <Button onClick={() => onDelete(routePointIndex)}
                    disabled={innerRoutePoints.length <= 1}
                    iconSrc={trashCanIconUrl}
                    iconAlt="Trash can icon"
                    width="fit-content"
                    buttonStyle="outline"
                    critical/>
        </div>
    }

    function renderAddButton(): ReactNode {
        return <div className={style.add}>
            <Button onClick={onAdd}
                    iconSrc={plusIconUrl}
                    iconAlt="Plus icon"
                    width="fit-content"/>
        </div>
    }

    // Events

    function onRoutePointChange(routePoint: RoutePointFields, i: number) {
        setInnerRoutePoints(oldInnerRoutePoints => {
            changedRef.current = true
            return splicedArray(oldInnerRoutePoints, i, 1, routePoint)
        })
    }

    function onDelete(i: number) {
        setInnerRoutePoints(oldInnerRoutePoints => {
            changedRef.current = true
            return splicedArray(oldInnerRoutePoints, i, 1)
        })
    }

    function onAdd() {
        setInnerRoutePoints(oldInnerRoutePoints => {
            changedRef.current = true
            return [...oldInnerRoutePoints, createEmptyRoutePoint()]
        })
    }

    // Util

    function createEmptyRoutePoint(): RoutePointFields {
        return {
            country: "",
            type: "earth",
            documents: [],
        }
    }
})

RoutePointListEditor.displayName = "RoutePointListEditor"

export default RoutePointListEditor
