import { useState } from "react"
import { Center, Pane } from "ui/ui"
import Message from "./Message"
import CreationForm from "./CreationForm"
import SuccessNotification from "./SuccessNotification"

type State =
    | "show-message"
    | "phone-mfa"
    | "success"

export default function InviteApplicationPane() {
    const [state, setState] = useState("show-message" satisfies State as State)

    return <Pane>
        {renderContent()}
    </Pane>

    function renderContent() {
        switch (state) {
            case "show-message":
                return <Center>
                    <Message onCreateInviteApplication={() => setState("phone-mfa")}/>
                </Center>

            case "phone-mfa":
                return <CreationForm onSuccess={() => setState("success")}/>

            case "success":
                return <SuccessNotification/>
        }
    }
}
