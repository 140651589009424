import { t } from "i18next"
import { MessageTargetType } from "model"
import { capitalized, joinGrammatically } from "my-util/string"

export function createMessageTargetInputLabel(types: readonly MessageTargetType[]): string {
    if (types.length === 0)
        return t("messageTargets.messages.noneIsAllowed")

    const typeStrings = types.map(messageTargetTypeToWordString)
    const joinedTypeStrings = joinGrammatically(typeStrings)
    const label = capitalized(joinedTypeStrings)

    return label
}

export function createMessageTargetInputPlaceholder(types: readonly MessageTargetType[]): string {
    const typeStrings = types.map(messageTargetTypeToWordString)
    const joinedTypeStrings = joinGrammatically(typeStrings)
    const placeholder = capitalized(t("misc.words.enter") + " " + joinedTypeStrings)

    return placeholder
}

function messageTargetTypeToWordString(type: MessageTargetType): string {
    return t(`messageTargets.placeholders.words.${type}`)
}
