import { ReadonlyDate } from "my-util/type"

export interface DateTimeFormatOptions extends DateFormatOptions, TimeFormatOptions {}

export function dateToDateTimeString(
    date: ReadonlyDate,
    options: DateTimeFormatOptions = {},
): string {
    return `${dateToDateString(date, options)} ${dateToTimeString(date, options)}`
}

export interface DateFormatOptions {
    date?: boolean
    month?: boolean
    year?: boolean
    fullYear?: boolean
}

export function dateToDateString(date: ReadonlyDate, options: DateFormatOptions = {}): string {
    const parts = new Array<number>()

    if (options.date ?? true)
        parts.push(date.getDate())

    if (options.month ?? true)
        parts.push(date.getMonth() + 1)

    if (options.year ?? true) {
        let year = date.getFullYear()

        if (!(options.fullYear ?? false))
            year %= 100

        parts.push(year)
    }

    return parts
        .map(String)
        .map(part => part.length === 1 ? "0" + part : part)
        .join(".")
}

export interface TimeFormatOptions {
    hours?: boolean
    minutes?: boolean
    seconds?: boolean
}

export function dateToTimeString(date: ReadonlyDate, options: TimeFormatOptions = {}): string {
    const parts = new Array<number>()

    if (options.hours ?? true)
        parts.push(date.getHours())

    if (options.minutes ?? true)
        parts.push(date.getMinutes())

    if (options.seconds ?? false)
        parts.push(date.getSeconds())

    return parts
        .map(String)
        .map(part => part.length === 1 ? "0" + part : part)
        .join(":")
}
