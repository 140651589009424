import { ForwardedRef, forwardRef, ReactElement } from "react"
import Logo from "ui/component/Logo"
import { Flex } from "ui/ui"
import Info from "./Info"
import LogoutButton from "./LogoutButton"
import NotificationsButton from "./NotificationsButton"
import ProfileButton from "./ProfileButton"
import style from "./style.module.css"

export interface HeaderProps {
    type?: HeaderTypeType
}

export type HeaderTypeType =
    | "auth"
    | "main"

const Header = forwardRef((
    { type }: Readonly<HeaderProps>,
    ref: ForwardedRef<HTMLHeadingElement>,
) => {
    const main = type === "main"

    // Render

    return main
        ? renderMain()
        : renderAuth()

    function renderMain(): ReactElement {
        return <header ref={ref}>
            <Flex align="start">
                <Flex justify="space-between"
                      direction="horizontal">
                    <Logo height="fit-content"
                          width="178px"
                          full/>

                    <Flex direction="horizontal"
                          width="fit-content">
                        <NotificationsButton/>
                        <ProfileButton/>
                        <LogoutButton/>
                    </Flex>
                </Flex>

                <div className={style.info}>
                    <Info/>
                </div>
            </Flex>
        </header>
    }

    function renderAuth(): ReactElement {
        return <header>
            <Logo refetchUserOnClick/>
        </header>
    }
})

Header.displayName = "Header"

export default Header
