import { isDigit } from "./class-check"

export function countDigits(phone: string): number {
    let digitCount = 0

    for (const char of phone)
        if (isDigit(char))
            ++digitCount

    return digitCount
}

export function hideLastNDigits(s: string, n: number, replaceWith: string = "*"): string {
    let result = ""

    for (let i = s.length - 1; i >= 0; --i) {
        let char = s[i]

        if (n > 0 && isDigit(char)) {
            char = replaceWith
            --n
        }

        result = char + result
    }

    return result
}
