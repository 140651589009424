import { z } from "zod"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"
import { NotificationTypeResponseSchema } from "./type"

export const NotificationResponseBaseSchema = ModelObjectResponseSchema.extend({
    type: NotificationTypeResponseSchema,
    recipientId: UuidSchema,
})

export type NotificationResponseBase = z.infer<typeof NotificationResponseBaseSchema>
