import assert from "assert"
import { ReactNode, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { isInviteExistsById } from "api"
import { isUuid } from "my-util"
import { Page } from "ui/component"
import { Loading } from "ui/ui"
import RegistrationPane from "./RegistrationPane"
import InviteApplicationPane from "./InviteApplicationPane"
import { REGISTRATION_PAGE_PATH_INVITE_ID_PARAM_NAME } from "./path"

export default function RegistrationPage() {
    const { [REGISTRATION_PAGE_PATH_INVITE_ID_PARAM_NAME]: inviteId } = useParams()
    const badInviteId = inviteId == null || !isUuid(inviteId)

    // State

    const [loading, setLoading] = useState(!badInviteId)
    const [inviteExists, setInviteExists] = useState(false)

    // Invite existence check

    useEffect(() => {
        if (badInviteId)
            return

        const controller = new AbortController()

        isInviteExistsById(inviteId, controller.signal)
            .then(setInviteExists)
            .catch(error => {
                if (!controller.signal.aborted)
                    console.error(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Render

    return <Page type="auth">
        {renderContent()}
    </Page>

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (inviteExists) {
            assert(inviteId != null)
            return <RegistrationPane inviteId={inviteId}/>
        }

        return <InviteApplicationPane/>
    }
}
