import { ForwardedRef, forwardRef } from "react"
import Carousel from "ui/ui/Carousel"

export interface YearCarouselProps {
    onYearSelect?: (year: number) => void
    year?: number

    maxShowYears?: number
    width?: string
}

const YEARS = new Array(3000).fill(0).map((_, i) => i)

const YearCarousel = forwardRef((props: Readonly<YearCarouselProps>, ref: ForwardedRef<HTMLDivElement>) => {
    return <Carousel items={YEARS}
                     onSelect={props.onYearSelect}
                     selected={props.year}
                     maxShowItems={props.maxShowYears ?? 3}
                     width={props.width}
                     ref={ref}/>
})

YearCarousel.displayName = "YearCarousel"

export default YearCarousel
