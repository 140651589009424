import { createOrCopyDate, DeepReadonly, ReadonlyDate } from "my-util"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "./AbstractModelObject"

import { Expirable } from "./interfaces"

export interface AbstractExpirableModelObjectCreationOptions
    extends
        AbstractModelObjectCreationOptions
{
    expiresAt?: Date | null
}

export interface AbstractExpirableModelObjectCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        AbstractExpirableModelObjectCreationOptions
{}

export default abstract class AbstractExpirableModelObject
    extends
        AbstractModelObject

    implements
        DeepReadonly<Expirable>
{
    readonly expiresAt: ReadonlyDate

    constructor(options: DeepReadonly<AbstractExpirableModelObjectCreationOptions> = {}) {
        super(options)

        this.expiresAt = createOrCopyDate(options.expiresAt)
    }

    get isExpired(): boolean {
        return this.expiresAt.getTime() <= Date.now()
    }

    override copyOrPass(
        arg?: AbstractExpirableModelObject
            | DeepReadonly<AbstractExpirableModelObjectCopyOptions>
            | null,
    ): AbstractExpirableModelObject {
        if (arg == null)
            return this

        if (arg instanceof AbstractExpirableModelObject)
            return arg

        return this.copy(arg)
    }

    abstract copy(options?: DeepReadonly<AbstractExpirableModelObjectCopyOptions>): AbstractExpirableModelObject
}
