import { ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export interface FieldSetProps {
    children?: ReactNode
    legend?: string
}

const FieldSet = forwardRef((
    {
        children,
        legend,
    }: Readonly<FieldSetProps>,
    ref: ForwardedRef<HTMLFieldSetElement>,
) =>
    <fieldset className={style.FieldSet}
              ref={ref}>
        {legend &&
            <legend className={style.legend}>
                {legend}
            </legend>
        }

        {children}
    </fieldset>
)

FieldSet.displayName = "FieldSet"

export default FieldSet
