import { ForwardedRef, forwardRef, ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { countTransfersByStatus, countMyTransfersInWork,
         countMyTransfersByStatus, countTransfersInWork } from "api"

import { ALL_TRANSFERS_PAGE_PATH } from "ui/page/sections/transfers/AllTransfersPage/path"
import { Clickable, CountDataRow, ErrorDisplay, Flex, Loading } from "ui/ui"
import Widget, { WidgetRef } from "../Widget"

export type TransfersWidgetCountType =
    | "all"
    | "my"

export interface TransfersWidgetProps {
    newCountType?: TransfersWidgetCountType
    inWorkCountType?: TransfersWidgetCountType

    maxContentWidth?: string

    width?: string
    height?: string
}

const TransfersWidget = forwardRef((
    {
        newCountType, inWorkCountType,
        maxContentWidth,
        width, height,
    }: Readonly<TransfersWidgetProps>,
    ref: ForwardedRef<WidgetRef>,
) => {
    const [t] = useTranslation()

    const navigate = useNavigate()

    // State

    const [newCount, setNewCount] = useState(0)
    const [inWorkCount, setInWorkCount] = useState(0)

    const [loadingNewCount, setLoadingNewCount] = useState(true)
    const [loadingInWorkCount, setLoadingInWorkCount] = useState(true)
    const loading = loadingNewCount || loadingInWorkCount

    const [error, setError] = useState(null as any)

    // Loading

    useEffect(() => {
        const controller = new AbortController()

        // New

        const countByStatus = newCountType === "my"
            ? countMyTransfersByStatus
            : countTransfersByStatus

        countByStatus("new", controller.signal)
            .then(setNewCount)
            .catch(setErrorIfNotAborted)
            .finally(setNotLoadingNewCountIfNotAborted)

        // In work

        const countInWork = inWorkCountType === "my"
            ? countMyTransfersInWork
            : countTransfersInWork

        countInWork(controller.signal)
            .then(setInWorkCount)
            .catch(setErrorIfNotAborted)
            .finally(setNotLoadingInWorkCountIfNotAborted)

        return () => controller.abort()

        function setErrorIfNotAborted(error: unknown) {
            if (!controller.signal.aborted)
                setError(error)
        }

        function setNotLoadingNewCountIfNotAborted() {
            if (!controller.signal.aborted)
                setLoadingNewCount(false)
        }

        function setNotLoadingInWorkCountIfNotAborted() {
            if (!controller.signal.aborted)
                setLoadingInWorkCount(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Render

    return <Widget title={t("widgets.transfers.header") + ":"}
                   onTitleClick={navigateToAllTransfersPage}
                   maxContentWidth={maxContentWidth}
                   backgroundColor="#FCF2F8"
                   height={height}
                   width={width}
                   ref={ref}>
        {renderContent()}
    </Widget>

    function renderContent(): ReactNode {
        if (loading)
            return <Loading centerType="flex"/>

        if (error)
            return <ErrorDisplay centerType="flex"
                                 error={error}/>

        return <Flex>
            <Clickable onClick={navigateToAllTransfersPage}
                       cursor="pointer"
                       width="100%">
                <CountDataRow label={t("widgets.transfers.labels.newCount")}
                              value={newCount}
                              important/>
            </Clickable>

            <Clickable onClick={navigateToAllTransfersPage}
                       cursor="pointer"
                       width="100%">
                <CountDataRow label={t("widgets.transfers.labels.inWorkCount")}
                              value={inWorkCount}/>
            </Clickable>
        </Flex>
    }

    // Util

    function navigateToAllTransfersPage() {
        navigate(ALL_TRANSFERS_PAGE_PATH)
    }
})

TransfersWidget.displayName = "TransferWidget"

export default TransfersWidget
