import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export interface TableProps {
    children?: ReactNode

    width?: string
}

const Table = forwardRef((
    {
        children,
        width,
    }: DeepReadonly<TableProps>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    return <table className={style.Table}
                  style={{ width }}
                  ref={ref}>
        {children}
    </table>
})

Table.displayName = "Table"

export default Table
