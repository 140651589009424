import countryCodes, { CountryProperty } from "country-codes-list"
import { ForwardedRef, forwardRef } from "react"
import Select from "ui/ui/Select"

const TOP_CURRENCIES = [
    "RUB",
    "USD",
    "CNY",
    "EUR",
    "JPY",
    "GBP",
    "INR",
    "CAD",
    "HKD",
    "BRL",
    "AUD",
    "TWD",
    "CHF",
]

const OPTIONS = Object.fromEntries(
    Object.entries(
        countryCodes.customList("currencyCode" as CountryProperty, "{flag} {currencyCode} - {currencyNameEn}")
    )
    .map(([key, text]) => [key.trim(), text.trim()])
    .filter(([key]) => key.length > 0)
    .sort(([lhs], [rhs]) => {
        const lhsTopIndex = TOP_CURRENCIES.indexOf(lhs)
        const lhsOutOfTop = lhsTopIndex < 0

        const rhsTopIndex = TOP_CURRENCIES.indexOf(rhs)
        const rhsOutOfTop = rhsTopIndex < 0

        if (lhsOutOfTop && rhsOutOfTop)
            return lhs.localeCompare(rhs)

        if (rhsOutOfTop)
            return -1

        if (lhsOutOfTop)
            return 1

        return lhsTopIndex - rhsTopIndex
    })
    .map(([key, text]) => [key, text.endsWith(" -") ? text.substring(0, text.length - 2) : text])
    .map(([key, value]) => [key, [value, key] as const])
)

export interface CurrencySelectProps {
    onSelect?: (currency: string) => void
    selected?: string

    label?: string
    information?: string

    disabled?: boolean
    loading?: boolean
    readonly?: boolean

    autoFocus?: boolean
    width?: string
}

const CurrencySelect = forwardRef((
    {
        onSelect, selected,
        label, information,
        disabled, loading, readonly,
        autoFocus, width,
    }: Readonly<CurrencySelectProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Select options={OPTIONS}
            onSelect={onSelect}
            selected={selected ?? "USD"}
            label={label}
            information={information}
            disabled={disabled}
            readonly={readonly}
            loading={loading}
            autoFocus={autoFocus}
            width={width}
            ref={ref}/>
)

CurrencySelect.displayName = "CurrencySelect"

export default CurrencySelect
