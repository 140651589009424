import EventEmitter from "events"
import { useEffect, useMemo, useState } from "react"
import { Identifiable } from "model"

export interface CreateStorageHookOptions<T extends Identifiable> {
    loadById(id: string, signal?: AbortSignal | null): Promise<T>

    debug?: boolean
}

export interface StorageHook<T extends Identifiable>
    extends
        StorageHookValues<T>,
        StorageHookActions<T>
{}

export interface StorageHookValues<T extends Identifiable> {
    loadedById: Map<string, T>
    loadingIds: Set<string>
    errorsById: Map<string, unknown>
}

export interface StorageHookActions<T extends Identifiable> {
    loadByIdIfNew(objectId: string): boolean
    loadById(objectId: string): void

    addAll(objects: Iterable<T>): void
    add(object: T): void,

    deleteByValue(object: T): void
    deleteById(objectId: string): void
    clear(): void

    isNew(object: T): boolean
    isNewById(objectId: string): boolean

    hasLoadedOrLoading(object: T): boolean
    hasLoadedOrLoadingById(objectId: string): boolean
}

export default function createStorageHook<T extends Identifiable>(
    {
        loadById,
        debug,
    }: Readonly<CreateStorageHookOptions<T>>,
): () => StorageHook<T> {
    const globalLoadedById = new Map<string, T>()
    const globalLoadingControllersById = new Map<string, AbortController>()
    const globalErrorsById = new Map<string, unknown>()

    const globalEventEmitter = new EventEmitter<{
        "loaded-by-id-updated": [],
        "loading-controllers-by-id-updated": [],
        "errors-by-id-updated": [],
    }>()

    const globalActions: StorageHookActions<T> = {
        // Loading

        loadByIdIfNew(objectId) {
            const isNew = globalActions.isNewById(objectId)

            if (isNew)
                globalActions.loadById(objectId)

            return isNew
        },

        async loadById(objectId) {
            logDebug(`Started loading ${objectId}`)

            if (globalLoadingControllersById.has(objectId)) {
                logDebug("Already loading")
                return
            }

            const controller = new AbortController()

            globalLoadingControllersById.set(objectId, controller)
            globalEventEmitter.emit("loading-controllers-by-id-updated")

            try {
                logDebug("Loading...")

                const object = await loadById(objectId, controller.signal)

                logDebug("Loaded", object)

                globalLoadedById.set(objectId, object)
                globalEventEmitter.emit("loaded-by-id-updated")

                globalErrorsById.delete(objectId)
                globalEventEmitter.emit("errors-by-id-updated")
            } catch (error) {
                if (controller.signal.aborted)
                    return

                console.error(error)

                globalErrorsById.set(objectId, error)
                globalEventEmitter.emit("errors-by-id-updated")
            } finally {
                // It's already deleted if it's aborted
                if (!controller.signal.aborted) {
                    globalLoadingControllersById.delete(objectId)
                    globalEventEmitter.emit("loading-controllers-by-id-updated")
                }
            }
        },

        // Addition

        addAll(objects) {
            for (const object of objects)
                globalActions.add(object)
        },

        add(object) {
            globalLoadedById.set(object.id, object)
            globalEventEmitter.emit("loaded-by-id-updated")

            globalLoadingControllersById.get(object.id)?.abort()
            globalLoadingControllersById.delete(object.id)
            globalEventEmitter.emit("loading-controllers-by-id-updated")

            globalErrorsById.delete(object.id)
            globalEventEmitter.emit("errors-by-id-updated")

            logDebug("Added", object)
        },

        // Deletion

        deleteByValue(object) {
            globalActions.deleteById(object.id)
        },

        deleteById(objectId) {
            logDebug(`Started deleting ${objectId}`)

            if (globalLoadedById.delete(objectId)) {
                globalEventEmitter.emit("loaded-by-id-updated")
                logDebug("Deleted from loaded")
            }

            const controller = globalLoadingControllersById.get(objectId)

            if (controller != null) {
                controller.abort()
                globalLoadingControllersById.delete(objectId)
                globalEventEmitter.emit("loading-controllers-by-id-updated")
                logDebug("Stopped loading")
            }

            if (globalErrorsById.delete(objectId)) {
                globalEventEmitter.emit("errors-by-id-updated")
                logDebug("Deleted from errors")
            }

            logDebug("Deleted")
        },

        clear() {
            logDebug("Started clearing")

            if (globalLoadedById.size > 0) {
                globalLoadedById.clear()
                globalEventEmitter.emit("loaded-by-id-updated")
                logDebug("Cleared loaded")
            }

            if (globalLoadingControllersById.size > 0) {
                for (const controller of globalLoadingControllersById.values())
                    controller.abort()

                globalLoadingControllersById.clear()
                globalEventEmitter.emit("loading-controllers-by-id-updated")
                logDebug("Cleared loading")
            }

            if (globalErrorsById.size > 0) {
                globalErrorsById.clear()
                globalEventEmitter.emit("errors-by-id-updated")
                logDebug("Cleared errors")
            }

            logDebug("Cleared")
        },

        // New check

        isNew(object) {
            return globalActions.isNewById(object.id)
        },

        isNewById(objectId) {
            return !globalActions.hasLoadedOrLoadingById(objectId)
                && !globalErrorsById.has(objectId)
        },

        // Loaded or loading check

        hasLoadedOrLoading(object) {
            return globalActions.hasLoadedOrLoadingById(object.id)
        },

        hasLoadedOrLoadingById(objectId) {
            return globalLoadedById.has(objectId)
                || globalLoadingControllersById.has(objectId)
        },
    }

    function useStorage(): StorageHook<T> {
        // State

        const [loadedById, setLoadedById] = useState(new Map(globalLoadedById))
        const [loadingIds, setLoadingIds] = useState(new Set(globalLoadingControllersById.keys()))
        const [errorsById, setErrorsById] = useState(new Map(globalErrorsById))

        const hook = useMemo<StorageHook<T>>(
            () => ({
                loadedById, loadingIds, errorsById: errorsById,
                ...globalActions,
            }),

            [loadedById, loadingIds, errorsById],
        )

        // Effects

        useEffect(() => {
            globalEventEmitter.on("loaded-by-id-updated", onGlobalLoadedByIdUpdated)
            globalEventEmitter.on("loading-controllers-by-id-updated", onGlobalLoadingControllersByIdUpdated)
            globalEventEmitter.on("errors-by-id-updated", onGlobalErrorsByIdUpdated)

            return () => {
                globalEventEmitter.off("loaded-by-id-updated", onGlobalLoadedByIdUpdated)
                globalEventEmitter.off("loading-controllers-by-id-updated", onGlobalLoadingControllersByIdUpdated)
                globalEventEmitter.off("errors-by-id-updated", onGlobalErrorsByIdUpdated)
            }

            function onGlobalLoadedByIdUpdated() {
                setLoadedById(new Map(globalLoadedById))
            }

            function onGlobalLoadingControllersByIdUpdated() {
                setLoadingIds(new Set(globalLoadingControllersById.keys()))
            }

            function onGlobalErrorsByIdUpdated() {
                setErrorsById(new Map(globalErrorsById))
            }
        }, [])

        return hook
    }

    // Util

    function logDebug(message: string, ...params: any[]) {
        if (debug)
            console.debug(message, ...params)
    }

    // Return
    //
    // Not used before because FireFox for some
    // reason complain about unreachable code
    // below it. Only here. IDK how it works...
    //
    // Maybe it's WebPack's, or TypeScript's, or
    // some other's transpiling package fault

    return useStorage
}
