import assert from "assert"
import { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { linkIconUrl } from "image"
import { getUserById } from "api"
import { User } from "model"
import { isUuid, normalizeUuid } from "my-util"
import { useUsers, useWindowSize } from "ui/hook"
import { UserContext } from "ui/context"
import { Error404Page, SessionExpiredErrorPage } from "ui/page/error"
import { createLoginPagePath } from "ui/page/LoginPage"
import { createMessengerPagePath } from "ui/page/sections/messenger/MessengerPage"
import { Page } from "ui/component"

import { ErrorDisplay, Copy, Name, Flex, Tab, Pane,
         UserStatusBadge, Loading, TabbedView, Link } from "ui/ui"

import BasicInfoTab from "./BasicInfoTab"
import LegalInfoTab from "./LegalInfoTab"
import PasswordTab from "./PasswordTab"
import RightsTab from "./RightsTab"
import NotificationsTab from "./NotificationsTab"
import { USER_PAGE_PATH_USER_ID_PARAM_NAME } from "./path"

const MAX_MOBILE_WIDTH = 800

export default function UserPage() {
    const [t] = useTranslation()

    const { width: windowWidth } = useWindowSize()
    const mobile = windowWidth <= MAX_MOBILE_WIDTH

    const { [USER_PAGE_PATH_USER_ID_PARAM_NAME]: rawUserId } = useParams()

    const [localUser, setLocalUser] = useContext(UserContext)

    const storedUsers = useUsers()

    // State

    // - User ids

    const userId = useMemo(
        () => rawUserId == null || !isUuid(rawUserId)
            ? undefined
            : normalizeUuid(rawUserId!),
        [rawUserId],
    )

    const userLoginPageLink = useMemo(
        () =>  window.location.origin + createLoginPagePath(userId ?? ""),
        [userId],
    )

    const [user, setUser] = useState(null as User | null)

    // - Loading

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(undefined as unknown)

    // Effects

    useEffect(() => {
        if (userId == null)
            return

        if (userId === localUser?.id) {
            setUser(localUser)
            setLoading(false)
            storedUsers.add(localUser)
            return
        }

        const controller = new AbortController()

        getUserById(userId, controller.signal)
            .then(user => {
                setUser(user)
                storedUsers.add(user)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    // Rendering

    if (userId == null)
        return <Error404Page/>

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderTitle(): ReactElement | null {
        if (user == null)
            return null

        return <Flex direction="horizontal"
                     justify="space-between"
                     gap="64px">
            <Flex direction="horizontal"
                  width="fit-content">
                <Name value={user}/>
                <UserStatusBadge status={user.status}/>
            </Flex>

            <Flex direction="horizontal"
                  width="fit-content"
                  gap="32px">
                {localUser?.isManager && userId !== localUser.id &&
                    <Link to={createMessengerPagePath(userId)}
                          text={t("misc.buttons.sendMessage")}
                          whiteSpace="nowrap"
                          fontSize="16px"/>
                }

                <Copy text={t("misc.buttons.copyLoginLink")}
                      copiedText={t("misc.messages.loginLinkCopied")}

                      fontSize="16px"

                      value={userLoginPageLink}

                      iconSrc={linkIconUrl}
                      iconAlt="Link icon"
                      iconFilter="brightness(0.4)"/>
            </Flex>
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay
                error={error}
                apiErrorMessageMapping={{
                    403: t("domain.users.messages.errors.accessDenied"),
                    404: t("domain.users.messages.errors.notFound"),
                }}
            />

        assert(user != null)

        const tabs = [
            <Tab name={t("sections.users.tabNames.basicInfo")}
                 key="basicInfo">
                <Pane>
                    <BasicInfoTab onUserChange={onUserChange}
                                  user={user}
                                  mobile={mobile}/>
                </Pane>
            </Tab>,

            <Tab name={t("sections.users.tabNames.legalInfo")}
                 key="legalInfo">
                <Pane>
                    <LegalInfoTab onUserChange={onUserChange}
                                  user={user}
                                  mobile={mobile}/>
                </Pane>
            </Tab>,
        ]

        if (userId === localUser?.id)
            tabs.push(
                <Tab name={t("sections.users.tabNames.password")}
                     key="password">
                    <Pane>
                        <PasswordTab mobile={mobile}/>
                    </Pane>
                </Tab>,

                <Tab name={t("sections.users.tabNames.notifications")}
                     key="notification">
                    <Pane>
                        <NotificationsTab onUserChange={onUserChange}
                                          user={user}
                                          mobile={mobile}/>
                    </Pane>
                </Tab>
            )

        if (user.role === "manager")
            tabs.push(
                <Tab name={t("sections.users.tabNames.rights")}
                     key="rights">
                    <Pane>
                        <RightsTab onUserChange={onUserChange}
                                   user={user}
                                   mobile={mobile}
                                   readonly={!localUser?.isAdmin}/>
                    </Pane>
                </Tab>
            )

        return <TabbedView height="100%">
            {tabs}
        </TabbedView>
    }

    // Events

    function onUserChange(newUser: User) {
        setUser(newUser)

        if (newUser.id === localUser?.id)
            setLocalUser(newUser)
    }
}
