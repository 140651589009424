import { DeepReadonly } from "my-util"
import { ForwardedRef, forwardRef, Fragment } from "react"
import { sticklessArrowRightIconUrl } from "image"
import { Icon } from "ui/ui/icon"
import { Flex } from "ui/ui/layout"
import { Link } from "ui/ui/link"

export interface BreadcrumbsProps {
    crumbs?: Breadcrumb[]
}

export interface Breadcrumb {
    text: string
    to: string
    color?: string
}

const Breadcrumbs = forwardRef((
    { crumbs }: DeepReadonly<BreadcrumbsProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    if (!crumbs?.length)
        return null

    return <Flex direction="horizontal"
                 align="start"
                 ref={ref}>
        {crumbs.map(({ text, to, color }, i) => {
            const key = `${text}-${color}-${to}-${i}`
            const linkColor = color ?? (i === crumbs.length - 1 ? "red" : undefined)

            return <Fragment key={key}>
                {i > 0 &&
                    <Icon src={sticklessArrowRightIconUrl}
                          alt="Arrow icon"
                          filter="brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(38%) hue-rotate(144deg) brightness(75%) contrast(97%)"

                          width="14px"
                          height="14px"/>
                }

                <Link color={linkColor}
                      text={text}
                      to={to}/>
            </Fragment>
        })}
    </Flex>
})

Breadcrumbs.displayName = "Breadcrumbs"

export default Breadcrumbs
