import { z } from "zod"
import { OtpResponseSchema } from "./otp"

// Status

const SUCCESS = "success"
const VERIFICATION_NEEDED = "verification-needed"

export type MfaResponseStatus =
    | typeof SUCCESS
    | typeof VERIFICATION_NEEDED

// Success

export const SuccessMfaResponseSchema = z.object({
    status: z.literal(SUCCESS),
    body: z.any(),
})

export type SuccessMfaResponse = z.infer<typeof SuccessMfaResponseSchema>

// Verification needed

export const VerificationNeededMfaResponseSchema = OtpResponseSchema.extend({
    status: z.literal(VERIFICATION_NEEDED),
})

export type VerificationNeededMfaResponse = z.infer<typeof VerificationNeededMfaResponseSchema>

// Common

export const MfaResponseSchema = SuccessMfaResponseSchema.or(VerificationNeededMfaResponseSchema)

export type MfaResponse = z.infer<typeof MfaResponseSchema>
