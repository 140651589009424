import { ForwardedRef, forwardRef, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getAllMonthNames, MonthIndex } from "my-util"
import Carousel from "ui/ui/Carousel"

export interface MonthCarouselProps {
    onMonthIndexSelect?: (monthIndex: MonthIndex) => void
    monthIndex?: number

    maxShowMonths?: number
    width?: string
}

const MonthCarousel = forwardRef((props: Readonly<MonthCarouselProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const [, { language }] = useTranslation()
    const monthNames = useMemo(getAllMonthNames, [language])

    return <Carousel items={monthNames}
                     onSelect={props.onMonthIndexSelect as (monthIndex: number) => void}
                     selected={props.monthIndex}
                     maxShowItems={props.maxShowMonths}
                     width={props.width}
                     ref={ref}/>
})

MonthCarousel.displayName = "MonthCarousel"

export default MonthCarousel
