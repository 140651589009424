import { collapseWhiteSpace } from "my-util"
import ViolationType from "./ViolationType"

export const MAX_SHORT_TEXT_LENGTH = 255

export function validateShortText(text: string): ViolationType | undefined {
    text = collapseWhiteSpace(text)

    if (text.length > MAX_SHORT_TEXT_LENGTH)
        return "too-long"

    return undefined
}
