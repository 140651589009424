import { useLayoutEffect, useState, useRef, forwardRef,
         useImperativeHandle, ForwardedRef, useEffect, useMemo } from "react"

import { matchPath, NavLink, useLocation } from "react-router-dom"
import { Icon } from "ui/ui"
import style from "./style.module.css"

export interface SectionLinkProps {
    onIsActiveChange?: (isActive: boolean) => void
    to: string

    text?: string

    iconSrc?: string
    iconAlt?: string

    short?: boolean

    firstRender?: boolean

    width?: string
    height?: string
}

const SectionLink = forwardRef((
    {
        onIsActiveChange, to,
        text,
        iconSrc, iconAlt,
        short,
        firstRender,
        width, height,
    }: Readonly<SectionLinkProps>,
    ref: ForwardedRef<HTMLAnchorElement>,
) => {
    const location = useLocation()

    const isActive = useMemo(
        () => matchPath(to + "/*", location.pathname) != null,
        [location, to],
    )

    // State

    const [visibleShort, setVisibleShort] = useState(short)

    // Refs

    const componentRef = useRef(null as HTMLAnchorElement | null)

    useImperativeHandle(ref, () => componentRef.current!, [])

    // Effects

    // - State propagation

    useEffect(
        () => onIsActiveChange?.(isActive),
        [isActive, onIsActiveChange],
    )

    // - Animation

    useLayoutEffect(() => {
        if (short === visibleShort)
            return

        if (!short) {
            setVisibleShort(false)
            return
        }

        const component = componentRef.current

        if (component == null)
            return

        const animations = component.getAnimations({ subtree: true })

        let finishCount = 0

        for (const animation of animations)
            animation.onfinish = onFinish

        function onFinish() {
            if (++finishCount >= animations.length)
                setVisibleShort(true)
        }
    }, [short, visibleShort])

    // Render

    const iconSize = height ?? "32px"

    return <NavLink className={isActive ? style.CurrentSectionLink : style.SectionLink}
                    style={{ width }}
                    ref={componentRef}
                    to={to}>
        {iconSrc &&
            <div className={style.icon}
                 style={{
                    width: iconSize,
                    height: iconSize,
                    borderRadius: `calc(.5 * ${iconSize})`,
                 }}>
                <Icon src={iconSrc}
                      alt={iconAlt ?? "Section icon"}
                      filter="brightness(0) saturate(100%) invert(29%) sepia(37%) saturate(574%) hue-rotate(164deg) brightness(99%) contrast(98%)"/>
            </div>
        }

        {text && !visibleShort &&
            <span style={{ height }}
                  className={renderTextClassName()}>
                {text}
            </span>
        }
    </NavLink>

    function renderTextClassName(): string {
        if (firstRender)
            return style.text

        if (short)
            return style.hidingText

        return style.appearingText
    }
})

SectionLink.displayName = "SectionLink"

export default SectionLink
