import { FormEvent, ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export interface FormProps {
    onSubmit?: () => void
    onReset?: () => void

    loading?: boolean

    children?: ReactNode

    width?: string
    height?: string
}

const Form = forwardRef((
    {
        onSubmit, onReset,
        loading,
        children,
        width, height,
    }: Readonly<FormProps>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    return <form className={loading ? style.LoadingForm : style.Form}
                 onSubmit={onInnerSubmit}
                 onReset={onInnerReset}
                 style={{ width, height }}
                 ref={ref}>
        {children}
    </form>

    function onInnerSubmit(event: FormEvent) {
        event.preventDefault()
        onSubmit?.()
    }

    function onInnerReset(event: FormEvent) {
        event.preventDefault()
        onReset?.()
    }
})

Form.displayName = "Form"

export default Form
