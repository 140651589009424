import { ForwardedRef, forwardRef } from "react"
import Output, { OutputProps } from "ui/ui/Output"
import Email from "../Email"

export interface EmailOutputProps extends Omit<OutputProps, "children"> {
    email: string
}

const EmailOutput = forwardRef((
    props: Readonly<EmailOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <Output { ...props }
                   ref={ref}>
        <Email email={props.email}/>
    </Output>
})

EmailOutput.displayName = "EmailOutput"

export default EmailOutput
