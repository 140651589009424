import { ForwardedRef, forwardRef, ReactElement, ReactNode } from "react"
import { useStateWithDeps } from "ui/hook"
import { Icon } from "ui/ui/icon"
import { TabProps } from "../Tab"
import style from "./style.module.css"

export interface TabbedViewProps {
    onSelect?: (selected: number) => void
    selected?: number

    children?: TabbedViewChildren
    right?: ReactNode

    gap?: string

    width?: string
    height?: string
}

export type TabbedViewChildren =
    | TabbedViewChild[]
    | TabbedViewChild

export type TabbedViewChild =
    | ReactElement<TabProps>
    | undefined
    | null
    | false

const TabbedView = forwardRef((
    {
        onSelect, selected,
        children, right,
        gap,
        width, height,
    }: Readonly<TabbedViewProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const tabs = children != null
        ? Array.isArray(children)
            ? children
            : [children]
        : []

    const [innerSelected, setInnerSelected] = useStateWithDeps(
        () => selected ?? 0,
        [selected],
    )

    // Render

    return <div style={{ width, height, gap }}
                className={style.TabbedView}
                ref={ref}>
        {renderTop()}
        {tabs[innerSelected]}
    </div>

    function renderTop(): ReactNode {
        return <div className={style.top}>
            {renderHeader()}
            {right}
        </div>
    }

    function renderHeader(): ReactNode {
        return <div className={style.header}>
            {tabs.map((tab, i) => {
                if (!tab)
                    return null

                const tabIndex = tab.props.index ?? i
                const selected = tabIndex === innerSelected

                const className = selected
                    ? style.selectedTab
                    : style.tab

                const {
                    name,
                    width,
                    iconSrc, iconAlt, iconFilter,
                } = tab.props

                return <div onClick={() => onInnerSelected(tabIndex)}
                            className={className}
                            style={{ width }}
                            key={i}>
                    {iconSrc &&
                        <Icon src={iconSrc}
                              alt={iconAlt}
                              filter={iconFilter}/>
                    }

                    {name}
                </div>
            })}
        </div>
    }

    // Events

    function onInnerSelected(newSelectedTabIndex: number) {
        setInnerSelected(newSelectedTabIndex)
        onSelect?.(newSelectedTabIndex)
    }
})

TabbedView.displayName = "TabbedView"

export default TabbedView
