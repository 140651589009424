import { ForwardedRef, forwardRef } from "react"
import { calendarIconUrl } from "image"
import { dateToDateString, DeepReadonly } from "my-util"
import Output, { OutputProps } from "ui/ui/Output"

export interface DateOutputProps extends Omit<OutputProps, "children"> {
    date: Date
}

const DateOutput = forwardRef((
    props: DeepReadonly<DateOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output iconSrc={calendarIconUrl}
            iconAlt="Calendar icon"
            iconFilter="brightness(0) saturate(100%) invert(71%) sepia(5%) saturate(5137%) hue-rotate(183deg) brightness(68%) contrast(104%)"
            overflow="hidden"
            height="32px"
            {...props as DateOutputProps}
            ref={ref}>
        {dateToDateString(props.date)}
    </Output>
)

DateOutput.displayName = "DateOutput"

export default DateOutput
