import { useTranslation } from "react-i18next"
import { ForwardedRef, forwardRef } from "react"
import ColorLegend from "../ColorLegend"

export interface ConnectionIndicatorProps {
    status: ConnectionStatus
    showLegend?: boolean
}

export type ConnectionStatus =
    | "opening"
    | "open"
    | "closing"
    | "closed"

const ConnectionIndicator = forwardRef((
    { status, showLegend }: Readonly<ConnectionIndicatorProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const [t] = useTranslation()

    const legend = showLegend ? t(`misc.messages.connectionStatuses.${status}`) : undefined
    const color = getColor()

    return <ColorLegend legend={legend}
                        color={color}
                        ref={ref}/>

    function getColor(): string {
        switch (status) {
            case "opening":
                return "blue"

            case "open":
                return "lime"

            case "closing":
                return "orange"

            case "closed":
                return "red"
        }
    }
})

ConnectionIndicator.displayName = "ConnectionIndicator"

export default ConnectionIndicator
