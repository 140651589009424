import formatDuration from "format-duration"
import { ForwardedRef, forwardRef } from "react"
import { DeepReadonly, Duration, durationToMillis } from "my-util"
import Output, { OutputProps } from "ui/ui/Output"

export interface DurationOutputProps extends Omit<OutputProps, "children"> {
    duration?: Duration
    millis?: number
}

const DurationOutput = forwardRef((
    props: DeepReadonly<DurationOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const millis = props.duration != null
        ? durationToMillis(props.duration)
        : (props.millis ?? 0)

    return <Output {...props as DurationOutputProps}
                   ref={ref}>
        {formatDuration(millis)}
    </Output>
})

DurationOutput.displayName = "DurationOutput"

export default DurationOutput
