import { z } from "zod"

export const TransferRecipientMessageSchema = z.object({
    name: z.string(),
    itn: z.string().nullable(),
    paymentAccount: z.string().nullish(),
    correspondentAccount: z.string().nullish(),
    iban: z.string().nullish(),

    bank: z.object({
        name: z.string(),
        address: z.string(),
        swift: z.string(),
    }),
})

export type TransferRecipientMessage = z.infer<typeof TransferRecipientMessageSchema>
