import { t } from "i18next"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, map, Nullish, tryNormalizeUuid } from "my-util"

// Type

export type RoutePointType =
    | "earth"
    | "air"
    | "water"

export function routePointTypeToString(type: RoutePointType): string {
    return t(`domain.routePoints.types.${type}`)
}

export function isRoutePointType(s: string): s is RoutePointType {
    switch (s) {
        case "earth":
        case "air":
        case "water":
            s satisfies RoutePointType
            return true

        default:
            return false
    }
}

// Route point

export interface RoutePointCreationOptions extends AbstractModelObjectCreationOptions {
    country: string
    type: RoutePointType
    documentIds?: Iterable<string> | null
}

export interface RoutePointCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<RoutePointCreationOptions>
{}

export default class RoutePoint extends AbstractModelObject {
    static createOrPass(arg: RoutePoint | DeepReadonly<RoutePointCreationOptions>): RoutePoint {
        return arg instanceof RoutePoint
            ? arg
            : new RoutePoint(arg)
    }

    readonly country: string
    readonly type: RoutePointType
    readonly documentIds: string[]

    constructor(options: DeepReadonly<RoutePointCreationOptions>) {
        super(options)

        this.country = options.country
        this.type = options.type
        this.documentIds = map(options.documentIds ?? [], tryNormalizeUuid)
    }

    override copyOrPass(
        arg?: RoutePoint
            | DeepReadonly<RoutePointCopyOptions>
            | null,
    ): RoutePoint {
        if (arg == null)
            return this

        if (arg instanceof RoutePoint)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<RoutePointCopyOptions> = {}): RoutePoint {
        return new RoutePoint({
            country: options.country ?? this.country,
            type: options.type ?? this.type,
            documentIds: options.documentIds ?? this.documentIds,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
