import { getUserRoleColor, UserRole } from "model"
import { ForwardedRef, forwardRef } from "react"
import { Arrow, ArrowProps } from "ui/ui/shape"

export interface UserRoleArrowProps extends Omit<ArrowProps, "color"> {
    role?: UserRole
}

const UserRoleArrow = forwardRef((
    {
        role,
        width, height,
    }: Readonly<UserRoleArrowProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const color = role != null
        ? getUserRoleColor(role)
        : undefined

    return <Arrow color={color}
                  width={width ?? "10px"}
                  height={height ?? "24px"}
                  ref={ref}/>
})

UserRoleArrow.displayName = "UserRoleArrow"

export default UserRoleArrow
