import { WithCreatorId } from "model/interfaces"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, map, Nullish, tryNormalizeUuid } from "my-util"

export interface ChatMessageCreationOptions extends AbstractModelObjectCreationOptions {
    creatorId: string
    recipientId?: string | null
    text?: string | null
    documentIds?: string[] | null
    readByIds?: Iterable<string> | null
    edited?: boolean | null
}

export interface ChatMessageCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<ChatMessageCreationOptions>
{}

export default class ChatMessage
    extends
        AbstractModelObject

    implements
        Readonly<WithCreatorId>
{
    static createOrPass(arg: ChatMessage | DeepReadonly<ChatMessageCreationOptions>): ChatMessage {
        return arg instanceof ChatMessage
            ? arg
            : new ChatMessage(arg)
    }

    readonly creatorId: string
    readonly recipientId: string | null
    readonly text: string | null
    readonly documentIds: readonly string[]
    readonly readByIds: ReadonlySet<string>
    readonly edited: boolean

    constructor(options: DeepReadonly<ChatMessageCreationOptions>) {
        super(options)

        this.creatorId = tryNormalizeUuid(options.creatorId)

        this.recipientId = options.recipientId != null
            ? tryNormalizeUuid(options.recipientId)
            : null

        this.text = options.text ?? null
        this.documentIds = options.documentIds?.map(tryNormalizeUuid) ?? []
        this.readByIds = new Set(map(options.readByIds ?? [], tryNormalizeUuid))
        this.edited = options.edited ?? false
    }

    getCreatorIdColor(opacity?: number | null): string {
         return ChatMessage.getIdColor(this.creatorId, opacity)
    }

    override copyOrPass(
        arg?: ChatMessage
            | DeepReadonly<ChatMessageCopyOptions>
            | null,
    ): ChatMessage {
        if (arg == null)
            return this

        if (arg instanceof ChatMessage)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<ChatMessageCopyOptions> = {}) {
        return new ChatMessage({
            creatorId: options.creatorId ?? this.creatorId,

            recipientId: options.recipientId !== undefined
                ? options.recipientId
                : this.recipientId,

            text: options.text !== undefined
                ? options.text
                : this.text,

            documentIds: options.documentIds ?? this.documentIds,
            readByIds: options.readByIds ?? this.readByIds,
            edited: options.edited ?? this.edited,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
