import assert from "assert"
import { ForwardedRef, forwardRef, ReactNode, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ZodType } from "zod"
import { cancelMfa, OtpResponse, resendMfaOtp, verifyMfaOtp } from "api"
import { determineMessageTargetType, determineNullableMessageTargetType } from "model"
import { DeepReadonly, ReadonlyDate, SECOND_MILLIS } from "my-util"
import { OTP_LENGTH, validateOtp } from "validation"
import { useStateWithDeps } from "ui/hook"
import { Button, ErrorDisplay, Flex, Input, Modal, ModalButton, Timer } from "ui/ui"
import Otp from "../Otp"

export interface MfaModalProps extends Partial<OtpResponse> {
    onSuccess?: (response: unknown) => void
    onClose?: () => void

    closeOnSuccess?: boolean

    responseSchema?: ZodType<any, any, any>

    header?: string

    width?: string
}

const MfaModal = forwardRef((
    {
        messageTarget, resendCoolDown, sentAt, expiresAt,
        onClose, onSuccess,
        closeOnSuccess,
        responseSchema,
        header,
        width,
    }: DeepReadonly<MfaModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const [code, setCode] = useState(undefined as string | undefined)
    const codeInputInvalid = code != null && validateOtp(code) != null
    const codeInvalid = code == null || codeInputInvalid

    const [innerMessageTarget, setInnerMessageTarget] = useStateWithDeps(() => messageTarget, [messageTarget])
    const [innerResendCoolDown, setInnerResendCoolDown] = useStateWithDeps(() => resendCoolDown, [resendCoolDown])
    const [innerSentAt, setInnerSentAt] = useStateWithDeps(() => sentAt, [sentAt])
    // For future possible use
    const [/*innerExpiresAt*/, setInnerExpiresAt] = useStateWithDeps(() => expiresAt, [expiresAt])

    const canResendAt = useMemo(evalResendMoment, [innerSentAt, innerResendCoolDown])
    const [canResend, setCanResend] = useState(new Date() >= canResendAt)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    // Render

    return <Modal onClose={onInnerClose}
                  header={renderHeader()}
                  width={width ?? "300px"}
                  loading={loading}
                  buttons={renderButtons()}
                  ref={ref}>
        <Flex>
            {renderCodeInput()}

            {canResend
                ? renderResendButton()
                : renderResendTimer()
            }

            {renderErrorDisplay()}

            <Otp key={innerSentAt?.getTime()}/>
        </Flex>
    </Modal>

    function renderHeader(): string {
        if (header != null)
            return header

        if (innerMessageTarget == null)
            return t("auth.mfa.headers.verification")

        const innerMessageTargetType = determineMessageTargetType(innerMessageTarget)

        return t(`auth.mfa.headers.${innerMessageTargetType}Verification`)
    }

    function renderButtons(): ModalButton[] {
        return [
            {
                text: t("misc.buttons.cancel"),
                buttonStyle: "text",
                onClick: onInnerClose,
            },

            {
                text: t("misc.buttons.ok"),
                type: "submit",
                disabled: codeInvalid,
                onClick: onVerify,
            },
        ]
    }

    function renderCodeInput(): ReactNode {
        return <Input onChange={setCode}
                      value={code}
                      max={OTP_LENGTH}
                      regex={/^\d*$/}
                      invalid={codeInputInvalid}
                      loading={loading}
                      placeholder={renderInputPlaceholder()}/>
    }

    function renderInputPlaceholder(): string {
        switch (determineNullableMessageTargetType(innerMessageTarget)) {
            case "phone":
                return t("auth.mfa.placeholders.smsCode")

            case "email":
                return t("auth.mfa.placeholders.emailCode")

            case null:
                return t("auth.mfa.placeholders.code")
        }
    }

    function renderResendButton(): ReactNode {
        return <Button text={t("auth.mfa.buttons.resendCode")}
                       loading={loading}
                       buttonStyle="outline"
                       onClick={onResend}/>
    }

    function renderResendTimer(): ReactNode{
        return <p>
            {t("auth.mfa.messages.canResendIn")}

            {" "}

            <Timer onExpired={() => setCanResend(true)}
                   expiresAt={canResendAt}/>
        </p>
    }

    function renderErrorDisplay(): ReactNode {
        return <ErrorDisplay
            centerType="flex"
            error={error}
            apiErrorMessageMapping={{
                422: t("auth.mfa.messages.errors.invalidCode"),
            }}
        />
    }

    // Event

    function onInnerClose() {
        cancelMfa()
        onClose?.()
    }

    async function onVerify() {
        setLoading(true)

        try {
            assert(code != null)

            const response = await verifyMfaOtp(code, responseSchema)

            if (closeOnSuccess)
                onClose?.()

            onSuccess?.(response)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    async function onResend() {
        setLoading(true)

        try {
            const response = await resendMfaOtp()

            setInnerMessageTarget(response.messageTarget)
            setInnerResendCoolDown(response.resendCoolDown)
            setInnerSentAt(response.sentAt)
            setInnerExpiresAt(response.expiresAt)

            setCanResend(false)
            setError(undefined)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    // Util

    function evalResendMoment(
        sentAt: ReadonlyDate | undefined | null = innerSentAt,
        coolDownSeconds: number | undefined | null = innerResendCoolDown,
    ): Date {
        return sentAt != null && coolDownSeconds != null
            ? new Date(SECOND_MILLIS * Math.floor(sentAt.getTime() / SECOND_MILLIS + coolDownSeconds))
            : new Date()
    }
})

MfaModal.displayName = "MfaModal"

export default MfaModal
