import { parsePhoneNumber } from "libphonenumber-js"

export default function normalizePhone(phone: string): string {
    phone = phone.trim()

    try {
        const parsedPhone = parsePhoneNumber(phone, "RU")
        return parsedPhone.number
    } catch {
        return phone
    }
}
