import { ForwardedRef, forwardRef } from "react"
import { DeepReadonly } from "my-util"
import { ErrorText, ErrorTextApiErrorMessageMapping } from "ui/ui/error"
import { Flex } from "ui/ui/layout"
import Button, { ButtonProps } from "ui/ui/Button"
import style from "./style.module.css"

export interface FormControlsProps {
    buttons?: (FormControlsButton | undefined | null)[]

    loading?: boolean
    disabled?: boolean

    width?: string
    mobile?: boolean
    wrap?: boolean

    error?: unknown
    apiErrorMessageMapping?: ErrorTextApiErrorMessageMapping
}

export interface FormControlsButton extends ButtonProps {
    position: FormControlsButtonPosition
    hidden?: boolean
}

export type FormControlsButtonPosition =
    | "left"
    | "right"

const FormControls = forwardRef((
    {
        buttons,
        loading, disabled,
        width, mobile, wrap,
        error, apiErrorMessageMapping,
    }: DeepReadonly<FormControlsProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <div className={mobile ? style.MobileFormControls : style.FormControls}
                style={{ width }}
                ref={ref}>
        <div className={style.leftButtons}>
            {renderButtons("left")}
        </div>

        <div className={style.error}>
            <ErrorText apiErrorMessageMapping={apiErrorMessageMapping}
                       error={error}/>
        </div>

        <div className={style.rightButtons}>
            {renderButtons("right")}
        </div>
    </div>

    function renderButtons(position: FormControlsButtonPosition) {
        const filteredButtons = buttons?.filter(button =>
            button != null &&
            !button.hidden &&
            button.position === position
        ) ?? [] as DeepReadonly<FormControlsButton[]>

        return <Flex direction="horizontal"
                     width="fit-content"
                     justify="start"
                     gap="8px"
                     wrap={wrap}>
            {
                filteredButtons.map((button, i) =>
                    <Button width="fit-content"
                            loading={loading}
                            disabled={disabled}
                            {...button}
                            key={i}/>
                )
            }
        </Flex>
    }
})

FormControls.displayName = "FormControls"

export default FormControls
