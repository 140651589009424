import { ForwardedRef, forwardRef } from "react"
import { Center, CenterType } from "ui/ui/layout"
import Dim from "ui/ui/Dim"
import LoadingIndicator from "../LoadingIndicator"
import style from "./style.module.css"

export interface LoadingProps {
    opacity?: number

    centerType?: CenterType
    fixed?: boolean

    indicatorSize?: string
}

const Loading = forwardRef((
    {
        opacity,
        fixed, centerType,
        indicatorSize,
    }: Readonly<LoadingProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div className={fixed ? style.FixedLoading : style.Loading}
         ref={ref}>
        <Center type={centerType ?? "absolute-fill"}>
            <Dim opacity={opacity ?? 0}/>
            <LoadingIndicator size={indicatorSize}/>
        </Center>
    </div>
)

Loading.displayName = "Loading"

export default Loading
