import { getOrComputeMapItem } from "my-util/map/misc"

export function join<T>(iterable: Iterable<T>, sep: string = ""): string {
    return [...iterable].join(sep)
}

export function unique<T>(
    iterable: Iterable<T>,
    getProperty: (item: T, index: number) => unknown = item => item,
): T[] {
    return [
        ...new Map(
            map(iterable, (item, index) => [
                getProperty(item, index),
                item,
            ]),
        ).values(),
    ]
}

export function map<T, R>(
    iterable: Iterable<T>,
    map: (value: T, i: number, iterable: Iterable<T>) => R,
): Array<R> {
    const result = new Array<R>()

    let i = 0

    for (const value of iterable) {
        const mappedValue = map(value, i++, iterable)
        result.push(mappedValue)
    }

    return result
}

export function group<T, P>(
    iterable: Iterable<T>,
    getProperty: (item: T, index: number) => P,
): Map<P, T[]> {
    const groups = new Map<P, T[]>()

    let i = 0

    for (const item of iterable) {
        const property = getProperty(item, i++)
        const group = getOrComputeMapItem(groups, property, () => [])

        group.push(item)
    }

    return groups
}
