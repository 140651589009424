import { z } from "zod"
import { UuidSchema } from "my-util"
import { ChatResponseType } from "./type"
import { ChatResponseBaseSchema } from "./base"

export const DeleteChatMessagesResponseSchema = ChatResponseBaseSchema.extend({
    type: z.literal("delete" satisfies ChatResponseType),

    messageIds: UuidSchema.array(),
})

export type DeleteChatMessagesResponse = z.infer<typeof DeleteChatMessagesResponseSchema>
