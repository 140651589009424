import { RU_NAME_INPUT_REGEX } from "my-util"
import ViolationType from "./ViolationType"
import { validateName } from "./name"

export const MIN_RU_NAME_PART_LENGTH = 1
export const MAX_RU_NAME_PART_LENGTH = 255

export function validateRuName(name: string): ViolationType | undefined {
    return validateName({
        name,
        regex: RU_NAME_INPUT_REGEX,
        minPartLength: MIN_RU_NAME_PART_LENGTH,
        maxPartLength: MAX_RU_NAME_PART_LENGTH,
    })
}
