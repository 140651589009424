import { ForwardedRef, forwardRef, ReactNode, useState,
         useImperativeHandle, useLayoutEffect, useRef, useEffect} from "react"
import { sticklessArrowLeftIconUrl, sticklessArrowRightIconUrl } from "image"
import { useStateWithDeps } from "ui/hook"
import Button from "ui/ui/Button"
import style from "./style.module.css"

const INITIAL_MAX_SHOW_ITEMS = 8

type State =
    | "growing"
    | "shrinking"
    | "showing"

export interface CarouselProps {
    onSelect?: (itemIndex: number) => void
    selected?: number
    items: ArrayLike<CarouselItem>

    maxShowItems?: number
    width?: string
}

export type CarouselItem =
    | string
    | number

const Carousel = forwardRef((
    {
        onSelect, selected, items,
        maxShowItems, width,
    }: Readonly<CarouselProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const maxShowItemsLimit = maxShowItems ?? Number.MAX_SAFE_INTEGER

    // State

    const [state, setState] = useState("growing" satisfies State as State)
    const [innerMaxShowItems, setInnerMaxShowItems] = useStateWithDeps(getInitialMaxShowItems, [maxShowItemsLimit])
    const [innerSelected, setInnerSelected] = useStateWithDeps(getInitialInnerSelected, [selected])
    const [showButtons, setShowButtons] = useState(false)

    // Ref

    const innerRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => innerRef.current!, [])

    // Window resize event handler

    useEffect(() => {
        window.addEventListener("resize", startRelayout)

        return () => window.removeEventListener("resize", startRelayout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Resizing

    useLayoutEffect(() => {
        const carousel = innerRef.current

        if (carousel == null)
            return

        const widthDelta = carousel.scrollWidth - carousel.offsetWidth

        switch (state) {
            case "growing":
                if (widthDelta > 0)
                    setState("shrinking")
                else {
                    let newInnerMaxShowItems = Math.min(
                        Math.min(2 * innerMaxShowItems, maxShowItemsLimit),
                        items.length,
                    )

                    if (newInnerMaxShowItems !== innerMaxShowItems)
                        setInnerMaxShowItems(newInnerMaxShowItems)
                    else
                        setState("shrinking")
                }

                break

            case "shrinking":
                if (innerMaxShowItems === 0)
                    setState("showing")
                else if (widthDelta > 0)
                    setInnerMaxShowItems(innerMaxShowItems - 1)
                else {
                    if (innerMaxShowItems < items.length)
                        setShowButtons(true)
                    else
                        setState("showing")
                }

                break

            case "showing":
                break
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, innerMaxShowItems, showButtons])

    // Render

    return <div className={style.Carousel}
                style={{ width }}
                ref={innerRef}>
        {renderItems()}
    </div>

    function renderItems(): ReactNode {
        const nodes = new Array<ReactNode>()

        let firstIndex = items.length > innerMaxShowItems
            ? Math.max(innerSelected - Math.floor(innerMaxShowItems / 2), 0)
            : 0

        if (items.length - firstIndex < innerMaxShowItems)
            firstIndex -= firstIndex - items.length + innerMaxShowItems

        const end = firstIndex + innerMaxShowItems

        for (let i = firstIndex; i < end; ++i) {
            const item = items[i]

            if (item == null)
                continue

            const node = <div className={style.item}
                              onClick={() => onSelectItem(i)}
                              key={`${item}-${i}`}>
                <Button text={item.toString()}
                        buttonStyle={i === innerSelected ? "fill" : "text"}
                        onClick={() => onSelectItem(i)}/>
            </div>

            nodes.push(node)
        }

        if (!showButtons)
            return nodes

        return <>
            {renderArrowButton(sticklessArrowLeftIconUrl, -1, innerSelected === 0)}
            {nodes}
            {renderArrowButton(sticklessArrowRightIconUrl, 1, innerSelected === items.length - 1)}
        </>
    }

    function renderArrowButton(iconSrc: string, step: number, disabled: boolean): ReactNode {
        return <Button buttonStyle="text"
                       width="fit-content"
                       onClick={onClick}
                       iconSrc={iconSrc}
                       iconAlt="Arrow icon"
                       disabled={disabled}/>

        function onClick() {
            const newSelected = innerSelected + step

            if (newSelected < 0)
                return

            if (newSelected >= items.length)
                return

            onSelectItem(newSelected)
        }
    }

    // Events

    function onSelectItem(itemIndex: number) {
        setInnerSelected(itemIndex)
        onSelect?.(itemIndex)
        startRelayout()
    }

    // Util

    function getInitialInnerSelected(): number {
        if (selected == null)
            return 0

        let initialInnerSelected = selected | 0

        if (initialInnerSelected > items.length)
            initialInnerSelected = items.length - 1

        return initialInnerSelected
    }

    function getInitialMaxShowItems(): number {
        return Math.min(INITIAL_MAX_SHOW_ITEMS, maxShowItemsLimit)
    }

    function startRelayout() {
        setInnerMaxShowItems(INITIAL_MAX_SHOW_ITEMS)
        setState("growing")
        setShowButtons(false)
    }
})

Carousel.displayName = "Carousel"

export default Carousel
