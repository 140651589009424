import { ReactNode, useCallback, useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { v4 as generateRandomUuid } from "uuid"
import { SECOND_MILLIS, splicedArray } from "my-util"
import { UserContext } from "ui/context"

import { ChatHookMarkMessagesReadRequest,
         ChatHookMarkMessagesReadResponse,
         ChatHookMessagesResponse, useChat,
         ChatHookRequestVerificationResponse,
         ChatHookSendMessageRequest, ChatHookResponse,
         ChatHookRequest, ChatHookLoadMessagesRequest,
         ChatHookDeleteMessagesResponse, ChatHookDeleteMessagesRequest } from "ui/hook"

import { Chat, UiChatMessage, copyUiDocument,
         chatMessageToUi, ReadonlyUiChatMessage, Page } from "ui/component"

import { Flex, FlexItem, Pane } from "ui/ui"
import DebugPane from "../DebugPane"

import { chatHookReadyStateToConnectionStatus,
         isUiChatMessageUnread, uiChatMessagesToUniqueAndSorted } from "../util"

import { deleteUnsentMessengerMessageById, getUnsentMessengerMessages,
         pushUnsentMessengerMessage, unsentMessageToUi } from "../storage"

// Consts

const LOAD_MORE_COUNT = 128
const LOAD_MORE_OFFSET = 512

// Types

type MessagesAction =
    | "merge"
    | "replace"

// Component

export default function ClientMessengerPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const chatHook = useChat({
        onResponse: onChatResponse,
        shouldReconnect: true,
        reconnectInterval: 5 * SECOND_MILLIS,
        reconnectAttempts: Infinity, // I think thats enough...
    })

    // State

    const [messages, setMessages] = useState(
        getUnsentMessengerMessages().map(message => unsentMessageToUi(message, { localUser }))
    )

    const [hasMoreToLoad, setHasMoreToLoad] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)
    const [loadingMoreError, setLoadingMoreError] = useState(undefined as unknown)

    const sentRequestsByIdRef = useRef(new Map<string, ChatHookRequest>())

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onViewMessagesMemo = useCallback(onViewMessages, [messages])

    // Render

    return <Page title={t("sections.messenger.headers.page")}
                 type="main">
        <Flex height="100%">
            {renderContentPane()}

            {process.env.NODE_ENV === "development" &&
                <FlexItem width="100%"
                          shrink={1}>
                    {renderDebugPane()}
                </FlexItem>
            }
        </Flex>
    </Page>

    function renderContentPane(): ReactNode {
        return <Pane contentPadding="0">
            {renderChat()}
        </Pane>
    }

    function renderChat(): ReactNode {
        return <Chat onViewMessages={onViewMessagesMemo}

                     onDeleteMessage={onDeleteMessage}
                     onResendMessage={onResendMessage}

                     senderId={localUser?.id}
                     sender={() => t("misc.words.you")}
                     onSend={onSendMessage}

                     onLoadMore={onLoadMore}
                     hasMoreToLoad={hasMoreToLoad}
                     loadingMore={loadingMore}
                     loadingMoreError={loadingMoreError}
                     loadMoreOffset={LOAD_MORE_OFFSET}

                     messages={messages}
                     onMessagesChange={updateMessages}

                     showScrollDownButton/>

        function onDeleteMessage(message: ReadonlyUiChatMessage, messageIndex: number) {
            if (!message.local)
                return

            updateMessages(splicedArray(messages, messageIndex, 1))

            if (typeof message.id !== "string")
                return

            switch (message.status ?? "sent") {
                case "error": {
                    deleteUnsentMessengerMessageById(message.id)
                    break
                }

                case "read":
                case "sent":
                case "sending": {
                    const request: ChatHookDeleteMessagesRequest = {
                        type: "delete",
                        messageIds: [message.id],
                    }

                    sendChatRequest(request)

                    break
                }
            }
        }

        function onResendMessage(message: ReadonlyUiChatMessage) {
            if (!message.local)
                return

            const request: ChatHookSendMessageRequest = {
                type: "send-message",

                id: typeof message.id === "string"
                    ? message.id
                    : generateRandomUuid(),

                text: message.text,

                documentIds: message.documents
                    ?.filter(({ status }) => status === "ready")
                    .map(document => document.document!.id),
            }

            sendChatRequest(request)

            const newMessage: UiChatMessage = {
                documents: message.documents?.map(copyUiDocument),
                date: new Date(message.date.getTime()),
                status: "sent",
            }

            updateMessages([newMessage], "merge")
        }

        function onSendMessage(message: UiChatMessage): UiChatMessage | undefined {
            message.id = generateRandomUuid()
            message.status = "sending"

            const request: ChatHookSendMessageRequest = {
                type: "send-message",
                id: message.id,
                text: message.text,

                documentIds: message.documents
                    ?.filter(({ status }) => status === "ready")
                    .map(document => document.document!.id),
            }

            sendChatRequest(request)

            return message
        }

        function onLoadMore() {
            const lastMessage = messages.length > 0
                ? messages[0]
                : null

            const request: ChatHookLoadMessagesRequest = {
                id: generateRandomUuid(),
                type: "load-messages",
                before: lastMessage?.date ?? new Date(),
                limit: LOAD_MORE_COUNT,
            }

            sendChatRequest(request)
            setLoadingMore(true)
        }
    }

    function renderDebugPane(): ReactNode {
        return <DebugPane connectionStatus={chatHookReadyStateToConnectionStatus(chatHook.readyState)}
                          onDisconnect={() => chatHook.close()}
                          messageCount={messages.length}
                          contentOverflow="auto"/>
    }

    // Events

    // - UI

    function onViewMessages(messages: UiChatMessage[]) {
        const unreadMessages = messages.filter(
            message =>
                isUiChatMessageUnread(message) &&
                typeof message.id === "string"
        )

        if (unreadMessages.length === 0)
            return

        const unreadMessageIds = new Set(unreadMessages.map(({ id }) => id) as string[])

        for (const request of sentRequestsByIdRef.current.values())
            if (request.type === "mark-read")
                for (const messageId of request.messageIds)
                    unreadMessageIds.delete(messageId)

        if (unreadMessageIds.size === 0)
            return

        const request: ChatHookMarkMessagesReadRequest = {
            type: "mark-read",
            id: generateRandomUuid(),
            messageIds: [...unreadMessageIds],
        }

        sendChatRequest(request)
    }

    // - WS

    function onChatResponse(response: ChatHookResponse) {
        switch (response.type) {
            case "messages":
                onMessagesChatResponse(response)
                break

            case "info":
                break

            case "mark-read":
                onMarkReadChatResponse(response)
                break

            case "delete":
                onDeleteChatResponse(response)
                break

            case "request-verification":
                onRequestVerificationChatResponse(response)
                break

            default:
                response satisfies never
                break
        }

        if (response.requestId != null)
            sentRequestsByIdRef.current.delete(response.requestId)
    }

    function onMessagesChatResponse({ messages, requestId }: ChatHookMessagesResponse) {
        tryHandleAsLoadMoreRequestResponse()

        updateMessages(
            messages.map(message => chatMessageToUi(message, { localUser })),
            "merge",
        )

        function tryHandleAsLoadMoreRequestResponse() {
            if (requestId == null)
                return

            const request = sentRequestsByIdRef.current.get(requestId)

            if (request == null || request.type !== "load-messages")
                return

            setHasMoreToLoad(messages.length >= LOAD_MORE_COUNT)
            setLoadingMore(false)
        }
    }

    function onMarkReadChatResponse({ messageIds }: ChatHookMarkMessagesReadResponse) {
        const messageIdsSet = new Set(messageIds)
        const markedMessages = messages.filter(
            ({ id }) => typeof id === "string" && messageIdsSet.has(id),
        )

        for (const message of markedMessages)
            message.status = "read"

        updateMessages(markedMessages, "merge")
    }

    function onDeleteChatResponse({ messageIds }: ChatHookDeleteMessagesResponse) {
        const messageIdsSet = new Set(messageIds)

        const newMessages = messages.filter(
            ({ id }) => typeof id !== "string" || !messageIdsSet.has(id)
        )

        updateMessages(newMessages)
    }

    function onRequestVerificationChatResponse(response: ChatHookRequestVerificationResponse) {
        const { requestId, error } = response

        const request = sentRequestsByIdRef.current.get(requestId)

        if (request == null)
            return

        if (error != null)
            console.error(`Request ${requestId} failed: ${error}`)

        switch (request.type) {
            case "send-message":
                handleSendMessageRequestVerification(request)
                break

            case "load-messages":
                handleLoadMessagesRequestVerification()
                break
        }

        function handleSendMessageRequestVerification(request: ChatHookSendMessageRequest) {
            deleteUnsentMessengerMessageById(requestId)

            const message = messages.find(({ id }) => id === requestId)

            if (message == null)
                return

            const newMessage: UiChatMessage = {
                ...message,

                status: error != null
                    ? "error"
                    : "sent",
            }

            updateMessages([newMessage], "merge")

            if (error != null)
                pushUnsentMessengerMessage(request)
        }

        function handleLoadMessagesRequestVerification() {
            if (error != null)
                setLoadingMoreError(t("errors.loadingFailed"))

            setLoadingMore(false)
        }
    }

    // Util

    // - UI

    function updateMessages(updateMessages: UiChatMessage[], action?: MessagesAction | null) {
        setMessages(oldMessages =>
            uiChatMessagesToUniqueAndSorted(
                action === "merge"
                    ? [...oldMessages, ...updateMessages]
                    : updateMessages
            )
        )
    }

    // - WS

    function sendChatRequest(request: ChatHookRequest) {
        chatHook.sendRequest(request)

        if (request.id != null)
            sentRequestsByIdRef.current.set(request.id, request)
    }
}
