import { randomInt } from "./math"

export const DEFAULT_RANDOM_PASSWORD_LENGTH = 10

export const RANDOM_PASSWORD_ALPHABET = "abcdefghijklmnopqrstuvwxyz"
                                      + "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                                      + "0123456789"

export function generateRandomPassword(length?: number): string {
    const chars = new Array<string>(length ?? DEFAULT_RANDOM_PASSWORD_LENGTH)

    for (let i = 0; i < chars.length; ++i)
        chars[i] = RANDOM_PASSWORD_ALPHABET[randomInt(0, RANDOM_PASSWORD_ALPHABET.length - 1)]

    return chars.join("")
}
