import { ForwardedRef, forwardRef } from "react"

export interface GapProps {
    width?: string
    height?: string
}

const Gap = forwardRef((
    { width, height }: Readonly<GapProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => 
    <div style={{ width, height }}
         ref={ref}/>
)

Gap.displayName = "Gap"

export default Gap
