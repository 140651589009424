const SEP = "/"

export function joinSubpaths(...subpaths: string[]): string {
    const normalized = subpaths.map(
        subpath => subpath.startsWith(SEP)
        ? subpath.substring(1)
        : subpath
    )

    const filtered = normalized.filter(Boolean)
    const joined = filtered.join(SEP)

    return SEP + joined
}

export function subpathWithoutFirstSep(subpath: string): string {
    return subpath.startsWith(SEP)
        ? subpath.substring(1)
        : subpath
}
