import { ForwardedRef, forwardRef } from "react"

export interface LabelProps {
    text?: string
    fontWeight?: string
    fontSize?: string
    wrap?: boolean
}

const Label = forwardRef((
    {
        text,
        fontWeight,
        fontSize,
        wrap,
    }: Readonly<LabelProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    return <span
        style={{
            display: text ? undefined : "none",
            fontSize: fontSize ?? "16px",
            fontWeight: fontWeight ?? "normal",
            whiteSpace: wrap ? undefined : "nowrap",
        }}
        ref={ref}
    >
        {text}
    </span>
})

Label.displayName = "Label"

export default Label
