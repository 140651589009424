import { get, post, del, countEntities, isEntityExists, tryDeleteEntity } from "api/http/util"
import { InviteApplicationRequest } from "api/request"
import { InviteApplicationResponseSchema, MfaResponse, MfaResponseSchema } from "api/response"
import { InviteApplication } from "model"

export const INVITE_APPLICATIONS_SUBPATH = "/invite-applications"

export async function countInviteApplications(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${INVITE_APPLICATIONS_SUBPATH}/count`, signal)
}

export async function isInviteApplicationExistsById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return isEntityExists(`/${INVITE_APPLICATIONS_SUBPATH}/${id}/exists`, signal)
}

export async function getAllInviteApplications(signal?: AbortSignal | null): Promise<InviteApplication[]> {
    return (await get({
        subpath: INVITE_APPLICATIONS_SUBPATH, 
        schema: InviteApplicationResponseSchema.array(),
        signal,
    })).map(response => new InviteApplication(response))
}

export async function getInviteApplicationById(id: string, signal?: AbortSignal | null): Promise<InviteApplication> {
    return new InviteApplication(await get({
        subpath: `${INVITE_APPLICATIONS_SUBPATH}/${id}`,
        schema: InviteApplicationResponseSchema,
        signal,
    }))
}

export async function clearInviteApplications(signal?: AbortSignal | null) {
    return del({
        subpath: INVITE_APPLICATIONS_SUBPATH,
        signal,
    })
}

export async function deleteInviteApplicationById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: `${INVITE_APPLICATIONS_SUBPATH}/${id}`,
        signal,
    })
}

export async function tryDeleteInviteApplicationById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return tryDeleteEntity(`${INVITE_APPLICATIONS_SUBPATH}/${id}`, signal)
}

export async function createInviteApplication(
    request: Readonly<InviteApplicationRequest>,
    signal?: AbortSignal | null,
): Promise<MfaResponse> {
    return await post({
        subpath: INVITE_APPLICATIONS_SUBPATH,
        schema: MfaResponseSchema,
        body: request,
        signal,
    })
}

