import { HTMLInputAutoCompleteAttribute, ForwardedRef, forwardRef, useEffect, useRef } from "react"
import { MessageTargetType, determineNullableMessageTargetType } from "model"
import { fixLayout, formatPhoneAsYouType, removeWhiteSpace, trimNullableToNull } from "my-util"
import { useStateWithDeps } from "ui/hook"
import Input from "ui/ui/Input"

const DEFAULT_COUNTRY = "RU"

export interface MessageTargetInputProps {
    onChange?: OnMessageTargetInputChange
    value?: string

    width?: string
    height?: string

    invalid?: boolean
    disabled?: boolean
    readonly?: boolean
    loading?: boolean
    required?: boolean

    autoFocus?: boolean
    autoComplete?: HTMLInputAutoCompleteAttribute

    information?: string
    placeholder?: string
    label?: string

    iconSrc?: string
    iconAlt?: string
    iconFilter?: string
}

export type OnMessageTargetInputChange = (
    messageTarget: string,
    messageTargetType: MessageTargetType | null,
) => void

const MessageTargetInput = forwardRef((
    {
        onChange, value,
        width, height,
        invalid, disabled, readonly, loading, required,
        autoFocus, autoComplete,
        information, placeholder, label,
        iconSrc, iconAlt, iconFilter,
    }: Readonly<MessageTargetInputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const changedRef = useRef(false)
    const layoutFixTimerRef = useRef(undefined as number | undefined)

    const [innerValue, setInnerValue] = useStateWithDeps<string>(
        oldValue => changedRef.current && oldValue != null
            ? oldValue
            : value != null
                ? postProcess(value)
                : "",

        [value],
    )

    const [messageTargetType, setMessageTargetType] = useStateWithDeps(
        () => determineNullableMessageTargetTypeTrimming(value),
        [value],
    )

    // Effects

    // - State propagation

    useEffect(() => {
        if (changedRef.current) {
            onChange?.(innerValue, messageTargetType)
            changedRef.current = false
        }
    }, [innerValue, messageTargetType, onChange])

    // Render

    return <Input onChange={onInnerChange}
                  value={innerValue}

                  postProcess={postProcess}

                  left={renderLeft()}

                  width={width}
                  height={height}

                  invalid={invalid}
                  disabled={disabled}
                  readonly={readonly}
                  loading={loading}
                  required={required}

                  autoFocus={autoFocus}
                  autoComplete={autoComplete}

                  placeholder={placeholder}
                  label={label}
                  information={information}

                  iconSrc={iconSrc}
                  iconAlt={iconAlt}
                  iconFilter={iconFilter}

                  ref={ref}/>

    function renderLeft(): string | undefined {
        return messageTargetType === "phone"
            ? "+"
            : undefined
    }

    // Events

    function onInnerChange(newValue: string) {
        if (layoutFixTimerRef.current != null) {
            window.clearTimeout(layoutFixTimerRef.current)
            layoutFixTimerRef.current = undefined
        }

        const newMessageTargetType = determineNullableMessageTargetTypeTrimming(newValue)

        switch (newMessageTargetType) {
            case "phone":
                newValue = "+" + newValue.replaceAll(/\s+/g, "")
                break

            case "email":
                layoutFixTimerRef.current = window.setTimeout(
                    () => {
                        updateInnerValue(fixLayout(newValue, { whitelist: "\"" }), "email")
                        layoutFixTimerRef.current = undefined
                    },

                    200,
                )

                break
        }

        updateInnerValue(newValue, newMessageTargetType)
    }

    // Util

    function postProcess(value: string): string {
        switch (determineNullableMessageTargetTypeTrimming(value)) {
            case "phone":
                return formatPhoneAsYouType(value, DEFAULT_COUNTRY)

            case "email":
                return removeWhiteSpace(value)

            default:
                return value
        }
    }

    function determineNullableMessageTargetTypeTrimming(
        value?: string | null,
    ): MessageTargetType | null {
        return determineNullableMessageTargetType(trimNullableToNull(value))
    }

    function updateInnerValue(newValue: string, newMessageTargetType: MessageTargetType | null) {
        setInnerValue(newValue)
        setMessageTargetType(newMessageTargetType)

        changedRef.current = true
    }
})

MessageTargetInput.displayName = "MessageTargetInput"

export default MessageTargetInput
