import { z } from "zod"
import { UuidSchema } from "my-util"
import { TransferStatusResponseSchema } from "../domain"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const TransferStatusChangedNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal("transfer-status-changed" satisfies NotificationTypeResponse),
    transferId: UuidSchema,
    from: TransferStatusResponseSchema,
    to: TransferStatusResponseSchema,
})

export type TransferStatusChangedNotificationResponse = z.infer<typeof TransferStatusChangedNotificationResponseSchema>
