import { ForwardedRef, forwardRef } from "react"
import { getTransferStatusColors, TransferStatus, transferStatusToString } from "model"
import Badge from "ui/ui/Badge"

export interface TransferStatusBadgeProps {
    status: TransferStatus
}

const TransferStatusBadge = forwardRef((
    { status }: Readonly<TransferStatusBadgeProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const colors = getTransferStatusColors(status)

    return <Badge { ...colors }
                  ref={ref}>
        {transferStatusToString(status)}
    </Badge>
})

TransferStatusBadge.displayName = "TransferStatusBadge"

export default TransferStatusBadge
