export function capitalizedText(text: string): string {
    const chars = text.split("")
    const newChars = new Array<string>()

    let lastSpace = true

    for (let char of chars) {
        const currentSpace = char.match(/\s/) != null

        if (!currentSpace)
            char = lastSpace
                ? char.toUpperCase()
                : char.toLowerCase()

        lastSpace = currentSpace

        newChars.push(char)
    }

    return newChars.join("")
}

export function capitalized(s: string): string {
    return withFirstUpper(s.toLowerCase())
}

export function withFirstUpper(s: string): string {
    return s.length !== 0
        ? s[0].toUpperCase() + s.substring(1)
        : ""
}
