import { ForwardedRef, forwardRef } from "react"
import { Count } from "ui/ui/count"
import { Flex } from "ui/ui/layout"
import style from "./style.module.css"

export interface DataProps {
    label: string
    value?: number
    important?: boolean

    width?: string
}

const Data = forwardRef((
    {
        label, value, important,
        width,
    }: Readonly<DataProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div className={style.CountDataRow}
         style={{ width }}
         ref={ref}>
        <Flex direction="horizontal"
              justify="space-between">
            <div className={style.label}>
                {label}
            </div>

            <Count important={important}
                   value={value}/>
        </Flex>
    </div>
)

Data.displayName = "Data"

export default Data
