import { z } from "zod"
import { parse, toSeconds } from "iso8601-duration"

const Iso8601DurationSchema = z.string().transform((arg, ctx) => {
    try {
        return toSeconds(parse(arg))
    } catch {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "not an ISO-8601 duration",
        })

        return z.NEVER
    }
})

export default Iso8601DurationSchema
