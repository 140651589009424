import { TransferStatus } from "model/domain"
import { DeepReadonly, Nullish, tryNormalizeUuid } from "my-util"

import AbstractNotification,
       { NotificationType,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

export interface TransferStatusChangedNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">
{
    transferId: string
    from: TransferStatus
    to: TransferStatus
}

export interface TransferStatusChangedNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<TransferStatusChangedNotificationCreationOptions>
{}

export default class TransferStatusChangedNotification
    extends
        AbstractNotification<"transfer-status-changed">
{
    static createOrPass(
        arg: TransferStatusChangedNotification
           | DeepReadonly<TransferStatusChangedNotificationCreationOptions>
    ): TransferStatusChangedNotification {
        return arg instanceof TransferStatusChangedNotification
            ? arg
            : new TransferStatusChangedNotification(arg)
    }

    readonly transferId: string
    readonly from: TransferStatus
    readonly to: TransferStatus

    constructor(options: DeepReadonly<TransferStatusChangedNotificationCreationOptions>) {
        super({ ...options, type: "transfer-status-changed" })

        this.transferId = tryNormalizeUuid(options.transferId)
        this.from = options.from
        this.to = options.to
    }

    override copyOrPass(
        arg?: TransferStatusChangedNotification
            | DeepReadonly<TransferStatusChangedNotificationCopyOptions>
            | null
    ): TransferStatusChangedNotification {
        if (arg == null)
            return this

        if (arg instanceof TransferStatusChangedNotification)
            return arg
        
        return this.copy(arg)
    }

    override copy(
        options: DeepReadonly<TransferStatusChangedNotificationCopyOptions> = {},
    ): TransferStatusChangedNotification {
        return new TransferStatusChangedNotification({
            transferId: options.transferId ?? this.transferId,
            from: options.from ?? this.from,
            to: options.to ?? this.to,

            // AbstractNotification

            recipientId: options.recipientId ?? this.recipientId,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
