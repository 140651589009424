import { ForwardedRef, forwardRef } from "react"
import { getLang } from "i18n"
import { Flex, Limit } from "ui/ui/layout"
import Output from "ui/ui/Output"

export interface DiLangOutputProps {
    enValue?: string
    ruValue?: string

    label?: string

    maxWidth?: string
}

const DiLangOutput = forwardRef((
    {
        enValue, ruValue,
        label,
        maxWidth,
    }: Readonly<DiLangOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const lang = getLang()
    const ru = lang === "ru"

    return <Flex direction="horizontal"
                 align="end"
                 ref={ref}
                 wrap>
        <Limit maxWidth={maxWidth}>
            <Output whiteSpace="nowrap"
                    label={label}>
                {ru ? ruValue : enValue}
            </Output>
        </Limit>

        <Limit maxWidth={maxWidth}>
            <Output backgroundColor="rgba(0, 0, 0, 0)"
                    borderColor="rgba(0, 0, 0, 0)"
                    whiteSpace="nowrap">
                {ru ? enValue : ruValue}
            </Output>
        </Limit>
    </Flex>
})

DiLangOutput.displayName = "DiLangOutput"

export default DiLangOutput
