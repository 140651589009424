import { ForwardedRef, forwardRef } from "react"
import { Transfer } from "model"
import { MONTH_INDEXES } from "my-util"
import { Flex, FlexProps } from "ui/ui/layout"
import SmallMonthCalendar from "../SmallMonthCalendar"

export interface YearCalendarProps extends FlexProps {
    onDateClick?: (date: Date) => void

    year?: number

    transfers?: Transfer[]
}

const YearCalendar = forwardRef((
    props: Readonly<YearCalendarProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { onDateClick, year, transfers } = props

    return <Flex { ...props }
                 ref={ref}>
        {MONTH_INDEXES.map(month =>
            <SmallMonthCalendar onDateClick={onDateClick}
                                transfers={transfers}
                                monthIndex={month}
                                year={year}
                                key={month}/>
        )}
    </Flex>
})

YearCalendar.displayName = "YearCalendar"

export default YearCalendar
