import { useContext } from "react"
import { UserContext } from "ui/context"
import Private from "./Private"
import Public from "./Public"

export default function MainPage() {
    const [localUser] = useContext(UserContext)

    return localUser != null
        ? <Private/>
        : <Public/>
}
