import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import ErrorPage from "../ErrorPage"

export interface SessionExpiredErrorPageProps {
    message?: string
}

const SessionExpiredErrorPage = forwardRef((
    { message}: Readonly<SessionExpiredErrorPageProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <ErrorPage
        header={{
            text: t("errors.pages.sessionExpired.header"),
            fontSize: "36px",
        }}
        message={{
            text: message ?? t("errors.pages.sessionExpired.defaultMessage"),
            fontSize: "16px",
        }}
        linkType="relogin"
        ref={ref}
    />
})

SessionExpiredErrorPage.displayName = "SessionExpiredErrorPage"

export default SessionExpiredErrorPage
