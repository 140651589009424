import { determineMessageTargetType } from "model"

type LoginRequest =
    | LoginByPhoneRequest
    | LoginByEmailRequest

export default LoginRequest

export interface LoginByPhoneRequest extends LoginRequestBase {
    phone: string
}

export interface LoginByEmailRequest extends LoginRequestBase {
    email: string
}

export interface LoginRequestBase {
    id: string

    phone?: string | null
    email?: string | null

    password: string
}

export function createLoginRequestFromMessageTarget(
    id: string,
    username: string,
    password: string,
): LoginRequest {
    switch (determineMessageTargetType(username)) {
        case "phone": 
            return { id, password, phone: username }

        case "email":
            return { id, password, email: username }
    }
}
