import { ForwardedRef, forwardRef } from "react"
import { arrowDownIconUrl } from "image"
import Icon from "../Icon"

export const IMPORT_ICON_SRC = arrowDownIconUrl
export const IMPORT_ICON_FILTER = "brightness(0) saturate(100%) invert(53%) sepia(39%) saturate(594%) hue-rotate(95deg) brightness(91%) contrast(95%)"
export const IMPORT_ICON_ALT = "Import icon"

export interface ImportIconProps {
    onClick?: () => void

    cursor?: string

    width?: string
    height?: string
}

const ImportIcon = forwardRef((
    {
        onClick,
        cursor,
        width, height,
    }: Readonly<ImportIconProps>,
    ref: ForwardedRef<HTMLImageElement>,
) =>
    <Icon src={IMPORT_ICON_SRC}
          filter={IMPORT_ICON_FILTER}
          alt={IMPORT_ICON_ALT}

          onClick={onClick}

          cursor={cursor}

          width={width}
          height={height}

          ref={ref}/>
)

ImportIcon.displayName = "ImportIcon"

export default ImportIcon
