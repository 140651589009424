import { z } from "zod"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewInviteApplicationNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal("new-invite-application" satisfies NotificationTypeResponse),
    inviteApplicationId: UuidSchema,
})

export type NewInviteApplicationNotificationResponse = z.infer<typeof NewInviteApplicationNotificationResponseSchema>
