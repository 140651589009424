import { UUID_REGEX } from "./regex"

export function tryNormalizeNullableUuid(s: string | undefined | null): string | null {
    return s != null
        ? tryNormalizeUuid(s)
        : null
}

export function tryNormalizeUuid(s: string): string {
    return isUuid(s)
        ? normalizeUuid(s)
        : s
}

export function normalizeNullableUuid(s: string | undefined | null): string | null {
    return s != null
        ? normalizeUuid(s)
        : null
}

export function normalizeUuid(s: string): string {
    const match = s.match(UUID_REGEX)

    if (match == null)
        throw Error("Not a valid UUID")

    return [
        match[1].padStart(8, "0"),

        match[2].padStart(4, "0"),
        match[3].padStart(4, "0"),
        match[4].padStart(4, "0"),

        match[5].padStart(12, "0"),
    ]
        .join("-")
        .toLowerCase()
}

export function isUuid(s: string): boolean {
    return s.match(UUID_REGEX) != null
}
