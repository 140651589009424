import getSymbolFromCurrency from "currency-symbol-map"
import Decimal from "decimal.js"
import { createOrPassDecimal, formatDecimal } from "my-util"

export interface MoneyCreationOptions {
    amount?: Decimal | number | string | null
    currency?: string
}

export interface MoneyCopyOptions extends MoneyCreationOptions {}

export default class Money {
    static createOrPass(arg?: Money | Readonly<MoneyCreationOptions> | null): Money {
        if (arg == null)
            return new Money()

        if (arg instanceof Money)
            return arg

        return new Money(arg)
    }

    readonly amount: Decimal
    readonly currency: string

    constructor({ amount, currency }: Readonly<MoneyCreationOptions> = {}) {
        this.amount = createOrPassDecimal(amount)
        this.currency = currency ?? "USD"
    }

    copyOrPass(arg?: Money | Readonly<MoneyCopyOptions> | null): Money {
        if (arg == null)
            return this

        if (arg instanceof Money)
            return arg

        return this.copy(arg)
    }

    copy(options: Readonly<MoneyCopyOptions> = {}): Money {
        return new Money({
            amount: options.amount ?? this.amount,
            currency: options.currency ?? this.currency,
        })
    }

    toString(precision?: number): string {
        return getSymbolFromCurrency(this.currency)
             + " "
             + formatDecimal(this.amount, precision)
    }
}
