import { ForwardedRef, forwardRef, ReactNode, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { providersSectionIconUrl,
         adminsSectionIconUrl, agentsSectionIconUrl,
         archiveSectionIconUrl, calendarSectionIconUrl,
         clientsSectionIconUrl, documentsSectionIconUrl,
         messagesSectionIconUrl, transfersSectionIconUrl } from "image"

import { getLang } from "i18n"
import { UserContext } from "ui/context"
import { ALL_TRANSFERS_PAGE_PATH } from "ui/page/sections/transfers/AllTransfersPage/path"
import { CALENDAR_PAGE_PATH } from "ui/page/sections/calendar/CalendarPage/path"
import { ALL_DOCUMENTS_PAGE_PATH } from "ui/page/sections/documents/AllDocumentsPage/path"
import { ALL_ARCHIVED_TRANSFERS_PAGE_PATH } from "ui/page/sections/archive/AllArchivedTransfersPage/path"
import { MESSENGER_PAGE_PATH } from "ui/page/sections/messenger/MessengerPage/path"
import { ALL_ADMINS_PAGE_PATH } from "ui/page/sections/admins/AllAdminsPage/path"
import { ALL_CLIENTS_PAGE_PATH } from "ui/page/sections/clients/AllClientsPage/path"
import { ALL_AGENTS_PAGE_PATH } from "ui/page/sections/agents/AllAgentsPage/path"
import { ALL_PROVIDERS_PAGE_PATH } from "ui/page/sections/providers/AllProvidersPage/path"
import { Flex, UserRoleArrow } from "ui/ui"
import SectionLink from "../SectionLink"
import style from "./style.module.css"

const VERTICAL_PADDING = 32
const LINK_GROUP_GAP = 32
const LINK_GAP = 16
const LINK_HEIGHT = 32

interface Link {
    to: string
    text?: string
    iconSrc?: string
}

export interface SectionLinkListProps {
    short?: boolean

    firstRender?: boolean
}

const SectionLinkList = forwardRef((
    {
        short,
        firstRender,
    }: Readonly<SectionLinkListProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    const [activeLinkIndex, setActiveLinkIndex] = useState([-1, -1])

    const linkGroups = useMemo<Link[][]>(() => {
        const linkGroups: Link[][] = [
            [
                {
                    to: ALL_TRANSFERS_PAGE_PATH,
                    text: t("sections.transfers.link"),
                    iconSrc: transfersSectionIconUrl,
                },
            ],

            [
                {
                    to: CALENDAR_PAGE_PATH,
                    text: t("sections.calendar.link"),
                    iconSrc: calendarSectionIconUrl,
                },

                {
                    to: ALL_DOCUMENTS_PAGE_PATH,
                    text: t("sections.documents.link"),
                    iconSrc: documentsSectionIconUrl,
                },

                {
                    to: ALL_ARCHIVED_TRANSFERS_PAGE_PATH,
                    text: t("sections.archive.link"),
                    iconSrc: archiveSectionIconUrl,
                },

                {
                    to: MESSENGER_PAGE_PATH,
                    text: t("sections.messenger.link"),
                    iconSrc: messagesSectionIconUrl,
                },
            ]
        ]

        if (localUser?.isAgent) {
            const groupLinks: Link[] = []

            if (localUser.isManager)
                groupLinks.push({
                    to: ALL_ADMINS_PAGE_PATH,
                    text: t("sections.admins.link"),
                    iconSrc: adminsSectionIconUrl,
                })

            if (localUser.isAgent)
                groupLinks.push({
                    to: ALL_CLIENTS_PAGE_PATH,
                    text: t("sections.clients.link"),
                    iconSrc: clientsSectionIconUrl,
                })


            if (localUser.isManager)
                groupLinks.push(
                    {
                        to: ALL_AGENTS_PAGE_PATH,
                        text: t("sections.agents.link"),
                        iconSrc: agentsSectionIconUrl,
                    },

                    {
                        to: ALL_PROVIDERS_PAGE_PATH,
                        text: t("sections.providers.link"),
                        iconSrc: providersSectionIconUrl,
                    },
                )

            linkGroups.push(groupLinks)
        }

        return linkGroups
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localUser, getLang()])

    const arrowTop = useMemo(() => {
        const [linkGroupIndex, linkIndex] = activeLinkIndex

        if (linkGroupIndex < 0 || linkIndex < 0)
            return -1

        let top = VERTICAL_PADDING + (LINK_GAP + LINK_HEIGHT) * linkIndex

        for (let i = 0; i < linkGroupIndex; ++i)
            top +=
                linkGroups[i].length * (LINK_GAP + LINK_HEIGHT) +
                LINK_GROUP_GAP -
                LINK_GAP

        return top
    }, [linkGroups, activeLinkIndex])

    // Render

    return <div style={{ padding: `${VERTICAL_PADDING}px 16px` }}
                className={renderClassName()}
                ref={ref}>
        {renderLinkGroups()}
        {renderArrow()}
    </div>

    function renderClassName(): string {
        if (firstRender)
            return short ? style.HiddenSectionLinkList : style.SectionLinkList

        if (short)
            return style.HidingSectionLinkList

        return style.AppearingSectionLinkList
    }

    function renderLinkGroups(): ReactNode {
        return <div className={style.linkGroups}>
            <Flex gap={LINK_GROUP_GAP + "px"}
                  align="start">
                {linkGroups.map((links, i) =>
                    <Flex gap={LINK_GAP + "px"}
                          align="start"
                          key={i}>
                        {links.map(({ to, text, iconSrc }, j) =>
                            <SectionLink onIsActiveChange={isActive => onIsLinkActiveChange(isActive, i, j)}
                                         height={LINK_HEIGHT + "px"}
                                         firstRender={firstRender}
                                         iconSrc={iconSrc}
                                         short={short}
                                         text={text}
                                         to={to}
                                         key={j}/>
                        )}
                    </Flex>
                )}
            </Flex>
        </div>
    }

    function renderArrow(): ReactNode {
        if (arrowTop < 0)
            return null

        return <div style={{ top: arrowTop }}
                    className={style.arrow}>
            <UserRoleArrow role={localUser?.role}/>
        </div>
    }

    // Events

    function onIsLinkActiveChange(isActive: boolean, linkGroupIndex: number, linkIndex: number) {
        if (isActive && linkGroupIndex !== activeLinkIndex[0] && linkIndex !== activeLinkIndex[1])
            setActiveLinkIndex([linkGroupIndex, linkIndex])
    }
})

SectionLinkList.displayName = "SectionLinkList"

export default SectionLinkList
