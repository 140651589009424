import { ReactNode, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { cancelMfa, getAllUsers, patchUserById } from "api"
import { User } from "model"
import { useStateWithDeps, useUsers } from "ui/hook"

import { CheckBox, ErrorDisplay, Flex, Form, Limit,
         FormControls, FormControlsButton, Loading, Pane } from "ui/ui"

import { UserCheckList } from "ui/component"
import style from "./style.module.css"

export interface RightsTabProps {
    onUserChange?: (user: User) => void
    user: User

    readonly?: boolean

    mobile?: boolean
}

export default function RightsTab(
    {
        onUserChange, user,
        readonly,
        mobile,
    }: Readonly<RightsTabProps>,
) {
    const [t] = useTranslation()

    const navigate = useNavigate()

    const storedUsers = useUsers()

    // State

    const [canManageTransfers, setCanManageTransfers] = useStateWithDeps(
        () => user.canManageTransfers,
        [user.canManageTransfers],
    )

    const [canManageInvites, setCanManageInvites] = useStateWithDeps(
        () => user.canManageInvites,
        [user.canManageInvites],
    )

    const [canManageUsers, setCanManageUsers] = useStateWithDeps(
        () => user.canManageUsers,
        [user.canManageUsers],
    )

    const [canSeeAllUsers, setCanSeeAllUsers] = useStateWithDeps(
        () => user.canSeeAllUsers,
        [user.canSeeAllUsers],
    )

    const [visibleUserIds, setVisibleUserIds] = useStateWithDeps(
        () => [...user.visibleUserIds],
        [user.visibleUserIds],
    )

    const [usersById, setUsersById] = useState(null as Map<string, User> | null)
    const [loadingUsers, setLoadingUsers] = useState(!user.canSeeAllUsers)
    const [usersLoadingError, setUsersLoadingError] = useState(undefined as unknown)

    const [updating, setUpdating] = useState(false)
    const [updateError, setUpdateError] = useState(undefined as unknown)

    // Effects

    useEffect(() => {
        if (!loadingUsers)
            return

        const controller = new AbortController()

        getAllUsers(controller.signal)
            .then(users => {
                setUsersById(User.groupById(users))
                storedUsers.addAll(users)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingUsers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers, readonly])

    // Render

    return <Form onSubmit={onSubmit}
                 loading={false}
                 height="100%">
        <Flex justify="space-between"
              align="start"
              height="100%">
            <Limit maxWidth="400px">
                {renderFields()}
            </Limit>

            {renderControls()}
        </Flex>
    </Form>

    function renderFields(): ReactNode {
        return <Flex align="start">
            <CheckBox label={t("domain.userRights.labels.canManageTransfers")}

                      onChange={setCanManageTransfers}
                      checked={canManageTransfers}

                      readonly={readonly}
                      loading={updating}/>

            <CheckBox label={t("domain.userRights.labels.canManageInvites")}

                      onChange={setCanManageInvites}
                      checked={canManageInvites}

                      readonly={readonly}
                      loading={updating}/>

            <CheckBox label={t("domain.userRights.labels.canManageUsers")}

                      onChange={setCanManageUsers}
                      checked={canManageUsers}

                      readonly={readonly}
                      loading={updating}/>

            <CheckBox label={t("domain.userRights.labels.canNotSeeAllUsers")}

                      onChange={checked => onCanSeeAllUsersChange(!checked)}
                      checked={!canSeeAllUsers}

                      readonly={readonly}
                      loading={updating}/>

            {!canSeeAllUsers &&
                <Pane header={t("domain.userRights.labels.visibleUsers")}
                      paneStyle="widget"
                      width="100%">
                    {(() => {
                        if (usersLoadingError != null)
                            return <ErrorDisplay error={usersLoadingError}/>

                        if (usersById == null)
                            return <Loading/>

                        return <div className={style.userList}>
                            <UserCheckList onChange={onVisibleUsersChange}
                                           checked={visibleUserIds}

                                           users={usersById}

                                           readonly={readonly}
                                           loading={updating}/>
                        </div>
                    })()}
                </Pane>
            }
        </Flex>
    }

    function renderControls(): ReactNode {
        const buttons: FormControlsButton[] = [
            {
                onClick() { navigate(-1) },
                text: t("misc.buttons.back"),
                buttonStyle: "outline",
                position: "right",
            }
        ]

        if (hasRightsChanged())
            buttons.push({
                text: t("misc.buttons.save"),
                position: "left",
                type: "submit",
                loading: updating,
            })

        return <FormControls buttons={buttons}
                             error={updateError}
                             mobile={mobile}/>
    }

    // Events

    async function onSubmit() {
        setUpdating(true)

        try {
            const response = await patchUserById(user.id, [
                {
                    op: "replace",
                    path: "/canManageTransfers",
                    value: canManageTransfers,
                },

                {
                    op: "replace",
                    path: "/canManageInvites",
                    value: canManageInvites,
                },

                {
                    op: "replace",
                    path: "/canManageUsers",
                    value: canManageUsers,
                },

                {
                    op: "replace",
                    path: "/canSeeAllUsers",
                    value: canSeeAllUsers,
                },

                {
                    op: "replace",
                    path: "/visibleUserIds",
                    value: visibleUserIds,
                },
            ])

            if (response.status === "verification-needed") {
                await cancelMfa()
                throw new Error("MFA is required but shouldn't")
            }

            onUserChange?.(new User(response.body))
        } catch (error) {
            setUpdateError(error)
            resetRights()
        } finally {
            setUpdating(false)
        }
    }

    function onCanSeeAllUsersChange(checked: boolean) {
        if (!checked && usersById == null)
            setLoadingUsers(true)

        setCanSeeAllUsers(checked)
    }

    function onVisibleUsersChange(users: User[]) {
        setVisibleUserIds(users.map(({ id }) => id))
    }

    // Util

    function resetRights() {
        setCanManageTransfers(user.canManageTransfers)
        setCanManageInvites(user.canManageInvites)
        setCanManageUsers(user.canManageUsers)
        setCanSeeAllUsers(user.canSeeAllUsers)
        setVisibleUserIds([...user.visibleUserIds])
    }

    function hasRightsChanged(): boolean {
        if (canManageTransfers !== user.canManageTransfers
            || canManageInvites !== user.canManageInvites
            || canManageUsers !== user.canManageUsers
            || canSeeAllUsers !== user.canSeeAllUsers)
            return true

        const newVisibleUserIds = new Set(visibleUserIds)
        const oldVisibleUserIds = new Set(user.visibleUserIds)

        if (newVisibleUserIds.size !== oldVisibleUserIds.size)
            return true

        for (const id of newVisibleUserIds)
            if (!oldVisibleUserIds.has(id))
                return true

        return false
    }
}
