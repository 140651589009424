import { DeepReadonly, Nullish, tryNormalizeUuid } from "my-util"

import AbstractNotification,
       { NotificationType,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

export interface NewChatMessagesNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">
{
    fromId?: string | null
    count?: number | null
}

export interface NewChatMessagesNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewChatMessagesNotificationCreationOptions>
{}

export default class NewChatMessagesNotification
    extends
        AbstractNotification<"new-chat-messages">
{
    static createOrPass(
        arg: NewChatMessagesNotification
           | DeepReadonly<NewChatMessagesNotificationCreationOptions>
    ): NewChatMessagesNotification {
        return arg instanceof NewChatMessagesNotification
            ? arg
            : new NewChatMessagesNotification(arg)
    }

    readonly fromId: string | null
    readonly count: number

    constructor(options: DeepReadonly<NewChatMessagesNotificationCreationOptions>) {
        super({ ...options, type: "new-chat-messages" })

        this.fromId = options.fromId != null
            ? tryNormalizeUuid(options.fromId)
            : null

        this.count = options.count ?? 1
    }

    override get userIds(): string[] {
        const userIds = super.userIds

        if (this.fromId != null)
            userIds.push(this.fromId)

        return userIds
    }

    override copyOrPass(
        arg?: NewChatMessagesNotification
            | DeepReadonly<NewChatMessagesNotificationCopyOptions>
            | null,
    ): NewChatMessagesNotification {
        if (arg == null)
            return this

        if (arg instanceof NewChatMessagesNotification)
            return arg

        return this.copy(arg)
    }

    override copy(
        options: DeepReadonly<NewChatMessagesNotificationCopyOptions> = {},
    ): NewChatMessagesNotification {
        return new NewChatMessagesNotification({
            fromId: options.fromId !== undefined
                ? options.fromId
                : this.fromId,

            count: options.count ?? this.count,

            // AbstractNotification

            recipientId: options.recipientId ?? this.recipientId,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
