import { determineMessageTargetType } from "model"
import { ForwardedRef, forwardRef } from "react"
import { OutputProps } from "ui/ui/Output"
import { PhoneOutput } from "../phone"
import { EmailOutput } from "../email"

export interface MessageTargetOutputProps extends Omit<OutputProps, "children"> {
    messageTarget: string
}

const MessageTargetOutput = forwardRef((
    props: Readonly<MessageTargetOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const { messageTarget } = props

    switch (determineMessageTargetType(messageTarget)) {
        case "phone":
            return <PhoneOutput phone={messageTarget}
                                { ...props }
                                ref={ref}/>

        case "email":
            return <EmailOutput email={messageTarget}
                                { ...props }
                                ref={ref}/>
    }
})

MessageTargetOutput.displayName = "MessageTargetOutput"

export default MessageTargetOutput
