import { z } from "zod"

export const BankMessageSchema = z.object({
    ruName: z.string().nullish(),
    enName: z.string().nullish(),

    ruAddress: z.string().nullish(),
    enAddress: z.string().nullish(),

    swift: z.string().nullish(),
    itn: z.string().nullish(),
    bic: z.string().nullish(),
})

export type BankMessage = z.infer<typeof BankMessageSchema>
