import { z } from "zod"

export const ChatResponseTypeSchema = z.enum([
    "message-list",
    "anonymous-message",
    "message",
    "info",
    "mark-read",
    "delete",
    "request-verification",
])

export type ChatResponseType = z.infer<typeof ChatResponseTypeSchema>
