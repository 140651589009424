import AbstractModelObject,
      { AbstractModelObjectCopyOptions,
        AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly } from "my-util"

export interface ProductCreationOptions extends AbstractModelObjectCreationOptions {
    name: string
    cnfeaCodes?: Iterable<string> | null
}

export interface ProductCopyOptions extends AbstractModelObjectCopyOptions {
    name?: string | null
    cnfeaCodes?: Iterable<string> | null
}

export default class Product extends AbstractModelObject {
    static createOrPass(arg: Product | DeepReadonly<ProductCreationOptions>): Product {
        return arg instanceof Product
            ? arg
            : new Product(arg)
    }

    readonly name: string
    readonly cnfeaCodes: ReadonlySet<string>

    constructor(options: DeepReadonly<ProductCreationOptions>) {
        super(options)

        this.name = options.name
        this.cnfeaCodes = new Set(options.cnfeaCodes)
    }

    override copyOrPass(arg?: Product | DeepReadonly<ProductCopyOptions> | null): Product {
        if (arg == null)
            return this

        if (arg instanceof Product)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<ProductCopyOptions> = {}): Product {
        return new Product({
            name: options.name ?? this.name,
            cnfeaCodes: options.cnfeaCodes ?? this.cnfeaCodes,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
