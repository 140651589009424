import assert from "assert"
import { ForwardedRef, forwardRef, ReactNode, useEffect, useRef, useState } from "react"
import { MfaResponse } from "api"
import { MessageTargetType } from "model"
import { Flex, OnMessageTargetInputChange, PhaseIndicator } from "ui/ui"
import MessageTargetForm, { OnMessageTargetFormSuccess } from "./MessageTargetForm"
import OtpForm from "./OtpForm"

type State =
    | "filling-in-phone"
    | "filling-in-otp"
    | "success"

export interface MessageTargetMfaFormProps {
    performMfa: MessageTargetMfaFormPerformMfa
    onMessageTargetChange?: OnMessageTargetMfaFormMessageTargetChange
    onSuccess?: OnMessageTargetMfaFormSuccess

    messageTargetPlaceholder?: string

    width?: string
}

export type MessageTargetMfaFormPerformMfa = (
    messageTarget: string,
    messageTargetType: MessageTargetType,
) => Promise<MfaResponse>

export type OnMessageTargetMfaFormMessageTargetChange = OnMessageTargetInputChange
export type OnMessageTargetMfaFormSuccess = OnMessageTargetFormSuccess

const MessageTargetMfaForm = forwardRef((
    {
        performMfa, onMessageTargetChange, onSuccess,
        messageTargetPlaceholder,
        width,
    }: Readonly<MessageTargetMfaFormProps>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    // State

    const [state, setState] = useState("filling-in-phone" satisfies State as State)

    const messageTargetRef = useRef("")
    const messageTargetTypeRef = useRef(null as MessageTargetType | null)

    // Success notification

    useEffect(() => {
        if (state === "success") {
            assert(messageTargetTypeRef.current != null)
            onSuccess?.(messageTargetRef.current, messageTargetTypeRef.current)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, onSuccess])

    // Render

    switch (state) {
        case "filling-in-phone":
            return <Flex align="start">
                {renderPhaseIndicator(0)}

                <MessageTargetForm performMfa={performMfa}
                                   onMessageTargetChange={onInnerMessageTargetChange}
                                   onVerificationNeeded={() => setState("filling-in-otp")}
                                   onSuccess={() => setState("success")}
                                   placeholder={messageTargetPlaceholder}
                                   width={width}
                                   ref={ref}/>
            </Flex>

        case "filling-in-otp":
            return <Flex align="start">
                {renderPhaseIndicator(1)}

                <OtpForm onSuccess={() => setState("success")}
                         onBack={() => setState("filling-in-phone")}
                         messageTarget={messageTargetRef.current}
                         width={width}
                         ref={ref}/>
            </Flex>

        case "success":
            return <form style={{ display: "none" }}
                         ref={ref}/>
    }

    function renderPhaseIndicator(phase: 0 | 1): ReactNode {
        return <PhaseIndicator
            current={phase}
            phases={[
                {
                    style: {
                        current: { size: "big" },
                        fallback: {
                            borderColor: "transperant",
                            backgroundColor: "#3182CE",
                            color: "white",
                        },
                    },
                },

                {
                    style: {
                        current: { size: "big" },
                        fallback: {
                            borderColor: "transperant",
                            backgroundColor: "#D69E2E",
                            color: "white",
                        },
                    },
                },
            ]}
        />
    }

    // Events

    function onInnerMessageTargetChange(
        newMessageTarget: string,
        newMessageTargetType: MessageTargetType | null,
    ) {
        messageTargetRef.current = newMessageTarget
        messageTargetTypeRef.current = newMessageTargetType

        onMessageTargetChange?.(newMessageTarget, newMessageTargetType)
    }
})

MessageTargetMfaForm.displayName = "MessageTargetMfaForm"

export default MessageTargetMfaForm
