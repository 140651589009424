import { useTranslation } from "react-i18next"

import { createInviteApplication, MfaResponse,
         InviteApplicationRequest, InviteApplicationRequestBase } from "api"

import { MessageTargetType } from "model"
import { MessageTargetMfaForm } from "ui/component"
import { Limit } from "ui/ui"

export interface CreationFormProps {
    onSuccess?: () => void
}

export default function CreationForm({ onSuccess }: Readonly<CreationFormProps>) {
    const [t] = useTranslation()

    const messageTargetPlaceholder = t("messageTargets.placeholders.messageTarget")

    return <Limit maxWidth="250px">
        <MessageTargetMfaForm messageTargetPlaceholder={messageTargetPlaceholder}
                              performMfa={performMfa}
                              onSuccess={onSuccess}/>
    </Limit>

    async function performMfa(
        messageTarget: string,
        messageTargetType: MessageTargetType | null,
    ): Promise<MfaResponse> {
        const request: InviteApplicationRequestBase = {
            phone: messageTargetType === "phone"
                ? messageTarget
                : undefined,

            email: messageTargetType === "email"
                ? messageTarget
                : undefined,
        }

        return createInviteApplication(request as InviteApplicationRequest)
    }
}
