import { filesize } from "filesize"
import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export interface FileSizeProps {
    bytes: number

    fontSize?: string
    fontWeight?: string
    color?: string
}

const FileSize = forwardRef((
    {
        bytes,
        fontSize, fontWeight, color,
    }: Readonly<FileSizeProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span style={{ fontSize, fontWeight, color }}
          className={style.FileSize}
          ref={ref}>
        {filesize(bytes)}
    </span>
)

FileSize.displayName = "FileSize"

export default FileSize
