import { z } from "zod"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"
import { ChatResponseType } from "./type"
import { ChatResponseBaseSchema } from "./base"

export const ChatMessageResponseSchema = ChatResponseBaseSchema.extend({
    type: z.literal("message" satisfies ChatResponseType),

    creatorId: UuidSchema,
    recipientId: UuidSchema.nullish(),
    text: z.string().nullish(),
    documentIds: UuidSchema.array().nullish(),
    readByIds: UuidSchema.array().nullish(),
    edited: z.coerce.boolean().nullish(),
}).merge(ModelObjectResponseSchema)

export type ChatMessageResponse = z.infer<typeof ChatMessageResponseSchema>
