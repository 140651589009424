import { ComponentType, ForwardedRef, forwardRef, RefAttributes } from "react"
import { TransferDirection } from "model"
import { ExportIcon, ExportIconProps, ImportIcon, ImportIconProps } from "ui/ui/icon"

export interface TransferDirectionIconProps {
    onClick?: (direction: TransferDirection) => void
    direction: TransferDirection

    cursor?: string

    width?: string
    height?: string
}

const TransferDirectionIcon = forwardRef((
    {
        onClick, direction,
        cursor,
        width, height,
    }: Readonly<TransferDirectionIconProps>,
    ref: ForwardedRef<HTMLImageElement>,
) => {
    let Component: ComponentType<ImportIconProps & ExportIconProps & RefAttributes<HTMLImageElement>>

    switch (direction) {
        case "import":
            Component = ImportIcon
            break

        case "export":
            Component = ExportIcon
            break

        default:
            return direction satisfies never
    }

    const onInnerClick = onClick != null
        ? () => onClick(direction)
        : undefined

    return <Component onClick={onInnerClick}

                      cursor={cursor}

                      width={width}
                      height={height}

                      ref={ref}/>
})

TransferDirectionIcon.displayName = "TransferDirectionIcon"

export default TransferDirectionIcon
