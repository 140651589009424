import { ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export type CenterType =
    | "flex"
    | "absolute"
    | "absolute-fill"

export interface CenterProps {
    children?: ReactNode
    type?: CenterType
}

const Center = forwardRef((
    { children, type }: Readonly<CenterProps>,
    ref: ForwardedRef<HTMLDivElement>
) =>
    <div className={style[`Center-${type ?? "flex"}`]}
         ref={ref}>
        {children}
    </div>
)

Center.displayName = "Center"

export default Center
