import { z } from "zod"
import { Iso8601DurationSchema } from "my-util"

export const OtpResponseSchema = z.object({
    messageTarget: z.string().nullish(),
    sentAt: z.coerce.date().nullish(),
    resendCoolDown: Iso8601DurationSchema,
    expiresAt: z.coerce.date(),
})

export type OtpResponse = z.infer<typeof OtpResponseSchema>
