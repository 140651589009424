import { ForwardedRef, forwardRef, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { avatarIconUrl } from "image"
import { UserContext } from "ui/context"
import { createUserPagePath } from "ui/page/sections/users/UserPage/path"
import { Icon } from "ui/ui"

const Profile = forwardRef((_, ref: ForwardedRef<HTMLImageElement>) => {
    const [localUser] = useContext(UserContext)

    const navigate = useNavigate()

    // Render

    return <Icon onClick={onClick}
                 src={avatarIconUrl}
                 alt="Avatar icon"
                 filter="brightness(0) saturate(100%) invert(29%) sepia(37%) saturate(574%) hue-rotate(164deg) brightness(99%) contrast(98%)"
                 cursor="pointer"
                 ref={ref}/>

    // Events

    function onClick() {
        if (localUser == null)
            return

        const localUserProfilePath = createUserPagePath(localUser.id)

        navigate(localUserProfilePath)
    }
})

Profile.displayName = "ProfileButton"

export default Profile
