import { t } from "i18next"

import { Named, WithManyMessageTargets, WithUserRights,
         WithNotificationOptions, WithNullableCreatorId } from "model/interfaces"

import { NamedMixin, NamedMixinCopyOptions, NamedMixinCreationOptions } from "model/mixins"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, map, Nullish, tryNormalizeUuid } from "my-util"
import Company, { CompanyCopyOptions, CompanyCreationOptions } from "./Company"

// Comparator

export type UsersComparator = (lhs: User, rhs: User) => number

// Status

export type UserStatus =
    | "active"
    | "unverified"
    | "blocked"

export function userStatusToString(status: UserStatus): string {
    return t(`domain.users.statuses.${status}`)
}

export function isUserStatus(s: string): s is UserStatus {
    switch (s) {
        case "active":
        case "unverified":
        case "blocked":
            s satisfies UserStatus
            return true

        default:
            return false
    }
}

// - Color

export interface UserStatusColors {
    backgroundColor: string
    borderColor: string
    color: string
}

export const ACTIVE_USER_STATUS_COLORS: Readonly<UserStatusColors> = {
    backgroundColor: "#E6F4EA",
    borderColor: "#E6F4EA",
    color: "#08922E",
}

export const UNVERIFIED_USER_STATUS_COLORS: Readonly<UserStatusColors> = {
    backgroundColor: "#fffcf4",
    borderColor: "#cc5500",
    color: "#cc5500",
}

export const BLOCKED_USER_STATUS_COLORS: Readonly<UserStatusColors> = {
    backgroundColor: "#F2F2F2",
    borderColor: "#F2F2F2",
    color: "#333",
}

export function getUserStatusColors(status: UserStatus): UserStatusColors {
    switch (status) {
        case "active":
            return { ...ACTIVE_USER_STATUS_COLORS }

        case "unverified":
            return { ...UNVERIFIED_USER_STATUS_COLORS }

        case "blocked":
            return { ...BLOCKED_USER_STATUS_COLORS }
    }
}

// - Sorting

export type UsersByStatusSortingOrder = UserStatus[]

export const ACTIVE_USERS_BY_STATUS_SORTING_ORDER: Readonly<UsersByStatusSortingOrder> = [
    "active",
    "blocked",
    "unverified",
]

export const UNVERIFIED_USERS_BY_STATUS_SORTING_ORDER: Readonly<UsersByStatusSortingOrder> = [
    "unverified",
    "active",
    "blocked",
]

export const BLOCKED_USERS_BY_STATUS_SORTING_ORDER: Readonly<UsersByStatusSortingOrder> = [
    "blocked",
    "active",
    "unverified",
]

export function getUsersByStatusSortingOrderComparator(
    statusOrOrder: UserStatus | Readonly<UsersByStatusSortingOrder>,
): UsersComparator {
    const order = typeof statusOrOrder === "string"
        ? getUsersByStatusSortingOrder(statusOrOrder)
        : statusOrOrder

    return (lhs, rhs) => Math.sign(order.indexOf(lhs.status) - order.indexOf(rhs.status))
}

export function getUsersByStatusSortingOrder(status: UserStatus): UsersByStatusSortingOrder {
    switch (status) {
        case "active":
            return [...ACTIVE_USERS_BY_STATUS_SORTING_ORDER]

        case "unverified":
            return [...UNVERIFIED_USERS_BY_STATUS_SORTING_ORDER]

        case "blocked":
            return [...BLOCKED_USERS_BY_STATUS_SORTING_ORDER]
    }
}

// Role

export type UserRole =
    | "admin"
    | "manager"
    | "agent"
    | "client"

export function userRoleToString(role: UserRole): string {
    return t(`domain.users.roles.${role}`)
}

export function isUserRole(s: string): s is UserRole {
    switch (s) {
        case "client":
        case "agent":
        case "manager":
        case "admin":
            s satisfies UserRole
            return true

        default:
            return false
    }
}

// - Abbr

export function getUserRoleAbbr(role: UserRole): string {
    return t(`domain.users.roles.abbrs.${role}`)
}

// - Color

export const CLIENT_USER_ROLE_COLOR = "#00A2FF"
export const AGENT_USER_ROLE_COLOR = "#00A2FF"
export const MANAGER_USER_ROLE_COLOR = "red"
export const ADMIN_USER_ROLE_COLOR = "red"

export function getUserRoleColor(role: UserRole): string {
    switch (role) {
        case "admin":
            return ADMIN_USER_ROLE_COLOR

        case "manager":
            return MANAGER_USER_ROLE_COLOR

        case "agent":
            return AGENT_USER_ROLE_COLOR

        case "client":
            return CLIENT_USER_ROLE_COLOR

        default:
            return "purple"
    }
}

// User

export interface UserCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        NamedMixinCreationOptions,
        Nullish<WithManyMessageTargets>,
        Nullish<WithNotificationOptions>,
        Omit<Nullish<WithUserRights>, "visibleUserIds">
{
    company?: Company | CompanyCreationOptions | null

    status?: UserStatus | null
    role?: UserRole | null

    creatorId?: string | null

    visibleUserIds?: Iterable<string> | null
}

export interface UserCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        NamedMixinCopyOptions,
        Nullish<WithManyMessageTargets>,
        Nullish<WithNotificationOptions>,
        Omit<Nullish<WithUserRights>, "visibleUserIds">
{
    company?: Company | CompanyCopyOptions | null

    status?: UserStatus | null
    role?: UserRole | null

    creatorId?: string | null

    visibleUserIds?: Iterable<string> | null
}

export default class User
    extends
        AbstractModelObject

    implements
        Readonly<WithNullableCreatorId>,
        Readonly<WithManyMessageTargets>,
        Readonly<Named>,
        Readonly<WithNotificationOptions>,
        DeepReadonly<WithUserRights>
{
    static createOrPass(arg: User | DeepReadonly<UserCreationOptions>): User {
        return arg instanceof User
            ? arg
            : new User(arg)
    }

    readonly phone: string | null
    readonly email: string | null

    readonly company: Company

    readonly status: UserStatus
    readonly role: UserRole

    readonly creatorId: string | null

    readonly canManageTransfers: boolean
    readonly canManageInvites: boolean
    readonly canManageUsers: boolean
    readonly canSeeAllUsers: boolean
    readonly visibleUserIds: readonly string[]

    readonly notifyOnNewChatMessage: boolean
    readonly notifyOnNewInviteApplication: boolean
    readonly notifyOnNewInvite: boolean
    readonly notifyOnNewTransfer: boolean
    readonly notifyOnNewUser: boolean
    readonly notifyOnTransferStatusChanged: boolean

    private readonly namedMixin: NamedMixin

    constructor(options: DeepReadonly<UserCreationOptions>) {
        super(options)

        this.phone = options.phone ?? null
        this.email = options.email ?? null

        this.company = Company.createOrPass(options.company)

        this.status = options.status ?? "active"
        this.role = options.role ?? "client"

        this.creatorId = options.creatorId != null
            ? tryNormalizeUuid(options.creatorId)
            : null

        this.canManageTransfers = options.canManageTransfers ?? false
        this.canManageInvites = options.canManageInvites ?? false
        this.canManageUsers = options.canManageUsers ?? false
        this.canSeeAllUsers = options.canSeeAllUsers ?? false
        this.visibleUserIds = map(new Set(options.visibleUserIds) ?? [], tryNormalizeUuid)

        this.notifyOnNewChatMessage = options.notifyOnNewChatMessage ?? false
        this.notifyOnNewInviteApplication = options.notifyOnNewInviteApplication ?? false
        this.notifyOnNewInvite = options.notifyOnNewInvite ?? false
        this.notifyOnNewTransfer = options.notifyOnNewTransfer ?? false
        this.notifyOnNewUser = options.notifyOnNewUser ?? false
        this.notifyOnTransferStatusChanged = options.notifyOnTransferStatusChanged ?? false

        this.namedMixin = new NamedMixin(options)
    }

    getCreatorIdColor(opacity?: number | null): string {
        return AbstractModelObject.getIdColor(this.creatorId ?? "", opacity)
    }

    // Roles

    get isClient(): boolean {
        return true
    }

    get isAgent(): boolean {
        return ["admin", "manager", "agent"].includes(this.role)
    }

    get isManager(): boolean {
        return ["admin", "manager"].includes(this.role)
    }

    get isAdmin(): boolean {
        return this.role === "admin"
    }

    // Rights

    get hasRightToManageTransfers(): boolean {
        return this.isAdminOrManagerAnd(this.canManageTransfers)
    }

    get hasRightToManageInvites(): boolean {
        return this.isAdminOrManagerAnd(this.canManageInvites)
    }

    get hasRightToManageUsers(): boolean {
        return this.isAdminOrManagerAnd(this.canManageUsers)
    }

    get hasRightToSeeAllUsers(): boolean {
        return this.isAdminOrManagerAnd(this.canSeeAllUsers)
    }

    private isAdminOrManagerAnd(value: boolean): boolean {
        return this.role === "admin"
            || (this.role === "manager" && value)
    }

    get effectiveVisibleUserIds(): string[] {
        const effectiveVisibleUserIds = new Set(this.visibleUserIds)

        effectiveVisibleUserIds.add(this.id)

        return [...effectiveVisibleUserIds]
    }

    // Name

    // - En

    get enFirstname(): string {
        return this.namedMixin.enFirstname
    }

    get enLastname(): string {
        return this.namedMixin.enLastname
    }

    get enPatronymic(): string | null {
        return this.namedMixin.enPatronymic
    }

    get enName(): string {
        return this.namedMixin.enName
    }

    get enFormalName(): string {
        return this.namedMixin.enFormalName
    }

    // - Ru

    get ruFirstname(): string {
        return this.namedMixin.ruFirstname
    }

    get ruLastname(): string {
        return this.namedMixin.ruLastname
    }

    get ruPatronymic(): string | null {
        return this.namedMixin.ruPatronymic
    }

    get ruName(): string {
        return this.namedMixin.ruName
    }

    get ruFormalName(): string {
        return this.namedMixin.ruFormalName
    }

    // - Current locale

    get firstname(): string {
        return this.namedMixin.firstname
    }

    get lastname(): string {
        return this.namedMixin.lastname
    }

    get patronymic(): string | null {
        return this.namedMixin.patronymic
    }

    get name(): string {
        return this.namedMixin.name
    }

    get formalName(): string {
        return this.namedMixin.formalName
    }

    // Copy

    override copyOrPass(arg?: User | DeepReadonly<UserCopyOptions> | null): User {
        if (arg == null)
            return this

        if (arg instanceof User)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<UserCopyOptions> = {}): User {
        return new User({
            phone: options.phone !== undefined
                ? options.phone
                : this.phone,

            email: options.email !== undefined
                ? options.email
                : this.email,

            company: this.company.copyOrPass(options.company),

            status: options.status ?? this.status,
            role: options.role ?? this.role,

            creatorId: options.creatorId !== undefined
                ? options.creatorId
                : this.creatorId,

            canManageTransfers: options.canManageTransfers ?? this.canManageTransfers,
            canManageInvites: options.canManageInvites ?? this.canManageInvites,
            canManageUsers: options.canManageUsers ?? this.canManageUsers,
            canSeeAllUsers: options.canSeeAllUsers ?? this.canSeeAllUsers,
            visibleUserIds: options.visibleUserIds ?? this.visibleUserIds,

            notifyOnNewChatMessage: options.notifyOnNewChatMessage ?? this.notifyOnNewChatMessage,
            notifyOnNewInviteApplication: options.notifyOnNewInviteApplication ?? this.notifyOnNewInviteApplication,
            notifyOnNewInvite: options.notifyOnNewInvite ?? this.notifyOnNewInvite,
            notifyOnNewTransfer: options.notifyOnNewTransfer ?? this.notifyOnNewTransfer,
            notifyOnNewUser: options.notifyOnNewUser ?? this.notifyOnNewUser,
            notifyOnTransferStatusChanged: options.notifyOnTransferStatusChanged ?? this.notifyOnTransferStatusChanged,

            // Named

            enFirstname: options.enFirstname ?? this.enFirstname,
            enLastname: options.enLastname ?? this.enLastname,

            enPatronymic: options.enPatronymic !== undefined
                ? options.enPatronymic
                : this.enPatronymic,

            ruFirstname: options.ruFirstname ?? this.ruFirstname,
            ruLastname: options.ruLastname ?? this.ruLastname,

            ruPatronymic: options.ruPatronymic !== undefined
                ? options.ruPatronymic
                : this.ruPatronymic,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
