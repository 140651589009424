import { ForwardedRef, forwardRef, MutableRefObject, useRef,
         ReactNode, useContext, useEffect, useImperativeHandle } from "react"

import { menuBurgerIconUrl } from "image"
import { DeepReadonly } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { UserContext } from "ui/context"
import { Clickable, Flex, Icon, Limit, Padding, UserRoleAbbr } from "ui/ui"
import SectionLinkList from "./SectionLinkList"
import style from "./style.module.css"

export interface MenuProps {
    onShortChange?: (short: boolean) => void
    short?: boolean

    title?: ReactNode

    mobile?: boolean

    firstRender?: boolean
}

export interface MenuRef {
    component: MutableRefObject<HTMLDivElement>
    title: MutableRefObject<HTMLDivElement | null>
    links: MutableRefObject<HTMLDivElement>
}

const Menu = forwardRef((
    {
        onShortChange, short,
        title,
        mobile,
        firstRender,
    }: DeepReadonly<MenuProps>,
    ref: ForwardedRef<MenuRef>,
) => {
    const [localUser] = useContext(UserContext)

    // Refs

    const changedRef = useRef(false)

    // State

    const [innerShort, setInnerShort] = useStateWithDeps(
        () => short ?? false,
        [short],
    )

    // Refs

    const componentRef = useRef(null as HTMLDivElement | null)
    const titleRef = useRef(null as HTMLDivElement | null)
    const linksRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => ({
        component: componentRef as MutableRefObject<HTMLDivElement>,
        title: titleRef as MutableRefObject<HTMLDivElement | null>,
        links: linksRef as MutableRefObject<HTMLDivElement>,
    }), [])

    // Effects

    useEffect(
        () => {
            if (changedRef.current) {
                onShortChange?.(innerShort)
                changedRef.current = false
            }
        },

        [innerShort, onShortChange],
    )

    // Render

    return <Flex width="fit-content"
                 align="start"
                 ref={componentRef}>
        <Padding paddingLeft="24px"
                 height="32px">
            <Limit maxWidth="232px"
                   overflow="hidden">
                <Flex justify="space-between"
                      direction="horizontal">
                    {renderBurger()}
                    {renderTitle()}
                </Flex>
            </Limit>
        </Padding>

        <SectionLinkList firstRender={firstRender}
                         short={innerShort}
                         ref={linksRef}/>
    </Flex>

    function renderBurger(): ReactNode {
        return <Clickable onClick={onShortToggle}
                          cursor="pointer">
            <Flex direction="horizontal"
                  gap="16px">
                <div className={style.burgerIcon}>
                    <Icon width="24px"
                          height="24px"

                          src={menuBurgerIconUrl}
                          alt="Menu burger icon"
                          filter="brightness(0)"/>
                </div>

                {(!mobile || !short) && localUser != null &&
                    <span className={style.role}>
                        <UserRoleAbbr role={localUser.role}
                                      colorful/>
                    </span>
                }
            </Flex>
        </Clickable>
    }

    function renderTitle(): ReactNode {
        if (!title)
            return null

        return <span className={style.title}
                     ref={titleRef}>
            {title}
        </span>
    }

    // Events

    function onShortToggle() {
        setInnerShort(oldInnerShort => {
            changedRef.current = true
            return !oldInnerShort
        })
    }
})

Menu.displayName = "Menu"

export default Menu
