import { ForwardedRef, forwardRef } from "react"
import Output, { OutputProps } from "ui/ui/Output"
import Phone from "../Phone"

export interface PhoneOutputProps extends Omit<OutputProps, "children"> {
    phone: string
}

const PhoneOutput = forwardRef((
    props: Readonly<PhoneOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output left="+"
            { ...props }
            ref={ref}>
        <Phone phone={props.phone}
               hidePlus/>
    </Output>
)

PhoneOutput.displayName = "PhoneOutput"

export default PhoneOutput
