import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"

export interface RequiredProps {
    children?: ReactNode
}

const Required = forwardRef((
    { children }: DeepReadonly<RequiredProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span ref={ref}>
        {children}

        <span style={{ color: "#E53E3E" }}>
            *
        </span>
    </span>
)

Required.displayName = "Required"

export default Required
