import { t } from "i18next"
import { z } from "zod"
import { User } from "model"
import { DeepReadonly, tryNormalizeNullableUuid, tryNormalizeUuid, UuidSchema } from "my-util"
import { UiChatMessage } from "ui/component"

// Last user ID

export const MESSENGER_PAGE_LAST_USER_ID_STORAGE_KEY = "messenger-last-user-id"

export function getLastMessengerUserId(): string | null {
    return tryNormalizeNullableUuid(localStorage.getItem(MESSENGER_PAGE_LAST_USER_ID_STORAGE_KEY))
}

export function setLastMessengerUserId(userId: string | undefined | null) {
    if (userId == null)
        localStorage.removeItem(MESSENGER_PAGE_LAST_USER_ID_STORAGE_KEY)
    else
        localStorage.setItem(MESSENGER_PAGE_LAST_USER_ID_STORAGE_KEY, userId)
}

// Unsent messages

export const MESSENGER_PAGE_UNSENT_MESSAGES_STORAGE_KEY = "messenger-unsent-messages"

export type UiChatMessageById = {
    [id: string]: UiChatMessage
}

const UnsentMessageSchema = z.object({
    id: UuidSchema.nullish(),
    recipientId: UuidSchema.nullish(),
    text: z.string().nullish(),
    documentIds: UuidSchema.array().nullish(),
})

export type UnsentMessage = z.infer<typeof UnsentMessageSchema>

export function deleteUnsentMessengerMessageById(id: string) {
    const oldMessages = getUnsentMessengerMessages()
    const newMessages = oldMessages.filter(message => message.id !== id)

    if (oldMessages.length !== newMessages.length)
        setUnsentMessengerMessages(newMessages, false)
}

export function pushUnsentMessengerMessage(message: DeepReadonly<UnsentMessage>) {
    const messages = getUnsentMessengerMessages()

    messages.push(normalizeUnsentMessage(message))

    setUnsentMessengerMessages(messages, false)
}

export function getUnsentMessengerMessages(): UnsentMessage[] {
    const item = localStorage.getItem(MESSENGER_PAGE_UNSENT_MESSAGES_STORAGE_KEY)

    if (item == null)
        return []

    try {
        const itemJson = JSON.parse(item)
        const messages = UnsentMessageSchema.array().parse(itemJson)

        return messages
    } catch (error) {
        console.error("Failed to get unread messenger messages: ", error)
        return []
    }
}

export function setUnsentMessengerMessages(
    messages: DeepReadonly<UnsentMessage[]>,
    normalize?: boolean | null,
) {
    const innerNormalize = normalize ?? true

    const normalizedMessages = innerNormalize
        ? messages.map(normalizeUnsentMessage)
        : messages

    const json = JSON.stringify(normalizedMessages)

    localStorage.setItem(MESSENGER_PAGE_UNSENT_MESSAGES_STORAGE_KEY, json)
}

export function clearUnsentMessengerMessages() {
    localStorage.removeItem(MESSENGER_PAGE_UNSENT_MESSAGES_STORAGE_KEY)
}

function normalizeUnsentMessage(message: DeepReadonly<UnsentMessage>): UnsentMessage {
    return {
        id: message.id,
        recipientId: tryNormalizeNullableUuid(message.recipientId),
        text: message.text,
        documentIds: message.documentIds?.map(tryNormalizeUuid),
    }
}

// Conversion

export interface UnsentMessageToUiOptions {
    localUser?: User | null
}

export function unsentMessageToUi(
    message: DeepReadonly<UnsentMessage>,
    { localUser }: Readonly<UnsentMessageToUiOptions> = {},
): UiChatMessage {
    return {
        id: message.id,
        status: "error",
        senderId: localUser?.id,
        sender: () => t("misc.words.you"),
        text: message.text,

        documents: message.documentIds?.map(id => ({
            status: "loading",
            id,
        })),

        date: new Date(),
        local: true,
    }
}
