import { useTranslation } from "react-i18next"
import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react"
import { crossIconUrl } from "image"

import { Notification, User, Invite,
         NewChatMessagesNotification,
         NewInviteApplicationNotification,
         InviteApplication, NewInviteNotification,
         NewTransferNotification, Transfer,
         NewUserNotification,
         TransferStatusChangedNotification,
         transferStatusToString,
         NewAnonymousChatMessagesNotification } from "model"

import { DeepReadonly } from "my-util"
import { UserLink } from "ui/component/user"
import { EventTime, Button, MessageTarget } from "ui/ui"
import style from "./style.module.css"

export interface NotificationCardProps {
    onClick?: (Notification: Notification) => void
    onDelete?: (notification: Notification) => void

    notification: Notification

    inviteApplications?: InviteApplication[] | Map<string, InviteApplication>
    invites?: Invite[] | Map<string, Invite>
    users?: User[] | Map<string, User>
    transfers?: Transfer[] | Map<string, Transfer>

    width?: string
    height?: string

    loading?: boolean
    disabled?: boolean
}

const NotificationCard = forwardRef((
    {
        onClick, onDelete,
        notification,
        inviteApplications, invites, users, transfers,
        width, height,
        loading, disabled,
    }: DeepReadonly<NotificationCardProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const usersById = useMemo(
        () => User.groupByIdOrPassOrCreate(users),
        [users],
    )

    const inviteApplicationsById = useMemo(
        () => InviteApplication.groupByIdOrPassOrCreate(inviteApplications),
        [inviteApplications],
    )

    const invitesById = useMemo(
        () => Invite.groupByIdOrPassOrCreate(invites),
        [invites],
    )

    const transferById = useMemo(
        () => Transfer.groupByIdOrPassOrCreate(transfers),
        [transfers],
    )

    // Render

    return <div onClick={() => onClick?.(notification as Notification)}
                className={style.NotificationCard}
                style={{ width, height }}
                ref={ref}>
        <div className={style.message}>
            {renderMessage(notification as Notification)}
        </div>

        <div onClick={event => event.stopPropagation()}
             className={style.delete}>
            <Button onClick={() => onDelete?.(notification as Notification)}

                    iconSrc={crossIconUrl}
                    iconAlt="Cross icon"

                    buttonStyle="text"
                    fontSize="12px"

                    width="24px"
                    height="24px"

                    loading={loading}
                    disabled={disabled}/>
        </div>

        <div className={style.time}>
            <EventTime time={notification.modifiedAt}/>
        </div>
    </div>

    function renderMessage(notification: Notification): ReactNode {
        switch (notification.type) {
            case "new-chat-messages":
                return renderNewChatMessagesMessage(notification)

            case "new-invite-application":
                return renderNewInviteApplicationMessage(notification)

            case "new-invite":
                return renderNewInviteMessage(notification)

            case "new-transfer":
                return renderNewTransferMessage(notification)

            case "new-user":
                return renderNewUserMessage(notification)

            case "transfer-status-changed":
                return renderTransferStatusChangedMessage(notification)

            default:
                notification satisfies never
        }
    }

    function renderNewChatMessagesMessage(
        notification:
            | NewAnonymousChatMessagesNotification
            | NewChatMessagesNotification
    ): ReactNode {
        const { count } = notification

        const fromId = notification instanceof NewChatMessagesNotification
            ? notification.fromId
            : null

        if (fromId == null)
            return t("notifications.messages.youHaveNewMessages", { count })

        const from = usersById.get(fromId)

        const fromName = from != null
            ? <UserLink stopClickPropagation
                        user={from}/>

            : t("misc.words.unknown")

        return <>
            {fromName}
            {" "}
            {t("notifications.messages.sentYouNewMessages", { count })}
        </>
    }

    function renderNewInviteApplicationMessage(
        notification: NewInviteApplicationNotification,
    ): ReactNode {
        const inviteApplication = inviteApplicationsById.get(notification.inviteApplicationId)

        return <>
            {t("notifications.messages.newInviteApplication")}

            {inviteApplication != null && <>
                {" "}
                {t("misc.words.from").toLowerCase()}
                {" "}

                <MessageTarget messageTarget={inviteApplication.messageTarget}
                               stopLinkClickPropagation/>
            </>}
        </>
    }

    function renderNewInviteMessage(notification: NewInviteNotification): ReactNode {
        const invite = invitesById.get(notification.inviteId)
        const creator = invite?.creatorId != null
            ? usersById.get(invite.creatorId)
            : undefined

        return <>
            {creator != null
                ? <>
                    <UserLink stopClickPropagation
                              user={creator}/>

                    {" "}
                    {t("notifications.messages.someoneCreatedNewInvite")}
                </>

                : t("notifications.messages.newInvite")
            }

            {invite != null && <>
                {" "}
                {t("misc.words.for").toLowerCase()}
                {" "}

                <MessageTarget messageTarget={invite.messageTarget}
                                stopLinkClickPropagation/>
            </>}
        </>
    }

    function renderNewTransferMessage(notification: NewTransferNotification): ReactNode {
        const transfer = transferById.get(notification.transferId)
        const creator = transfer?.creatorId != null
            ? usersById.get(transfer.creatorId)
            : undefined

        return <>
            {t("notifications.messages.newTransfer")}

            {creator != null && <>
                {" "}
                {t("misc.words.from").toLowerCase()}
                {" "}

                <UserLink stopClickPropagation
                          user={creator}/>
            </>}
        </>
    }

    function renderNewUserMessage(notification: NewUserNotification): ReactNode {
        const user = usersById.get(notification.userId)

        return user != null
            ? <>
                <UserLink stopClickPropagation
                          user={user}/>

                {" "}
                {t("notifications.messages.someNewUser")}
            </>

            : t("notifications.messages.newUser")
    }

    function renderTransferStatusChangedMessage(
        notification: TransferStatusChangedNotification,
    ): ReactNode {
        const { from, to } = notification

        return <>
            {t("notifications.messages.transferStatusChangedFrom")}
            {" "}

            <em className={style.emphasis}>
                {transferStatusToString(from).toLowerCase()}
            </em>

            {" "}
            {t("misc.words.to").toLowerCase()}
            {" "}

            <em className={style.emphasis}>
                {transferStatusToString(to).toLowerCase()}
            </em>
        </>
    }
})

NotificationCard.displayName = "NotificationCard"

export default NotificationCard
