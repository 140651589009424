import { ChangeEvent, ForwardedRef, FocusEvent, ReactNode,
         forwardRef, HTMLInputAutoCompleteAttribute, useState } from "react"

import { eyeIconUrl, eyeOffIconUrl } from "image"
import { useStateWithDeps } from "ui/hook"
import { Icon } from "ui/ui/icon"
import { OptionallyRequired } from "ui/ui/required"
import Label from "ui/ui/Label"
import Information from "ui/ui/Information"
import style from "./style.module.css"

// Generated automatically
const DEFAULT_ICON_FILTER = "brightness(0) saturate(100%) invert(61%) sepia(18%) saturate(0%) hue-rotate(259deg) brightness(96%) contrast(93%)"

export interface InputProps {
    onChange?: (text: string) => void
    onFocus?: () => void
    onBlur?: (text: string) => string | undefined
    value?: string

    postProcess?: (text: string) => string
    test?: (text: string) => boolean
    regex?: RegExp
    max?: number

    left?: ReactNode
    right?: ReactNode
    over?: ReactNode

    type?: InputType
    autoComplete?: HTMLInputAutoCompleteAttribute
    autoFocus?: boolean

    width?: string
    height?: string

    color?: string
    backgroundColor?: string
    borderColor?: string

    invalid?: boolean
    disabled?: boolean
    readonly?: boolean
    loading?: boolean
    required?: boolean

    label?: string
    placeholder?: string
    information?: string

    onIconClick?: () => void
    iconSrc?: string
    iconAlt?: string
    iconFilter?: string
}

export type InputType =
    | "text"
    | "password"
    | "email"
    | "search"
    | "tel"
    | "url"

const Input = forwardRef((
    {
        onChange, onFocus, onBlur, value,
        postProcess, test, regex, max,
        right, left, over,
        type, autoFocus, autoComplete,
        width, height,
        color, backgroundColor, borderColor,
        invalid, disabled, readonly, loading, required,
        label, placeholder, information,
        onIconClick, iconSrc, iconAlt, iconFilter,
    }: Readonly<InputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const innerDisabled = disabled || loading || readonly
    const innerInvalid = invalid && !innerDisabled

    // State

    const [innerValue, setInnerValue] = useStateWithDeps(
        () => value != null
            ? postProcess?.(value) ?? value
            : "",

        [value],
    )

    const [showPassword, setShowPassword] = useState(false)

    // Render

    return <div className={renderInputClassName()}
                style={{ width }}
                ref={ref}>
        <OptionallyRequired required={required}>
            <Label text={label}/>
        </OptionallyRequired>

        <div className={style.htmlInputContainer}>
            {over &&
                <div className={style.over}>
                    {over}
                </div>
            }

            <input className={renderHtmlInputClassName()}
                   style={{ height, color, backgroundColor, borderColor }}

                   value={innerValue}

                   placeholder={placeholder}

                   type={renderHtmlInputType()}
                   autoFocus={autoFocus}
                   autoComplete={autoComplete}

                   disabled={innerDisabled}

                   onChange={onInnerChange}
                   onFocus={onFocus}
                   onBlur={onInnerBlur}/>

            {left &&
                <div className={style.left}>
                    {left}
                </div>
            }

            {right &&
                <div className={style.right}>
                    {right}
                </div>
            }

            <div className={style.icons}>
                {type === "password" &&
                    <Icon src={renderEyeIconUrl()}
                          alt="Eye icon"
                          filter={DEFAULT_ICON_FILTER}

                          onClick={onToggleShowPassword}

                          width="1em"
                          height="1em"/>
                }

                {iconSrc &&
                    <Icon src={iconSrc}
                          alt={iconAlt}
                          filter={iconFilter ?? DEFAULT_ICON_FILTER}

                          onClick={onIconClick}

                          width="1em"
                          height="1em"/>
                }
            </div>
        </div>

        <Information type={innerInvalid ? "error" : "regular"}
                     text={information}/>
    </div>

    function renderInputClassName() {
        if (readonly)
            return style.ReadonlyInput

        if (loading)
            return style.LoadingInput

        return style.Input
    }

    function renderHtmlInputType(): string | undefined {
        return type === "password"
            ? showPassword
                ? "text"
                : "password"
           : type
    }

    function renderHtmlInputClassName() {
        return innerInvalid
            ? style.invalidHtmlInput
            : style.htmlInput
    }

    function renderEyeIconUrl(): string {
        return showPassword
            ? eyeOffIconUrl
            : eyeIconUrl
    }

    // Event

    function onInnerChange(event: ChangeEvent<HTMLInputElement>) {
        updateValue(event.currentTarget.value)
    }

    function onInnerBlur(event: FocusEvent<HTMLInputElement>) {
        const value = event.currentTarget.value
        const newValue = onBlur?.(value)

        if (newValue != null)
            updateValue(newValue)
    }

    function onToggleShowPassword() {
        setShowPassword(oldShowPassword => !oldShowPassword)
    }

    // Util

    function updateValue(newValue: string): string | undefined {
        if (postProcess)
            newValue = postProcess(newValue)

        if (max != null && newValue.length > max)
            newValue = newValue.substring(0, max)

        if (regex != null && newValue.match(regex) == null)
            return

        if (test?.(newValue) === false)
            return

        setInnerValue(newValue)
        onChange?.(newValue)

        return newValue
    }
})

Input.displayName = "Input"

export default Input
