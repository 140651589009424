// Months

export const MONTH_COUNT = 12

// Days

export const WEEK_DAYS = 7
export const YEAR_DAYS = 365

// Hours

export const DAY_HOURS = 24
export const WEEK_HOURS = WEEK_DAYS * DAY_HOURS
export const YEAR_HOURS = YEAR_DAYS * DAY_HOURS

// Minutes

export const HOUR_MINUTES = 60
export const DAY_MINUTES = DAY_HOURS * HOUR_MINUTES
export const WEEK_MINUTES = WEEK_DAYS * DAY_MINUTES
export const YEAR_MINUTES = YEAR_DAYS * DAY_MINUTES

// Seconds

export const MINUTE_SECONDS = 60
export const HOUR_SECONDS = HOUR_MINUTES * MINUTE_SECONDS
export const DAY_SECONDS = DAY_HOURS * HOUR_SECONDS
export const WEEK_SECONDS = WEEK_DAYS * DAY_SECONDS
export const YEAR_SECONDS = YEAR_DAYS * DAY_SECONDS

// Milliseconds

export const SECOND_MILLIS = 1_000
export const MINUTE_MILLIS = MINUTE_SECONDS * SECOND_MILLIS
export const HOUR_MILLIS = HOUR_MINUTES * MINUTE_MILLIS
export const DAY_MILLIS = DAY_HOURS * HOUR_MILLIS
export const WEEK_MILLIS = WEEK_DAYS * DAY_MILLIS
export const YEAR_MILLIS = YEAR_DAYS * DAY_MILLIS
