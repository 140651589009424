import { getLang } from "i18n"
import { Named } from "model/interfaces"
import { makeFormalName, makeName, Nullish } from "my-util"

export interface NamedMixinCreationOptions {
    enFirstname: string
    enLastname: string
    enPatronymic?: string | null

    ruFirstname: string
    ruLastname: string
    ruPatronymic?: string | null
}

export interface NamedMixinCopyOptions extends Nullish<NamedMixinCreationOptions> {}

export default class NamedMixin implements Named {
    static createOrPass(arg: NamedMixin | Readonly<NamedMixinCreationOptions>): NamedMixin {
        return arg instanceof NamedMixin
            ? arg
            : new NamedMixin(arg)
    }

    readonly enFirstname: string
    readonly enLastname: string
    readonly enPatronymic: string | null

    readonly ruFirstname: string
    readonly ruLastname: string
    readonly ruPatronymic: string | null

    private cachedEnName: string | null = null
    private cachedRuName: string | null = null

    private cachedEnFormalName: string | null = null
    private cachedRuFormalName: string | null = null

    constructor(options: Readonly<NamedMixinCreationOptions>) {
        this.enFirstname = options.enFirstname
        this.enLastname = options.enLastname
        this.enPatronymic = options.enPatronymic ?? null

        this.ruFirstname = options.ruFirstname
        this.ruLastname = options.ruLastname
        this.ruPatronymic = options.ruPatronymic ?? null
    }

    // En

    get enName(): string {
        if (this.cachedEnName == null)
            this.cachedEnName = makeName(this.enFirstname, this.enLastname, this.enPatronymic)

        return this.cachedEnName
    }

    get enFormalName(): string {
        if (this.cachedEnFormalName == null)
            this.cachedEnFormalName = makeFormalName(this.enFirstname, this.enPatronymic)

        return this.cachedEnFormalName
    }

    // Ru

    get ruName(): string {
        if (this.cachedRuName == null)
            this.cachedRuName = makeName(this.ruFirstname, this.ruLastname, this.ruPatronymic)

        return this.cachedRuName
    }

    get ruFormalName(): string {
        if (this.cachedRuFormalName == null)
            this.cachedRuFormalName = makeFormalName(this.ruFirstname, this.ruPatronymic)

        return this.cachedRuFormalName
    }

    // Current locale

    get firstname(): string {
        return getLang() === "ru"
            ? this.ruFirstname
            : this.enFirstname
    }

    get lastname(): string {
        return getLang() === "ru"
            ? this.ruLastname
            : this.enLastname
    }

    get patronymic(): string | null {
        return getLang() === "ru"
            ? this.ruPatronymic
            : this.enPatronymic
    }

    get name(): string {
        return getLang() === "ru"
            ? this.ruName
            : this.enName
    }

    get formalName(): string {
        return getLang() === "ru"
            ? this.ruFormalName
            : this.enFormalName
    }

    // Copy

    copyOrPass(arg?: NamedMixin | Readonly<NamedMixinCopyOptions> | null): NamedMixin {
        if (arg == null)
            return this

        if (arg instanceof NamedMixin)
            return arg

        return this.copy(arg)
    }

    copy(options: Readonly<NamedMixinCopyOptions> = {}): NamedMixin {
        return new NamedMixin({
            // En

            enFirstname: options.enFirstname ?? this.enFirstname,
            enLastname: options.enLastname ?? this.enLastname,

            enPatronymic: options.enPatronymic !== undefined
                ? options.enPatronymic
                : this.enPatronymic,

            // Ru

            ruFirstname: options.ruFirstname ?? this.ruFirstname,
            ruLastname: options.ruLastname ?? this.ruLastname,

            ruPatronymic: options.ruPatronymic !== undefined
                ? options.ruPatronymic
                : this.ruPatronymic,
        })
    }
}
