import Decimal from "decimal.js"
import { ForwardedRef, forwardRef, useMemo } from "react"
import { formatDecimal } from "my-util"
import Output, { OutputProps } from "ui/ui/Output"

export interface DecimalOutputProps extends Omit<OutputProps, "children"> {
    value: Decimal | number | string
    precision?: number
}

const DecimalOutput = forwardRef((
    props: Readonly<DecimalOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const value = useMemo(
        () => formatDecimal(new Decimal(props.value), props.precision),
        [props.precision, props.value],
    )

    return <Output whiteSpace="nowrap"
                   { ...props }
                   ref={ref}>
        {value}
    </Output>
})

DecimalOutput.displayName = "DecimalOutput"

export default DecimalOutput
