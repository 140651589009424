const canvas = document.createElement("canvas")

export function computeTextWidthForElement(text: string, element: HTMLElement): number {
    const context = canvas.getContext("2d")

    const computedStyle = document.defaultView?.getComputedStyle(element)
    const fontSize = Number.parseInt(computedStyle?.fontSize ?? "16")

    if (context == null)
        return fontSize * text.length

    const fontWeight = computedStyle?.fontWeight ?? "normal"
    const fontFamily = computedStyle?.fontFamily ?? "sans-serif"
    const font = `${fontWeight} ${fontSize}px ${fontFamily}`

    context.font = font

    const metrics = context.measureText(text)

    return metrics.width
}
