import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { RoutePointType, routePointTypeToString } from "model"
import { DeepReadonly } from "my-util"
import { MAX_SHORT_TEXT_LENGTH } from "validation"
import { copyRoutePointFields, RoutePointFields } from "ui/fields"
import { useStateWithDeps } from "ui/hook"
import { copyUiDocument, DocumentListUpload, FieldDocument, UiDocument } from "ui/component/document"
import { Flex, Input, Label, OptionallyRequired, Output, RoutePointTypeSelect } from "ui/ui"
import style from "./style.module.css"

export interface RoutePointEditorProps {
    onChange?: (routePoint: RoutePointFields) => void
    routePoint: RoutePointFields

    validateCountry?: (country: string, entering: boolean) => boolean

    noDocumentDelete?: boolean

    loading?: boolean
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    output?: boolean

    width?: string
}

const RoutePointEditor = forwardRef((
    {
        onChange, routePoint,
        validateCountry,
        noDocumentDelete,
        loading, disabled, readonly, required, output,
        width,
    }: DeepReadonly<RoutePointEditorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const changedRef = useRef(false)

    const [innerRoutePoint, setInnerRoutePoint] = useStateWithDeps<RoutePointFields>(
        oldInnerRoutePoints =>
            !changedRef.current || oldInnerRoutePoints == null
                ? copyRoutePointFields(routePoint)
                : oldInnerRoutePoints,
        [routePoint],
    )

    const [documents, setDocuments] = useState<UiDocument[]>(routePoint.documents.map(copyUiDocument))
    const [enteringCountry, setEnteringCountry] = useState(false)

    // Changes propagation

    useEffect(() => {
        if (changedRef.current) {
            onChange?.(copyRoutePointFields(innerRoutePoint))
            changedRef.current = false
        }
    }, [innerRoutePoint, onChange])

    // Render

    const countryInvalid = validateCountry?.(innerRoutePoint.country, enteringCountry)
    const immutable = readonly || output
    const showDocuments = !immutable || documents.length > 0

    return <div className={style.TransferRoutePointEditor}
                style={{ width }}
                ref={ref}>
        <Flex>
            <Flex direction="horizontal">
                <Flex direction="horizontal">
                    <OptionallyRequired required={required}>
                        <Label text={t("domain.routePoints.labels.country")}/>
                    </OptionallyRequired>

                    {output
                        ? <Output>{innerRoutePoint.country}</Output>

                        : <Input placeholder={t("domain.routePoints.placeholders.country")}

                                 onChange={onCountryChange}
                                 value={innerRoutePoint.country}

                                 max={MAX_SHORT_TEXT_LENGTH}

                                 invalid={countryInvalid}
                                 loading={loading}
                                 disabled={disabled}
                                 readonly={readonly}/>
                    }
                </Flex>

                <Flex direction="horizontal">
                    <Label text={t("domain.routePoints.labels.type")}/>

                    {output
                        ? <Output>{routePointTypeToString(innerRoutePoint.type)}</Output>
                        : <RoutePointTypeSelect onSelect={onTypeChange}
                                                selected={innerRoutePoint.type}

                                                loading={loading}
                                                disabled={disabled}
                                                readonly={readonly}/>
                    }
                </Flex>
            </Flex>

            {showDocuments &&
                <DocumentListUpload onChange={onDocumentsChange}
                                    buttonText={t("domain.routePoints.buttons.addDocuments")}
                                    documents={documents}
                                    disabled={disabled}
                                    readonly={immutable}
                                    noDelete={noDocumentDelete}
                                    multiple/>
            }
        </Flex>
    </div>

    // Events

    function onCountryChange(newCountry: string) {
        updateInnerRoute({
            country: newCountry,
        })

        setEnteringCountry(true)
    }

    function onTypeChange(newType: RoutePointType) {
        updateInnerRoute({
            type: newType,
        })
    }

    function onDocumentsChange(newDocuments: UiDocument[]) {
        updateInnerRoute({
            documents: newDocuments.filter(({ status }) =>
                status === "loading" ||
                status === "ready"
            ) as FieldDocument[],
        })

        setDocuments(newDocuments)
    }

    // Util

    function updateInnerRoute(changes: Partial<RoutePointFields>) {
        setInnerRoutePoint(oldInnerRoutePoint => {
            changedRef.current = true

            return {
                country: changes.country ?? oldInnerRoutePoint.country,
                type: changes.type ?? oldInnerRoutePoint.type,
                documents: changes.documents ?? oldInnerRoutePoint.documents,
            }
        })
    }
})

RoutePointEditor.displayName = "RoutePointEditor"

export default RoutePointEditor
