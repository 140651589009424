import { useNavigate } from "react-router-dom"
import { ForwardedRef, forwardRef, useEffect } from "react"
import { NewChatMessagesNotification, Notification } from "model"

import { useInviteApplications, useInvites,
         useNotifications, useTransfers, useUsers } from "ui/hook"

import { ALL_INVITE_APPLICATIONS_PAGE_PATH } from "ui/page/sections/clients/invites/applications/AllInviteApplicationsPage"
import { createInvitePagePath } from "ui/page/sections/clients/invites/InvitePage/path"
import { createMessengerPagePath } from "ui/page/sections/messenger/MessengerPage"
import { createTransferPagePath } from "ui/page/sections/transfers/TransferPage/path"
import { createUserPagePath } from "ui/page/sections/users/UserPage/path"
import { NotificationList } from "ui/component/notification"

export interface GlobalNotificationListProps {
    onChange?: (notifications: Notification[]) => void

    width?: string
    height?: string
}

const GlobalNotificationList = forwardRef((
    {
        onChange,
        width, height,
    }: Readonly<GlobalNotificationListProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const navigate = useNavigate()

    // State

    const [notifications, { clear, deleteByValue }] = useNotifications()

    const inviteApplications = useInviteApplications()
    const invites = useInvites()
    const users = useUsers()
    const transfers = useTransfers()

    // Effects

    useEffect(
        () => onChange?.(notifications),
        [notifications, onChange],
    )

    useEffect(() => {
        for (const notification of notifications) {
            for (const userId of notification.userIds)
                users.loadByIdIfNew(userId)

            switch (notification.type) {
                case "new-invite-application":
                    inviteApplications.loadByIdIfNew(notification.inviteApplicationId)
                    break

                case "new-invite": {
                    const { inviteId } = notification

                    invites.loadByIdIfNew(inviteId)

                    const invite = invites.loadedById.get(inviteId)

                    if (invite?.creatorId != null)
                        users.loadByIdIfNew(invite.creatorId)

                    break
                }

                case "new-transfer":
                case "transfer-status-changed": {
                    const { transferId } = notification

                    transfers.loadByIdIfNew(transferId)

                    const transfer = transfers.loadedById.get(transferId)

                    if (transfer?.creatorId != null)
                        users.loadByIdIfNew(transfer.creatorId)

                    break
                }
            }
        }
    }, [notifications, inviteApplications, invites, users, transfers])

    // Render

    return <NotificationList notifications={notifications}
                             inviteApplications={inviteApplications.loadedById}
                             invites={invites.loadedById}
                             users={users.loadedById}
                             transfers={transfers.loadedById}

                             onClick={onClick}
                             onClear={clear}
                             onDelete={deleteByValue}

                             width={width}
                             height={height}

                             ref={ref}/>

    // Events

    function onClick(notification: Notification) {
        switch (notification.type) {
            case "new-chat-messages":
                const newPath = createMessengerPagePath(
                    notification instanceof NewChatMessagesNotification
                        ? notification.fromId
                        : null,
                )

                return navigate(newPath)

            case "new-invite-application":
                return navigate(ALL_INVITE_APPLICATIONS_PAGE_PATH, { })

            case "new-invite":
                return navigate(createInvitePagePath(notification.inviteId))

            case "new-transfer":
            case "transfer-status-changed":
                return navigate(createTransferPagePath(notification.transferId))

            case "new-user":
                return navigate(createUserPagePath(notification.userId))

            default:
                notification satisfies never
        }
    }
})

GlobalNotificationList.displayName = "GlobalNotificationList"

export default GlobalNotificationList
