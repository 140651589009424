import { ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export interface BadgeProps {
    backgroundColor?: string
    borderColor?: string
    color?: string

    children?: ReactNode
}

const Badge = forwardRef((
    {
        backgroundColor, borderColor, color,
        children,
    }: Readonly<BadgeProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span style={{ backgroundColor, borderColor, color }}
          className={style.Badge}
          ref={ref}>
        {children}
    </span>
)

Badge.displayName = "Badge"

export default Badge
