import assert from "assert"
import Decimal from "decimal.js"
import { Fragment, useContext, forwardRef, ForwardedRef, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { arrowsLeftRightIconUrl, minusIconUrl, plusIconUrl, trashCanIconUrl } from "image"
import { TransferDirection, Product, Money } from "model"

import { PAYMENT_ACCOUNT_INPUT_REGEX, splicedArray,
         removeWhiteSpace, COMMA_WITH_WHITESPACE_REGEX,
         collapseWhiteSpace, DeepReadonly, isNullOrBlank,
         collapseWhiteSpaceToNull, removeWhiteSpaceToNull,
         SWIFT_INPUT_REGEX,  CORRESPONDENT_ACCOUNT_INPUT_REGEX,
         dateWithDateSet, dateWithTimeSet, IBAN_INPUT_REGEX, join } from "my-util"

import { MAX_SHORT_TEXT_LENGTH, LEGAL_ITN_LENGTH,
         PAYMENT_ACCOUNT_LENGTH, MAX_SWIFT_LENGTH,
         validateCnfea, validateCorrespondentAccount,
         validateLegalItn, validateIban, MAX_IBAN_LENGTH,
         validatePaymentAccount, validateSwift, CNFEA_LENGTH,
         CORRESPONDENT_ACCOUNT_LENGTH, MAX_MEDIUM_TEXT_LENGTH } from "validation"

import { copyTransferFields, copyRoutePointFields, TransferFields } from "ui/fields"
import { useStateWithDeps } from "ui/hook"
import { UserContext } from "ui/context"

import { DocumentListUpload, UiDocument, copyUiDocument,
         FieldDocument, RoutePointListEditor, isFieldDocument } from "ui/component"

import { Button, Label, FormControlsButton, TextArea, Form, Icon,
         Input, MoneyInput, Required, FormControls, Information, DateInput, Flex,
         FLEX_DEFAULT_GAP, TransferDirectionRadio, Subheader, TimeInput, DecimalInput } from "ui/ui"

import style from "./style.module.css"

// Styles

const DATE_INPUT_WIDTH = "105px"
const TIME_INPUT_WIDTH = "80px"

// Product

interface ProductInput {
    name: string | undefined
    cnfeaCodes: string | undefined
}

const BLANK_PRODUCT_INPUT: Readonly<ProductInput> = {
    name: undefined,
    cnfeaCodes: undefined,
}

function productToProductInput(product: Product): ProductInput {
    return {
        name: product.name,
        cnfeaCodes: join(product.cnfeaCodes, ", ")
    }
}

// Component and props

export interface TransferFillInFormProps {
    onSubmit?: (fields: TransferFields) => void
    onReset?: () => void
    onChange?: (fields: TransferFields) => void

    fields?: TransferFields
    buttons?: FormControlsButton[]

    noDocumentsDelete?: boolean
    showAgentPercentInput?: boolean

    loading?: boolean
    disabled?: boolean
    error?: unknown

    width?: string
    height?: string

    exportDisabled?: boolean
    importDisabled?: boolean
}

const TransferFillInForm = forwardRef((
    {
        onSubmit, onReset, onChange,
        fields, buttons,
        noDocumentsDelete, showAgentPercentInput,
        loading, disabled, error,
        width, height,
        exportDisabled, importDisabled,
    }: DeepReadonly<TransferFillInFormProps>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    // - Status

    const status = fields?.status ?? "new"
    const isNew = status === "new"
    const isWaitingMoment = status === "waiting-moment"
    const isWaitingPayment = status === "waiting-payment"
    const isWaiting = isWaitingMoment || isWaitingPayment
    const isPaid = status === "paid"
    const isDone = status === "done"

    // - Direction

    const [direction, setDirection] = useStateWithDeps(
        () => evalInitialDirection(fields?.direction),
        [fields?.direction],
    )

    const directionDisabled = direction === "export"
        ? exportDisabled
        : importDisabled

    // - Moment

    const isMomentStatus = isWaiting || isPaid || isDone

    const [moment, setMoment] = useStateWithDeps(
        () => fields?.moment != null
            ? new Date(fields.moment.getTime())
            : null,
        [fields?.moment],
    )

    // - Country

    const [country, setCountry] = useStateWithDeps(() => fields?.country, [fields?.country])
    const countryInvalid = isNullOrBlank(country)
    const countryInputInvalid = country != null && countryInvalid

    // - Recipient

    // -- Name

    const [recipientName, setRecipientName] = useStateWithDeps(
        () => fields?.recipient?.name,
        [fields?.recipient?.name],
    )

    const recipientNameInvalid = isNullOrBlank(recipientName)
    const recipientNameInputInvalid = recipientName != null && recipientNameInvalid

    // -- ITN

    const [recipientItn, setRecipientItn] = useStateWithDeps(
        () => fields?.recipient?.itn ?? undefined,
        [fields?.recipient?.itn],
    )

    const recipientItnInvalid =
        !isNullOrBlank(recipientItn) &&
        validateLegalItn(recipientItn!) != null

    const recipientItnInputInvalid =
        recipientItn != null &&
        recipientItnInvalid

    // - Correspondent account

    const [recipientCorrespondentAccount, setRecipientCorrespondentAccount] = useStateWithDeps(
        () => fields?.recipient?.correspondentAccount ?? undefined,
        [fields?.recipient?.correspondentAccount],
    )

    const recipientCorrespondentAccountInvalid =
        !isNullOrBlank(recipientCorrespondentAccount) &&
        validateCorrespondentAccount(recipientCorrespondentAccount!) != null

    const recipientCorrespondentAccountInputInvalid = recipientCorrespondentAccountInvalid

    // - Payment account

    const [recipientPaymentAccount, setRecipientPaymentAccount] = useStateWithDeps(
        () => fields?.recipient?.paymentAccount ?? undefined,
        [fields?.recipient?.paymentAccount],
    )

    const recipientPaymentAccountInvalid =
        !isNullOrBlank(recipientPaymentAccount) &&
        validatePaymentAccount(recipientPaymentAccount!) != null

    const recipientPaymentAccountInputInvalid =
        recipientPaymentAccount != null &&
        recipientPaymentAccountInvalid

    // - IBAN

    const [recipientIban, setRecipientIban] = useStateWithDeps(
        () => fields?.recipient?.iban ?? undefined,
        [fields?.recipient?.paymentAccount],
    )

    const recipientIbanInvalid =
        !isNullOrBlank(recipientIban) &&
        validateIban(recipientIban!) != null

    const recipientIbanInputInvalid =
        recipientIban != null &&
        recipientIbanInvalid

    // - Bank

    // -- Name

    const [recipientBankName, setRecipientBankName] = useStateWithDeps(
        () => fields?.recipient?.bank?.name,
        [fields?.recipient?.bank?.name],
    )

    const recipientBankNameInvalid = isNullOrBlank(recipientBankName)
    const recipientBankNameInputInvalid = recipientBankName != null && recipientBankNameInvalid

    // -- Address

    const [recipientBankAddress, setRecipientBankAddress] = useStateWithDeps(
        () => fields?.recipient?.bank?.address,
        [fields?.recipient?.bank?.address],
    )

    const recipientBankAddressInvalid = isNullOrBlank(recipientBankAddress)

    const recipientBankAddressInputInvalid =
        recipientBankAddress != null &&
        recipientBankAddressInvalid

    // -- Swift

    const [recipientBankSwift, setRecipientBankSwift] = useStateWithDeps(
        () => fields?.recipient?.bank?.swift,
        [fields?.recipient?.bank?.swift],
    )

    const recipientBankSwiftInvalid =
        !isNullOrBlank(recipientBankSwift) &&
        validateSwift(recipientBankSwift!) != null

    const recipientBankSwiftInputInvalid =
        recipientBankSwift != null &&
        recipientBankSwiftInvalid

    // - Money

    const [money, setMoney] = useStateWithDeps(() => fields?.money ?? new Money(), [fields?.money])

    // - Products

    const [products, setProducts] = useStateWithDeps(
        () => (
            fields?.products?.map(productToProductInput) ?? [{ ...BLANK_PRODUCT_INPUT }]
        ) satisfies ProductInput[] as ProductInput[],

        [fields?.products],
    )

    const productsMeta = products.map(({ name, cnfeaCodes }) => {
        const nameInvalid = isNullOrBlank(name)
        const nameInputInvalid = name != null && nameInvalid

        const splittedCnfeaCodes = cnfeaCodes != null
            ? cnfeaCodes
                .trim()
                .split(COMMA_WITH_WHITESPACE_REGEX)
                .filter(Boolean)
            : []

        const normalizedCnfeaCodes = splittedCnfeaCodes.map(code => code.padEnd(CNFEA_LENGTH, "0"))

        const cnfeaCodesInvalid =
            normalizedCnfeaCodes.length === 0 ||
            normalizedCnfeaCodes.some(code => validateCnfea(code) != null)

        const cnfeaCodesInputInvalid = cnfeaCodes != null && cnfeaCodesInvalid

        return {
            name: {
                invalid: nameInvalid,
                inputInvalid: nameInputInvalid,
            },

            cnfeaCodes: {
                invalid: cnfeaCodesInvalid,
                inputInvalid: cnfeaCodesInputInvalid,
            },
        }
    })

    // - Invoice

    // -- Number

    const [invoiceNumber, setInvoiceNumber] = useStateWithDeps(
        () => fields?.invoice?.number,
        [fields?.invoice?.number],
    )

    const invoiceNumberInvalid = isNullOrBlank(invoiceNumber)
    const invoiceNumberInputInvalid = invoiceNumber != null && invoiceNumberInvalid

    // -- Date

    const [invoiceDate, setInvoiceDate] = useStateWithDeps(
        () => new Date(fields?.invoice?.date.getTime() ?? Date.now()),
        [fields?.invoice?.date],
    )

    // -- Document

    const [invoiceDocument, setInvoiceDocument] = useStateWithDeps<UiDocument | undefined>(
        () => fields?.invoice?.document != null
            ? copyUiDocument(fields.invoice.document)
            : undefined,

        [fields?.contract?.document],
    )

    const invoiceDocumentLoading = invoiceDocument?.status === "loading"

    // - Contract

    // -- Number

    const [contractNumber, setContractNumber] = useStateWithDeps(
        () => fields?.contract?.number,
        [fields?.contract?.number],
    )

    const contractNumberInvalid = isNullOrBlank(contractNumber)
    const contractNumberInputInvalid = contractNumber != null && contractNumberInvalid

    // -- Date

    const [contractDate, setContractDate] = useStateWithDeps(
        () => new Date(fields?.contract?.date.getTime() ?? Date.now()),
        [fields?.contract?.date],
    )

    // -- Document

    const [contractDocument, setContractDocument] = useStateWithDeps<UiDocument | undefined>(
        () => fields?.contract?.document != null
            ? copyUiDocument(fields.contract.document)
            : undefined,

        [fields?.contract?.document],
    )

    const contractDocumentLoading = contractDocument?.status === "uploading"

    // - Documents

    const [documents, setDocuments] = useStateWithDeps(
        () => [...fields?.documents ?? []] as UiDocument[],
        [fields?.documents],
    )

    const documentsUploading = documents.some(({ status }) => status === "uploading" )

    // - Route points

    const [routePoints, setRoutePoints] = useStateWithDeps(
        () => fields?.routePoints?.map(copyRoutePointFields) ?? [],
        [fields?.routePoints],
    )

    // - Currency rate

    const isCurrencyRateStatus = isWaitingPayment || isPaid || isDone

    // -- Money

    const [currencyRateMoney, setCurrencyRateMoney] = useStateWithDeps(
        () => fields?.currencyRate?.money ?? null,
        [fields?.currencyRate?.money],
    )

    // -- Moment

    const [currencyRateMoment, setCurrencyRateMoment] = useStateWithDeps(
        () => fields?.currencyRate?.moment != null
            ? new Date(fields?.currencyRate.moment.getTime())
            : null,

        [fields?.currencyRate?.moment],
    )

    // -- Evidence

    const [currencyRateEvidence, setCurrencyRateEvidence] = useStateWithDeps<UiDocument | undefined>(
        () => fields?.currencyRate?.evidence != null
            ? copyUiDocument(fields?.currencyRate?.evidence)
            : undefined,

        [fields?.currencyRate?.evidence],
    )

    const currencyRateEvidenceUploading = currencyRateEvidence?.status === "uploading"

    // -- Evidence link

    const [currencyRateEvidenceLink, setCurrencyRateEvidenceLink] = useStateWithDeps(
        () => fields?.currencyRate?.evidenceLink,
        [fields?.currencyRate?.evidenceLink],
    )

    const currencyRateEvidenceLinkInvalid = isCurrencyRateStatus
        ? isNullOrBlank(currencyRateEvidenceLink)
        : false

    const currencyRateEvidenceLinkInputInvalid =
        currencyRateEvidenceLink != null &&
        currencyRateEvidenceLinkInvalid

    // - Shares and expenses

    // -- Attorney fee

    const [attorneyFee, setAttorneyFee] = useStateWithDeps(
        () => fields?.attorneyFee?.amount ?? null,
        [fields?.attorneyFee?.amount],
    )

    // -- Agent percent

    const [agentPercent, setAgentPercent] = useStateWithDeps(
        () => fields?.agentPercent ?? null,
        [fields?.agentPercent],
    )

    // -- Expenses

    const [expenses, setExpenses] = useStateWithDeps(
        () => fields?.expenses ?? null,
        [fields?.expenses],
    )

    // - Comments

    // -- Admin's

    const [adminComment, setAdminComment] = useStateWithDeps(
        () => fields?.adminComment ?? "",
        [fields?.adminComment],
    )

    // -- User's

    const [comment, setComment] = useStateWithDeps(
        () => fields?.comment ?? "",
        [fields?.comment],
    )

    // Submit disabled

    const submitDisabled =
        disabled ||
        directionDisabled ||

        countryInvalid ||

        recipientNameInvalid ||
        recipientItnInvalid ||
        recipientCorrespondentAccountInvalid ||
        recipientPaymentAccountInvalid ||
        recipientIbanInvalid ||

        recipientBankNameInvalid ||
        recipientBankAddressInvalid ||
        recipientBankSwiftInvalid ||

        invoiceNumberInvalid ||
        invoiceDocument == null ||
        invoiceDocumentLoading ||

        contractNumberInvalid ||
        contractDocument == null ||
        contractDocumentLoading ||

        (isCurrencyRateStatus && (
            currencyRateEvidenceUploading ||
            currencyRateEvidenceLinkInvalid
        )) ||

        (expenses?.isZero() && !localUser?.isAdmin) ||

        documentsUploading
        productsMeta.some((meta, i) =>
            meta.name.invalid ||
            meta.cnfeaCodes.invalid
        ) ||
        routePoints.some(({ country }) => isNullOrBlank(country))

    // Form

    return <Form onSubmit={onInnerSubmit}
                 onReset={onReset}
                 loading={loading}
                 width={width}
                 height={height}
                 ref={ref}>
        <Flex justify="space-between"
              height="100%">
            <div className={style.fields}>
                {renderDirection()}
                {renderMoment()}
                {renderRecipientSection()}
                {renderProductsSection()}
                {renderRouteSection()}
                {renderCurrencyRateSection()}
                {renderSharesAndExpensesSection()}
                {renderDocumentsSection()}
                {renderCommentsSection()}
            </div>

            {renderControls()}
        </Flex>
    </Form>

    function renderDirection(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.direction")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <TransferDirectionRadio onChange={setDirection}
                                        checked={direction}

                                        loading={loading}
                                        disabled={disabled}

                                        exportDisabled={exportDisabled}
                                        importDisabled={importDisabled}/>
            </div>
        </>
    }

    function renderMoment(): ReactNode {
        if (!isMomentStatus)
            return

        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.date")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <DateInput
                        value={moment}
                        onChange={({ date }) =>
                            setMoment(oldMoment =>
                                oldMoment != null
                                    ? dateWithDateSet(oldMoment, date)
                                    : date
                            )
                        }

                        loading={loading}
                        disabled={disabled}

                        width={DATE_INPUT_WIDTH}/>

                    <Required>
                        <Label text={t("domain.transfers.labels.time")}/>
                    </Required>

                    <TimeInput
                        value={moment}
                        onChange={({ date }) =>
                            setMoment(oldMoment =>
                                oldMoment != null
                                    ? dateWithTimeSet(oldMoment, date)
                                    : date
                            )
                        }

                        loading={loading}
                        disabled={disabled}

                        width={TIME_INPUT_WIDTH}/>
                </Flex>
            </div>
        </>
    }

    // Recipient

    function renderRecipientSection(): ReactNode {
        return <>
            {renderRecipientSubheader()}
            {renderCountry()}
            {renderRecipient()}
            {renderMoney()}
            {renderBank()}
            {renderAccounts()}
        </>
    }

    function renderRecipientSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.recipient")}/>
        </div>
    }

    function renderCountry(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.country")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Input placeholder={t("domain.transfers.placeholders.country")}

                       onChange={setCountry}
                       value={country}

                       invalid={countryInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>
        </>
    }

    function renderRecipient(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.recipient.name.full")}/>
                </Required>
            </div>

            <div className={style.column2Value}>
                <Input placeholder={t("domain.transfers.placeholders.recipient.name")}

                       onChange={setRecipientName}
                       value={recipientName}

                       invalid={recipientNameInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>

            {/* ITN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.itn")}/>
            </div>

            <div className={style.column4Value}>
                <Input placeholder={t("domain.transfers.placeholders.recipient.itn")}

                       onChange={setRecipientItn}
                       value={recipientItn}

                       invalid={recipientItnInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={LEGAL_ITN_LENGTH}/>
            </div>
        </>
    }

    function renderMoney(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.money")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <MoneyInput placeholder={t("domain.transfers.placeholders.money")}

                            onChange={setMoney}
                            value={money}

                            loading={loading}
                            disabled={disabled}/>
            </div>
        </>
    }

    function renderBank(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.recipient.bank.name")}/>
                </Required>
            </div>

            <div className={style.column2Value}>
                <Input placeholder={t("domain.transfers.placeholders.recipient.bank.name")}

                       onChange={setRecipientBankName}
                       value={recipientBankName}

                       invalid={recipientBankNameInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>

            {/* SWIFT */}

            <div className={style.column3Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.recipient.bank.swift")}/>
                </Required>
            </div>

            <div className={style.column4Value}>
                <Input placeholder={t("domain.transfers.placeholders.recipient.bank.swift")}

                       onChange={setRecipientBankSwift}
                       value={recipientBankSwift}

                       invalid={recipientBankSwiftInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       postProcess={text => text.toUpperCase()}

                       regex={SWIFT_INPUT_REGEX}
                       max={MAX_SWIFT_LENGTH}/>
            </div>

            {/* Address */}

            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.recipient.bank.address")}/>
                </Required>
            </div>

            <div className={style.column2Value}>
                <Input placeholder={t("domain.transfers.placeholders.recipient.bank.address")}

                       onChange={setRecipientBankAddress}
                       value={recipientBankAddress}

                       invalid={recipientBankAddressInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>

            {/* IBAN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.iban")}/>
            </div>

            <div className={style.column4Value}>
                <Input placeholder={t("domain.transfers.placeholders.recipient.iban")}

                       onChange={setRecipientIban}
                       value={recipientIban}

                       invalid={recipientIbanInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       regex={IBAN_INPUT_REGEX}
                       max={MAX_IBAN_LENGTH}/>
            </div>
        </>
    }

    function renderAccounts(): ReactNode {
        return <>
            {/* Correspondent account */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.correspondentAccount.short")}/>
            </div>

            <div className={style.column2Value}>
                <Input placeholder={t("domain.transfers.placeholders.recipient.correspondentAccount")}

                       onChange={setRecipientCorrespondentAccount}
                       value={recipientCorrespondentAccount}

                       invalid={recipientCorrespondentAccountInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       regex={CORRESPONDENT_ACCOUNT_INPUT_REGEX}
                       max={CORRESPONDENT_ACCOUNT_LENGTH}/>
            </div>

            {/* Payment account */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.paymentAccount.short")}/>
            </div>

            <div className={style.column4Value}>
                <Input placeholder={t("domain.transfers.placeholders.recipient.paymentAccount")}

                       onChange={setRecipientPaymentAccount}
                       value={recipientPaymentAccount}

                       invalid={recipientPaymentAccountInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       regex={PAYMENT_ACCOUNT_INPUT_REGEX}
                       max={PAYMENT_ACCOUNT_LENGTH}/>
            </div>
        </>
    }

    // Products

    function renderProductsSection(): ReactNode {
        return <>
            {renderProductsSubheader()}
            {renderProducts()}
        </>
    }

    function renderProductsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.products")}/>
        </div>
    }

    function renderProducts(): ReactNode {
        const BUTTON_WIDTH = "32px"
        const CNFEA_CODES_INPUT_WIDTH = `calc(100% - 2 * (${BUTTON_WIDTH} + ${FLEX_DEFAULT_GAP}))`

        return products.map((product, i) => {
            const meta = productsMeta[i]

            return <Fragment key={i}>
                {/* Name */}

                <div className={style.column1Label}>
                    <Required>
                        <Label text={t("domain.products.labels.name")}/>
                    </Required>
                </div>

                <div className={style.column2Value}>
                    <Input placeholder={t("domain.products.placeholders.name")}

                           onChange={newName => onProductNameChange(newName, i)}
                           value={product.name}

                           invalid={meta.name.inputInvalid}
                           loading={loading}
                           disabled={disabled}/>
                </div>

                {/* CN FEA codes */}

                <div className={style.column3Label}>
                    <Required>
                        <Label text={t("domain.products.labels.cnfeaCodes")}/>
                    </Required>
                </div>

                <div className={style.column4Value}>
                    <Flex direction="horizontal">
                        <Input placeholder={t("domain.products.placeholders.cnfeaCodes")}

                               width={CNFEA_CODES_INPUT_WIDTH}

                               onChange={newCnfeaCodes => onProductCnfeaCodesChange(newCnfeaCodes, i)}
                               value={product.cnfeaCodes}

                               invalid={meta.cnfeaCodes.inputInvalid}
                               loading={loading}
                               disabled={disabled}

                               regex={/^[,\d\s]*$/g}/>

                        <Button onClick={() => onRemoveProduct(i)}

                                width={BUTTON_WIDTH}
                                buttonStyle="outline"
                                critical

                                disabled={disabled || products.length < 2}
                                loading={loading}

                                iconSrc={trashCanIconUrl}
                                iconAlt="Trash can icon"/>

                        {i === products.length - 1 &&
                            <Button onClick={onAddProduct}

                                    width={BUTTON_WIDTH}

                                    disabled={disabled}
                                    loading={loading}

                                    iconSrc={plusIconUrl}
                                    iconAlt="Plus icon"/>
                        }
                    </Flex>
                </div>
            </Fragment>
        })

        function onProductNameChange(newName: string, productIndex: number) {
            setProducts(oldProducts => {
                const oldProduct = oldProducts[productIndex]

                const newProduct: ProductInput = {
                    name: newName,
                    cnfeaCodes: oldProduct.cnfeaCodes,
                }

                return splicedArray(oldProducts, productIndex, 1, newProduct)
            })
        }

        function onProductCnfeaCodesChange(newCnfeaCodes: string, productIndex: number) {
            setProducts(oldProducts => {
                const oldProduct = oldProducts[productIndex]

                const newProduct = {
                    name: oldProduct.name,
                    cnfeaCodes: newCnfeaCodes,
                }

                return splicedArray(oldProducts, productIndex, 1, newProduct)
            })
        }

        function onRemoveProduct(productIndex: number) {
            setProducts(oldProducts => splicedArray(oldProducts, productIndex, 1))
        }

        function onAddProduct() {
            setProducts(oldProducts => [...oldProducts, { ...BLANK_PRODUCT_INPUT }])
        }
    }

    // Route

    function renderRouteSection(): ReactNode {
        return <>
            {renderRouteSubheader()}
            {renderRoute()}
        </>
    }

    function renderRouteSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.route")}/>
        </div>
    }

    function renderRoute(): ReactNode {
        return <>
            <div className={style.column1To4Value}>
                <Information text={t("domain.transfers.messages.routeNote")}
                             whiteSpace="pre-line"/>
            </div>

            <div className={style.column1To4Value}>
                <RoutePointListEditor onChange={setRoutePoints}
                                      routePoints={routePoints}

                                      noDocumentDelete={noDocumentsDelete}

                                      validateCountry={isCountryInputValid}

                                      loading={loading}
                                      disabled={disabled}
                                      required/>

            </div>
        </>
    }

    // CurrencyRate

    function renderCurrencyRateSection(): ReactNode {
        if (!localUser?.isManager || !isCurrencyRateStatus)
            return null

        return <>
            {renderCurrencyRateSubheader()}
            {renderCurrencyRateMoney()}
            {renderCurrencyRateMoment()}
            {renderCurrencyRateEvidence()}
            {renderCurrencyRateEvidenceLink()}
        </>
    }

    function renderCurrencyRateSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.currencyRate")}/>
        </div>
    }

    function renderCurrencyRateMoney(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.currencyRate")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal">
                    <MoneyInput onChange={setCurrencyRateMoney}
                                value={currencyRateMoney}
                                precision={4}

                                loading={loading}
                                disabled={disabled}/>

                    <Icon src={arrowsLeftRightIconUrl}
                          alt="Left and right arrows"
                          filter="brightness(0) invert(50%)"/>

                    <MoneyInput onChange={({ currency }) => setTimeout(() => setMoney(money.copy({ currency })), 0)}
                                value={new Money({ currency: money.currency, amount: 1 })}
                                readonlyAmount
                                precision={4}/>
                </Flex>
            </div>
        </>
    }

    function renderCurrencyRateMoment(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.currencyRates.labels.date")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <DateInput
                        value={currencyRateMoment}

                        onChange={({ date }) => setCurrencyRateMoment(oldCurrencyRateMoment =>
                            oldCurrencyRateMoment != null
                                ? dateWithDateSet(oldCurrencyRateMoment, date)
                                : date,
                        )}

                        loading={loading}
                        disabled={disabled}

                        width={DATE_INPUT_WIDTH}
                    />

                    <Label text={t("datetime.labels.time")}/>

                    <TimeInput
                        value={currencyRateMoment}

                        onChange={({ date }) => setCurrencyRateMoment(oldCurrencyRateMoment =>
                            oldCurrencyRateMoment != null
                                ? dateWithTimeSet(oldCurrencyRateMoment, date)
                                : date,
                        )}

                        loading={loading}
                        disabled={disabled}

                        width={TIME_INPUT_WIDTH}
                    />
                </Flex>
            </div>
        </>
    }

    function renderCurrencyRateEvidence(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.currencyRates.labels.evidence")}/>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload
                    onChange={documents =>
                        setCurrencyRateEvidence(
                            documents.length > 0
                                ? documents[0]
                                : undefined
                        )
                    }

                    documents={
                        currencyRateEvidence != null
                            ? [currencyRateEvidence]
                            : undefined
                    }

                    disabled={loading || disabled}
                />
            </div>
        </>
    }

    function renderCurrencyRateEvidenceLink(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.currencyRates.labels.evidenceLink")}/>
                </Required>
            </div>

            <div className={style.column2Value}>
                <Input onChange={setCurrencyRateEvidenceLink}
                       value={currencyRateEvidenceLink ?? undefined}

                       placeholder={t("domain.currencyRates.placeholders.evidenceLink")}

                       invalid={currencyRateEvidenceLinkInputInvalid}
                       loading={loading}
                       disabled={disabled}

                       max={MAX_SHORT_TEXT_LENGTH}/>
            </div>
        </>
    }

    // Shares and expenses

    function renderSharesAndExpensesSection(): ReactNode {
        if (!localUser?.isManager)
            return null

        return <>
            {renderSharesAndExpensesSubheader()}
            {renderAttorneyFee()}
            {renderAgentPercent()}
            {renderExpenses()}
        </>
    }

    function renderSharesAndExpensesSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.sharesAndExpenses")}/>
        </div>
    }

    function renderAttorneyFee(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.attorneyFee")}/>
            </div>

            <div className={style.column2Value}>
                {(() => {
                    if (attorneyFee == null)
                        return <Button onClick={() => setAttorneyFee(new Decimal(100))}
                                       iconSrc={plusIconUrl}
                                       iconAlt="Plus icon"
                                       width="fit-content"/>

                        return <Flex direction="horizontal"
                                     gap="32px">
                            <DecimalInput onChange={setAttorneyFee}
                                          value={attorneyFee}

                                          placeholder={t("domain.transfers.placeholders.attorneyFee")}
                                          right="₽"

                                          loading={loading}
                                          disabled={disabled}/>

                            <Button onClick={() => setAttorneyFee(null)}
                                    iconSrc={minusIconUrl}
                                    iconAlt="Minus icon"
                                    width="fit-content"/>
                        </Flex>
                })()}
            </div>
        </>
    }

    function renderAgentPercent(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.agentPercent")}/>
            </div>

            <div className={style.column2Value}>
                {(() => {
                    if (agentPercent == null)
                        return <Button onClick={() => setAgentPercent(new Decimal(2.5))}
                                       iconSrc={plusIconUrl}
                                       iconAlt="Plus icon"
                                       width="fit-content"/>

                        return <Flex direction="horizontal"
                                     gap="32px">

                            <DecimalInput onChange={setAgentPercent}
                                          value={agentPercent}
                                          precision={4}

                                          placeholder={t("domain.transfers.placeholders.agentPercent")}
                                          right="%"

                                          loading={loading}
                                          disabled={disabled}/>

                            <Button onClick={() => setAgentPercent(null)}
                                    iconSrc={minusIconUrl}
                                    iconAlt="Minus icon"
                                    width="fit-content"/>
                        </Flex>
                })()}
            </div>
        </>
    }

    function renderExpenses(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.expenses")}/>
            </div>

            <div className={style.column2Value}>
                {(() => {
                    if (expenses == null)
                        return <Button onClick={() => setExpenses(new Decimal(5_000))}
                                       iconSrc={plusIconUrl}
                                       iconAlt="Plus icon"
                                       width="fit-content"/>

                        return <Flex direction="horizontal"
                                     gap="32px">
                            <DecimalInput onChange={setExpenses}
                                          value={expenses}

                                          placeholder={t("domain.transfers.placeholders.expenses")}
                                          right="₽"

                                          loading={loading}
                                          disabled={disabled}/>

                            <Button onClick={() => setExpenses(null)}
                                    iconSrc={minusIconUrl}
                                    iconAlt="Minus icon"
                                    width="fit-content"/>
                        </Flex>
                })()}
            </div>
        </>
    }

    // - Documents

    function renderDocumentsSection(): ReactNode {
        return <>
            {renderDocumentsSubheader()}
            {renderInvoice()}
            {renderContract()}
            {renderDocuments()}
        </>
    }

    function renderDocumentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.documents")}/>
        </div>
    }

    function renderInvoice(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.invoice.number")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <Input placeholder={t("domain.transfers.placeholders.invoice.number")}

                           onChange={setInvoiceNumber}
                           value={invoiceNumber}

                           invalid={invoiceNumberInputInvalid}
                           loading={loading}
                           disabled={disabled}

                           max={MAX_SHORT_TEXT_LENGTH}/>

                    <Required>
                        <Label text={t("domain.transfers.labels.invoice.date")}/>
                    </Required>

                    <div>
                        <DateInput onChange={({ date }) => setInvoiceDate(date)}
                                   value={invoiceDate}

                                   loading={loading}
                                   disabled={disabled}

                                   width={DATE_INPUT_WIDTH}/>
                    </div>
                </Flex>
            </div>

            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.invoice.document")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload
                    onChange={documents =>
                        setInvoiceDocument(
                            documents.length > 0
                                ? documents[0]
                                : undefined
                        )
                    }

                    documents={
                        invoiceDocument != null
                            ? [invoiceDocument]
                            : undefined
                    }

                    buttonText={t("domain.transfers.buttons.addInvoice")}

                    disabled={disabled || loading}

                    noDelete={noDocumentsDelete}
                />
            </div>
        </>
    }

    function renderContract(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.contract.number")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <Input placeholder={t("domain.transfers.placeholders.contract.number")}

                           onChange={setContractNumber}
                           value={contractNumber}

                           invalid={contractNumberInputInvalid}
                           loading={loading}
                           disabled={disabled}

                           max={MAX_SHORT_TEXT_LENGTH}/>

                    <Required>
                        <Label text={t("domain.transfers.labels.contract.date")}/>
                    </Required>

                    <div>
                        <DateInput onChange={({ date }) => setContractDate(date)}
                                   value={contractDate}

                                   loading={loading}
                                   disabled={disabled}

                                   width={DATE_INPUT_WIDTH}/>
                    </div>
                </Flex>
            </div>

            <div className={style.column1Label}>
                <Required>
                    <Label text={t("domain.transfers.labels.contract.document")}/>
                </Required>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload
                    onChange={documents =>
                        setContractDocument(
                            documents.length > 0
                                ? documents[0]
                                : undefined
                        )
                    }

                    documents={
                        contractDocument != null
                            ? [contractDocument]
                            : undefined
                    }

                    buttonText={t("domain.transfers.buttons.addContract")}

                    disabled={disabled || loading}

                    noDelete={noDocumentsDelete}
                />
            </div>
        </>
    }

    function renderDocuments(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.documents")}/>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload buttonText={t("domain.transfers.buttons.addDocuments")}

                                    onChange={onDocumentsChange}
                                    documents={documents}

                                    noDelete={noDocumentsDelete}

                                    disabled={loading || disabled}

                                    multiple/>
            </div>
        </>

        function onDocumentsChange(newDocuments: UiDocument[]) {
            setDocuments(newDocuments)

            if (fields == null ||
                onChange == null ||
                newDocuments.some(({ status }) => status !== "ready" && status !== "loading"))
                return

            const newFields = copyTransferFields(fields)

            newFields.documents = newDocuments as FieldDocument[]

            onChange?.(newFields)
        }
    }

    // - Comments

    function renderCommentsSection(): ReactNode {
        return <>
            {renderCommentsSubheader()}
            {renderComment()}

            {!isNew &&
                renderAdminComment()
            }
        </>
    }

    function renderCommentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.comments")}/>
        </div>
    }

    function renderComment(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.comment")}/>
            </div>

            <div className={style.column2To4Value}>
                <TextArea placeholder={t("domain.transfers.placeholders.comment")}

                          onChange={setComment}
                          value={comment}

                          loading={loading}
                          disabled={disabled}

                          resize="vertical"

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          showMax/>
            </div>
        </>
    }

    function renderAdminComment(): ReactNode {
        if (!localUser?.isManager)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.adminComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <TextArea placeholder={t("domain.transfers.placeholders.comment")}

                          onChange={setAdminComment}
                          value={adminComment}

                          loading={loading}
                          disabled={disabled}

                          resize="vertical"

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          showMax/>
            </div>
        </>
    }

    function renderControls(): ReactNode {
        const localButtons: FormControlsButton[] = buttons?.map(button =>
            button.type === "submit"
                ? { ...button, disabled: submitDisabled }
                : button
        ) ?? []

        if (process.env.NODE_ENV === "development")
            localButtons.push({
                onClick: fillWithTestData,
                text: t("test.buttons.fillWithTestData"),
                buttonStyle: "outline",
                position: "right",
                disabled,
                loading,
            })

        return <FormControls buttons={localButtons}
                             loading={loading}
                             disabled={disabled}
                             error={error}/>
    }

    // Events

    function onInnerSubmit() {
        if (!onSubmit)
            return

        assert(
            country != null &&

            recipientName != null &&
            recipientPaymentAccount != null &&

            recipientBankName != null &&
            recipientBankAddress != null &&
            recipientBankSwift != null &&

            invoiceNumber != null &&
            contractNumber != null
        )

        const newFields: TransferFields = {
            // Model object

            id: fields?.id,

            createdAt: fields?.createdAt != null
                ? new Date(fields.createdAt.getTime())
                : null,

            modifiedAt: fields?.modifiedAt != null
                ? new Date(fields.modifiedAt.getTime())
                : null,

            // Common

            creatorId: fields?.id ?? localUser?.id ?? "00000000-0000-0000-0000-000000000000",
            direction,
            country: collapseWhiteSpace(country),

            recipient: {
                name: collapseWhiteSpace(recipientName),

                itn: recipientItn != null
                    ? removeWhiteSpaceToNull(recipientItn)
                    : null,

                paymentAccount: recipientPaymentAccount != null
                    ? removeWhiteSpaceToNull(recipientPaymentAccount)?.toUpperCase() ?? null
                    : null,

                correspondentAccount: recipientCorrespondentAccount != null
                    ? removeWhiteSpaceToNull(recipientCorrespondentAccount)?.toUpperCase() ?? null
                    : null,

                iban: recipientIban != null
                    ? removeWhiteSpaceToNull(recipientIban)?.toUpperCase() ?? null
                    : null,

                bank: {
                    name: collapseWhiteSpace(recipientBankName),
                    address: collapseWhiteSpace(recipientBankAddress),
                    swift: removeWhiteSpace(recipientBankSwift).toUpperCase(),
                },
            },

            // Money

            money,

            currencyRate: currencyRateMoney != null && currencyRateMoment != null
                ? {
                    money: currencyRateMoney,
                    moment: currencyRateMoment,

                    evidence: isFieldDocument(currencyRateEvidence)
                        ? currencyRateEvidence
                        : null,

                    evidenceLink: currencyRateEvidenceLink != null
                        ? collapseWhiteSpaceToNull(currencyRateEvidenceLink)
                        : null,
                }
                : null,

            // Special documents

            invoice: {
                number: collapseWhiteSpace(invoiceNumber),
                date: invoiceDate,

                document: isFieldDocument(invoiceDocument)
                    ? invoiceDocument
                    : null,
            },

            contract: {
                number: collapseWhiteSpace(contractNumber),
                date: contractDate,

                document: isFieldDocument(contractDocument)
                    ? contractDocument
                    : null,
            },

            // Payments

            expenses,
            agentPercent,

            attorneyFee: attorneyFee != null
                ? new Money({
                    amount: attorneyFee,
                    currency: fields?.attorneyFee?.currency ?? "RUB",
                })
                : null,

            // Comment

            comment: collapseWhiteSpaceToNull(comment),
            adminComment: collapseWhiteSpaceToNull(adminComment),

            // Moment

            moment,

            // Status

            status,

            // Collections

            documents: documents.filter(isFieldDocument),

            products: products.map(({ name, cnfeaCodes }) => {
                assert(name != null && cnfeaCodes != null)

                return new Product({
                    name: collapseWhiteSpace(name),

                    cnfeaCodes: removeWhiteSpace(cnfeaCodes)
                        .split(COMMA_WITH_WHITESPACE_REGEX)
                        .map(code => code.padEnd(CNFEA_LENGTH, "0")),
                })
            }),

            routePoints: routePoints.map(routePoint => ({
                ...routePoint,
                country: collapseWhiteSpace(routePoint.country),
            })),
        }

        onSubmit(newFields)
    }

    // Util

    function evalInitialDirection(direction?: TransferDirection | null): TransferDirection {
        if (direction != null)
            return direction

        if (exportDisabled && !importDisabled)
            return "import"

        return "export"
    }

    function isCountryInputValid(country: string, entering: boolean): boolean {
        return entering && isNullOrBlank(country)
    }

    function fillWithTestData() {
        setDirection("import")
        setCountry("Турция")

        setMoney(new Money({
            currency: "USD",
            amount: new Decimal(1_000_000),
        }))

        setRecipientName("Horns and Hooves - Turkey LLC")
        setRecipientCorrespondentAccount("30101810600000000957")
        setRecipientPaymentAccount("40817810099910004312")

        setRecipientBankName("DenizBanko")
        setRecipientBankAddress("Büyükdere Cad. No:141 34394 Esentepe-İSTANBUL")
        setRecipientBankSwift("DENITRISXXX")

        setInvoiceNumber("001")
        setContractNumber("002")

        setProducts([
            {
                name: "Золотые слитки",
                cnfeaCodes: "7108200001",
            }
        ])

        setRoutePoints([
            {
                country: "Россия",
                type: "water",
                documents: [],
            },
        ])

        setComment("Сделка серая. Нужно как можно быстрее")
    }
})

TransferFillInForm.displayName = "TransferFillInForm"

export default TransferFillInForm
