import stringHash from "string-hash"
import uniqolor from "uniqolor"
import { v4 as generateRandomUuid } from "uuid"
import { createOrCopyDate, DeepReadonly, ReadonlyDate, tryNormalizeUuid } from "my-util"
import { ModelObject } from "./interfaces"

export interface AbstractModelObjectCreationOptions {
    id?: string | null
    createdAt?: Date | null
    modifiedAt?: Date | null
}

export interface AbstractModelObjectCopyOptions extends AbstractModelObjectCreationOptions {}

export default abstract class AbstractModelObject implements DeepReadonly<ModelObject> {
    static groupByIdOrPassOrCreate<T extends DeepReadonly<ModelObject>>(
        objects:
            | ReadonlyMap<string, T>
            | Iterable<T>
            | undefined
            | null
    ): Map<string, T> {
        return objects != null
            ? this.groupByIdOrPass(objects)
            : new Map()
    }

    static groupByIdOrPass<T extends DeepReadonly<ModelObject>>(
        objects:
            | ReadonlyMap<string, T>
            | Iterable<T>
    ): Map<string, T> {
        return objects instanceof Map
            ? objects
            : this.groupById(objects as readonly T[])
    }

    static groupById<T extends DeepReadonly<ModelObject>>(objects: Iterable<T>): Map<string, T> {
        const map = new Map<string, T>()

        for (const object of objects)
            map.set(object.id, object)

        return map
    }

    static getIdColor(id: string, opacity?: number | null): string {
        const hash = stringHash(id)

        let color = uniqolor(hash, { format: "rgb" }).color

        color = color.replace("rgb", "rgba")
        color = color.replace(")", `, ${opacity ?? 1})`)

        return color
    }

    readonly id: string
    readonly createdAt: ReadonlyDate
    readonly modifiedAt: ReadonlyDate

    constructor(
        {
            id,
            createdAt,
            modifiedAt,
        }: DeepReadonly<AbstractModelObjectCreationOptions> = {},
    ) {
        this.id = id != null
            ? tryNormalizeUuid(id)
            : generateRandomUuid()

        this.createdAt = createOrCopyDate(createdAt)
        this.modifiedAt = createOrCopyDate(modifiedAt)
    }

    getIdColor(opacity?: number | null): string {
        return AbstractModelObject.getIdColor(this.id, opacity)
    }

    copyOrPass(
        arg?: AbstractModelObject
            | DeepReadonly<AbstractModelObjectCopyOptions>
            | null,
    ): AbstractModelObject {
        if (arg == null)
            return this

        if (arg instanceof AbstractModelObject)
            return arg

        return this.copy(arg)
    }

    abstract copy(options?: DeepReadonly<AbstractModelObjectCopyOptions>): AbstractModelObject
}
