import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly, isIterable } from "my-util"
import style from "./style.module.css"

export interface ListProps {
    type?: ListType

    autoPunct?: boolean

    children?: ReactNode
}

export type ListType =
    | "ordered"
    | "unordered"

const List = forwardRef((
    {
        type,
        autoPunct,
        children,
    }: DeepReadonly<ListProps>,
    ref: ForwardedRef<HTMLElement>,
) => {
    switch (type) {
        case "ordered":
            return <ol className={style.List}>
                {renderChildren()}
            </ol>

        case undefined:
        case "unordered":
            return <ul className={style.List}>
                {renderChildren()}
            </ul>
    }

    function renderChildren(): ReactNode {
        if (!autoPunct || !isIterable(children))
            return <li>{children}</li>

        const childrenArray = Array.isArray(children)
            ? children
            : [...children]

        return childrenArray.map((child, i) => {
            const key = "key" in child && child.key != null
                ? child.key
                : i

            return <li key={key}>
                    {child}

                    {i !== childrenArray.length - 1
                        ? ";"
                        : "."
                    }
            </li>
        })
    }
})

List.displayName = "List"

export default List
