import { ForwardedRef, forwardRef } from "react"
import { createDocumentDownloadUrlById } from "api"
import { Document } from "model"
import { Link, LinkProps } from "ui/ui"

export interface DocumentLinkProps extends Omit<LinkProps, "to"> {
    document: Document
}

const DocumentLink = forwardRef((
    props: Readonly<DocumentLinkProps>,
    ref: ForwardedRef<HTMLAnchorElement>,
) => {
    const {
        document,
        text, children,
        target, download,
    } = props

    return <Link { ...props }

                 to={createDocumentDownloadUrlById(document.id)}
                 target={target ?? "_blank"}
                 download={download ?? document.name}

                 ref={ref}>
        {children ?? text ?? document.name}
    </Link>
})

DocumentLink.displayName = "DocumentLink"

export default DocumentLink
