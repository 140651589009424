import { z } from "zod"
import { UuidSchema } from "my-util"
import { MoneyMessageSchema } from "./money"

export const CurrencyRateMessageSchema = z.object({
    moment: z.coerce.date(),
    money: MoneyMessageSchema,
    evidenceId: UuidSchema.nullish(),
    evidenceLink: z.string().nullish(),
})

export type CurrencyRateMessage = z.infer<typeof CurrencyRateMessageSchema>
