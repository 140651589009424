import { ForwardedRef, forwardRef, useMemo } from "react"
import { getAllMonthDayNames, normalizeDayOrGetCurrent,
         normalizeMonthIndexOrGetCurrent, normalizeYearOrGetCurrent } from "my-util"
import Carousel from "ui/ui/Carousel"
import { useTranslation } from "react-i18next"

export interface DayCarouselProps {
    onDayIndexSelect?: (dayIndex: number) => void
    dayIndex?: number
    monthIndex?: number
    year?: number

    maxShowDays?: number
    width?: string
}

const DayCarousel = forwardRef((props: Readonly<DayCarouselProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const { onDayIndexSelect, width, maxShowDays } = props

    const today = new Date()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const year = useMemo(() => normalizeYearOrGetCurrent(props.year, today), [props.year])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const monthIndex = useMemo(() => normalizeMonthIndexOrGetCurrent(props.monthIndex, today), [props.monthIndex])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const day = useMemo(() => normalizeDayOrGetCurrent(props.dayIndex, today), [props.dayIndex])

    const [, { language }] = useTranslation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const allDays = useMemo(() => getAllMonthDayNames(monthIndex, year, true), [monthIndex, year, language])

    return <Carousel items={allDays}
                     onSelect={onDayIndexSelect}
                     selected={day}
                     maxShowItems={maxShowDays}
                     width={width}
                     ref={ref}/>
})

DayCarousel.displayName = "DayCarousel"

export default DayCarousel
