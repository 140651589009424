import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import ErrorPage from "../ErrorPage"

export interface Error403PageProps {
    message?: string
}

const Error403Page = forwardRef((
    { message }: Readonly<Error403PageProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <ErrorPage
        header={{
            text: t("errors.pages.403.header"),
        }}
        message={{
            text: message ?? t("errors.pages.403.defaultMessage"),
        }}
        ref={ref}
    />
})

Error403Page.displayName = "Error403Page"

export default Error403Page
