import { DeepReadonly, Nullish, ReadonlyDate } from "my-util"

export interface LegalDocumentCreationOptions {
    number: string
    date: Date
    documentId?: string | null
}

export interface LegalDocumentCopyOptions extends Nullish<LegalDocumentCreationOptions> {}

export default class LegalDocument {
    static createOrPass(
        arg:
            | LegalDocument
            | DeepReadonly<LegalDocumentCreationOptions>,
        ): LegalDocument {
            return arg instanceof LegalDocument
                ? arg
                : new LegalDocument(arg)
        }

    readonly number: string
    readonly date: ReadonlyDate
    readonly documentId: string | null

    constructor(options: DeepReadonly<LegalDocumentCreationOptions>) {
        this.number = options.number
        this.date = new Date(options.date.getTime())
        this.documentId = options.documentId ?? null
    }

    copyOrPass(
        arg?: LegalDocument
            | DeepReadonly<LegalDocumentCopyOptions>
            | null,
    ): LegalDocument {
        if (arg == null)
            return this

        if (arg instanceof LegalDocument)
            return arg

        return this.copy(arg)
    }

    copy(options: DeepReadonly<LegalDocumentCopyOptions> = {}): LegalDocument {
        return new LegalDocument({
            number: options.number ?? this.number,
            date: options.date ?? this.date,

            documentId: options.documentId !== undefined
                ? options.documentId
                : this.documentId,
        })
    }
}
