// Collapse spaces

export function collapseSpacesToNull(s: string): string | null {
    s = collapseSpaces(s)

    return s.length !== 0
        ? s
        : null
}

export function collapseSpaces(s: string): string {
    return s
        .trim()
        .replaceAll(/[^\S\n]+/g, " ")
}

// Collapse white space

export function collapseWhiteSpaceToNull(s: string): string | null {
    s = collapseWhiteSpace(s)

    return s.length !== 0
        ? s
        : null
}

export function collapseWhiteSpace(s: string): string {
    return s
        .trim()
        .replaceAll(/\s+/g, " ")
}

// Remove white space

export function removeWhiteSpaceToNull(s: string): string | null {
    s = removeWhiteSpace(s)

    return s.length !== 0
        ? s
        : null
}

export function removeWhiteSpace(s: string): string {
    return s.replaceAll(/\s+/g, "")
}

// Trim to null

export function trimNullableToNull(s?: string | null): string | null {
    return s != null
        ? trimToNull(s)
        : null
}

export function trimToNull(s: string): string | null {
    s = s.trim()

    return s.length !== 0
        ? s
        : null
}

// Count lines

export function countLines(s: string): number {
    let lines = 1

    for (const c of s)
        if (c === "\n")
            ++lines

    return lines
}
