import { getUserById } from "api"
import { User } from "model"

import createStorageHook,
       { StorageHook, StorageHookActions, StorageHookValues } from "./createStorageHook"

export interface UsersHook extends StorageHook<User> {}
export interface UsersHookValues extends StorageHookValues<User> {}
export interface UsersHookActions extends StorageHookActions<User> {}

const useUsers = createStorageHook({
    loadById: getUserById,
    // debug: true,
}) satisfies () => UsersHook

export default useUsers
