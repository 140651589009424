import { CSSProperties, ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export interface IconProps {
    onClick?: () => void

    src?: string
    alt?: string
    filter?: string
    cursor?: CSSProperties["cursor"]

    width?: string
    height?: string
}

const Icon = forwardRef((
    {
        onClick,
        src, alt, filter, cursor,
        width, height,
    }: Readonly<IconProps>,
    ref: ForwardedRef<HTMLImageElement>,
) =>
    <img className={style.Icon}
         style={{ cursor, width, height, filter }}
         src={src}
         alt={alt ?? "Icon"}
         onClick={onClick}
         ref={ref}/>
)

Icon.displayName = "Icon"

export default Icon
