import { ForwardedRef, forwardRef, ReactNode } from "react"
import { dateToDateString, dateToDateTimeString, dateToTimeString, DeepReadonly } from "my-util"
import Output, { OutputProps } from "ui/ui/Output"

export interface DateTimeOutputProps extends Omit<OutputProps, "children"> {
    date: Date

    hideDate?: boolean
    hideTime?: boolean
}

const DateTimeOutput = forwardRef((
    props: DeepReadonly<DateTimeOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <Output {...props as DateTimeOutputProps}
                   ref={ref}>
        {renderChildren()}
    </Output>

    function renderChildren(): ReactNode {
        const { date, hideDate, hideTime } = props

        if (hideDate && hideTime)
            return null

        if (hideDate)
            return dateToTimeString(date)

        if (hideTime)
            return dateToDateString(date)

        return dateToDateTimeString(date)
    }
})

DateTimeOutput.displayName = "DateTimeOutput"

export default DateTimeOutput
