import { ForwardedRef, forwardRef, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { BackToTheMainPageLink, ReloginLink } from "ui/component"
import { Center, Flex, Padding } from "ui/ui"
import style from "./style.module.css"

export interface ErrorPageProps {
    header?: ErrorPageText
    message?: ErrorPageText
    linkType?: ErrorPageLinkType
}

export interface ErrorPageText {
    text?: string
    fontSize?: string
    fontWeight?: string
}

export type ErrorPageLinkType =
    | "main"
    | "relogin"

const ErrorPage = forwardRef((
    {
        header,
        message,
        linkType,
    }: Readonly<ErrorPageProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Center type="absolute-fill"
                   ref={ref}>
        <Padding padding="32px">
            <Flex>
                {renderHeader()}
                {renderMessage()}
                {renderLink()}
            </Flex>
        </Padding>
    </Center>

    function renderHeader(): ReactNode {
        return <h1 className={style.header}
            style={{
                fontSize: header?.fontSize,
                fontWeight: header?.fontWeight,
            }}>
            {header?.text ?? t("errors.pages.defaultHeader")}
        </h1>
    }

    function renderMessage(): ReactNode {
        if (!message?.text)
            return null

        return <p className={style.message}
                  style={{
                      fontSize: message?.fontSize,
                      fontWeight: message?.fontWeight,
                  }}>
            {message?.text}
        </p>
    }

    function renderLink(): ReactNode {
        const effectiveLinkType = linkType ?? "main"

        switch (effectiveLinkType) {
            case "main":
                return <BackToTheMainPageLink/>

            case "relogin":
                return <ReloginLink/>

            default:
                effectiveLinkType satisfies never
        }
    }
})

ErrorPage.displayName = "ErrorPage"

export default ErrorPage
