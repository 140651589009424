import assert from "assert"
import { ForwardedRef, forwardRef, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { tickIconUrl } from "image"
import { cancelMfa, verifyMfaOtp } from "api"
import { determineNullableMessageTargetType } from "model"
import { OTP_LENGTH, validateOtp } from "validation"
import { Form, ErrorText, Button, Input, Flex, MessageTargetInput } from "ui/ui"
import Otp from "../../Otp"

export interface OtpFormProps {
    onSuccess?: () => void
    onBack?: () => void

    messageTarget?: string

    width?: string
}

const OtpForm = forwardRef((
    {
        onSuccess, onBack,
        messageTarget,
        width,
    }: Readonly<OtpFormProps>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    const [t, { language }] = useTranslation()

    // State

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as any)

    const [otp, setOtp] = useState(undefined as string | undefined)

    const otpInputInvalid = otp != null && validateOtp(otp) != null
    const otpInvalid = otp == null || otpInputInvalid

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const otpInputPlaceholder = useMemo(renderOtpInputPlaceholder, [messageTarget, language])

    // Render

    return <Form onSubmit={onSubmit}
                 loading={loading}
                 width={width}
                 ref={ref}>
        <Flex align="start">
            <MessageTargetInput value={messageTarget}

                                loading={loading}

                                iconSrc={tickIconUrl}
                                iconAlt="Tick icon"
                                iconFilter="invert(64%) sepia(96%) saturate(3701%) hue-rotate(88deg) brightness(113%) contrast(135%)"

                                readonly/>

            <Input onChange={setOtp}
                   value={otp}

                   placeholder={otpInputPlaceholder}

                   regex={/^\d*$/g}
                   max={OTP_LENGTH}

                   loading={loading}
                   invalid={otpInputInvalid}

                   autoFocus/>

            <Flex direction="horizontal">
                <Button text={t("misc.buttons.back")}
                        buttonStyle="text"
                        onClick={onInnerBack}
                        loading={loading}/>

                <Button text={t("misc.buttons.ok")}
                        disabled={otpInvalid}
                        loading={loading}
                        type="submit"/>
            </Flex>

            <ErrorText
                textAlign="left"

                error={error}

                apiErrorMessageMapping={{
                    422: t("auth.mfa.messages.errors.invalidCode"),
                }}
            />

            <Otp/>
        </Flex>
    </Form>

    function renderOtpInputPlaceholder(): string {
        switch (determineNullableMessageTargetType(messageTarget)) {
            case "phone":
                return t("auth.mfa.placeholders.smsCode")

            case "email":
                return t("auth.mfa.placeholders.emailCode")

            default:
                return t("auth.mfa.placeholders.code")
        }
    }

    // Events

    async function onSubmit() {
        setLoading(true)

        try {
            assert(otp != null)

            await verifyMfaOtp(otp)

            setError(undefined)
            onSuccess?.()
        } catch (error) {
            setLoading(false)
            setError(error)
        }
    }

    function onInnerBack() {
        cancelMfa()
        onBack?.()
    }
})

OtpForm.displayName = "OtpForm"

export default OtpForm
