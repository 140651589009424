import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export interface SubheaderProps {
    text: string
}

const Subheader = forwardRef((
    { text }: Readonly<SubheaderProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div className={style.Subheader}
         ref={ref}>
        {text}
    </div>
)

Subheader.displayName = "Subheader"

export default Subheader
