import { z } from "zod"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewTransferNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal("new-transfer" satisfies NotificationTypeResponse),
    transferId: UuidSchema,
})

export type NewTransferNotificationResponse = z.infer<typeof NewTransferNotificationResponseSchema>
