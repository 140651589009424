import { del } from "api/http/util"

export const NOTIFICATIONS_SUBPATH = "/notifications"

export async function deleteNotificationById(
    id: string,
    signal?: AbortSignal | null,
): Promise<void> {
    return del({
        subpath: `${NOTIFICATIONS_SUBPATH}/${id}`,
        signal,
    })
}

export async function clearMyNotifications(signal?: AbortSignal | null): Promise<null> {
    return del({
        subpath: `${NOTIFICATIONS_SUBPATH}/my`,
        signal,
    })
}

export async function clearNotifications(signal?: AbortSignal | null): Promise<null> {
    return del({
        subpath: NOTIFICATIONS_SUBPATH,
        signal,
    })
}
