import { ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export const FLEX_DEFAULT_GAP = "16px"

export interface FlexProps {
    direction?: FlexDirection
    justify?: FlexJustify
    align?: FlexAlign
    gap?: string
    wrap?: boolean

    children?: ReactNode

    width?: string
    height?: string
}

export type FlexDirection =
    | "vertical"
    | "horizontal"

export type FlexJustify =
    | "start"
    | "end"
    | "center"
    | "left"
    | "right"
    | "normal"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"

export type FlexAlign =
    | "normal"
    | "center"
    | "start"
    | "end"
    | "stretch"

const Flex = forwardRef((
    {
        direction, justify, align, gap, wrap,
        children,
        width, height,
    }: Readonly<FlexProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <div className={style.Flex}
                style={{
                    justifyContent: justify,
                    alignItems: align,
                    flexWrap: wrap ? "wrap" : "nowrap",
                    flexDirection: direction === "horizontal" ? "row" : "column",
                    gap: gap ?? FLEX_DEFAULT_GAP,
                    width,
                    height,
                }}
                ref={ref}>
        {children}
    </div>
})

Flex.displayName = "Flex"

export default Flex
