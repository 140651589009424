import { useTranslation } from "react-i18next"
import { Center, Flex, Pane, Stopwatch } from "ui/ui"
import style from "./style.module.css"

export default function WaitPane() {
    const [t] = useTranslation()

    return <Pane>
        <Center>
            <Flex gap="48px">
                <p className={style.text}>
                    {t("auth.reg.messages.waitVerification")}
                </p>

                <span className={style.stopwatch}>
                    <Stopwatch/>
                </span>
            </Flex>
        </Center>
    </Pane>
}
