import { useEffect, useState } from "react"
import { v4 as generateRandomUuid } from "uuid"
import { UploadingUiDocument } from "ui/component"

export default function useDocumentPaste(): UploadingUiDocument | null {
    const [last, setLast] = useState(null as UploadingUiDocument | null)

    useEffect(() => {
        document.addEventListener("paste", handler)

        return () => document.removeEventListener("paste", handler)

        function handler(event: ClipboardEvent) {
            const file = event.clipboardData?.files?.[0]

            if (file == null)
                return

            const newLast: UploadingUiDocument = {
                id: generateRandomUuid(),
                status: "uploading",
                file,
            }

            setLast(newLast)
        }
    }, [])

    return last
}
