import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export interface LimitProps {
    width?: string
    height?: string

    minWidth?: string
    maxWidth?: string

    minHeight?: string
    maxHeight?: string

    overflow?: CSSProperties["overflow"]

    children?: ReactNode
}

const Limit = forwardRef((
    {
        width, height,
        minWidth, maxWidth,
        minHeight, maxHeight,
        overflow,
        children,
    }: Readonly<LimitProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div
        className={style.Limit}
        style={{
            width, height,
            minWidth, maxWidth,
            minHeight, maxHeight,
            overflow,
        }}
        ref={ref}
    >
        {children}
    </div>
)

Limit.displayName = "Limit"

export default Limit
