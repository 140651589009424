import { ForwardedRef, forwardRef } from "react"
import { clamp } from "my-util"
import style from "./style.module.css"

export interface ProgressBarProps {
    progress: number // From 0 to 1
    width?: string
}

const ProgressBar = forwardRef((
    {
        progress,
        width,
    }: Readonly<ProgressBarProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const innerProgress = clamp(progress, 0, 1)
    const innerWidth = `${100 * innerProgress}%`

    return <div className={style.outer}
                style={{ width }}
                ref={ref}>
        <div style={{ width: innerWidth }}
             className={style.inner}/>
    </div>
})

ProgressBar.displayName = "ProgressBar"

export default ProgressBar
