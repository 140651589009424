import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"

export interface TabProps {
    // Used by TabbedView

    index?: number
    name: string

    width?: string

    iconSrc?: string
    iconAlt?: string
    iconFilter?: string

    // Used by Tab

    children?: ReactNode

    overflow?: string
}

const Tab = forwardRef((
    {
        children,
        overflow,
    }: DeepReadonly<TabProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div style={{ overflow }}
         ref={ref}>
        {children}
    </div>
)

Tab.displayName = "Tab"

export default Tab
