import Decimal from "decimal.js"
import { t } from "i18next"
import { WithCreatorId } from "model/interfaces"

import AbstractModelObject,
       { AbstractModelObjectCreationOptions,
         AbstractModelObjectCopyOptions } from "model/AbstractModelObject"

import { dateWithTimeNulled, DeepReadonly, nullDateTime, tryCopyDate,
         ReadonlyDate, map, tryNormalizeUuid, createOrPassNullableDecimal } from "my-util"

import CurrencyRate, { CurrencyRateCreationOptions, CurrencyRateCopyOptions } from "./CurrencyRate"
import Money, { MoneyCreationOptions, MoneyCopyOptions } from "./Money"
import Product, { ProductCreationOptions, ProductCopyOptions } from "./Product"
import RoutePoint, { RoutePointCreationOptions, RoutePointCopyOptions } from "./RoutePoint"
import LegalDocument, { LegalDocumentCreationOptions, LegalDocumentCopyOptions } from "./LegalDocument"

// Comparator

export type TransfersComparator = (lhs: Transfer, rhs: Transfer) => number

// Direction

export type TransferDirection =
    | "import"
    | "export"

export function transferDirectionToString(direction: TransferDirection): string {
    return t(`domain.transfers.directions.${direction}`)
}

export function isTransferDirection(s: string): s is TransferDirection {
    switch (s) {
        case "import":
        case "export":
            s satisfies TransferDirection
            return true

        default:
            return false
    }
}

// Status

export type TransferStatus =
    | "new"
    | "waiting-moment"
    | "waiting-payment"
    | "paid"
    | "done"
    | "payment-expired"
    | "rejected"

export function transferStatusToString(status: TransferStatus): string {
    return t(`domain.transfers.statuses.${status}`)
}

export function isTransferStatus(s: string): s is TransferStatus {
    switch (s) {
        case "new":
        case "waiting-moment":
        case "waiting-payment":
        case "paid":
        case "done":
        case "payment-expired":
        case "rejected":
            s satisfies TransferStatus
            return true

        default:
            return false
    }
}

export function isTransferStatusDeclined(status: TransferStatus): boolean {
    return status === "rejected"
        || status === "payment-expired"
}

// - Color

export interface TransferStatusColors {
    backgroundColor: string
    borderColor: string
    color: string
}

export const NEW_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = {
    backgroundColor: "#F5F9FF",
    borderColor: "#2D6FCC",
    color: "#2D6FCC",
}

export const WAITING_MOMENT_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = NEW_TRANSFER_STATUS_COLORS

export const WAITING_PAYMENT_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = NEW_TRANSFER_STATUS_COLORS

export const PAID_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = {
    backgroundColor: "#E6F4EA",
    borderColor: "#E6F4EA",
    color: "#08922E",
}

export const DONE_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = PAID_TRANSFER_STATUS_COLORS

export const PAYMENT_EXPIRED_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = {
    backgroundColor: "#F2F2F2",
    borderColor: "#F2F2F2",
    color: "#333",
}

export const REJECTED_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = PAYMENT_EXPIRED_TRANSFER_STATUS_COLORS

export function getTransferStatusColors(status: TransferStatus): TransferStatusColors {
    switch (status) {
        case "new":
            return { ...NEW_TRANSFER_STATUS_COLORS }

        case "waiting-moment":
            return { ...WAITING_MOMENT_TRANSFER_STATUS_COLORS }

        case "waiting-payment":
            return { ...WAITING_PAYMENT_TRANSFER_STATUS_COLORS }

        case "paid":
            return { ...PAID_TRANSFER_STATUS_COLORS }

        case "done":
            return { ...DONE_TRANSFER_STATUS_COLORS }

        case "payment-expired":
            return { ...PAYMENT_EXPIRED_TRANSFER_STATUS_COLORS }

        case "rejected":
            return { ...REJECTED_TRANSFER_STATUS_COLORS }
    }
}

// Transfer

export interface TransferCreationOptions extends AbstractModelObjectCreationOptions {
    creatorId: string
    direction?: TransferDirection | null
    country: string
    recipient: {
        name: string
        itn?: string | null
        paymentAccount?: string | null
        correspondentAccount?: string | null
        iban?: string | null
        bank: {
            name: string
            address: string
            swift: string
        }
    }

    money?: Money | MoneyCreationOptions | null
    currencyRate?: CurrencyRate | CurrencyRateCreationOptions | null

    invoice: LegalDocument | LegalDocumentCreationOptions
    contract: LegalDocument | LegalDocumentCreationOptions

    expenses?: Decimal | number | string | null
    agentPercent?: Decimal | number | string | null
    attorneyFee?: Money | MoneyCreationOptions | null

    comment?: string | null
    adminComment?: string | null

    moment?: Date | null

    status?: TransferStatus | null

    documentIds?: Iterable<string> | null
    products?: Iterable<Product | ProductCreationOptions> | null
    routePoints?: Iterable<RoutePoint | RoutePointCreationOptions> | null
}

export interface TransferCopyOptions extends AbstractModelObjectCopyOptions {
    creatorId?: string | null
    direction?: TransferDirection | null
    country?: string | null
    recipient?: {
        name?: string | null
        itn?: string | null
        paymentAccount?: string | null
        correspondentAccount?: string | null
        iban?: string | null
        bank?: {
            name?: string | null
            address?: string | null
            swift?: string | null
        } | null
    } | null

    money?: Money | MoneyCopyOptions | null
    currencyRate?: CurrencyRate | CurrencyRateCopyOptions | null

    invoice?: LegalDocument | LegalDocumentCopyOptions | null
    contract?: LegalDocument | LegalDocumentCopyOptions | null

    expenses?: Decimal | number | string | null
    agentPercent?: Decimal | number | string | null
    attorneyFee?: Money | MoneyCopyOptions | null

    comment?: string | null
    adminComment?: string | null

    moment?: Date | null

    status?: TransferStatus | null

    documentIds?: Iterable<string> | null
    products?: Iterable<Product | ProductCopyOptions> | null
    routePoints?: Iterable<RoutePoint | RoutePointCopyOptions> | null
}

export default class Transfer
    extends
        AbstractModelObject

    implements
        Readonly<WithCreatorId>
{
    static createOrPass(arg: Transfer | DeepReadonly<TransferCreationOptions>): Transfer {
        return arg instanceof Transfer
            ? arg
            : new Transfer(arg)
    }

    readonly creatorId: string
    readonly direction: TransferDirection
    readonly country: string
    readonly recipient: Readonly<{
        name: string
        itn: string | null
        paymentAccount: string | null
        correspondentAccount: string | null
        iban: string | null
        bank: Readonly<{
            name: string
            address: string
            swift: string
        }>
    }>

    readonly money: Money
    readonly currencyRate: CurrencyRate | null

    readonly invoice: LegalDocument
    readonly contract: LegalDocument

    readonly expenses: Decimal | null
    readonly agentPercent: Decimal | null
    readonly attorneyFee: Money | null

    readonly comment: string | null
    readonly adminComment: string | null

    readonly moment: ReadonlyDate | null

    readonly status: TransferStatus

    readonly documentIds: readonly string[]
    readonly products: readonly Product[]
    readonly routePoints: readonly RoutePoint[]

    constructor(options: DeepReadonly<TransferCreationOptions>) {
        super(options)

        // Common

        this.creatorId = tryNormalizeUuid(options.creatorId)
        this.direction = options.direction ?? "import"
        this.country = options.country

        this.recipient = {
            name: options.recipient.name,
            itn: options.recipient.itn ?? null,
            paymentAccount: options.recipient.paymentAccount ?? null,
            correspondentAccount: options.recipient.correspondentAccount ?? null,
            iban: options.recipient.iban ?? null,

            bank: {
                name: options.recipient.bank.name,
                address: options.recipient.bank.address,
                swift: options.recipient.bank.swift,
            },
        }

        // Money

        this.money = Money.createOrPass(options.money)

        this.currencyRate = options.currencyRate != null
            ? CurrencyRate.createOrPass(options.currencyRate)
            : null

        // Special documents

        this.invoice = LegalDocument.createOrPass(options.invoice)
        this.contract = LegalDocument.createOrPass(options.contract)

        // Payments

        this.expenses = createOrPassNullableDecimal(options.expenses)
        this.agentPercent = createOrPassNullableDecimal(options.agentPercent)

        this.attorneyFee = options.attorneyFee != null
            ? Money.createOrPass(options.attorneyFee)
            : null

        // Comments

        this.comment = options.comment ?? null
        this.adminComment = options.adminComment ?? null

        // Moment

        this.moment = tryCopyDate(options.moment)

        // Status

        this.status = options.status ?? "new"

        // Collections

        this.documentIds = map(options.documentIds ?? [], tryNormalizeUuid)
        this.products = map(options.products ?? [], Product.createOrPass)
        this.routePoints = map(options.routePoints ?? [], RoutePoint.createOrPass)
    }

    getCreatorIdColor(opacity?: number): string {
        return AbstractModelObject.getIdColor(this.creatorId, opacity)
    }

    get isToday(): boolean {
        if (this.moment == null)
            return false

        const today = nullDateTime(new Date()).getTime()
        const momentDay = dateWithTimeNulled(this.moment).getTime()

        return today === momentDay
    }

    get isInWork(): boolean {
        return this.status === "waiting-moment"
            || this.status === "waiting-payment"
            || this.status === "paid"
    }

    get allDocumentIds(): readonly string[] {
        const allDocumentIds = [...this.documentIds]

        for (const routePoint of this.routePoints)
            allDocumentIds.push(...routePoint.documentIds)

        if (this.currencyRate?.evidenceId != null)
            allDocumentIds.push(this.currencyRate.evidenceId)

        if (this.invoice.documentId != null)
            allDocumentIds.push(this.invoice.documentId)

        if (this.contract.documentId != null)
            allDocumentIds.push(this.contract.documentId)

        return allDocumentIds
    }

    override copyOrPass(arg?: Transfer | DeepReadonly<TransferCopyOptions> | null): Transfer {
        if (arg == null)
            return this

        if (arg instanceof Transfer)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<TransferCopyOptions> = {}): Transfer {
        return new Transfer({
            // Common

            creatorId: options.creatorId ?? this.creatorId,
            direction: options.direction ?? this.direction,
            country: options.country ?? this.country,

            recipient: {
                name: options.recipient?.name ?? this.recipient.name,
                itn: options.recipient?.itn ?? this.recipient.itn,
                paymentAccount: options.recipient?.paymentAccount ?? this.recipient.paymentAccount,

                correspondentAccount: options.recipient?.correspondentAccount !== undefined
                    ? options.recipient.correspondentAccount
                    : this.recipient.correspondentAccount,

                iban: options.recipient?.iban !== undefined
                    ? options.recipient.iban
                    : this.recipient.iban,

                bank: {
                    name: options.recipient?.bank?.name ?? this.recipient.bank.name,
                    address: options.recipient?.bank?.address ?? this.recipient.bank.address,
                    swift: options.recipient?.bank?.swift ?? this.recipient.bank.swift,
                },
            },

            // Money

            money: this.money.copyOrPass(options.money),

            currencyRate: options.currencyRate !== undefined
                ? options.currencyRate != null
                    ? (this.currencyRate?.copyOrPass(options.currencyRate) ?? options.currencyRate)
                    : null
                : this.currencyRate,

            // Special documents

            invoice: this.invoice.copyOrPass(options.invoice),
            contract: this.contract.copyOrPass(options.contract),

            // Payments

            expenses: options.expenses !== undefined
                ? options.expenses
                : this.expenses,

            agentPercent: options.agentPercent !== undefined
                ? options.agentPercent
                : this.agentPercent,

            attorneyFee: options.attorneyFee !== undefined
                ? options.attorneyFee != null
                    ? (this.attorneyFee?.copyOrPass(options.attorneyFee) ?? options.attorneyFee)
                    : null
                : this.attorneyFee,

            // Comments

            comment: options.comment !== undefined
                ? options.comment
                : this.comment,

            adminComment: options.adminComment !== undefined
                ? options.adminComment
                : this.adminComment,

            // Moment

            moment: options.moment !== undefined
                ? options.moment
                : this.moment,

            // Status

            status: options.status ?? this.status,

            // Collections

            documentIds: options.documentIds ?? this.documentIds,
            products: this.copyProducts(options.products),
            routePoints: this.copyRoutePoints(options.routePoints),

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }

    private copyProducts(newProducts?: DeepReadonly<TransferCopyOptions["products"]>): readonly Product[] {
        if (newProducts == null)
            return this.products

        const resultProducts = new Array<Product>()
        const newProductsArray = [...newProducts]

        for (let i = 0, max = Math.max(newProductsArray.length, this.products.length); i < max; ++i) {
            const newProduct: typeof newProductsArray[0] | undefined = newProductsArray[i]

            if (newProduct instanceof Product) {
                resultProducts.push(newProduct)
                continue
            }

            const product: Product | undefined = this.products[i]

            if (newProduct == null) {
                // Should be always true here
                // but in order to be 100% safe
                // i'll leave this if-statement

                if (product != null)
                    resultProducts.push(product)

                continue
            }

            if (product != null)
                resultProducts.push(product.copy(newProduct))
        }

        return resultProducts
    }

    private copyRoutePoints(newRoutePoints?: DeepReadonly<TransferCopyOptions["routePoints"]>): readonly RoutePoint[] {
        if (newRoutePoints == null)
            return this.routePoints

        const resultRoutePoints = new Array<RoutePoint>()
        const newRoutePointsArray = [...newRoutePoints]

        for (let i = 0, max = Math.max(newRoutePointsArray.length, this.routePoints.length); i < max; ++i) {
            const newRoutePoint: typeof newRoutePointsArray[0] | undefined = newRoutePointsArray[i]

            if (newRoutePoint instanceof RoutePoint) {
                resultRoutePoints.push(newRoutePoint)
                continue
            }

            const routePoint: RoutePoint | undefined = this.routePoints[i]

            if (newRoutePoint == null) {
                // Should be always true here
                // but in order to be 100% safe
                // i'll leave this if-statement

                if (routePoint != null)
                    resultRoutePoints.push(routePoint)

                continue
            }

            if (routePoint != null)
                resultRoutePoints.push(routePoint.copy(newRoutePoint))
        }

        return resultRoutePoints
    }
}
