import { z } from "zod"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewChatMessagesNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal("new-chat-messages" satisfies NotificationTypeResponse),
    fromId: UuidSchema.nullish(),
    count: z.number().nonnegative().nullish(),
})

export type NewChatMessagesNotificationResponse = z.infer<typeof NewChatMessagesNotificationResponseSchema>
