import { getInviteApplicationById } from "api"
import { InviteApplication } from "model"

import createStorageHook,
       { StorageHook, StorageHookActions, StorageHookValues } from "./createStorageHook"

export interface InviteApplicationsHook extends StorageHook<InviteApplication> {}
export interface InviteApplicationsHookValues extends StorageHookValues<InviteApplication> {}
export interface InviteApplicationsHookActions extends StorageHookActions<InviteApplication> {}

const useInviteApplications = createStorageHook({
    loadById: getInviteApplicationById,
    // debug: true,
}) satisfies () => InviteApplicationsHook

export default useInviteApplications
