import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react"
import { getLang } from "i18n"
import { Transfer, User } from "model"

import { getMonthCalendarDays, getMonthDayName,
         normalizeMonthIndexOrGetCurrent, getAllWeekDayNames,
         normalizeYearOrGetCurrent, splitArray, SATURDAY_INDEX} from "my-util"

import { Flex } from "ui/ui/layout"
import { SmallTransferCard } from "ui/ui/transfer"
import style from "./style.module.css"

export interface MonthCalendarProps {
    monthIndex?: number
    year?: number

    onTransferClick?: (transfer: Transfer) => void
    transfers?: Transfer[]

    users?: Map<string, User> | Iterable<User>

    width?: string
}

const MonthCalendar = forwardRef((
    {
        monthIndex, year,
        onTransferClick, transfers,
        users,
        width,
    }: Readonly<MonthCalendarProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const today = useMemo(() => new Date(), [])

    const innerYear = useMemo(
        () => normalizeYearOrGetCurrent(year, today),
        [today, year],
    )

    const innerMonthIndex = useMemo(
        () => normalizeMonthIndexOrGetCurrent(monthIndex, today),
        [today, monthIndex],
    )

    const days = useMemo(
        () => getMonthCalendarDays(innerMonthIndex, innerYear),
        [innerMonthIndex, innerYear],
    )

    const daysByWeeks = useMemo(() => splitArray(days, 7), [days])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const weekDayNames = useMemo(() => getAllWeekDayNames(true), [getLang()])

    const innerTransfers = useMemo(() =>
        transfers?.filter(({ moment }) =>
            moment != null &&
            moment.getFullYear() === innerYear &&
            moment.getMonth() === innerMonthIndex
        ) ?? [],

        [innerMonthIndex, innerYear, transfers],
    )

    // Render

    return <div className={style.tableWrapper}
                style={{ width }}
                ref={ref}>
        {renderTable()}
    </div>

    function renderTable(): ReactNode {
        return <table className={style.table}>
            {renderTableHeader()}
            {renderTableBody()}
        </table>
    }

    function renderTableHeader(): JSX.Element {
        return <thead>
            <tr>
                {weekDayNames.map(weekDayName =>
                    <th className={style.weekDayName}
                        key={weekDayName}>
                        {weekDayName}
                    </th>
                )}
            </tr>
        </thead>
    }

    function renderTableBody(): JSX.Element {
        return <tbody>
            {daysByWeeks.map((weekDays, i) =>
                <tr key={i}>
                    {weekDays.map((day, weekDayIndex) => {
                        const allClassNames = [
                            day.type !== "cur"
                                ? style.anotherMonthDay
                                : style.day
                        ]

                        if (weekDayIndex >= SATURDAY_INDEX)
                            allClassNames.push(style.weekend)

                        const className = allClassNames.join(" ")
                        const dayNumber = day.index + 1

                        const dayTransfers = innerTransfers.filter(({ moment }) =>
                            day.type === "cur" &&
                            moment?.getDate() === dayNumber
                        )

                        return <td className={className}
                                   key={day.index}>
                            <div className={style.dayDate}>
                                {getMonthDayName(innerMonthIndex, dayNumber, true)}
                            </div>

                            <div className={style.transfers}>
                                <Flex gap="4px">
                                    {dayTransfers.map(transfer =>
                                        <SmallTransferCard onClick={onTransferClick}
                                                           transfer={transfer}
                                                           users={users}
                                                           key={transfer.id}/>
                                    )}
                                </Flex>
                            </div>
                        </td>
                    })}
                </tr>
            )}
        </tbody>
    }
})

MonthCalendar.displayName = "MonthCalendar"

export default MonthCalendar
