import { DeepReadonly, Nullish, tryNormalizeUuid } from "my-util"

import AbstractNotification,
       { NotificationType,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

export interface NewTransferNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">
{
    transferId: string
}

export interface NewTransferNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewTransferNotificationCreationOptions>
{}

export default class NewTransferNotification
    extends
        AbstractNotification<"new-transfer">
{
    static createOrPass(
        arg: NewTransferNotification
           | DeepReadonly<NewTransferNotificationCreationOptions>
    ): NewTransferNotification {
        return arg instanceof NewTransferNotification
            ? arg
            : new NewTransferNotification(arg)
    }

    readonly transferId: string

    constructor(options: DeepReadonly<NewTransferNotificationCreationOptions>) {
        super({ ...options, type: "new-transfer" })

        this.transferId = tryNormalizeUuid(options.transferId)
    }

    override copyOrPass(
        arg?: NewTransferNotification
            | DeepReadonly<NewTransferNotificationCopyOptions>
            | null
    ): NewTransferNotification {
        if (arg == null)
            return this

        if (arg instanceof NewTransferNotification)
            return arg
        
        return this.copy(arg)
    }

    override copy(
        options: DeepReadonly<NewTransferNotificationCopyOptions> = {},
    ): NewTransferNotification {
        return new NewTransferNotification({
            transferId: options.transferId ?? this.transferId,

            // AbstractNotification

            recipientId: options.recipientId ?? this.recipientId,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
