import assert from "assert"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { completePasswordReset, getPasswordResetConfig,
         PasswordResetConfigResponse, startPasswordReset, StartPasswordResetRequest } from "api"

import { messageTargetToObject } from "model"
import { createMessageTargetInputPlaceholder, isAllowedByMessageTargetTypeToMessageTargetTypeList, isUuid, tryNormalizeUuid } from "my-util"
import { validatePassword } from "validation"
import { MessageTargetMfaForm, Page } from "ui/component"

import { Button, Form, CapsLockDetector, ErrorBlock, Loading,
         Flex, Input, Limit, NotificationModal, Pane,ErrorDisplay } from "ui/ui"

import { MAIN_PAGE_PATH } from "../sections/MainPage/path"
import { Error404Page } from "../error"
import { createLoginPagePath } from "../LoginPage"
import { PASSWORD_RESET_PAGE_PATH_USER_ID_PARAM_NAME } from "./path"

export default function PasswordResetPage() {
    const [t, { language }] = useTranslation()

    const navigate = useNavigate()

    const { [PASSWORD_RESET_PAGE_PATH_USER_ID_PARAM_NAME]: userId } = useParams()
    const badUrl = userId == null || !isUuid(userId)

    // State

    // - Config

    const [config, setConfig] = useState(undefined as PasswordResetConfigResponse | undefined)
    const [loadingConfig, setLoadingConfig] = useState(true)
    const [configLoadingError, setConfigLoadingError] = useState(undefined as unknown)

    const messageTargetInputPlaceholder = useMemo(
        () =>
            config != null
                ? createMessageTargetInputPlaceholder(
                    isAllowedByMessageTargetTypeToMessageTargetTypeList(config),
                )

                : undefined,

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [config, language],
    )

    // - MFA

    const [passedMfa, setPassedMfa] = useState(false)

    // - Password

    const [password, setPassword] = useState(undefined as string | undefined)
    const [passwordAgain, setPasswordAgain] = useState(undefined as string | undefined)

    // - Resetting status

    const [resetting, setResetting] = useState(false)
    const [resetError, setResetError] = useState(undefined as unknown)

    // - Success

    const [success, setSuccess] = useState(false)

    // Effects

    useEffect(() => {
        if (!loadingConfig)
            return

        const controller = new AbortController()

        getPasswordResetConfig(controller.signal)
            .then(setConfig)
            .catch(error => {
                if (!controller.signal.aborted)
                    setConfigLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingConfig(false)
            })

        return () => controller.abort()
    }, [loadingConfig])

    // Render

    if (badUrl)
        return <Error404Page/>

    return <Page type="auth">
        <Pane header={t("auth.passwordRest.header")}>
            <Limit maxWidth="375px">
                {renderContent()}
            </Limit>

            {renderSuccessNotificationModalIfNeeded()}
        </Pane>
    </Page>

    function renderContent(): ReactNode {
        if (loadingConfig)
            return <Loading/>

        if (configLoadingError != null)
            return <ErrorDisplay error={configLoadingError}/>

        return passedMfa
            ? renderPasswordResetForm()
            : renderMfaForm()
    }

    function renderPasswordResetForm(): ReactNode {
        const passwordInvalid = password == null || validatePassword(password) != null
        const passwordInputInvalid = password != null && passwordInvalid

        const passwordAgainInvalid = password !== passwordAgain
        const passwordAgainInputInvalid = passwordAgain != null && passwordAgainInvalid

        const resetDisabled = passwordInvalid || passwordAgainInvalid

        return <Form onSubmit={onSubmit}
                     loading={resetting}>
            <Flex align="start">
                <Input placeholder={t("misc.placeholders.newPassword")}
                       invalid={passwordInputInvalid}
                       loading={resetting}
                       onChange={setPassword}
                       value={password}
                       type="password"/>

                <Input placeholder={t("misc.placeholders.newPasswordAgain")}
                       invalid={passwordAgainInputInvalid}
                       loading={resetting}
                       onChange={setPasswordAgain}
                       value={passwordAgain}
                       type="password"/>

                <Button text={t("misc.buttons.resetPassword")}
                        disabled={resetDisabled}
                        loading={resetting}
                        width="fit-content"
                        type="submit"/>

                <ErrorBlock error={resetError}/>

                <CapsLockDetector/>
            </Flex>
        </Form>

        async function onSubmit() {
            assert(password != null && userId != null)

            setResetting(true)

            try {
                await completePasswordReset(password)

                setResetError(undefined)
                setSuccess(true)
            } catch (error) {
                setResetError(error)
            } finally {
                setResetting(false)
            }
        }
    }

    function renderMfaForm(): ReactNode {
        assert(userId != null)

        return <MessageTargetMfaForm
            messageTargetPlaceholder={messageTargetInputPlaceholder}
            onSuccess={() => setPassedMfa(true)}
            performMfa={(messageTarget, messageTargetType) => {
                const request: StartPasswordResetRequest = {
                    ...messageTargetToObject(messageTarget, messageTargetType),
                    id: tryNormalizeUuid(userId),
                }

                return startPasswordReset(request)
            }}
        />
    }

    function renderSuccessNotificationModalIfNeeded(): ReactNode {
        if (!success)
            return null

        const loginRequired = config?.isLoginRequiredAfter ?? true

        let message = `${t("auth.passwordRest.messages.success")}.`

        if (loginRequired)
            message += ` ${t("auth.passwordRest.messages.youWillBeNavigatedToTheLoginPage")}.`

        return <NotificationModal onClose={onClose}>
            {message}
        </NotificationModal>

        function onClose() {
            assert(userId != null)

            const newPath = loginRequired
                ? createLoginPagePath(userId)
                : MAIN_PAGE_PATH

            navigate(newPath)
        }
    }
}
