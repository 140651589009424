import { z } from "zod"
import { BankMessageSchema } from "./bank"

export const CompanyMessageSchema = z.object({
    itn: z.string().nullish(),

    ruName: z.string().nullish(),
    enName: z.string().nullish(),

    ruAddress: z.string().nullish(),
    enAddress: z.string().nullish(),

    bank: BankMessageSchema,

    correspondentAccount: z.string().nullish(),
    paymentAccount: z.string().nullish(),
})

export type CompanyMessage = z.infer<typeof CompanyMessageSchema>
