import { ForwardedRef, forwardRef, HTMLInputAutoCompleteAttribute, useEffect, useRef } from "react"
import { fixLayout } from "my-util"
import { useStateWithDeps } from "ui/hook"
import Input from "ui/ui/Input"

export interface EmailInputProps {
    onChange?: (email: string) => void
    value?: string

    width?: string
    height?: string

    invalid?: boolean
    disabled?: boolean
    readonly?: boolean
    loading?: boolean

    autoFocus?: boolean
    autoComplete?: HTMLInputAutoCompleteAttribute

    information?: string
    placeholder?: string
    label?: string

    iconSrc?: string
    iconAlt?: string
    iconFilter?: string
}

const EmailInput = forwardRef((
    {
        onChange, value,
        width, height,
        invalid, disabled, readonly, loading,
        autoFocus, autoComplete,
        information, placeholder, label,
        iconSrc, iconAlt, iconFilter,
    }: Readonly<EmailInputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const changedRef = useRef(false)
    const layoutFixTimerRef = useRef(undefined as number | undefined)

    const [innerValue, setInnerValue] = useStateWithDeps<string>(
        oldValue => changedRef.current && oldValue != null
            ? oldValue
            : value ?? "",

        [value],
    )

    // Effects

    // - State propagation

    useEffect(() => {
        if (changedRef.current) {
            onChange?.(innerValue)
            changedRef.current = false
        }
    }, [innerValue, onChange])

    // Render

    return <Input onChange={onInnerChange}
                  value={innerValue}

                  width={width}
                  height={height}

                  invalid={invalid}
                  disabled={disabled}
                  readonly={readonly}
                  loading={loading}

                  autoFocus={autoFocus}
                  autoComplete={autoComplete}

                  label={label}
                  placeholder={placeholder}
                  information={information}

                  iconSrc={iconSrc}
                  iconAlt={iconAlt}
                  iconFilter={iconFilter}

                  ref={ref}/>

    // Events

    function onInnerChange(newValue: string) {
        if (layoutFixTimerRef.current != null) {
            window.clearTimeout(layoutFixTimerRef.current)
            layoutFixTimerRef.current = undefined
        }

        updateInnerValue(newValue)

        layoutFixTimerRef.current = window.setTimeout(
            () => {
                updateInnerValue(fixLayout(newValue, { whitelist: "\"" }))
                layoutFixTimerRef.current = undefined
            },

            200,
        )
    }

    // Util

    function updateInnerValue(newValue: string) {
        changedRef.current = true
        setInnerValue(newValue)
    }
})



export default EmailInput
