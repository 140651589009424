import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import style from "./style.module.css"

export interface CountProps {
    value?: number
    important?: boolean
}

const Count = forwardRef((
    { value, important }: Readonly<CountProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const [t] = useTranslation()

    const zero = value === 0

    return <span className={important && !zero ? style.importantValue : style.value}
                 ref={ref}>
        {zero
            ? t("misc.words.zero")
            : value
        }
    </span>
})

Count.displayName = "Count"

export default Count
