import { uniqueArray } from "my-util"
import { ReadyState } from "ui/hook"
import { UiChatMessage } from "ui/component"
import { ConnectionStatus } from "ui/ui"

export function chatHookReadyStateToConnectionStatus(readyState: ReadyState): ConnectionStatus {
    switch (readyState) {
        case ReadyState.CONNECTING:
            return "opening"

        case ReadyState.OPEN:
            return "open"

        case ReadyState.CLOSING:
            return "closing"

        case ReadyState.UNINSTANTIATED:
        case ReadyState.CLOSED:
            return "closed"
    }
}

export function uiChatMessagesToUniqueAndSorted(messages: UiChatMessage[]) {
    return uniqueArray(messages, ({ id }) => id)
        .sort((lhs, rhs) => {
            const lhsError = lhs.status === "error"
            const rhsError = rhs.status === "error"

            if (lhsError && rhsError)
                return compareByDate()

            if (lhsError)
                return 1

            if (rhsError)
                return -1

            return compareByDate()

            function compareByDate(): number {
                return lhs.date.getTime() - rhs.date.getTime()
            }
        })
}

export function isUiChatMessageUnread(message: UiChatMessage) {
    return !message.local
        && message.status === "sent"
}
