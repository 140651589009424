export function getOrComputeMapItem<K, V>(
    map: Map<K, V>,
    key: K,
    compute: (key: K) => V,
): V {
    let value = map.get(key)

    if (value == null)
        map.set(key, value = compute(key))

    return value
}
