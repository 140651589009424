import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { getAllEffectiveAgents } from "api"
import { getUsersByStatusSortingOrderComparator, User, UserStatus } from "model"
import { checkUserStatusPresence } from "my-util"
import { useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { Page, UserTable } from "ui/component"
import { Center, ErrorDisplay, Flex, Loading, Padding, UserStatusColorLegendList } from "ui/ui"

export default function AllAgentsPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()

    // State

    const [agents, setAgents] = useState(new Array<User>())
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(undefined as unknown)

    const agentsStatusPresence = useMemo(() => checkUserStatusPresence(agents), [agents])

    const [sortStatus, setSortStatus] = useState("active" satisfies UserStatus as UserStatus)

    const agentsComparator = useMemo(
        () => getUsersByStatusSortingOrderComparator(sortStatus),
        [sortStatus],
    )

    // Loading

    useEffect(() => {
        if (!localUser?.isManager)
            return

        const controller = new AbortController()

        getAllEffectiveAgents(controller.signal)
            .then(agents => {
                setAgents(agents)
                storedUsers.addAll(agents)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isManager)
        return <Error403Page/>

    return <Page title={t("sections.agents.header").toUpperCase()}
                 type="main">
        {renderContent()}
    </Page>

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        if (agents.length === 0)
            return <Center>
                {t("sections.agents.messages.no")}
            </Center>

        return <Flex width="fit-content"
                     align="start"
                     gap="8px">
            <Padding paddingLeft="16px">
                <UserStatusColorLegendList onClick={setSortStatus}

                                           hideActive={!agentsStatusPresence.activePresent}
                                           hideBlocked={!agentsStatusPresence.blockedPresent}
                                           hideUnverified={!agentsStatusPresence.unverifiedPresent}

                                           fontSize="12px"

                                           showLegend/>
            </Padding>

            <UserTable comparator={agentsComparator}
                       users={agents}
                       showStatuses/>
        </Flex>
    }
}
