import assert from "assert"
import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useParams } from "react-router-dom"
import { arrowLeftIconUrl } from "image"

import { cancelMfa, createLoginRequestFromMessageTarget, getLoginConfig,
         OtpResponse, login as performLogin, resendMfaOtp, verifyMfaOtp } from "api"

import { determineNullableMessageTargetType, MessageTargetType } from "model"

import { createMessageTargetInputPlaceholder,
         normalizeUuid, SECOND_MILLIS, isUuid,
         IS_ALLOWED_BY_EVERY_MESSAGE_TARGET_TYPE,
         isAllowedByMessageTargetTypeToMessageTargetTypeList } from "my-util"

import { normalizeMessageTarget } from "normalize"
import { OTP_LENGTH, validateOtp, validatePassword, validateMessageTarget } from "validation"
import { useStateWithDeps } from "ui/hook"
import { UserContext } from "ui/context"

import { setLastMessengerUserId,
         clearUnsentMessengerMessages } from "ui/page/sections/messenger/MessengerPage"

import { Otp, Page } from "ui/component"

import { Button, ErrorBlock, ErrorDisplay, Loading, Pane, Limit, Timer,
         Input, Flex, Form, MessageTargetInput, CapsLockDetector, Link } from "ui/ui"

import { MAIN_PAGE_PATH } from "../sections/MainPage/path"
import { Error404Page } from "../error"
import { LOGIN_PAGE_PATH_USER_ID_PARAM_NAME } from "./path"
import { createPasswordResetPagePath } from "../PasswordResetPage"

const MAX_WIDTH = "375px"

type State =
    // Config
    | "loading-config"
    | "loading-config-failed"

    // Credentials
    | "filling-in-credentials"
    | "verifying-credentials"

    // MFA
    | "filling-in-otp"
    | "resending-otp"
    | "verifying-otp"

    // Success
    | "success"

export default function LoginPage() {
    const [t, { language }] = useTranslation()

    const [,, refetchUser] = useContext(UserContext)

    const { [LOGIN_PAGE_PATH_USER_ID_PARAM_NAME]: userId } = useParams()
    const badUrl = userId == null || !isUuid(userId)

    // State

    const [error, setError] = useState(undefined as any)
    const [state, setState] = useState("loading-config" satisfies State as State)

    const loadingConfig = state === "loading-config"
    const loadingConfigFailed = state === "loading-config-failed"

    const fillingInCredentials = state === "filling-in-credentials"
    const verifyingCredentials = state === "verifying-credentials"

    const fillingInOtp = state === "filling-in-otp"
    const resendingOtp = state === "resending-otp"
    const verifyingOtp = state === "verifying-otp"

    const success = state === "success"

    const performingMfa =
        fillingInOtp ||
        resendingOtp ||
        verifyingOtp

    const mfaLoading =
        resendingOtp ||
        verifyingOtp

    // - Config

    const [config, setConfig] = useState(IS_ALLOWED_BY_EVERY_MESSAGE_TARGET_TYPE)

    const allowedLoginMessageTargetTypes = useMemo(
        () => isAllowedByMessageTargetTypeToMessageTargetTypeList(config),
        [config],
    )

    const loginPlaceholder = useMemo(
        () => createMessageTargetInputPlaceholder(allowedLoginMessageTargetTypes),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allowedLoginMessageTargetTypes, language],
    )

    // - Credentials

    const [login, setLogin] = useState(undefined as string | undefined)
    const loginInputInvalid = login != null && validateMessageTarget(login, config) != null
    const loginInvalid = login == null || loginInputInvalid

    const [password, setPassword] = useState(undefined as string | undefined)
    const passwordInputInvalid = password != null && validatePassword(password) != null
    const passwordInvalid = password == null || passwordInputInvalid

    const loginButtonDisabled = loginInvalid || passwordInvalid

    // - MFA

    const [otp, setOtp] = useState(undefined as string | undefined)
    const otpInputInvalid = otp != null && validateOtp(otp) != null
    const otpInvalid = otp == null || otpInputInvalid

    const [canResendOtpAt, setCanResendOtpAt] = useState(new Date())
    const [canResendOtp, setCanResendOtp] = useState(false)
    const [otpMessageTargetType, setOtpMessageTargetType] = useState(
        null as MessageTargetType | null,
    )

    const [otpInputPlaceholder] = useStateWithDeps(
        () => messageTargetTypeToOtpInputPlaceholder(otpMessageTargetType),
        [otpMessageTargetType, language],
    )

    // Effects

    // - Config loading

    useEffect(() => {
        if (badUrl || !loadingConfig)
            return

        const controller = new AbortController()

        getLoginConfig(controller.signal)
            .then(config => {
                setConfig(config)
                setState("filling-in-credentials")
            })
            .catch(error => {
                if (controller.signal.aborted)
                    return

                setError(error)
                setState("loading-config-failed")
            })

        return () => controller.abort()
    }, [badUrl, loadingConfig])

    // - Success handling

    useEffect(() => {
        return () => {
            if (success) {
                clearUnsentMessengerMessages()
                setLastMessengerUserId(null)
                refetchUser()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    // Render

    if (badUrl)
        return <Error404Page/>

    if (success)
        return <Navigate to={MAIN_PAGE_PATH}/>

    return <Page type="auth">
        <Pane>{renderContent()}</Pane>
    </Page>

    function renderContent() {
        if (loadingConfig)
            return <Loading/>

        if (loadingConfigFailed)
            return <ErrorDisplay error={error}/>

        return <Form onSubmit={onSubmit}>
            <Flex align="start"
                  wrap>
                {renderLoginInput()}

                {performingMfa
                    ? renderMfaPart()

                    : <>
                        {renderPasswordInput()}
                        {renderResetPasswordLink()}
                        {renderLoginButton()}
                    </>
                }

                {renderError()}
            </Flex>
        </Form>
    }

    function renderLoginInput(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <MessageTargetInput placeholder={loginPlaceholder}

                                onChange={setLogin}
                                value={login}

                                readonly={fillingInOtp}
                                loading={verifyingCredentials}
                                invalid={loginInputInvalid}/>
        </Limit>
    }

    function renderPasswordInput(): ReactNode {
        return <Flex direction="horizontal"
                     wrap>
            <Limit maxWidth={MAX_WIDTH}>
                <Input placeholder={t("misc.placeholders.password")}

                       onChange={setPassword}
                       value={password}

                       readonly={fillingInOtp}
                       loading={verifyingCredentials}
                       invalid={passwordInputInvalid}

                       type="password"

                       key="password"/>
            </Limit>

            <CapsLockDetector/>
        </Flex>
    }

    function renderResetPasswordLink(): ReactNode {
        assert(userId != null)

        return <Link to={createPasswordResetPagePath(userId)}
                     text={t("auth.passwordRest.link")}/>
    }

    function renderLoginButton(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Button text={t("misc.buttons.login")}
                    width="50%"

                    loading={verifyingCredentials}
                    disabled={loginButtonDisabled}

                    type="submit"/>
        </Limit>
    }

    function renderMfaPart(): ReactNode {
        return <>
            {renderOtpInput()}

            {renderSubmitMfaButton()}

            {canResendOtp
                ? renderResendOtpButton()
                : renderResendCoolDownMessage()
            }

            {renderCancelMfaButton()}
            {renderOtp()}
        </>
    }

    function renderOtpInput(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Input placeholder={otpInputPlaceholder}

                   onChange={setOtp}
                   value={otp ?? ""}

                   invalid={otpInputInvalid}
                   loading={mfaLoading}

                   autoComplete="off"

                   regex={/^\d*$/g}
                   max={OTP_LENGTH}

                   key="otp"/>
        </Limit>
    }

    function renderSubmitMfaButton(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Button text={t("misc.buttons.submit")}
                    disabled={otpInvalid}
                    loading={mfaLoading}
                    type="submit"/>
        </Limit>
    }

    function renderResendOtpButton(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Button text={t("auth.mfa.buttons.resendCode")}
                    loading={mfaLoading}
                    buttonStyle="outline"
                    onClick={onResendOtp}/>
        </Limit>
    }

    function renderResendCoolDownMessage(): ReactNode {
        return <p>
            {t("auth.mfa.messages.canResendIn")}

            {" "}

            <Timer onExpired={() => setCanResendOtp(true)}
                   expiresAt={canResendOtpAt}/>
        </p>
    }

    function renderCancelMfaButton(): ReactNode {
        return <Button onClick={onCancelMfa}

                       text={t("misc.buttons.back")}

                       buttonStyle="text"
                       width="fit-content"

                       iconSrc={arrowLeftIconUrl}
                       iconAlt="Arrow left icon"/>
    }

    function renderOtp(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <Otp key={canResendOtpAt.getTime()}/>
        </Limit>
    }

    function renderError(): ReactNode {
        return <Limit maxWidth={MAX_WIDTH}>
            <ErrorBlock
                error={error}
                apiErrorMessageMapping={{
                    422: t("auth.mfa.messages.errors.invalidCode"),
                    403: t("auth.login.messages.errors.invalidCredentials"),
                }}
            />
        </Limit>
    }

    // Events

    function onSubmit() {
        if (fillingInCredentials)
            return onLogin()

        if (fillingInOtp)
            return onVerifyOtp()
    }

    async function onLogin() {
        setState("verifying-credentials")

        try {
            assert(
                userId != null &&
                login != null &&
                password != null
            )

            const normalizedUserId = normalizeUuid(userId)
            const normalizedLogin = normalizeMessageTarget(login)

            const request = createLoginRequestFromMessageTarget(
                normalizedUserId,
                normalizedLogin,
                password,
            )

            const response = await performLogin(request)

            let nextState: State

            if (response.status === "verification-needed") {
                updateOtpState(response)
                nextState = "filling-in-otp"
            } else
                nextState = "success"

            setError(undefined)
            setState(nextState)
        } catch (error) {
            setError(error)
            setState("filling-in-credentials")
        }
    }

    async function onVerifyOtp() {
        setState("verifying-otp")

        try {
            assert(otp != null)

            await verifyMfaOtp(otp)

            setState("success")
            setError(undefined)
        } catch (error) {
            setState("filling-in-otp")
            setError(error)
        }
    }

    async function onResendOtp() {
        setState("resending-otp")

        try {
            const response = await resendMfaOtp()

            updateOtpState(response)
            setError(undefined)
        } catch (error) {
            setError(error)
        } finally {
            setState("filling-in-otp")
        }
    }

    function onCancelMfa() {
        cancelMfa()
        setOtp(undefined)
        setState("filling-in-credentials")
    }

    // Util

    function updateOtpState(response: OtpResponse) {
        setOtp(undefined)

        const sentAtMilli = response.sentAt?.getTime() ?? Date.now()
        const sentAtSecond = sentAtMilli / SECOND_MILLIS
        const resendCoolDownEndSecond = sentAtSecond + response.resendCoolDown
        const newCanResendOtpAt = new Date(SECOND_MILLIS * Math.floor(resendCoolDownEndSecond))

        setCanResendOtpAt(newCanResendOtpAt)

        setCanResendOtp(false)

        const newOtpMessageTargetType = determineNullableMessageTargetType(response.messageTarget)

        setOtpMessageTargetType(newOtpMessageTargetType)
    }

    function messageTargetTypeToOtpInputPlaceholder(type?: MessageTargetType | null): string {
        switch (type) {
            case "phone":
                return t("auth.mfa.placeholders.smsCode")

            case "email":
                return t("auth.mfa.placeholders.emailCode")

            default:
                return t("auth.mfa.placeholders.code")
        }
    }
}
