import assert from "assert"
import { useTranslation } from "react-i18next"

import { ForwardedRef, useEffect, forwardRef, ReactNode, Key,
         useLayoutEffect, Fragment, useRef, useMemo, useState } from "react"

import { arrowDownIconUrl, crossIconUrl } from "image"
import { getLang } from "i18n"

import { dateToTimeString, splicedArray, areNullableDatesWithSameDay,
         dateToDateString, collapseSpacesToNull, ReadonlyDate, DeepReadonly } from "my-util"

import { useStateWithDeps } from "ui/hook"
import { copyUiDocument, DocumentListUpload, UiDocument } from "ui/component/document"
import { Flex, LoadingIndicator, ContextMenu, Button, Form, LinkedText, ErrorText } from "ui/ui"

import { UiChatMessageSender, UiChatMessageMultiLangSender,
         ReadonlyUiChatMessage, UiChatMessage, UiChatMessageSenderValue} from "../types"

import ChatMessageTicks from "../ChatMessageTicks"
import ChatInputs from "./Inputs"
import style from "./style.module.css"

// Consts

const MESSAGE_ID_ATTRIBUTE_NAME = "custom-chat-message-id"

const DEFAULT_LOAD_MORE_OFFSET = 256
const DEFAULT_SCROLL_DOWN_BUTTON_OFFSET = 256

const CONTEXT_MENU_BUTTON_FONT_SIZE = "12px"
const CONTEXT_MENU_BUTTON_HEIGHT = "24px"

// Types

export interface ChatProps {
    onViewMessages?: (messages: UiChatMessage[]) => void

    onScroll?: (scroll: number) => void
    scroll?: number

    header?: string

    canDeleteMessage?: (message: ReadonlyUiChatMessage, messageIndex: number) => boolean
    canResendMessage?: (message: ReadonlyUiChatMessage, messageIndex: number) => boolean
    canEditMessage?: (message: ReadonlyUiChatMessage, messageIndex: number) => boolean

    onDeleteMessage?: (message: ReadonlyUiChatMessage, messageIndex: number) => void
    onResendMessage?: (message: ReadonlyUiChatMessage, messageIndex: number) => UiChatMessage | void
    onEditMessage?: (message: UiChatMessage, messageIndex: number) => UiChatMessage | void

    onMessagesChange?: (newMessages: UiChatMessage[]) => void
    messages?: UiChatMessage[]

    onLoadMore?: () => void
    loadMoreOffset?: number
    loadingMore?: boolean
    loadingMoreError?: unknown
    hasMoreToLoad?: boolean

    onSend?: (message: UiChatMessage) => UiChatMessage | void
    sender?: UiChatMessageSender
    senderId?: Key | null

    onMessageTextChange?: (messageText: string) => void
    messageText?: string
    maxMessageTextLength?: number

    onMessageDocumentsChange?: (messageDocuments: UiDocument[]) => void
    messageDocuments?: UiDocument[]

    noAutoLink?: boolean

    showScrollDownButton?: boolean
    scrollDownButtonOffset?: number

    width?: string
    height?: string
}

const Chat = forwardRef((
    {
        onViewMessages,
        onScroll, scroll,
        header,
        canDeleteMessage, canEditMessage, canResendMessage,
        onDeleteMessage, onEditMessage, onResendMessage,
        onMessagesChange, messages,
        onLoadMore, loadMoreOffset, loadingMore, loadingMoreError, hasMoreToLoad,
        onSend, sender, senderId,
        messageText, onMessageTextChange, maxMessageTextLength,
        messageDocuments, onMessageDocumentsChange,
        noAutoLink,
        showScrollDownButton, scrollDownButtonOffset,
        width,height,
    }: DeepReadonly<ChatProps>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    const [t] = useTranslation()

    const innerLoadMoreOffset = loadMoreOffset ?? DEFAULT_LOAD_MORE_OFFSET
    const innerScrollDownButtonOffset = scrollDownButtonOffset ?? DEFAULT_SCROLL_DOWN_BUTTON_OFFSET

    // State

    const [innerMessages, setInnerMessages] = useStateWithDeps(
        () => messages ?? [],
        [messages],
    )

    const innerMessagesById = useMemo(() => {
        // Used only for optimizing this event handling
        if (onViewMessages == null)
            return null

        const messagesById = new Map<string, DeepReadonly<UiChatMessage>>()

        for (const message of innerMessages) {
            const id = getEffectiveMessageId(message)

            messagesById.set(id, message)
        }

        return messagesById
    }, [innerMessages, onViewMessages])

    const [innerShowScrollDownButton, setInnerShowScrollDownButton] = useState(false)

    const [selectedMessageIndex, setSelectedMessageIndex] = useStateWithDeps(() => -1, [innerMessages])
    const [editingMessageIndex, setEditingMessageIndex] = useStateWithDeps(() => -1, [innerMessages])

    // Refs

    const messagesElementRef = useRef(null as HTMLDivElement | null)

    const messageTextRef = useRef(messageText)
    const messageDocumentsRef = useRef(messageDocuments)

    const scrollToBottomRequiredRef = useRef(false)
    const scrollRef = useRef(scroll ?? 0)

    // Effects

    // - Auto scrolling

    useLayoutEffect(() => {
        const messagesElement = messagesElementRef.current

        if (messagesElement == null)
            return

        const newScrollTop = scrollToBottomRequiredRef.current
            ? messagesElement.scrollHeight

            : messagesElement.scrollHeight -
              messagesElement.offsetHeight -
              scrollRef.current

        messagesElement.scrollTo(messagesElement.scrollLeft, newScrollTop)

        scrollToBottomRequiredRef.current = false
    }, [innerMessages])

    // - Scroll notifying and scrollRef updating

    useEffect(() => {
        const messagesElement = messagesElementRef.current

        if (messagesElement == null)
            return

        messagesElement.addEventListener("scroll", handleScroll)

        return () => messagesElement.removeEventListener("scroll", handleScroll)

        function handleScroll() {
            const messagesElement = messagesElementRef.current

            if (messagesElement == null)
                return

            const scrollBottom =
                messagesElement.scrollHeight -
                messagesElement.offsetHeight -
                messagesElement.scrollTop

            scrollRef.current = scrollBottom

            setInnerShowScrollDownButton(
                Boolean(showScrollDownButton) && scrollBottom >= innerScrollDownButtonOffset
            )

            onScroll?.(scrollBottom)
        }
    }, [onScroll, showScrollDownButton, innerScrollDownButtonOffset])

    // - More loading

    useEffect(
        () => {
            const messagesElement = messagesElementRef.current

            if (messagesElement == null || onLoadMore == null ||
                loadingMore || loadingMoreError || !hasMoreToLoad)
                return

            const timer = setTimeout(handleScroll, 0)

            messagesElement.addEventListener("scroll", handleScroll)

            return () => {
                messagesElement.removeEventListener("scroll", handleScroll)
                clearTimeout(timer)
            }

            function handleScroll() {
                const messagesElement = messagesElementRef.current

                if (messagesElement != null && messagesElement.scrollTop <= innerLoadMoreOffset)
                    onLoadMore?.()
            }
        },

        [
            innerLoadMoreOffset,
            loadingMore, loadingMoreError, hasMoreToLoad,
            onLoadMore,
        ],
    )

    // - Messages view handling

    useLayoutEffect(() => {
        const messagesElement = messagesElementRef.current

        if (messagesElement == null || onViewMessages == null || innerMessagesById == null)
            return

        messagesElement.addEventListener("scroll", handleScroll)

        handleScroll()

        return () => messagesElement.removeEventListener("scroll", handleScroll)

        function handleScroll() {
            assert(onViewMessages != null && innerMessagesById != null)

            const messagesElement = messagesElementRef.current

            if (messagesElement == null)
                return

            const messagesElementRect = messagesElement.getBoundingClientRect()
            const messagesInView = new Array<UiChatMessage>()

            for (const childElement of messagesElement.children) {
                const messageId = childElement.getAttribute(MESSAGE_ID_ATTRIBUTE_NAME)

                if (messageId == null)
                    continue

                const message = innerMessagesById.get(messageId)

                if (message == null)
                    continue

                const childElementRect = childElement.getBoundingClientRect()

                const childElementInView =
                    childElementRect.bottom > messagesElementRect.top &&
                    childElementRect.top < messagesElementRect.bottom

                if (!childElementInView)
                    continue

                messagesInView.push({
                    ...message,

                    documents: message.documents?.map(copyUiDocument),
                    date: new Date(message.date.getTime()),
                })
            }

            onViewMessages(messagesInView)
        }
    }, [innerMessagesById, onViewMessages])

    // - Message text and documents update

    useEffect(() => {
        messageTextRef.current = messageText
        messageDocumentsRef.current = messageDocuments
    }, [messageText, messageDocuments])

    // Render

    return <Form width={width}
                 height={height ?? "100%"}
                 ref={ref}>
        <div className={style.Chat}>
            {renderHeader()}
            {renderAllMessages()}
            {renderInputs()}
        </div>
    </Form>

    function renderHeader(): ReactNode {
        return header &&
            <div className={style.header}>
                {header}
            </div>
    }


    function renderAllMessages(): ReactNode {
        return <div className={style.allMessagesWrapper}>
            <div className={style.allMessages}
                 ref={messagesElementRef}>
                <div className={style.gap}/>

                {loadingMore &&
                    <LoadingIndicator/>
                }

                <ErrorText error={loadingMoreError}/>

                {innerMessages?.map((_, i) => renderMessage(i))}
            </div>

            <div style={{ opacity: innerShowScrollDownButton ? 1 : 0 }}
                 className={style.scrollDownButton}>
                <Button onClick={onScrollDown}
                        iconSrc={arrowDownIconUrl}
                        buttonStyle="text"/>
            </div>
        </div>
    }

    function renderMessage(messageIndex: number): ReactNode {
        const message = innerMessages[messageIndex]

        const prevMessage = messageIndex > 0
            ? innerMessages[messageIndex - 1]
            : null

        const id = getEffectiveMessageId(message)

        const prevSender = getNullableSenderValue(prevMessage?.sender)
        const sender = getNullableSenderValue(message.sender)

        const senderChanged = areSendersDistinct(
            prevMessage?.senderId, prevSender,
            message.senderId, sender,
        )

        return <Fragment key={id}>
            {!areNullableDatesWithSameDay(prevMessage?.date, message.date) &&
                renderMessageDate(message.date)
            }

            <div className={renderMessageClassName(message)}
                 { ...{ [MESSAGE_ID_ATTRIBUTE_NAME]: id } }>
                {senderChanged &&
                    renderMessageSender(sender)
                }

                <div className={style.messageBody}>
                    <Flex justify="space-between"
                          direction="horizontal"
                          width="fit-content"
                          align="end"
                          gap="8px"
                          wrap>
                        {renderMessagePayload(message, messageIndex)}
                        {renderMessageStatus(message)}
                    </Flex>

                    {renderMessageContextMenu(message, messageIndex)}
                </div>
            </div>
        </Fragment>
    }

    function renderMessageDate(date: ReadonlyDate): ReactNode {
        return <div className={style.messageDate}>
            {dateToDateString(date)}
        </div>
    }

    function renderMessageClassName(
        message: ReadonlyUiChatMessage,
        messageIndex: number = innerMessages.indexOf(message),
    ): string {
        const classNames = ["message", message.status ?? "sent"]

        if (message.local)
            classNames.push("local")

        if (messageIndex === selectedMessageIndex)
            classNames.push("selected")

        return classNames
            .map(className => style[className])
            .join(" ")
    }

    function renderMessageSender(sender?: Readonly<UiChatMessageSenderValue> | null): ReactNode {
        if (sender == null)
            return null

        const content = typeof sender === "string"
            ? sender
            : getLang() === "ru"
                ? sender.ruName
                : sender.enName

        return <div className={style.messageSender}>
            {content}
        </div>
    }

    function renderMessagePayload(
        message: ReadonlyUiChatMessage,
        messageIndex: number = innerMessages.indexOf(message),
    ): ReactNode {
        return <Flex width="fit-content"
                     align="start"
                     gap="8px">
            {renderMessageText(message.text)}
            {renderMessageDocuments(message, messageIndex)}
        </Flex>
    }

    function renderMessageText(text?: string | null): ReactNode {
        return Boolean(text) &&
            <span className={style.messageText}>
                {noAutoLink
                    ? text
                    : <LinkedText text={text!}/>
                }
            </span>
    }

    function renderMessageDocuments(
        message: ReadonlyUiChatMessage,
        messageIndex: number = innerMessages.indexOf(message),
    ): ReactNode {
        const { documents } = message

        return documents != null && documents.length > 0 &&
            <DocumentListUpload
                onChange={newDocuments => onSentMessageDocumentsChange(messageIndex, newDocuments)}
                documents={documents}
                align={message.local ? "end" : "start"}
                direction="vertical"
                width="fit-content"
                readonly
            />
    }

    function renderMessageStatus(message: ReadonlyUiChatMessage): ReactNode {
        return <div className={style.messageStatus}>
            <Flex direction="horizontal"
                  width="fit-content"
                  align="end"
                  gap="4px">
                {message.edited &&
                    <span className={style.messageEditedMark}>
                        {t("chat.labels.edited")}
                    </span>
                }

                <span className={style.messageTime}>
                    {dateToTimeString(message.date)}
                </span>

                {message.local &&
                    <ChatMessageTicks status={message.status ?? "sent"}/>
                }
            </Flex>
        </div>
    }

    function renderMessageContextMenu(
        message: ReadonlyUiChatMessage,
        messageIndex: number = innerMessages.indexOf(message),
    ): ReactNode {
        const canResendThisMessage = canResendMessage?.(message, messageIndex)
            ?? message.status === "error"

        const canEditThisMessage = canEditMessage?.(message, messageIndex)
            ?? message.local ?? false

        const canDeleteThisMessage = canDeleteMessage?.(message, messageIndex)
            ?? message.local ?? false

        if (!canResendThisMessage && !canEditThisMessage && !canDeleteThisMessage)
            return null

        return <ContextMenu onToggle={onToggle}>
            <Flex gap="8px">
                {canResendThisMessage &&
                    <Button onClick={innerOnResendMessage}
                            fontSize={CONTEXT_MENU_BUTTON_FONT_SIZE}
                            height={CONTEXT_MENU_BUTTON_HEIGHT}
                            text={t("misc.buttons.resend")}
                            buttonStyle="text"/>
                }

                {canEditThisMessage &&
                    <Button onClick={() => setEditingMessageIndex(messageIndex)}
                            fontSize={CONTEXT_MENU_BUTTON_FONT_SIZE}
                            height={CONTEXT_MENU_BUTTON_HEIGHT}
                            text={t("misc.buttons.edit")}
                            buttonStyle="text"/>
                }

                {canDeleteThisMessage &&
                    <Button onClick={() => onDeleteMessage?.(message, messageIndex)}
                            fontSize={CONTEXT_MENU_BUTTON_FONT_SIZE}
                            height={CONTEXT_MENU_BUTTON_HEIGHT}
                            text={t("misc.buttons.delete")}
                            buttonStyle="text"
                            critical/>
                }
            </Flex>
        </ContextMenu>

        function onToggle(show: boolean) {
            if (show)
                setSelectedMessageIndex(messageIndex)
            else if (messageIndex === selectedMessageIndex)
                setSelectedMessageIndex(-1)
        }

        function innerOnResendMessage() {
            const result = onResendMessage?.(message, messageIndex)

            if (result == null)
                return

            const newInnerMessages = splicedArray(innerMessages, messageIndex, 1, result)

            onInnerMessagesChange(newInnerMessages)
        }
    }

    function renderInputs(): ReactNode {
        const editingMessage = innerMessages[editingMessageIndex] as ReadonlyUiChatMessage | undefined
        const editing = editingMessage != null

        const effectiveOnMessageTextChange = editing
            ? undefined
            : onInnerMessageTextChange

        const effectiveMessageText = editing
            ? (editingMessage.text ?? undefined)
            : messageTextRef.current

        const effectiveOnMessageDocumentsChange = editing
            ? undefined
            : onInnerMessageDocumentsChange

        const effectiveMessageDocuments = editing
            ? (editingMessage.documents ?? undefined)
            : messageDocumentsRef.current

        return <div className={style.inputs}>
            <ChatInputs onSubmit={onSubmit}

                        onMessageTextChange={effectiveOnMessageTextChange}
                        messageText={effectiveMessageText}
                        maxMessageTextLength={maxMessageTextLength}

                        onMessageDocumentsChange={effectiveOnMessageDocumentsChange}
                        messageDocuments={effectiveMessageDocuments}

                        information={renderInformation()}/>
        </div>

        function renderInformation(): ReactNode {
            if (editingMessageIndex < 0)
                return

            return <Flex justify="space-between"
                         direction="horizontal">
                {t("chat.messages.editingMessage")}

                <Button onClick={() => setEditingMessageIndex(-1)}

                        buttonStyle="text"

                        width="24px"
                        height="24px"

                        fontSize="12px"

                        iconSrc={crossIconUrl}/>
            </Flex>
        }
    }

    // Events

    function onSubmit(messageText: string, messageDocuments: UiDocument[]) {
        const editingMessage = innerMessages[editingMessageIndex] as ReadonlyUiChatMessage | undefined
        const editing = editingMessage != null

        let newInnerMessages: readonly ReadonlyUiChatMessage[]

        if (editing) {
            let newMessage: UiChatMessage = {
                ...editingMessage,

                text: collapseSpacesToNull(messageText),
                documents: messageDocuments.filter(({ status }) => status === "ready"),
                date: new Date(editingMessage.date.getTime()),
                edited: true,
            }

            newMessage = onResendMessage?.(newMessage, editingMessageIndex) ?? newMessage
            newInnerMessages = splicedArray(innerMessages, editingMessageIndex, 1, newMessage)
        } else {
            let newMessage: UiChatMessage = {
                text: collapseSpacesToNull(messageText),
                documents: messageDocuments.filter(({ status }) => status === "ready"),
                status: "sent",
                date: new Date(),
                local: true,
                senderId,
                sender,
            }

            newMessage = onSend?.(newMessage) ?? newMessage
            newInnerMessages = [...innerMessages, newMessage]

            scrollToBottomRequiredRef.current = true
        }

        onInnerMessagesChange(newInnerMessages)

        messageTextRef.current = ""
        messageDocumentsRef.current = []
    }

    function onScrollDown() {
        const messagesElement = messagesElementRef.current

        if (messagesElement == null)
            return

        messagesElement.style.scrollBehavior = "smooth"

        messagesElement.addEventListener("scrollend", handleScrollEnd)

        messagesElement.scroll(messagesElement.scrollLeft, messagesElement.scrollHeight)

        function handleScrollEnd() {
            const messagesElement = messagesElementRef.current

            if (messagesElement == null)
                return

            messagesElement.style.scrollBehavior = "auto"

            messagesElement.removeEventListener("scrollend", handleScrollEnd)
        }
    }

    function onSentMessageDocumentsChange(
        messageIndex: number,
        newDocuments: UiDocument[],
    ) {
        const oldMessage = innerMessages[messageIndex]

        const newMessage: DeepReadonly<UiChatMessage> = {
            ...oldMessage,
            documents: newDocuments,
        }

        const newInnerMessages = splicedArray(innerMessages, messageIndex, 1, newMessage)

        onInnerMessagesChange(newInnerMessages)
    }

    function onInnerMessagesChange(newInnerMessages: DeepReadonly<UiChatMessage[]>) {
        setInnerMessages(newInnerMessages)

        if (onMessagesChange == null)
            return

        const newMutableInnerMessages = newInnerMessages.map((message): UiChatMessage => ({
            ...message,
            documents: message.documents?.map(copyUiDocument),
            date: new Date(message.date.getTime()),
        }))

        onMessagesChange(newMutableInnerMessages)
    }

    function onInnerMessageTextChange(newMessageText: string) {
        onMessageTextChange?.(newMessageText)
        messageTextRef.current = newMessageText
    }

    function onInnerMessageDocumentsChange(newMessageDocuments: UiDocument[]) {
        onMessageDocumentsChange?.(newMessageDocuments)
        messageDocumentsRef.current = newMessageDocuments
    }

    // Util

    function getEffectiveMessageId(message: DeepReadonly<UiChatMessage>): string {
        return (message.id ?? message.date.getTime()).toString()
    }

    function getNullableSenderValue(
        sender?: UiChatMessageSender | null,
    ): UiChatMessageSenderValue | null {
        return sender != null
            ? getSenderValue(sender)
            : null
    }

    function getSenderValue(sender: UiChatMessageSender): UiChatMessageSenderValue {
        return typeof sender === "function"
            ? sender()
            : sender
    }

    function areSendersDistinct(
        firstId?: Key | null,
        firstValue?: UiChatMessageSenderValue | null,

        secondId?: Key | null,
        secondValue?: UiChatMessageSenderValue | null,
    ): boolean {
        if (firstId != null && secondId != null)
            return firstId !== secondId

        if (typeof firstValue !== typeof secondValue)
            return true

        switch (typeof firstValue) {
            case "string":
            case "undefined":
                return firstValue !== secondValue

            case "object":
                if ((firstValue == null) !== (secondValue == null))
                    return true

                if (firstValue == null) // newSender is null too then
                    return false

                return firstValue.enName !== (secondValue as UiChatMessageMultiLangSender).enName
                    || firstValue.ruName !== (secondValue as UiChatMessageMultiLangSender).ruName
        }
    }
})

Chat.displayName = "Chat"

export default Chat
