import assert from "assert"

import { useEffect, useImperativeHandle, useState,
         ForwardedRef, forwardRef, ReactNode, useRef } from "react"

import { DeepReadonly } from "my-util"
import { Padding } from "../layout"
import Hovering from "../Hovering"
import style from "./style.module.css"

const WINDOW_PADDING = 8

export interface ContextMenuProps {
    onToggle?: (shown: boolean) => void

    children?: ReactNode

    disabled?: boolean

    width?: string
    height?: string
}

const ContextMenu = forwardRef((
    {
        onToggle,
        children,
        disabled,
        width, height,
    }: DeepReadonly<ContextMenuProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // Refs

    const containerRef = useRef(null as HTMLDivElement | null)
    const menuRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => containerRef.current!, [])

    // State

    const [show, setShow] = useState(false)

    // Effects

    // - State propagation

    useEffect(() => onToggle?.(show), [show, onToggle])

    // - Event handling

    useEffect(() => {
        const container = containerRef.current
        const menu = menuRef.current

        if (container == null || menuRef == null || disabled)
            return

        document.addEventListener("click", handleClick)
        document.addEventListener("contextmenu", handleContextMenu)

        return () => {
            document.removeEventListener("click", handleClick)
            document.removeEventListener("contextmenu", handleContextMenu)
        }

        function handleClick(event: MouseEvent) {
            assert(menu != null)

            if (!clickedOnMenu(event))
                setShow(false)
        }

        function handleContextMenu(event: MouseEvent) {
            assert(container != null && menu != null)

            if (show) {
                if (!clickedOnMenu(event))
                    setShow(false)

                return
            }

            if (isSelecting() || clickedOnElementWithinClass(event, style.menu))
                return

            const { x, y } = event
            const containerRect = container.getBoundingClientRect()

            if (!isPointInsideDOMRect(x, y, containerRect))
                return

            event.preventDefault()

            setShow(true)

            updateMenuPosition(x, y)
        }

        function clickedOnMenu(event: MouseEvent): boolean {
            assert(menu != null)

            return event.target instanceof Node
                && menu.contains(event.target)
        }

        function clickedOnElementWithinClass(event: MouseEvent, className: string): boolean {
            if (!(event.target instanceof HTMLElement))
                return false

            let element: HTMLElement | null = event.target

            do {
                if (element.classList.contains(className))
                    return true

                element = element.parentElement
            } while (element != null)

            return false
        }

        function isSelecting(): boolean {
            return document.getSelection()?.type === "Range"
        }

        function isPointInsideDOMRect(x: number, y: number, rect: DOMRect): boolean {
            return x >= rect.left
                && x <= rect.right

                && y >= rect.top
                && y <= rect.bottom
        }

        function updateMenuPosition(x: number, y: number) {
            assert(menu != null)

            let newMenuLeft = x

            if (newMenuLeft + menu.offsetWidth > window.innerWidth - WINDOW_PADDING)
                newMenuLeft = x - menu.offsetWidth

            if (newMenuLeft < WINDOW_PADDING)
                newMenuLeft = WINDOW_PADDING

            menu.style.left = `${newMenuLeft}px`
            menu.style.top = `${y}px`
        }
    }, [disabled, show])

    // Render

    const visibility = show ? "visible" : "hidden"

    return <div className={style.container}
                style={{ visibility }}
                ref={containerRef}>
        <div style={{ width, height }}
             className={style.menu}
             ref={menuRef}>
            <Hovering>
                <Padding padding="8px">
                    {children}
                </Padding>
            </Hovering>
        </div>
    </div>
})

ContextMenu.displayName = "ContextMenu"

export default ContextMenu
