import { z } from "zod"
import { AnonymousChatMessageResponseSchema } from "./anonymous-message"
import { ChatMessageResponseSchema } from "./message"
import { ChatResponseType } from "./type"
import { ChatResponseBaseSchema } from "./base"

export const ChatMessageListResponseSchema = ChatResponseBaseSchema.extend({
    type: z.literal("message-list" satisfies ChatResponseType),

    messages: AnonymousChatMessageResponseSchema.or(ChatMessageResponseSchema).array(),
})

export type ChatMessageListResponse = z.infer<typeof ChatMessageListResponseSchema>
