import { determineMessageTargetType, MessageTargetType } from "model/enums"
import { WithOneMessageTarget } from "model/interfaces"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, Nullish } from "my-util"

export interface InviteApplicationCreationOptions extends AbstractModelObjectCreationOptions {
    messageTarget: string
}

export interface InviteApplicationCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<InviteApplicationCreationOptions>
{}

export default class InviteApplication
    extends
        AbstractModelObject

    implements
        WithOneMessageTarget
{
    static createOrPass(
        arg: InviteApplication | DeepReadonly<InviteApplicationCreationOptions>,
    ): InviteApplication {
        return arg instanceof InviteApplication
            ? arg
            : new InviteApplication(arg)
    }

    readonly messageTarget: string

    constructor(options: DeepReadonly<InviteApplicationCreationOptions>) {
        super(options)

        this.messageTarget = options.messageTarget
    }

    get messageTargetType(): MessageTargetType {
        return determineMessageTargetType(this.messageTarget)
    }

    override copyOrPass(
        arg?: InviteApplication
            | DeepReadonly<InviteApplicationCopyOptions>
            | null,
    ): InviteApplication {
        if (arg == null)
            return this
        
        if (arg instanceof InviteApplication)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<InviteApplicationCopyOptions> = {}): InviteApplication {
        return new InviteApplication({
            messageTarget: options.messageTarget ?? this.messageTarget,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
