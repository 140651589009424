import { ForwardedRef, forwardRef, useContext, useMemo } from "react"
import { getLang } from "i18n"
import { User } from "model"
import { DeepReadonly, tryNormalizeUuid } from "my-util"
import { UserContext } from "ui/context"
import { Flex } from "ui/ui"
import ChatCard from "../ChatCard"
import { UiChat } from "../types"

export interface ChatListProps {
    onClick?: (chat: UiChat) => void
    chats: Iterable<UiChat>
    selected?: UiChat | User | string

    filter?: (chat: UiChat) => boolean

    formal?: boolean

    width?: string
}

const ChatList = forwardRef((
    {
        onClick, chats, selected,
        filter,
        formal,
        width,
    }: DeepReadonly<ChatListProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const lang = getLang()

    const [localUser] = useContext(UserContext)
    const localUserId = localUser?.id

    const innerChats = useMemo(
        () => [...chats]
            .filter(filter ?? (() => true))
            .sort((lhs, rhs) => {
                // Locality

                const lhsLocal = lhs.user.id === localUserId
                const rhsLocal = rhs.user.id === localUserId

                if (lhsLocal && !rhsLocal)
                    return -1

                if (!lhsLocal && rhsLocal)
                    return 1

                // Unread count

                const lhsUnreadCount = lhs.unreadCount ?? 0
                const rhsUnreadCount = rhs.unreadCount ?? 0

                if (lhsUnreadCount > rhsUnreadCount)
                    return -1

                if (lhsUnreadCount < rhsUnreadCount)
                    return 1

                // Last sent

                const lhsLastSentTime = lhs.messages[lhs.messages.length - 1]?.date?.getTime()
                const rhsLastSentTime = rhs.messages[rhs.messages.length - 1]?.date?.getTime()

                if (lhsLastSentTime != null && rhsLastSentTime)
                    return rhsLastSentTime - lhsLastSentTime

                if (lhsLastSentTime != null)
                    return -1

                if (rhsLastSentTime != null)
                    return 1

                // Name

                const lhsName = getUserName(lhs.user)
                const rhsName = getUserName(rhs.user)

                return lhsName.localeCompare(rhsName)

                function getUserName(user: User): string {
                    return formal
                        ? user.formalName
                        : user.name
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [chats, localUserId, formal, lang],
    )

    const selectedUserId = useMemo(
        () => {
            if (selected == null)
                return null

            if (typeof selected === "string")
                return tryNormalizeUuid(selected)

            if (selected instanceof User)
                return selected.id

            return (selected as UiChat).user.id
        },
        [selected],
    )

    return <Flex width={width}
                 align="start"
                 gap="8px"
                 ref={ref}>
        {innerChats.map(chat =>
            <ChatCard onClick={onClick}
                      selected={chat.user.id === selectedUserId}
                      chat={chat}

                      formal={formal}
                      key={chat.user.id}/>
        )}
    </Flex>
})

ChatList.displayName = "ChatList"

export default ChatList
