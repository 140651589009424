import { t } from "i18next"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, Nullish, tryNormalizeUuid } from "my-util"

// Type

export type NotificationType =
    | "new-chat-messages"
    | "new-invite-application"
    | "new-invite"
    | "new-transfer"
    | "new-user"
    | "transfer-status-changed"

export function notificationTypeToString(type: NotificationType): string {
    return t(`notifications.types.${type}`)
}

export function isNotificationType(s: string): s is NotificationType {
    switch (s) {
        case "new-chat-messages":
        case "new-invite-application":
        case "new-invite":
        case "new-transfer":
        case "new-user":
            s satisfies NotificationType
            return true

        default:
            return false
    }
}

// AbstractNotification

export interface AbstractNotificationCreationOptions<T extends NotificationType>
    extends
        AbstractModelObjectCreationOptions
{
    type: T
    recipientId: string
}

export interface AbstractNotificationCopyOptions
    extends
        AbstractModelObjectCopyOptions,

        Nullish<
            Omit<
                AbstractNotificationCreationOptions<NotificationType>,
                "type"
            >
        >
{}

export default abstract class AbstractNotification<T extends NotificationType>
    extends
        AbstractModelObject
{
    readonly type: T
    readonly recipientId: string

    constructor(options: DeepReadonly<AbstractNotificationCreationOptions<T>>) {
        super(options)

        this.type = options.type as T
        this.recipientId = tryNormalizeUuid(options.recipientId)
    }

    get userIds(): string[] {
        return [this.recipientId]
    }

    override copyOrPass(
        arg?: AbstractNotification<NotificationType>
            | DeepReadonly<AbstractNotificationCopyOptions>
            | null,
    ): AbstractNotification<NotificationType> {
        if (arg == null)
            return this

        if (arg instanceof AbstractNotification)
            return arg

        return this.copy(arg)
    }

    abstract copy(options?: DeepReadonly<AbstractNotificationCopyOptions>): AbstractNotification<NotificationType>
}
