import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export interface ArrowProps {
    color?: string

    width?: string
    height?: string
}

const Arrow = forwardRef((
    {
        color,
        width, height,
    }: Readonly<ArrowProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const halfHeight = height != null
        ? `calc(.5 * ${height})`
        : undefined

    return <div
        style={{
            borderLeftColor: color,
            borderLeftWidth: width,
            borderTopWidth: halfHeight,
            borderBottomWidth: halfHeight,
        }}

        className={style.Arrow}
        ref={ref}
    />
})

Arrow.displayName = "Arrow"

export default Arrow
