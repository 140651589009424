import Decimal from "decimal.js"
import { ForwardedRef, forwardRef, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { patchTransferById } from "api"
import { Transfer, TransferStatus } from "model"
import { MAX_MEDIUM_TEXT_LENGTH } from "validation"
import { UserContext } from "ui/context"
import { DecimalInput, ErrorDisplay, Label, Modal, Required, TextArea } from "ui/ui"
import style from "./style.module.css"

export interface TransferExpensesSetModalProps {
    onClose?: () => void
    onSet?: (transfer: Transfer) => void

    transferId: string

    width?: string
}

const TransferExpensesSetModal = forwardRef((
    {
        onClose, onSet,
        transferId,
        width,
    }: Readonly<TransferExpensesSetModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    const [expenses, setExpenses] = useState(new Decimal(100))
    const expensesInvalid = expenses.isZero() && !localUser?.isAdmin

    const [comment, setComment] = useState("")

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    // Render

    return <Modal
        header={t("domain.transfers.headers.expenses")}
        loading={loading}
        onClose={onInnerClose}
        width={width}
        buttons={[
            {
                onClick: onInnerClose,
                text: t("misc.buttons.cancel"),
                buttonStyle: "outline",
            },

            {
                onClick: onApprove,
                text: t("misc.buttons.ok"),
                type: "submit",
                disabled: expensesInvalid,
            },
        ]}
        ref={ref}
    >
        <div className={style.layout}>
            {/* Expenses */}

            <div className={style.label}>
                <Required>
                    <Label text={t("domain.transfers.labels.expenses")}/>
                </Required>
            </div>

            <div className={style.value}>
                <DecimalInput onChange={setExpenses}
                              value={expenses}

                              placeholder={t("domain.transfers.placeholders.expenses")}
                              right="₽"

                              invalid={expensesInvalid}
                              loading={loading}/>
            </div>

            {/* Expenses error */}

            {expensesInvalid &&
                <div className={style.error}>
                    <ErrorDisplay error={t("domain.transfers.messages.errors.zeroExpenses")}
                                  centerType="flex"/>
                </div>
            }

            {/* Comment */}

            <div className={style.label}>
                <Required>
                    <Label text={t("domain.transfers.labels.comment")}/>
                </Required>
            </div>

            <div className={style.value}>
                <TextArea onChange={setComment}
                          value={comment}

                          placeholder={t("domain.transfers.placeholders.comment")}

                          resize="vertical"

                          loading={loading}

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          showMax

                          canSubmit/>
            </div>

            {/* Error */}

            {error != null &&
                <div className={style.error}>
                    <ErrorDisplay centerType="flex"
                                  error={error}/>
                </div>
            }
        </div>
    </Modal>

    // Events

    function onInnerClose() {
        if (!loading)
            onClose?.()
    }

    async function onApprove() {
        setLoading(true)

        try {
            const transfer = await patchTransferById(transferId, [
                {
                    op: "replace",
                    path: "/status",
                    value: "done" satisfies TransferStatus,
                },

                {
                    op: "replace",
                    path: "/expenses",
                    value: expenses.toString(),
                },

                {
                    op: "replace",
                    path: "/adminComment",
                    value: comment,
                },
            ])

            setError(undefined)
            onSet?.(transfer)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
})

TransferExpensesSetModal.displayName = "TransferExpensesSetModal"

export default TransferExpensesSetModal
