import { ReadonlyDate } from "my-util/type"

export function createOrCopyDate(date?: ReadonlyDate | null): Date {
    return date != null
         ? new Date(date.getTime())
         : new Date()
}

export function tryCopyDate(date?: ReadonlyDate | null): Date | null {
    return date != null
         ? new Date(date.getTime())
         : null
}
