import { ForwardedRef, forwardRef } from "react"
import { getUserRoleAbbr, getUserRoleColor, UserRole } from "model"

export interface UserRoleAbbrProps {
    role: UserRole

    colorful?: boolean
}

const UserRoleAbbr = forwardRef((
    {
        role,
        colorful,
    }: Readonly<UserRoleAbbrProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span style={{ color: colorful ? getUserRoleColor(role) : undefined }}
          ref={ref}>
        {getUserRoleAbbr(role)}
    </span>
)

UserRoleAbbr.displayName = "UserRoleAbbr"

export default UserRoleAbbr
