import { z } from "zod"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewUserNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal("new-user" satisfies NotificationTypeResponse),
    userId: UuidSchema,
})

export type NewUserNotificationResponse = z.infer<typeof NewUserNotificationResponseSchema>
