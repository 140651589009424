import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import { UserContext } from "ui/context"
import { MAIN_PAGE_PATH } from "ui/page/sections/MainPage/path"
import { NotificationModal } from "ui/ui"

export default function SuccessNotification() {
    const [t] = useTranslation()

    const [,, refetchUser] = useContext(UserContext)

    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        return () => {
            if (clicked)
                refetchUser()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clicked])

    if (clicked)
        return <Navigate to={MAIN_PAGE_PATH}/>

    return <NotificationModal onClose={() => setClicked(true)}>
        {t("auth.reg.messages.waitAdminResponse")}
    </NotificationModal>
}
