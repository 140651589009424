import { z } from "zod"
import { CompanyMessageSchema } from "api/message"
import { UserRole, UserStatus } from "model"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"
import { AnonymousUserResponseSchema } from "./anonymous-user"

// Status

export const UserStatusResponseSchema = z.enum([
    "active",
    "unverified",
    "blocked",
] satisfies [UserStatus, ...UserStatus[]])

export type UserStatusResponse = z.infer<typeof UserStatusResponseSchema>

// Role

export const UserRoleResponseSchema = z.enum([
    "client",
    "agent",
    "manager",
    "admin",
] satisfies [UserRole, ...UserRole[]])

export type UserRoleResponse = z.infer<typeof UserRoleResponseSchema>

// User

export const UserResponseSchema = z.object({
    phone: z.string().nullish(),
    email: z.string().nullish(),

    company: CompanyMessageSchema.nullish(),

    status: UserStatusResponseSchema.nullish(),
    role: UserRoleResponseSchema.nullish(),

    creatorId: UuidSchema.nullish(),

    canManageTransfers: z.coerce.boolean(),
    canManageInvites: z.coerce.boolean(),
    canManageUsers: z.coerce.boolean(),
    canSeeAllUsers: z.coerce.boolean(),
    visibleUserIds: UuidSchema.array().nullish(),

    notifyOnNewChatMessage: z.coerce.boolean(),
    notifyOnNewInviteApplication: z.coerce.boolean(),
    notifyOnNewInvite: z.coerce.boolean(),
    notifyOnNewTransfer: z.coerce.boolean(),
    notifyOnNewUser: z.coerce.boolean(),
    notifyOnTransferStatusChanged: z.coerce.boolean(),
})
    .merge(AnonymousUserResponseSchema)
    .merge(ModelObjectResponseSchema)

export type UserResponse = z.infer<typeof UserResponseSchema>
