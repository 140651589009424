import { z } from "zod"
import { UuidSchema } from "my-util"
import { ChatResponseTypeSchema } from "./type"

export const ChatResponseBaseSchema = z.object({
    requestId: UuidSchema.nullish(),
    type: ChatResponseTypeSchema,
})

export type ChatResponseBase = z.infer<typeof ChatResponseBaseSchema>
