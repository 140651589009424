import { ForwardedRef, forwardRef } from "react"
import { arrowUpIconUrl } from "image"
import Icon from "../Icon"

export const EXPORT_ICON_SRC = arrowUpIconUrl
export const EXPORT_ICON_FILTER = "brightness(0) saturate(100%) invert(33%) sepia(92%) saturate(1574%) hue-rotate(203deg) brightness(87%) contrast(82%)"
export const EXPORT_ICON_ALT = "Export icon"

export interface ExportIconProps {
    onClick?: () => void

    cursor?: string

    width?: string
    height?: string
}

const ExportIcon = forwardRef((
    {
        onClick,
        cursor,
        width, height,
    }: Readonly<ExportIconProps>,
    ref: ForwardedRef<HTMLImageElement>,
) => 
    <Icon src={EXPORT_ICON_SRC}
          filter={EXPORT_ICON_FILTER}
          alt={EXPORT_ICON_ALT}

          onClick={onClick}

          cursor={cursor}

          width={width}
          height={height}

          ref={ref}/>
)

ExportIcon.displayName = "ExportIcon"

export default ExportIcon
