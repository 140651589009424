import { t } from "i18next"

export function joinGrammatically(
    strings: readonly string[],
    lastSep: string = t("misc.words.or"),
): string {
    switch (strings.length) {
        case 0:
            return ""

        case 1:
            return strings[0]

        default:
            const firstJoined = strings
                .slice(0, strings.length - 1)
                .join(", ")

            const last = strings[strings.length - 1]

            return `${firstJoined} ${lastSep} ${last}`
    }
}
