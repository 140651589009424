import { ForwardedRef, MutableRefObject, useRef,
         ReactNode, useImperativeHandle, forwardRef } from "react"

import { DeepReadonly } from "my-util"
import { Flex } from "ui/ui"
import style from "./style.module.css"

export interface WidgetProps {
    onTitleClick?: () => void
    title?: ReactNode

    children?: ReactNode
    right?: ReactNode

    backgroundColor?: string

    maxContentWidth?: string

    width?: string
    height?: string
}

export interface WidgetRef {
    component: MutableRefObject<HTMLDivElement>
    title: MutableRefObject<HTMLDivElement>
    content: MutableRefObject<HTMLDivElement>
}

const Widget = forwardRef((
    {
        onTitleClick, title,
        children, right,
        backgroundColor,
        maxContentWidth,
        width, height,
    }: DeepReadonly<WidgetProps>,
    ref: ForwardedRef<WidgetRef>,
) => {
    // Refs

    const componentRef = useRef(null as HTMLDivElement | null)
    const titleRef = useRef(null as HTMLDivElement | null)
    const contentRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => ({
        component: componentRef as MutableRefObject<HTMLDivElement>,
        title: titleRef as MutableRefObject<HTMLDivElement>,
        content: contentRef as MutableRefObject<HTMLDivElement>,
    }), [])

    // Render

    return <Flex ref={componentRef}
                 height={height}
                 width={width}
                 align="start"
                 gap="4px">
        {title &&
            <div style={{ cursor: onTitleClick != null ? "pointer" : undefined }}
                 className={style.title}
                 onClick={onTitleClick}
                 ref={titleRef}>
                {title}
            </div>
        }

        <Flex direction="horizontal"
              align="start"
              gap="8px">
            <div style={{ backgroundColor, maxWidth: maxContentWidth }}
                 className={style.content}
                 ref={contentRef}>
                {children}
            </div>

            {right}
        </Flex>
    </Flex>
})

Widget.displayName = "Widget"

export default Widget
