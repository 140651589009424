import assert from "assert"
import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getRegistrationConfig } from "api"
import { MessageTargetType, User } from "model"
import { UserContext } from "ui/context"
import { createLoginPagePath } from "ui/page/LoginPage/path"
import { MAIN_PAGE_PATH } from "ui/page/sections"
import { ErrorDisplay, Loading } from "ui/ui"
import StartPane from "./StartPane"
import CompletePane from "./CompletePane"
import SuccessPane from "./SuccessPane"
import WaitPane from "./WaitPane"

type State =
    | "start"
    | "complete"
    | "success"
    | "wait"

export interface RegistrationPaneProps {
    inviteId: string
}

export default function RegistrationPane({ inviteId }: Readonly<RegistrationPaneProps>) {
    const [,, refetchUser] = useContext(UserContext)

    const navigate = useNavigate()

    // State

    const [state, setState] = useState("start" satisfies State as State)

    const [isLoginRequiredAfter, setIsLoginRequiredAfter] = useState(true)

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(undefined as unknown)

    const messageTargetRef = useRef("")
    const messageTargetTypeRef = useRef(null as MessageTargetType | null)

    const userRef = useRef(null as User | null)
    const passwordRef = useRef("")

    // Config loading

    useEffect(() => {
        const controller = new AbortController()

        getRegistrationConfig(controller.signal)
            .then(({ isLoginRequiredAfter }) => setIsLoginRequiredAfter(isLoginRequiredAfter))
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    }, [])

    // Render

    if (loading)
        return <Loading/>

    if (error)
        return <ErrorDisplay error={error}/>

    switch (state) {
        case "start":
            return <StartPane
                inviteId={inviteId}
                onSuccess={(newMessageTarget, newMessageTargetType) => {
                    messageTargetRef.current = newMessageTarget
                    messageTargetTypeRef.current = newMessageTargetType

                    setState("complete")
                }}
            />

        case "complete":
            return <CompletePane
                messageTarget={messageTargetRef.current}
                onSuccess={(user, password) => {
                    userRef.current = user
                    passwordRef.current = password

                    setState("success")
                }}
            />

        case "success":
            assert(userRef.current != null)

            return <SuccessPane
                user={userRef.current}
                password={passwordRef.current}
                onSuccess={() => {
                    const user = userRef.current

                    if (!isLoginRequiredAfter) {
                        navigate(MAIN_PAGE_PATH)
                        refetchUser()
                        return
                    }

                    if (user?.status === "active") {
                        navigate(createLoginPagePath(user.id))
                        return
                    }
                
                    setState("wait")
                }}
            />

        case "wait":
            return <WaitPane/>
    }
}
