import { ForwardedRef, forwardRef } from "react"
import { TransferGroup } from "my-util"
import { Flex, FlexProps } from "ui/ui/layout"
import TransferGroupColorLegend from "../TransferGroupColorLegend"

export interface TransferGroupColorLegendListProps extends Omit<FlexProps, "children"> {
    onClick?: (group: TransferGroup) => void

    hideNew?: boolean
    hideInWork?: boolean
    hideToday?: boolean
    hideDone?: boolean
    hideRejected?: boolean

    showLegend?: boolean
}

const TransferGroupColorLegendList = forwardRef((
    props: Readonly<TransferGroupColorLegendListProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        onClick,
        hideNew, hideInWork, hideToday, hideDone, hideRejected,
        showLegend,
    } = props

    return <Flex direction="horizontal"
                 width="fit-content"
                 { ...props }
                 ref={ref}>
        {!hideNew &&
            <TransferGroupColorLegend showLegend={showLegend}
                                      onClick={onClick}
                                      group="new"/>
        }

        {!hideInWork &&
            <TransferGroupColorLegend showLegend={showLegend}
                                      onClick={onClick}
                                      group="in-work"/>
        }

        {!hideToday &&
            <TransferGroupColorLegend showLegend={showLegend}
                                      onClick={onClick}
                                      group="today"/>
        }

        {!hideDone &&
            <TransferGroupColorLegend showLegend={showLegend}
                                      onClick={onClick}
                                      group="done"/>
        }

        {!hideRejected &&
            <TransferGroupColorLegend showLegend={showLegend}
                                      onClick={onClick}
                                      group="rejected"/>
        }
    </Flex>
})

TransferGroupColorLegendList.displayName = "TransferGroupColorLegendList"

export default TransferGroupColorLegendList
