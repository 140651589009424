import { z } from "zod"
import { UuidSchema } from "my-util"

export const ModelObjectResponseSchema = z.object({
    id: UuidSchema,
    createdAt: z.coerce.date(),
    modifiedAt: z.coerce.date(),
})

export type ModelObjectResponse = z.infer<typeof ModelObjectResponseSchema>
