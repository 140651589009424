import { CSSProperties, ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export interface InformationProps {
    type?: InformationType
    text?: string
    fontWeight?: string
    fontSize?: string
    whiteSpace?: CSSProperties["whiteSpace"]
}

export type InformationType =
    | "regular"
    | "error"
    | "warning"
    | "success"

const Information = forwardRef((
    {
        type,
        text,
        fontSize,
        fontWeight,
        whiteSpace,
    }: Readonly<InformationProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <span
        className={style[`Information-${type ?? "regular"}`]}
        style={{
            display: text ? undefined : "none",
            fontWeight,
            fontSize,
            whiteSpace,
        }}
        ref={ref}
    >
        {text}
    </span>
})

Information.displayName = "Information"

export default Information
