import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export interface HoveringProps {
    children?: ReactNode

    borderRadius?: string

    width?: string
    height?: string
}

const Hovering = forwardRef((
    {
        children,
        borderRadius,
        width, height,
    }: DeepReadonly<HoveringProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div style={{ width, height, borderRadius }}
         className={style.Hovering}
         ref={ref}>
        {children}
    </div>
)

Hovering.displayName = "Hovering"

export default Hovering
