import { useContext } from "react"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import ClientMessenger from "./ClientMessengerPage"
import ManagerMessenger from "./ManagerMessengerPage"

export default function MessengerPage() {
    const [localUser] = useContext(UserContext)

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return localUser.isManager
         ? <ManagerMessenger/>
         : <ClientMessenger/>
}
