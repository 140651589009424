import { ReadonlyDate } from "my-util/type"

export function decDate(date: Date): Date {
    return subDate(date, 1)
}

export function dateDiff(date: ReadonlyDate, toSub: number): Date {
    return subDate(new Date(date.getTime()), toSub)
}

export function subDate(date: Date, toSub: number): Date {
    return addDate(date, -toSub)
}

export function incDate(date: Date): Date {
    return addDate(date, 1)
}

export function dateSum(date: ReadonlyDate, toAdd: number): Date {
    return addDate(new Date(date.getTime()), toAdd)
}

export function addDate(date: Date, toAdd: number): Date {
    date.setDate(date.getDate() + toAdd)
    return date
}
