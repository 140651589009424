import { t } from "i18next"
import { DeepReadonly } from "my-util/type"
import { incDate, subDate } from "./date/math"
import { WEEK_DAYS } from "./consts"
import { MonthIndex, getMonthDayName } from "./month"

export interface Week {
    start: WeekEdge
    end: WeekEdge
}

export interface WeekEdge {
    monthIndex: MonthIndex
    dayIndex: number
}

export const MONDAY_INDEX = 0
export const TUESDAY_INDEX = 1
export const WEDNESDAY_INDEX = 2
export const THURSDAY_INDEX = 3
export const FRIDAY_INDEX = 4
export const SATURDAY_INDEX = 5
export const SUNDAY_INDEX = 6

export const WEEK_DAY_INDEXES = [
    MONDAY_INDEX,
    TUESDAY_INDEX,
    WEDNESDAY_INDEX,
    THURSDAY_INDEX,
    FRIDAY_INDEX,
    SATURDAY_INDEX,
    SUNDAY_INDEX,
] as const

export type WeekDayIndex =
    | typeof MONDAY_INDEX
    | typeof TUESDAY_INDEX
    | typeof WEDNESDAY_INDEX
    | typeof THURSDAY_INDEX
    | typeof FRIDAY_INDEX
    | typeof SATURDAY_INDEX
    | typeof SUNDAY_INDEX

export function normalizeWeekDayIndex(weekDayIndex: number): WeekDayIndex {
    return (WEEK_DAYS + (weekDayIndex | 0) % WEEK_DAYS) % WEEK_DAYS as WeekDayIndex
}

export function getAllWeekDayNames(short: boolean = false): string[] {
    return WEEK_DAY_INDEXES.map(weekDayIndex => getWeekDayName(weekDayIndex, short))
}

export function getWeekDayName(weekDayIndex: WeekDayIndex, short: boolean = false): string {
    switch (weekDayIndex) {
        case MONDAY_INDEX:
            return short
                ? t("datetime.weekDayNames.monday.short")
                : t("datetime.weekDayNames.monday.full")
                         
        case TUESDAY_INDEX:
            return short
                ? t("datetime.weekDayNames.tuesday.short")
                : t("datetime.weekDayNames.tuesday.full")
                         
        case WEDNESDAY_INDEX:
            return short
                ? t("datetime.weekDayNames.wednesday.short")
                : t("datetime.weekDayNames.wednesday.full")
                         
        case THURSDAY_INDEX:
            return short
                ? t("datetime.weekDayNames.thursday.short")
                : t("datetime.weekDayNames.thursday.full")
                         
        case FRIDAY_INDEX:
            return short
                ? t("datetime.weekDayNames.friday.short")
                : t("datetime.weekDayNames.friday.full")
                         
        case SATURDAY_INDEX:
            return short
                ? t("datetime.weekDayNames.saturday.short")
                : t("datetime.weekDayNames.saturday.full")
                         
        case SUNDAY_INDEX:
            return short
                ? t("datetime.weekDayNames.sunday.short")
                : t("datetime.weekDayNames.sunday.full")
                         
        default:
            return t("datetime.messages.errors.invalidWeekDayIndex")
    }
}

export function dateDayToWeekDayIndex(day: number): WeekDayIndex {
    day = normalizeWeekDayIndex(day)
    // Swaps sunday and monday
    return (day > 0 ? day - 1 : 6) as WeekDayIndex
}

export function getWeekDayIndex(
    year: number,
    monthIndex: MonthIndex,
    dayIndex: number,
): WeekDayIndex {
    return dateDayToWeekDayIndex(new Date(year, monthIndex, dayIndex + 1).getDay())
}

export function weekToString(week: DeepReadonly<Week>, short: boolean = false): string {
    const { start, end } = week

    return start.monthIndex !== end.monthIndex
        ? `${getMonthDayName(start.monthIndex, start.dayIndex + 1, short)} - ${getMonthDayName(end.monthIndex, end.dayIndex + 1, short)}`
        : `${start.dayIndex + 1}-${getMonthDayName(start.monthIndex, end.dayIndex + 1, short)}`
}

export function getWeekDayIndexes(
    year: number,
    monthIndex: MonthIndex,
    dayIndex: number,
): number[] {
    const date = new Date(year, monthIndex, dayIndex + 1)
    const day = date.getDay()
    const weekDayIndex = dateDayToWeekDayIndex(day)

    if (weekDayIndex > 0)
        subDate(date, weekDayIndex)

    const weekDayIndexes = new Array<number>()

    for (let i = 0; i < WEEK_DAYS; ++i) {
        const dayIndex = date.getDate() - 1

        weekDayIndexes.push(dayIndex)

        incDate(date)
    }

    return weekDayIndexes
}
