import assert from "assert"
import { ForwardedRef, forwardRef, Fragment, ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { arrowsLeftRightIconUrl } from "image"
import { Money, User } from "model"
import { join, isNullOrBlank, DeepReadonly, normalizeUrl } from "my-util"
import { copyTransferFields, TransferFields } from "ui/fields"
import { createUserPagePath } from "ui/page"

import { DocumentListUpload, FieldDocument,
         RoutePointListEditor, UiDocument, DocumentUpload } from "ui/component"

import { Form, FormControls, FormControlsButton, Link,
         Label, Flex, TransferDirectionRadio, MoneyOutput, Icon,
         Subheader, TimeOutput, DecimalOutput, DateOutput, Output } from "ui/ui"

import style from "./style.module.css"

export interface TransferViewFormProps {
    onSubmit?: () => void
    onReset?: () => void
    onChange?: (fields: TransferFields) => void

    fields: TransferFields
    buttons?: FormControlsButton[]

    users?: Map<string, User> | Iterable<User>

    loading?: boolean
    disabled?: boolean
    error?: unknown

    width?: string
    height?: string
}

const TransferViewForm = forwardRef((
    {
        onSubmit, onReset, onChange,
        fields, buttons,
        users,
        loading, disabled, error,
        width, height,
    }: DeepReadonly<TransferViewFormProps>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    const [t] = useTranslation()

    const usersById = useMemo(() => User.groupByIdOrPassOrCreate(users,), [users])

    // Render

    return <Form onSubmit={onSubmit}
                 onReset={onReset}

                 loading={loading}

                 width={width}
                 height={height}

                 ref={ref}>
        <Flex justify="space-between"
              height="100%">
            <div className={style.fields}>
                {renderApplicant()}
                {renderAgent()}
                {renderDirection()}
                {renderMoment()}

                {renderRecipientSubheader()}
                {renderCountry()}
                {renderRecipient()}
                {renderMoney()}
                {renderBank()}
                {renderAccounts()}

                {renderProductsSubheader()}
                {renderProducts()}

                {renderRouteSubheader()}
                {renderRoutePoints()}

                {renderCurrencyRateSubheader()}
                {renderCurrencyRate()}

                {renderSharesAndExpensesSubheader()}
                {renderAttorneyFee()}
                {renderAgentPercent()}
                {renderExpenses()}

                {renderDocumentsSubheader()}
                {renderInvoice()}
                {renderContract()}
                {renderDocuments()}

                {renderCommentsSubheader()}
                {renderComment()}
                {renderAdminComment()}
            </div>

            {renderControls()}
        </Flex>
    </Form>

    function renderApplicant(): ReactNode {
        const creator = usersById.get(fields.creatorId)

        if (creator == null)
            return null

        const applicant = creator.company.name ?? creator.name

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.applicant")}/>
            </div>

            <div className={style.column2To4Value}>
                <Output>
                    <Link to={createUserPagePath(creator.id)}
                          text={applicant}/>
                </Output>
            </div>
        </>
    }

    function renderAgent(): ReactNode {
        const creator = usersById.get(fields.creatorId)

        if (creator?.creatorId == null)
            return null

        const agent = usersById.get(creator.creatorId)

        if (agent == null || !agent.isAgent)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.users.labels.agent")}/>
            </div>

            <div className={style.column2To4Value}>
                <Output>
                    <Link to={createUserPagePath(agent.id)}
                          text={agent.name}/>
                </Output>
            </div>
        </>
    }

    function renderDirection(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.direction")}/>
            </div>

            <div className={style.direction}>
                <TransferDirectionRadio checked={fields.direction}
                                        exportHidden={fields.direction === "import"}
                                        importHidden={fields.direction === "export"}
                                        hideButton/>
            </div>
        </>
    }

    function renderMoment(): ReactNode {
        const { moment } = fields

        if (moment == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.date")}/>
            </div>

            <div className={style.column2Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <DateOutput date={moment}/>

                    <Label text={t("datetime.labels.time")}/>

                    <TimeOutput date={moment}/>
                </Flex>
            </div>
        </>
    }

    function renderRecipientSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.recipient")}/>
        </div>
    }

    function renderCountry(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.country")}/>
            </div>

            <div className={style.column2To4Value}>
                <Output>{fields.country}</Output>
            </div>
        </>
    }

    function renderRecipient(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.name.full")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.name}</Output>
            </div>

            {/* ITN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.itn")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.itn}</Output>
            </div>
        </>
    }

    function renderMoney(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.money")}/>
            </div>

            <div className={style.column2To4Value}>
                <MoneyOutput value={fields.money}/>
            </div>
        </>
    }

    function renderBank(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.name")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.bank.name}</Output>
            </div>

            {/* Swift */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.swift")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.bank.swift}</Output>
            </div>

            {/* Address */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.address")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.bank.address}</Output>
            </div>

            {/* IBAN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.iban")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.iban}</Output>
            </div>
        </>
    }

    function renderAccounts(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.correspondentAccount.short")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.correspondentAccount}</Output>
            </div>

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.paymentAccount.short")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.paymentAccount}</Output>
            </div>
        </>
    }

    function renderProductsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.products")}/>
        </div>
    }

    function renderProducts(): ReactNode {
        const { products } = fields

        if (products.length === 0)
            return

        return products.map(({ name, cnfeaCodes }, i) => {
            const joinedCNFEACodes = join(cnfeaCodes, ", ")
            const key = `${i}-${name}-${joinedCNFEACodes}`

            return <Fragment key={key}>
                {/* Name */}

                <div className={style.column1Label}>
                    <Label text={t("domain.products.labels.name")}/>
                </div>

                <div className={style.column2Value}>
                    <Output>{name}</Output>
                </div>

                {/* CN FEA codes */}

                <div className={style.column3Label}>
                    <Label text={t("domain.products.labels.cnfeaCodes")}/>
                </div>

                <div className={style.column4Value}>
                    <Output>{joinedCNFEACodes}</Output>
                </div>
            </Fragment>
        })
    }

    function renderRouteSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.route")}/>
        </div>
    }

    function renderRoutePoints(): ReactNode {
        const { routePoints } = fields

        if (routePoints.length === 0)
            return null

        return <div className={style.column1To4Value}>
            <RoutePointListEditor routePoints={routePoints}
                                  output/>
        </div>
    }

    function renderCurrencyRateSubheader(): ReactNode {
        if (fields.currencyRate == null)
            return null

        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.currencyRate")}/>
        </div>
    }

    function renderCurrencyRate(): ReactNode {
        const { currencyRate, money } = fields

        if (currencyRate == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.currencyRate")}/>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <MoneyOutput value={currencyRate.money}
                                 precision={4}/>

                    <Icon src={arrowsLeftRightIconUrl}
                          alt="Left and right arrows"
                          filter="brightness(0) invert(50%)"/>

                    <MoneyOutput value={new Money({ currency: money.currency, amount: 1 })}
                                 precision={4}/>
                </Flex>
            </div>

            <div className={style.column1Label}>
                <Label text={t("domain.currencyRates.labels.date")}/>
            </div>

            <div className={style.column2Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <DateOutput date={currencyRate.moment}/>

                    <Label text={t("datetime.labels.time")}/>

                    <TimeOutput date={currencyRate.moment}/>
                </Flex>
            </div>

            {currencyRate.evidence != null && <>
                <div className={style.column1Label}>
                    <Label text={t("domain.currencyRates.labels.evidence")}/>
                </div>

                <div className={style.column2To4Value}>
                    <DocumentUpload document={currencyRate.evidence}
                                    onChange={onCurrencyRateEvidenceChange}
                                    readonly/>
                </div>
            </>}

            {currencyRate.evidenceLink && <>
                <div className={style.column1Label}>
                    <Label text={t("domain.currencyRates.labels.evidenceLink")}/>
                </div>

                <div className={style.column2Value}>
                    <Link to={normalizeUrl(currencyRate.evidenceLink)}
                          text={currencyRate.evidenceLink}/>
                </div>
            </>}
        </>
    }

    function renderSharesAndExpensesSubheader(): ReactNode {
        if (fields.attorneyFee == null && fields.agentPercent == null && fields.expenses == null)
            return null

        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.sharesAndExpenses")}/>
        </div>
    }

    function renderAttorneyFee(): ReactNode {
        const { attorneyFee } = fields

        if (attorneyFee == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.attorneyFee")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <DecimalOutput value={attorneyFee.amount}
                               right="₽"/>
            </div>
        </>
    }

    function renderAgentPercent(): ReactNode {
        const { agentPercent } = fields

        if (agentPercent == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.agentPercent")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <DecimalOutput value={agentPercent}
                               precision={4}
                               right="%"/>
            </div>
        </>
    }

    function renderExpenses(): ReactNode {
        const { expenses } = fields

        if (expenses == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.expenses")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <DecimalOutput value={expenses}
                               right="₽"/>
            </div>
        </>
    }

    function renderDocumentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.documents")}/>
        </div>
    }

    function renderInvoice(): ReactNode {
        return <>
            {/* Date and number */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.invoice.number")}/>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <Output>{fields.invoice.number}</Output>

                    <Label text={t("domain.transfers.labels.invoice.date")}/>

                    <DateOutput date={fields.invoice.date}/>
                </Flex>
            </div>

            {/* Document */}

            {fields.invoice.document != null && <>
                <div className={style.column1Label}>
                    <Label text={t("domain.transfers.labels.invoice.document")}/>
                </div>

                <div className={style.column2To4Value}>
                    <DocumentUpload onChange={onInvoiceDocumentChange}
                                    document={fields.invoice.document}
                                    readonly/>
                </div>
            </>}
        </>
    }

    function renderContract(): ReactNode {
        return <>
            {/* Date and number */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.contract.number")}/>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <Output>{fields.contract.number}</Output>

                    <Label text={t("domain.transfers.labels.contract.date")}/>

                    <DateOutput date={fields.contract.date}/>
                </Flex>
            </div>

            {/* Document */}

            {fields.contract.document != null && <>
                <div className={style.column1Label}>
                    <Label text={t("domain.transfers.labels.contract.document")}/>
                </div>

                <div className={style.column2To4Value}>
                    <DocumentUpload onChange={onContractDocumentChange}
                                    document={fields.contract.document}
                                    readonly/>
                </div>
            </>}
        </>
    }

    function renderDocuments(): ReactNode {
        const { documents } = fields

        if (documents.length === 0)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.documents")}/>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload onChange={onDocumentsChange}
                                    documents={documents}
                                    readonly/>
            </div>
        </>
    }

    function renderCommentsSubheader(): ReactNode {
        if (isNullOrBlank(fields.comment) && isNullOrBlank(fields.adminComment))
            return null

        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.comments")}/>
        </div>
    }

    function renderComment(): ReactNode {
        const { comment } = fields

        if (isNullOrBlank(comment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.comment")}/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {comment}
                </Output>
            </div>
        </>
    }

    function renderAdminComment(): ReactNode {
        const { adminComment } = fields

        if (isNullOrBlank(adminComment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.adminComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {adminComment}
                </Output>
            </div>
        </>
    }

    function renderControls(): ReactNode {
        return <FormControls buttons={buttons}
                             disabled={disabled}
                             loading={loading}
                             error={error}/>
    }

    // Events

    function onInvoiceDocumentChange(newDocument: UiDocument) {
        if (onChange == null ||
            (newDocument.status !== "ready" && newDocument.status !== "loading"))
            return

        const newFields = copyTransferFields(fields)

        newFields.invoice.document = newDocument

        onChange(newFields)
    }

    function onContractDocumentChange(newDocument: UiDocument) {
        if (onChange == null ||
            (newDocument.status !== "ready" && newDocument.status !== "loading"))
            return

        const newFields = copyTransferFields(fields)

        newFields.contract.document = newDocument

        onChange(newFields)
    }

    function onDocumentsChange(newDocuments: UiDocument[]) {
        if (onChange == null ||
            newDocuments.some(({ status }) => status !== "ready" && status !== "loading"))
            return

        const newFields = copyTransferFields(fields)

        newFields.documents = newDocuments as FieldDocument[]

        onChange(newFields)
    }

    function onCurrencyRateEvidenceChange(newEvidence: UiDocument) {
        if (onChange == null ||
            fields.currencyRate == null ||
            (newEvidence.status !== "ready" && newEvidence.status !== "loading"))
            return

        const newFields = copyTransferFields(fields)

        assert(newFields.currencyRate != null)

        newFields.currencyRate.evidence = newEvidence

        onChange(newFields)
    }
})

TransferViewForm.displayName = "TransferViewForm"

export default TransferViewForm
