import convertLayout from "convert-layout/ru"
import { join, map } from "my-util/iterable/func"

export interface FixLayoutOptions {
    whitelist?: string
    all?: boolean
    reverse?: boolean
}

export function fixLayout(text: string, options?: Readonly<FixLayoutOptions>): string {
    const { whitelist, all, reverse } = options ?? {}

    return join(
        map(
            text,

            char => {
                const convert =
                    all ||
                    whitelist?.includes(char) ||
                    char.match(/^\p{L}/u) != null

                return convert
                    ? (reverse ? convertLayout.fromEn : convertLayout.toEn)(char)
                    : char
            },
        )
    )
}
