import { createOrCopyDate, DeepReadonly, ReadonlyDate } from "my-util"
import Money, { MoneyCreationOptions, MoneyCopyOptions } from "./Money"

export interface CurrencyRateCreationOptions {
    moment?: Date | null
    money?: Money | MoneyCreationOptions | null
    evidenceId?: string | null
    evidenceLink?: string | null
}

export interface CurrencyRateCopyOptions extends CurrencyRateCreationOptions {
    money?: Money | MoneyCopyOptions | null
}

export default class CurrencyRate {
    static createOrPass(
        arg?: CurrencyRate
            | DeepReadonly<CurrencyRateCreationOptions>
            | null,
    ): CurrencyRate {
        if (arg == null)
            return new CurrencyRate()

        if (arg instanceof CurrencyRate)
            return arg

        return new CurrencyRate(arg)
    }

    readonly moment: ReadonlyDate
    readonly money: Money
    readonly evidenceId: string | null
    readonly evidenceLink: string | null

    constructor(
        {
            moment,
            money,
            evidenceId,
            evidenceLink,
        }: DeepReadonly<CurrencyRateCreationOptions> = {}
    ) {
        this.moment = createOrCopyDate(moment)
        this.money = Money.createOrPass(money)
        this.evidenceId = evidenceId ?? null
        this.evidenceLink = evidenceLink ?? null
    }

    copyOrPass(
        arg?: CurrencyRate
            | DeepReadonly<CurrencyRateCopyOptions>
            | null,
    ): CurrencyRate {
        if (arg == null)
            return this

        if (arg instanceof CurrencyRate)
            return arg

        return this.copy(arg)
    }

    copy(options: DeepReadonly<CurrencyRateCopyOptions> = {}): CurrencyRate {
        return new CurrencyRate({
            moment: options.moment ?? this.moment,
            money: this.money.copyOrPass(options.money),

            evidenceId: options.evidenceId !== undefined
                ? options.evidenceId
                : this.evidenceId,

            evidenceLink: options.evidenceLink !== undefined
                ? options.evidenceLink
                : this.evidenceLink,
        })
    }
}
