import { z } from "zod"
import { UuidSchema } from "my-util"
import { ChatResponseType } from "./type"
import { ChatResponseBaseSchema } from "./base"

export const MarkChatMessagesReadResponseSchema = ChatResponseBaseSchema.extend({
    type: z.literal("mark-read" satisfies ChatResponseType),

    messageIds: UuidSchema.array(),
})

export type MarkChatMessagesReadResponse = z.infer<typeof MarkChatMessagesReadResponseSchema>
