import { getLang } from "i18n"

import AbstractModelObject,
      { AbstractModelObjectCopyOptions,
        AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { Named, WithCompanyName, WithCreatorId, WithManyMessageTargets } from "model/interfaces"
import { NamedMixin, NamedMixinCopyOptions, NamedMixinCreationOptions } from "model/mixins"
import { DeepReadonly, Nullable, Nullish } from "my-util"

export interface ProviderCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        NamedMixinCreationOptions,
        Nullish<WithManyMessageTargets>,
        Nullish<WithCompanyName>,
        Nullish<WithCreatorId>
{}

export interface ProviderCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        NamedMixinCopyOptions,
        Nullish<WithManyMessageTargets>,
        Nullish<WithCompanyName>,
        Nullish<WithCreatorId>
{}

export default class Provider
    extends
        AbstractModelObject

    implements
        Readonly<Nullable<WithCompanyName>>,
        Readonly<Nullable<WithCreatorId>>,
        Readonly<WithManyMessageTargets>,
        Readonly<Named>
{
    static createOrPass(arg: Provider | DeepReadonly<ProviderCreationOptions>): Provider {
        return arg instanceof Provider
            ? arg
            : new Provider(arg)
    }

    readonly creatorId: string | null

    readonly phone: string | null
    readonly email: string | null

    readonly enCompany: string | null
    readonly ruCompany: string | null

    private readonly namedMixin: NamedMixin

    constructor(options: DeepReadonly<ProviderCreationOptions>) {
        super(options)

        this.creatorId = options.creatorId ?? null

        this.phone = options.phone ?? null
        this.email = options.email ?? null

        this.enCompany = options.enCompany ?? null
        this.ruCompany = options.ruCompany ?? null

        this.namedMixin = new NamedMixin(options)
    }

    // Creator id

    getCreatorIdColor(opacity?: number | null): string {
        return AbstractModelObject.getIdColor(this.creatorId ?? "", opacity)
    }

    // Name

    // - En

    get enFirstname(): string {
        return this.namedMixin.enFirstname
    }

    get enLastname(): string {
        return this.namedMixin.enLastname
    }

    get enPatronymic(): string | null {
        return this.namedMixin.enPatronymic
    }

    get enName(): string {
        return this.namedMixin.enName
    }

    get enFormalName(): string {
        return this.namedMixin.enFormalName
    }

    // - Ru

    get ruFirstname(): string {
        return this.namedMixin.ruFirstname
    }

    get ruLastname(): string {
        return this.namedMixin.ruLastname
    }

    get ruPatronymic(): string | null {
        return this.namedMixin.ruPatronymic
    }

    get ruName(): string {
        return this.namedMixin.ruName
    }

    get ruFormalName(): string {
        return this.namedMixin.ruFormalName
    }

    // - Current locale

    get firstname(): string {
        return this.namedMixin.firstname
    }

    get lastname(): string {
        return this.namedMixin.lastname
    }

    get patronymic(): string | null {
        return this.namedMixin.patronymic
    }

    get name(): string {
        return this.namedMixin.name
    }

    get formalName(): string {
        return this.namedMixin.formalName
    }

    // Company

    get company(): string | null {
        return getLang() === "ru"
            ? this.ruCompany
            : this.enCompany
    }

    get anyCompany(): string | null {
        return getLang() === "ru"
            ? this.ruCompany ?? this.enCompany
            : this.enCompany ?? this.ruCompany
    }

    // Copy

    copyOrPass(arg?: Provider | DeepReadonly<ProviderCopyOptions> | null): Provider {
        if (arg == null)
            return this

        if (arg instanceof Provider)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<ProviderCopyOptions> = {}): Provider {
        return new Provider({
            // WithCreatorId

            creatorId: options.creatorId !== undefined
                ? options.creatorId
                : this.creatorId,

            // WithManyMessageTargets

            phone: options.phone !== undefined
                ? options.phone
                : this.phone,

            email: options.email !== undefined
                ? options.email
                : this.email,

            // WithCompanyName

            enCompany: options.enCompany !== undefined
                ? options.enCompany
                : this.enCompany,

            ruCompany: options.ruCompany !== undefined
                ? options.ruCompany
                : this.ruCompany,

            // Named

            enFirstname: options.enFirstname ?? this.enFirstname,
            enLastname: options.enLastname ?? this.enLastname,

            enPatronymic: options.enPatronymic !== undefined
                ? options.enPatronymic
                : this.enPatronymic,

            ruFirstname: options.ruFirstname ?? this.ruFirstname,
            ruLastname: options.ruLastname ?? this.ruLastname,

            ruPatronymic: options.ruPatronymic !== undefined
                ? options.ruPatronymic
                : this.ruPatronymic,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
