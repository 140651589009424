import { z } from "zod"

import { TransferRecipientMessageSchema, MoneyMessageSchema,
         LegalDocumentMessageSchema, CurrencyRateMessageSchema } from "api/message"

import { TransferDirection, TransferStatus } from "model"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"
import { ProductResponseSchema } from "./product"
import { RoutePointResponseSchema } from "./route-point"

// Direction

export const TransferDirectionResponseSchema = z.enum([
    "export",
    "import",
] satisfies [TransferDirection, ...TransferDirection[]])

export type TransferDirectionResponse = z.infer<typeof TransferDirectionResponseSchema>

// Status

export const TransferStatusResponseSchema = z.enum([
    "new",
    "waiting-moment",
    "waiting-payment",
    "paid",
    "done",
    "payment-expired",
    "rejected",
] satisfies [TransferStatus, ...TransferStatus[]])

export type TransferStatusResponse = z.infer<typeof TransferStatusResponseSchema>

// Transfer

export const TransferResponseSchema = ModelObjectResponseSchema.extend({
    creatorId: UuidSchema,
    direction: TransferDirectionResponseSchema,
    country: z.string(),
    recipient: TransferRecipientMessageSchema,

    money: MoneyMessageSchema,
    currencyRate: CurrencyRateMessageSchema.nullish(),

    invoice: LegalDocumentMessageSchema,
    contract: LegalDocumentMessageSchema,

    expenses: z.string().nullish(),
    agentPercent: z.string().nullish(),
    attorneyFee: MoneyMessageSchema.nullish(),

    comment: z.string().nullish(),
    adminComment: z.string().nullish(),

    moment: z.coerce.date().nullish(),

    status: TransferStatusResponseSchema,

    documentIds: UuidSchema.array(),
    products: ProductResponseSchema.array(),
    routePoints: RoutePointResponseSchema.array(),
})

export type TransferResponse = z.infer<typeof TransferResponseSchema>
