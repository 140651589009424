import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import ErrorPage from "../ErrorPage"

export interface Error404PageProps {
    message?: string
}

const Error404Page = forwardRef((
    { message }: Readonly<Error404PageProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <ErrorPage
        header={{
            text: t("errors.pages.404.header"),
            fontSize: "36px",
        }}
        message={{
            text: message ?? t("errors.pages.404.defaultMessage"),
            fontSize: "16px",
        }}
        ref={ref}
    />
})

Error404Page.displayName = "Error404Page"

export default Error404Page
