import { Named } from "model/interfaces"
import { NamedMixin, NamedMixinCopyOptions, NamedMixinCreationOptions } from "model/mixins"
import { DeepReadonly } from "my-util"

import AbstractNotification,
       { NotificationType,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

export interface NewAnonymousChatMessagesNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">
{
    from?: NamedMixin | NamedMixinCreationOptions | null
    count?: number | null
}

export interface NewAnonymousChatMessagesNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">
{
    from?: NamedMixin | NamedMixinCopyOptions | null
    count?: number | null
}

export default class NewAnonymousChatMessagesNotification
    extends
        AbstractNotification<"new-chat-messages">
{
    static createOrPass(
        arg: NewAnonymousChatMessagesNotification
           | DeepReadonly<NewAnonymousChatMessagesNotificationCreationOptions>
    ): NewAnonymousChatMessagesNotification {
        return arg instanceof NewAnonymousChatMessagesNotification
            ? arg
            : new NewAnonymousChatMessagesNotification(arg)
    }

    readonly count: number

    private readonly fromMixin: NamedMixin | null

    constructor(options: DeepReadonly<NewAnonymousChatMessagesNotificationCreationOptions>) {
        super({ ...options, type: "new-chat-messages" })

        this.count = options.count ?? 1

        this.fromMixin = options.from != null
            ? NamedMixin.createOrPass(options.from)
            : null
    }

    get from(): Named | null {
        return this.fromMixin
    }

    override copyOrPass(
        arg?: NewAnonymousChatMessagesNotification
            | DeepReadonly<NewAnonymousChatMessagesNotificationCopyOptions>
            | null,
    ): NewAnonymousChatMessagesNotification {
        if (arg == null)
            return this

        if (arg instanceof NewAnonymousChatMessagesNotification)
            return arg

        return this.copy(arg)
    }

    override copy(
        options: DeepReadonly<NewAnonymousChatMessagesNotificationCopyOptions> = {},
    ): NewAnonymousChatMessagesNotification {
        return new NewAnonymousChatMessagesNotification({
            from: this.fromMixin?.copyOrPass(options.from),

            count: options.count ?? this.count,

            // AbstractNotification

            recipientId: options.recipientId ?? this.recipientId,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
