import { z } from "zod"
import { UuidSchema } from "my-util"
import { ChatResponseType } from "./type"
import { ChatResponseBaseSchema } from "./base"

export const ChatRequestVerificationResponseSchema = ChatResponseBaseSchema.extend({
    requestId: UuidSchema,
    type: z.literal("request-verification" satisfies ChatResponseType),

    error: z.string().nullish(),
})

export type ChatRequestVerificationResponse = z.infer<typeof ChatRequestVerificationResponseSchema>
