import assert from "assert"
import { ForwardedRef, forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { createProvider, ProviderRequest } from "api"
import { Provider } from "model"
import { capitalizedText, EN_NAME_INPUT_REGEX, isBlank, RU_NAME_INPUT_REGEX } from "my-util"

import { validateRuName, validateEmail, validateEnName,
         MAX_SHORT_TEXT_LENGTH, validatePhone, validateShortText } from "validation"

import { ActionModal, DiLangInput, EmailInput, Flex, Limit, PhoneInput } from "ui/ui"

const MAX_WIDTH = "400px"

export interface ProviderCreationModalProps {
    onCreated?: (provider: Provider) => void
    onClose?: () => void

    closeOnCreated?: boolean

    width?: string
}

const ProviderCreationModal = forwardRef((
    {
        onCreated, onClose,
        closeOnCreated,
        width,
    }: Readonly<ProviderCreationModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    // - Name

    const [name, setName] = useState(undefined as { en: string, ru: string } | undefined)

    const nameInvalid =
        name == null ||
        validateRuName(name.ru) != null ||
        validateEnName(name.en) != null

    const nameInputInvalid = name != null && nameInvalid

    // - Company

    const [company, setCompany] = useState({ en: "", ru: "" })

    const companyInvalid =
        (!isBlank(company.en) && validateShortText(company.en) != null) ||
        (!isBlank(company.ru) && validateShortText(company.ru) != null)

    // - Phone

    const [phone, setPhone] = useState("")
    const phoneInvalid = !isBlank(phone) && validatePhone(phone) != null

    // - Email

    const [email, setEmail] = useState("")
    const emailInvalid = !isBlank(email) && validateEmail(email) != null

    // - Loading

    const [loading, setLoading] = useState(false)

    // Render

    const saveDisabled =
        nameInvalid ||
        companyInvalid ||
        phoneInvalid ||
        emailInvalid

    return <ActionModal header={t("domain.provider.headers.creation")}
                        yes={t("misc.buttons.save")}
                        no={t("misc.buttons.cancel")}

                        onYes={onCreate}
                        onNo={onClose}

                        yesDisabled={saveDisabled}

                        closeOnSuccess={closeOnCreated}

                        apiErrorMessageMapping={{
                            409: t("messageTargets.messages.errors.occupied.messageTarget")
                        }}

                        width={width}

                        ref={ref}>

        <Flex align="start">
            <DiLangInput onChange={(en, ru) => setName({ en, ru })}
                         enValue={name?.en}
                         ruValue={name?.ru}

                         postProcess={capitalizedText}

                         ruRegex={RU_NAME_INPUT_REGEX}
                         enRegex={EN_NAME_INPUT_REGEX}

                         label={t("domain.provider.labels.name")}
                         placeholder={t("domain.provider.placeholders.name")}

                         loading={loading}
                         invalid={nameInputInvalid}

                         maxWidth={MAX_WIDTH}

                         required/>

            <DiLangInput onChange={(en, ru) => setCompany({ en, ru })}
                         enValue={company.en}
                         ruValue={company.ru}

                         max={MAX_SHORT_TEXT_LENGTH}

                         label={t("domain.provider.labels.company")}
                         placeholder={t("domain.provider.placeholders.company")}

                         loading={loading}
                         invalid={companyInvalid}

                         maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    <PhoneInput onChange={setPhone}
                                value={phone}

                                label={t("messageTargets.labels.phone")}
                                placeholder={t("messageTargets.placeholders.phone")}

                                loading={loading}
                                invalid={phoneInvalid}/>

                    <EmailInput onChange={setEmail}
                                value={email}

                                label={t("messageTargets.labels.email")}
                                placeholder={t("messageTargets.placeholders.email")}

                                loading={loading}
                                invalid={emailInvalid}/>
                </Flex>
            </Limit>
        </Flex>
    </ActionModal>

    // Events

    async function onCreate() {
        setLoading(true)

        try {
            assert(name != null)

            const [enFirstname, enLastname, enPatronymic] = name.en
                .trim()
                .split(/\s+/)

            const [ruFirstname, ruLastname, ruPatronymic] = name.ru
                .trim()
                .split(/\s+/)

            const request: ProviderRequest = {
                enFirstname, enLastname, enPatronymic,
                ruFirstname, ruLastname, ruPatronymic,

                enCompany: company.en,
                ruCompany: company.ru,

                phone,
                email,
            }

            const provider = await createProvider(request)

            onCreated?.(provider)
        } finally {
            setLoading(false)
        }
    }
})

ProviderCreationModal.displayName = "ProviderCreationModal"

export default ProviderCreationModal
