export function clamp(value: number, min: number, max: number): number {
    if (min > max) {
        const tmpMin = min

        min = max
        max = tmpMin
    }

    if (value < min)
        return min

    if (value > max)
        return max

    return value
}

export function randomInt(min: number, max: number): number {
    if (max < min)
        throw Error(`max(${max}) < min(${min})`)

    return Math.floor(Math.random() * (max - min))
}
