import { ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export interface PaddingProps {
    padding?: string
    paddingLeft?: string
    paddingRight?: string
    paddingTop?: string
    paddingBottom?: string

    width?: string
    height?: string

    children?: ReactNode
}

const Padding = forwardRef((
    {
        padding, paddingLeft, paddingRight, paddingTop, paddingBottom,
        width, height,
        children,
    }: Readonly<PaddingProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div
        style={{
            width, height,

            paddingLeft: paddingLeft ?? padding,
            paddingRight: paddingRight ?? padding,
            paddingTop: paddingTop ?? padding,
            paddingBottom: paddingBottom ?? padding,
        }}
        className={style.Padding}
        ref={ref}
    >
        {children}
    </div>
)

Padding.displayName = "Padding"

export default Padding
