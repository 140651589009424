import { ForwardedRef, forwardRef } from "react"
import GlobalLangSelector from "ui/component/GlobalLangSelector"
import { Copyright, Flex } from "ui/ui"
import style from "./style.module.css"

export type FooterType =
    | "auth"
    | "main"

export interface FooterProps {
    type?: FooterType
}

const Footer = forwardRef((
    { type }: Readonly<FooterProps>,
    ref: ForwardedRef<HTMLElement>,
) => {
    const main = type === "main"

    return <footer className={style.Footer}
                   ref={ref}>
        <Flex direction="horizontal"
              justify="space-between">
            <GlobalLangSelector/>

            {main &&
                <Copyright/>
            }
        </Flex>
    </footer>
})

Footer.displayName = "Footer"

export default Footer
