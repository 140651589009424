import { trimToNull } from "my-util"

export async function throwIfResponseNotOk(response: Response) {
    if (response.ok)
        return

    throw createApiError(
        response.status,
        response.statusText,
        await response.text(),
    )
}

// API Error

export function createApiError(statusCode: number, statusText: string, text: string = ""): ApiError {
    let message = text.trim()

    if (message.length === 0)
        message = statusText.trim()

    if (message.length === 0)
        message = String(statusCode)

    const options: ApiErrorOptions = {
        statusText: trimToNull(statusText),
        statusCode,
        message,
    }

    switch (statusCode) {
        case 403:
            return new AccessDeniedApiError(options)

        case 404:
            return new NotFoundApiError(options)

        default:
            return new ApiError(options)
    }
}

export interface ApiErrorOptions {
    statusCode: number
    statusText?: string | null
    message?: string | null
    cause?: unknown
}

export class ApiError extends Error {
    readonly statusCode: number
    readonly statusText: string | null

    constructor(options: Readonly<ApiErrorOptions>) {
        super(options.message ?? undefined, { cause: options.cause })

        this.statusCode = options.statusCode
        this.statusText = options.statusText ?? null
    }
}

export type StatusApiErrorOptions = Omit<ApiErrorOptions, "statusCode">

export class AccessDeniedApiError extends ApiError {
    static readonly STATUS_CODE = 403

    constructor(options: Readonly<StatusApiErrorOptions>) {
        super({
            ...options,
            statusCode: AccessDeniedApiError.STATUS_CODE,
        })
    }
}

export class NotFoundApiError extends ApiError {
    static readonly STATUS_CODE = 404

    constructor(options: Readonly<StatusApiErrorOptions>) {
        super({
            ...options,
            statusCode: NotFoundApiError.STATUS_CODE,
        })
    }
}

// Network Error

export class NetworkError extends Error {}
