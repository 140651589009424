import Decimal from "decimal.js"
import { AsYouType, CountryCode, parsePhoneNumber } from "libphonenumber-js"
import { determineMessageTargetType } from "model"
import { removeWhiteSpace } from "./white-space"
import { countDigits } from "./digits"

export function formatDecimal(
    value: Decimal | number,
    precision: number = 2,
): string {
    const fixed = value.toFixed(precision)

    const dotIndex = fixed.indexOf(".")
    const noDot = dotIndex === -1

    const int = noDot ? fixed : fixed.substring(0, dotIndex)
    const formattedInt = int
        .split("")
        .reverse()
        .join("")

        .match(/.{0,3}/g)
        ?.join(" ")

        ?.split("")
        ?.reverse()
        ?.join("")

        .trim()

        ?? int

    const formatted = noDot ? formattedInt
                            : formattedInt + fixed.substring(dotIndex)

    return formatted
}

export function formatMessageTarget(messageTarget: string): string {
    switch (determineMessageTargetType(messageTarget)) {
        case "phone":
            return formatPhone(messageTarget)

        case "email":
            return formatEmail(messageTarget)
    }
}

export function formatPhone(phone: string): string {
    try {
        const parsedPhone = parsePhoneNumber(phone, "RU")
        return parsedPhone.formatInternational()
    } catch {
        return phone
    }
}

export function formatPhoneAsYouType(phone: string, country?: CountryCode): string {
    phone = phone.replaceAll(/\D/g, "")

    const asYouType = new AsYouType(country ?? "RU")

    phone = asYouType.input(phone)

    if (asYouType.isValid() && !asYouType.isInternational()) {
        const international = asYouType.getNumber()?.formatInternational()

        if (international != null && countDigits(international) === countDigits(phone))
            phone = international
    }

    phone = phone.replaceAll(/[+()]/g, "")
    phone = phone.replaceAll(/-+/g, " ")

    return phone
}

export function formatEmail(email: string): string {
    return removeWhiteSpace(email).toLowerCase()
}
