import { useEffect, useState } from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"

import { AnonymousChatMessageResponse, ChatMessageListResponse,
         ChatMessageResponse, ChatRequest, ChatRequestVerificationResponse,
         ChatResponse, ChatResponseSchema, GetChatInfoRequest,
         LoadChatMessagesRequest, MarkChatMessagesReadRequest,
         DeleteChatMessagesResponse, DeleteChatMessagesRequest,
         MarkChatMessagesReadResponse, SendChatMessageRequest, ChatInfoResponse } from "api"

import { AnonymousChatMessage, ChatMessage } from "model"
import { DeepReadonly, tryNormalizeNullableUuid, tryNormalizeUuid } from "my-util"

import { ChatHookDeleteMessagesRequest, ChatHookRequest,
         ChatHookLoadMessagesRequest, ChatHookGetInfoRequest,
         ChatHookMarkMessagesReadRequest, ChatHookSendMessageRequest } from "./request"

import { ChatHookInfoResponse, ChatHookResponse,
         ChatHookRequestVerificationResponse, ChatHookMessagesResponse,
         ChatHookMarkMessagesReadResponse, ChatHookDeleteMessagesResponse } from "./response"

import { CHAT_HOOK_URL } from "./url"

export { ReadyState } from "react-use-websocket"

export interface ChatHookOptions {
    onResponse?: (response: ChatHookResponse) => void

    shouldReconnect?: boolean
    reconnectInterval?: number
    reconnectAttempts?: number
}

export interface ChatHook {
    lastResponse: ChatHookResponse | null
    sendRequest: SendChatHookRequest
    readyState: ReadyState

    close: () => void
}

export type SendChatHookRequest = (request: DeepReadonly<ChatHookRequest>, keep?: boolean) => void

export default function useChat(
    {
        onResponse,
        shouldReconnect, reconnectInterval, reconnectAttempts,
    }: Readonly<ChatHookOptions> = {},
): ChatHook {
    const {
        sendJsonMessage, lastJsonMessage,
        readyState, getWebSocket,
    } =
        useWebSocket(CHAT_HOOK_URL, {
            shouldReconnect: () => shouldReconnect ?? false,
            retryOnError: shouldReconnect,
            reconnectInterval,
            reconnectAttempts,
        })

    const [lastResponse, setLastResponse] = useState(null as ChatHookResponse | null)

    useEffect(() => {
        if (lastJsonMessage == null)
            return

        const parsedLastJsonMessage = ChatResponseSchema.safeParse(lastJsonMessage)

        if (!parsedLastJsonMessage.success) {
            console.error(parsedLastJsonMessage.error)
            return
        }

        const newLastResponse = chatResponseToChatHookResponse(parsedLastJsonMessage.data)

        setLastResponse(newLastResponse)
        onResponse?.(newLastResponse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastJsonMessage])

    return {
        lastResponse, sendRequest, readyState,

        close() {
            getWebSocket()?.close()
        }
    }

    // Response

    function chatResponseToChatHookResponse(response: DeepReadonly<ChatResponse>): ChatHookResponse {
        switch (response.type) {
            case "message-list":
                return chatMessageListResponseToChatHookMessagesResponse(response)

            case "anonymous-message":
                return anonymousChatMessageResponseToChatHookMessagesResponse(response)

            case "message":
                return chatMessageResponseToChatHookMessagesResponse(response)

            case "info":
                return chatInfoResponseToChatHookInfoResponse(response)

            case "mark-read":
                return markChatMessagesReadResponseToChatHookMarkReadResponse(response)

            case "delete":
                return deleteChatMessagesResponseToChatHookDeleteResponse(response)

            case "request-verification":
                return chatRequestVerificationResponseToChatHookRequestVerificationResponse(response)

            default:
                return response satisfies never
        }
    }

    function chatMessageListResponseToChatHookMessagesResponse(response: DeepReadonly<ChatMessageListResponse>): ChatHookMessagesResponse {
        return {
            requestId: tryNormalizeNullableUuid(response.requestId),
            type: "messages",

            messages: response.messages.map(message =>
                message.type === "anonymous-message"
                    ? new AnonymousChatMessage(message)
                    : new ChatMessage(message)
            )
        }
    }

    function anonymousChatMessageResponseToChatHookMessagesResponse(response: DeepReadonly<AnonymousChatMessageResponse>): ChatHookMessagesResponse {
        return {
            requestId: tryNormalizeNullableUuid(response.requestId),
            type: "messages",

            messages: [
                new AnonymousChatMessage(response),
            ],
        }
    }

    function chatMessageResponseToChatHookMessagesResponse(response: DeepReadonly<ChatMessageResponse>): ChatHookMessagesResponse {
        return {
            requestId: tryNormalizeNullableUuid(response.requestId),
            type: "messages",

            messages: [
                new ChatMessage(response),
            ],
        }
    }

    function chatInfoResponseToChatHookInfoResponse(response: DeepReadonly<ChatInfoResponse>): ChatHookInfoResponse {
        return {
            requestId: tryNormalizeNullableUuid(response.requestId),
            type: "info",

            userId: tryNormalizeUuid(response.userId),
            unreadCount: response.unreadCount,

            lastMessage: response.lastMessage != null
                ? response.lastMessage.type === "anonymous-message"
                    ? new AnonymousChatMessage(response.lastMessage)
                    : new ChatMessage(response.lastMessage)
                : null,
        }
    }

    function markChatMessagesReadResponseToChatHookMarkReadResponse(response: DeepReadonly<MarkChatMessagesReadResponse>): ChatHookMarkMessagesReadResponse {
        return {
            requestId: tryNormalizeNullableUuid(response.requestId),
            type: "mark-read",

            messageIds: response.messageIds.map(tryNormalizeUuid),
        }
    }

    function deleteChatMessagesResponseToChatHookDeleteResponse(response: DeepReadonly<DeleteChatMessagesResponse>): ChatHookDeleteMessagesResponse {
        return {
            requestId: tryNormalizeNullableUuid(response.requestId),
            type: "delete",

            messageIds: response.messageIds.map(tryNormalizeUuid),
        }
    }

    function chatRequestVerificationResponseToChatHookRequestVerificationResponse(response: DeepReadonly<ChatRequestVerificationResponse>): ChatHookRequestVerificationResponse {
        return {
            requestId: tryNormalizeUuid(response.requestId),
            type: "request-verification",

            error: response.error ?? null,
        }
    }

    // Request

    function sendRequest(request: DeepReadonly<ChatHookRequest>, keep?: boolean) {
        const apiRequest = chatHookRequestToChatRequest(request)

        sendJsonMessage(apiRequest, keep)
    }

    function chatHookRequestToChatRequest(request: DeepReadonly<ChatHookRequest>): ChatRequest {
        switch (request.type) {
            case "send-message":
                return chatHookSendMessageRequestToSendChatMessageRequest(request)

            case "mark-read":
                return chatHookMarkMessagesReadRequestToMarkChatMessagesReadRequest(request)

            case "delete":
                return chatHookDeleteMessagesRequestToDeleteChatMessagesRequest(request)

            case "load-messages":
                return chatHookLoadMessagesRequestToLoadChatMessagesRequest(request)

            case "get-info":
                return chatHookGetInfoRequestToGetChatInfoRequest(request)

            default:
                return request satisfies never
        }
    }

    function chatHookSendMessageRequestToSendChatMessageRequest(request: DeepReadonly<ChatHookSendMessageRequest>): SendChatMessageRequest {
        return {
            type: "send-message",
            id: request.id,
            text: request.text,

            documentIds: request.documentIds != null
                ? [...request.documentIds]
                : null,

            recipientId: request.recipientId,
        }
    }

    function chatHookMarkMessagesReadRequestToMarkChatMessagesReadRequest(request: DeepReadonly<ChatHookMarkMessagesReadRequest>): MarkChatMessagesReadRequest {
        return {
            type: "mark-read",
            id: request.id,
            messageIds: [...request.messageIds],
        }
    }

    function chatHookDeleteMessagesRequestToDeleteChatMessagesRequest(request: DeepReadonly<ChatHookDeleteMessagesRequest>): DeleteChatMessagesRequest {
        return {
            type: "delete",
            id: request.id,
            messageIds: [...request.messageIds],
        }
    }

    function chatHookLoadMessagesRequestToLoadChatMessagesRequest(request: DeepReadonly<ChatHookLoadMessagesRequest>): LoadChatMessagesRequest {
        return {
            type: "load-messages",
            id: request.id,
            before: request.before.toISOString(),
            limit: request.limit,
            userId: request.userId,
        }
    }

    function chatHookGetInfoRequestToGetChatInfoRequest(request: DeepReadonly<ChatHookGetInfoRequest>): GetChatInfoRequest {
        return {
            type: "get-info",
            id: request.id,
            userId: request.userId,
        }
    }
}
