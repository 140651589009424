import { getTransferById } from "api"
import { Transfer } from "model"

import createStorageHook,
       { StorageHook, StorageHookActions, StorageHookValues } from "./createStorageHook"

export interface TransfersHook extends StorageHook<Transfer> {}
export interface TransfersHookValues extends StorageHookValues<Transfer> {}
export interface TransfersHookActions extends StorageHookActions<Transfer> {}

const useTransfers = createStorageHook({
    loadById: getTransferById,
    // debug: true,
}) satisfies () => TransfersHook

export default useTransfers
