import { z } from "zod"
import { UuidSchema } from "my-util"
import { ChatResponseType } from "./type"
import { ChatMessageResponseSchema } from "./message"
import { AnonymousChatMessageResponseSchema } from "./anonymous-message"
import { ChatResponseBaseSchema } from "./base"

export const ChatInfoResponseSchema = ChatResponseBaseSchema.extend({
    type: z.literal("info" satisfies ChatResponseType),

    lastMessage: ChatMessageResponseSchema.or(AnonymousChatMessageResponseSchema).nullish(),
    unreadCount: z.number().int().nonnegative().finite(),
    userId: UuidSchema,
})

export type ChatInfoResponse = z.infer<typeof ChatInfoResponseSchema>
