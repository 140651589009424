import { Invite } from "model"

export interface InviteStatusPresence {
    activePresent: boolean
    expiresPresent: boolean
    expiredPresent: boolean
}

export function checkInviteStatusPresence(invite: readonly Invite[]): InviteStatusPresence {
    return {
        activePresent: invite.some(({ status }) => status === "active"),
        expiresPresent: invite.some(({ status }) => status === "expires"),
        expiredPresent: invite.some(({ status }) => status === "expired"),
    }
}
