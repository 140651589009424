import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react"
import { arrowLeftIconUrl, arrowRightIconUrl } from "image"
import { Transfer } from "model"
import { getMonthCalendarDays, getMonthName, getWeekDayName, splitArray,
         normalizeYearOrGetCurrent, normalizeMonthIndexOrGetCurrent,
         WEEK_DAY_INDEXES, WeekDayIndex, CalendarMonthDay, MonthIndex,
         normalizeMonthIndex} from "my-util"
import { Flex } from "ui/ui/layout"
import Button from "ui/ui/Button"
import style from "./style.module.css"
import { useStateWithDeps } from "ui/hook"

export interface SmallMonthProps {
    onDateClick?: (date: Date) => void
    onYearChange?: (year: number) => void
    onMonthIndexChange?: (monthIndex: MonthIndex) => void

    year?: number
    monthIndex?: number

    showScrollButtons?: boolean
    showYear?: boolean

    transfers?: Transfer[]
}

const SmallMonth = forwardRef((
    {
        onDateClick, onYearChange, onMonthIndexChange,
        year, monthIndex,
        showScrollButtons, showYear,
        transfers,
    }: Readonly<SmallMonthProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const today = new Date()

    const [innerYear, setInnerYear] = useStateWithDeps(
        () => normalizeYearOrGetCurrent(year, today),
        [year],
    )

    const [innerMonthIndex, setInnerMonthIndex] = useStateWithDeps(
        () => normalizeMonthIndexOrGetCurrent(monthIndex, today),
        [monthIndex],
    )

    const days = useMemo(() => getMonthCalendarDays(innerMonthIndex, innerYear), [innerYear, innerMonthIndex])
    const rowDays = splitArray(days, 7)

    const innerTransfers = useMemo(() =>
        transfers?.filter(({ moment }) =>
            moment != null &&
            moment.getFullYear() === innerYear &&
            moment.getMonth() === innerMonthIndex
        ) ?? [],
        [transfers, innerYear, innerMonthIndex],
    )

    // Render

    return <Flex width="fit-content"
                 gap="8px"
                 ref={ref}>
        <table className={style.YearMonth}>
            {renderCaption()}
            {renderHeader()}
            {renderBody()}
        </table>

        {renderYearIfNeeded()}
    </Flex>

    function renderCaption(): ReactNode {
        return showScrollButtons
             ? renderCaptionWithScrollButtons()
             : renderCaptionWithoutScrollButtons()
    }

    function renderCaptionWithScrollButtons(): ReactNode {
        return <caption>
            <Flex direction="horizontal"
                  justify="space-between">
                {renderScrollButton("left")}

                <div className={style.monthName}>
                    {getMonthName(innerMonthIndex)}
                </div>

                {renderScrollButton("right")}
            </Flex>
        </caption>
    }

    function renderCaptionWithoutScrollButtons(): ReactNode {
        return <caption className={style.monthName}>
            {getMonthName(innerMonthIndex)}
        </caption>
    }

    function renderScrollButton(direction: "left" | "right"): ReactNode {
        const [onClick, iconSrc, iconAlt] = direction === "left"
            ? [() => onScrollMonth(-1), arrowLeftIconUrl, "Arrow left icon"]
            : [() => onScrollMonth(1), arrowRightIconUrl, "Arrow right icon"]

        return <Button onClick={onClick}

                       width="fit-content"
                       height="20px"

                       buttonStyle="text"

                       iconSrc={iconSrc}
                       iconAlt={iconAlt}/>
    }

    function renderHeader(): ReactNode {
        return <thead>
            <tr>
                {WEEK_DAY_INDEXES.map(weekDay =>
                    <th className={style.weekDayName}
                        key={weekDay}>
                        {getWeekDayName(weekDay as WeekDayIndex, true)}
                    </th>
                )}
            </tr>
        </thead>
    }

    function renderBody(): ReactNode {
        return <tbody>
            {rowDays.map((days, row) =>
                <tr key={row}>
                    {days.map((day, i) => {
                        const dayNumber = day.index + 1
                        const hasTransfers = innerTransfers.some(({ moment }) =>
                            day.type === "cur" &&
                            moment!.getDate() === dayNumber
                        ) ?? false

                        const allClassNames = [style[`day-${day.type}`]]

                        if (hasTransfers)
                            allClassNames.push(style.transfer)

                        if (i >= 5)
                            allClassNames.push(style.weekend)

                        const className = allClassNames.join(" ")
                        const key = `${day.index}-${day.type}`

                        return <td onClick={() => onDayClick?.(day)}
                                   className={className}
                                   key={key}>
                            {dayNumber}
                        </td>
                    })}
                </tr>
            )}
        </tbody>
    }

    function renderYearIfNeeded(): ReactNode {
        return showYear
             ? renderYear()
             : null
    }

    function renderYear(): ReactNode {
        return innerYear
    }

    // Events

    function onScrollMonth(delta: -1 | 1) {
        const newInnerMonthIndex = normalizeMonthIndex(innerMonthIndex + delta)

        setInnerMonthIndex(newInnerMonthIndex)
        onMonthIndexChange?.(newInnerMonthIndex)

        const newInnerYear = innerMonthIndex === 0 && delta < 0
            ? innerYear - 1
            : innerMonthIndex === 11 && delta > 0
                ? innerYear + 1
                : innerYear

        if (newInnerYear !== innerYear) {
            setInnerYear(newInnerYear)
            onYearChange?.(newInnerYear)
        }
    }

    function onDayClick(day: CalendarMonthDay) {
        if (onDateClick == null)
            return

        let dateMonthIndex = innerMonthIndex

        switch (day.type) {
            case "prev":
                --dateMonthIndex
                break

            case "next":
                ++dateMonthIndex
                break
        }

        const date = new Date(innerYear, dateMonthIndex, day.index + 1, 12)

        onDateClick(date)
    }
})

SmallMonth.displayName = "SmallMonth"

export default SmallMonth
