import { ForwardedRef, forwardRef, ReactNode, MouseEvent } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export interface ClickableProps {
    onClick?: () => void

    stopPropagation?: boolean

    cursor?: string

    width?: string
    height?: string

    children?: ReactNode
}

const Clickable = forwardRef((
    {
        onClick,
        stopPropagation,
        cursor,
        width, height,
        children,
    }: DeepReadonly<ClickableProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    return <div style={{ cursor, width, height }}
                className={style.Clickable}
                onClick={onInnerClick}
                ref={ref}>
        {children}
    </div>

    function onInnerClick(event: MouseEvent<HTMLDivElement>) {
        if (stopPropagation)
            event.stopPropagation()

        onClick?.()
    }
})

Clickable.displayName = "Clickable"

export default Clickable
