import { ForwardedRef, forwardRef } from "react"
import { Named } from "model"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export interface NameProps {
    value: Named
    formal?: boolean
}

const Name = forwardRef((
    {
        value,
        formal,
    }: DeepReadonly<NameProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span className={style.Name}
          ref={ref}>
        {
            formal
                ? value.formalName
                : value.name
        }
    </span>
)

Name.displayName = "Name"

export default Name
