import { forwardRef, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { exitIconUrl } from "image"
import { logout, USER_ID_STORAGE_KEY } from "api"
import { UserContext } from "ui/context"
import { createLoginPagePath } from "ui/page/LoginPage/path"
import { MAIN_PAGE_PATH } from "ui/page/sections/MainPage/path"
import { Button } from "ui/ui"

const LogoutButton = forwardRef(() => {
    const [, setLocalUser] = useContext(UserContext)

    const navigate = useNavigate()

    return <Button iconSrc={exitIconUrl}
                   buttonStyle="text"
                   onClick={onLogout}/>

    function onLogout() {
        logout().catch(console.error)

        setLocalUser(null)

        const userId = localStorage.getItem(USER_ID_STORAGE_KEY)

        const newPath = userId != null
            ? createLoginPagePath(userId)
            : MAIN_PAGE_PATH

        navigate(newPath)
    }
})

LogoutButton.displayName = "LogoutButton"

export default LogoutButton
