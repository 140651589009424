import { isDigits, removeWhiteSpace } from "my-util"
import ViolationType from "./ViolationType"

export const BIC_LENGTH = 9

export function validateBic(bic: string): ViolationType | undefined {
    bic = removeWhiteSpace(bic)

    if (bic.length > BIC_LENGTH)
        return "too-long"

    if (bic.length < BIC_LENGTH)
        return "too-short"

    if (!isDigits(bic))
        return "illegal-char"

    return undefined
}
