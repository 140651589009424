import { ForwardedRef, forwardRef } from "react"
import { InviteStatus } from "model"
import { Flex, FlexProps } from "ui/ui/layout"
import InviteStatusColorLegend from "../InviteStatusColorLegend"

export interface InviteStatusColorLegendListProps extends Omit<FlexProps, "children"> {
    onClick?: (group: InviteStatus) => void

    hideActive?: boolean
    hideExpires?: boolean
    hideExpired?: boolean

    fontSize?: string

    showLegend?: boolean
}

const InviteStatusColorLegendList = forwardRef((
    props: Readonly<InviteStatusColorLegendListProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        onClick,
        hideActive, hideExpires, hideExpired,
        fontSize,
        showLegend,
    } = props

    return <Flex direction="horizontal"
                 width="fit-content"
                 { ...props }
                 ref={ref}>

        {!hideActive &&
            <InviteStatusColorLegend showLegend={showLegend}
                                     fontSize={fontSize}
                                     onClick={onClick}
                                     status="active"/>
        }

        {!hideExpires &&
            <InviteStatusColorLegend showLegend={showLegend}
                                     fontSize={fontSize}
                                     onClick={onClick}
                                     status="expires"/>
        }

        {!hideExpired &&
            <InviteStatusColorLegend showLegend={showLegend}
                                     fontSize={fontSize}
                                     onClick={onClick}
                                     status="expired"/>
        }
    </Flex>
})

InviteStatusColorLegendList.displayName = "InviteStatusColorLegendList"

export default InviteStatusColorLegendList
