import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { RoutePointType } from "model"
import Select from "ui/ui/Select"

export interface RoutePointTypeSelectProps {
    onSelect?: (type: RoutePointType) => void
    selected?: RoutePointType

    loading?: boolean
    disabled?: boolean
    readonly?: boolean

    label?: string
    information?: string
}

const RoutePointTypeSelect = forwardRef((
    {
        onSelect, selected,
        loading, disabled, readonly,
        label, information,
    }: Readonly<RoutePointTypeSelectProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Select
        onSelect={onSelect as (key: string) => void}
        selected={selected}
        
        options={{
            ["air" satisfies RoutePointType]: t("domain.routePoints.types.air"),
            ["earth" satisfies RoutePointType]: t("domain.routePoints.types.earth"),
            ["water" satisfies RoutePointType]: t("domain.routePoints.types.water"),
        }}

        loading={loading}
        disabled={disabled}
        readonly={readonly}

        label={label}
        information={information}

        ref={ref}
    />
})

RoutePointTypeSelect.displayName = "RoutePointTypeSelect"

export default RoutePointTypeSelect
