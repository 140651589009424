import { z } from "zod"
import { NotificationType } from "model"

export const NotificationTypeResponseSchema = z.enum([
    "new-chat-messages",
    "new-invite-application",
    "new-invite",
    "new-transfer",
    "new-user",
    "transfer-status-changed",
] satisfies [NotificationType, ...NotificationType[]])

export type NotificationTypeResponse = z.infer<typeof NotificationTypeResponseSchema>
