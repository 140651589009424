import { z } from "zod"
import { RoutePointType } from "model"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"

// Type

export const RoutePointTypeResponseSchema = z.enum([
    "air",
    "earth",
    "water",
] satisfies [RoutePointType, ...RoutePointType[]])

export type RoutePointTypeResponse = z.infer<typeof RoutePointTypeResponseSchema>

// Route point

export const RoutePointResponseSchema = ModelObjectResponseSchema.extend({
    country: z.string(),
    type: RoutePointTypeResponseSchema,
    documentIds: UuidSchema.array(),
})

export type ShortRoutePointResponse = z.infer<typeof RoutePointResponseSchema>
