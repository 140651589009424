import { ReadonlyDate } from "my-util/type"
import { dateWithTimeNulled } from "./set"

export function areNullableDatesWithSameDay(
    first?: ReadonlyDate | null,
    second?: ReadonlyDate | null,
): boolean {
    return first != null && second != null
        ? areDatesWithSameDay(first, second)
        : false
}

export function areDatesWithSameDay(first: ReadonlyDate, second: ReadonlyDate): boolean {
    return dateWithTimeNulled(first).getTime() === dateWithTimeNulled(second).getTime()
}
