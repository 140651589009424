import { get, post, del, countEntities, isEntityExists, tryDeleteEntity } from "api/http/util"
import { InviteRequest } from "api/request"
import { InviteResponseSchema } from "api/response"
import { Invite } from "model"

const INVITES_SUBPATH = "/invites"

// Count

export async function countInvites(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${INVITES_SUBPATH}/count`, signal)
}

export async function countMyInvites(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${INVITES_SUBPATH}/my/count`, signal)
}

export async function isInviteExistsById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return isEntityExists(`${INVITES_SUBPATH}/${id}/exists`, signal)
}

// Get

export async function getAllInvites(signal?: AbortSignal | null): Promise<Invite[]> {
    return (await get({
        subpath: INVITES_SUBPATH,
        schema: InviteResponseSchema.array(),
        signal,
    })).map(response => new Invite(response))
}

export async function getAllMyInvites(signal?: AbortSignal | null): Promise<Invite[]> {
    return (await get({
        subpath: `${INVITES_SUBPATH}/my`,
        schema: InviteResponseSchema.array(),
        signal,
    })).map(response => new Invite(response))
}

export async function getInviteById(id: string, signal?: AbortSignal | null): Promise<Invite> {
    return new Invite(await get({
        subpath: `${INVITES_SUBPATH}/${id}`,
        schema: InviteResponseSchema,
        signal,
    }))
}

// Delete

export async function clearInvites(signal?: AbortSignal | null) {
    return del({
        subpath: INVITES_SUBPATH,
        signal,
    })
}

export async function clearMyInvites(signal?: AbortSignal | null) {
    return del({
        subpath: `${INVITES_SUBPATH}/my`,
        signal,
    })
}

export async function deleteInviteById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: `${INVITES_SUBPATH}/${id}`,
        signal,
    })
}

export async function tryDeleteInviteById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return tryDeleteEntity(`${INVITES_SUBPATH}/${id}`, signal)
}

// Resend

export async function resendInviteById(id: string, signal?: AbortSignal | null): Promise<void> {
    return post({
        subpath: `${INVITES_SUBPATH}/${id}/resend`,
        signal,
    })
}

// Create

export async function createInvite(request: Readonly<InviteRequest>, signal?: AbortSignal | null): Promise<Invite> {
    return new Invite(await post({
        subpath: INVITES_SUBPATH,
        schema: InviteResponseSchema,
        body: request,
        signal,
    }))
}
