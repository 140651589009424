import { ForwardedRef, forwardRef, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"

export interface NotificationModalProps {
    onClose?: () => void

    header?: string
    opacity?: number
    children?: ReactNode
}

const NotificationModal = forwardRef((
    {
        onClose,
        header,
        opacity,
        children,
    }: Readonly<NotificationModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Modal
        header={header}
        onClose={onClose}
        opacity={opacity}
        buttons={[{
            onClick: onClose,
            text: t("misc.buttons.ok"),
        }]}
        ref={ref}
    >
        {children}
    </Modal>
})

NotificationModal.displayName = "NotificationModal"

export default NotificationModal
