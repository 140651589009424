import { ForwardedRef, forwardRef, ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DAY_MILLIS, DeepReadonly, HOUR_MILLIS, MINUTE_MILLIS } from "my-util"

export interface EventTimeProps {
    time: Date
}

const EventTime = forwardRef((
    { time }: DeepReadonly<EventTimeProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const [t] = useTranslation()

    // State

    const [millisPassed, setMillisPassed] = useState(Date.now() - time.getTime())

    // Effect

    useEffect(() => {
        const interval = setInterval(
            () => setMillisPassed(Date.now() - time.getTime()),
            MINUTE_MILLIS,
            MINUTE_MILLIS,
        )

        return () => clearInterval(interval)
    }, [time])

    // Render

    return <span ref={ref}>
        {render()}
    </span>

    function render(): ReactNode {
        const absMillisPassed = Math.abs(millisPassed)

        if (absMillisPassed < MINUTE_MILLIS)
            return t("datetime.labels.now").toLowerCase()

        const agoSuffix = " " + t("datetime.labels.ago").toLowerCase()

        if (absMillisPassed < HOUR_MILLIS) {
            const minutes = Math.trunc(millisPassed / MINUTE_MILLIS)

            return t("datetime.duration.minutes.fullWithCount", { count: minutes }) + agoSuffix
        }

        if (absMillisPassed < DAY_MILLIS) {
            const hours = Math.trunc(millisPassed / HOUR_MILLIS)

            return t("datetime.duration.hours.fullWithCount", { count: hours }) + agoSuffix
        }

        const days = Math.trunc(millisPassed / DAY_MILLIS)

        return t("datetime.duration.days.fullWithCount", { count: days }) + agoSuffix
    }
})

EventTime.displayName = "EventTime"

export default EventTime
