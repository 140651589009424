import { DeepReadonly, Nullish, tryNormalizeUuid } from "my-util"

import AbstractNotification,
       { NotificationType,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

export interface NewInviteNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">
{
    inviteId: string
}

export interface NewInviteNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewInviteNotificationCreationOptions>
{}

export default class NewInviteNotification
    extends
        AbstractNotification<"new-invite">
{
    static createOrPass(
        arg: NewInviteNotification
           | DeepReadonly<NewInviteNotificationCreationOptions>
    ): NewInviteNotification {
        return arg instanceof NewInviteNotification
            ? arg
            : new NewInviteNotification(arg)
    }

    readonly inviteId: string

    constructor(options: DeepReadonly<NewInviteNotificationCreationOptions>) {
        super({ ...options, type: "new-invite" })

        this.inviteId = tryNormalizeUuid(options.inviteId)
    }

    override copyOrPass(
        arg?: NewInviteNotification
            | DeepReadonly<NewInviteNotificationCopyOptions>
            | null
    ): NewInviteNotification {
        if (arg == null)
            return this

        if (arg instanceof NewInviteNotification)
            return arg
        
        return this.copy(arg)
    }

    override copy(
        options: DeepReadonly<NewInviteNotificationCopyOptions> = {},
    ): NewInviteNotification {
        return new NewInviteNotification({
            inviteId: options.inviteId ?? this.inviteId,

            // AbstractNotification

            recipientId: options.recipientId ?? this.recipientId,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
