import { ReactNode, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { cancelMfa, patchUserById } from "api"
import { User } from "model"
import { useStateWithDeps } from "ui/hook"
import { CheckBox, Flex, Form, FormControls, FormControlsButton } from "ui/ui"

export interface NotificationsTabProps {
    onUserChange?: (user: User) => void
    user: User

    readonly?: boolean

    mobile?: boolean
}

export default function NotificationsTab(
    {
        onUserChange, user,
        readonly,
        mobile,
    }: Readonly<NotificationsTabProps>,
) {
    const [t] = useTranslation()

    const navigate = useNavigate()

    // State

    const [notifyOnNewChatMessage, setNotifyOnNewChatMessage] = useStateWithDeps(
        () => user.notifyOnNewChatMessage,
        [user.notifyOnNewChatMessage],
    )

    const [notifyOnNewInviteApplication, setNotifyOnNewInviteApplication] = useStateWithDeps(
        () => user.notifyOnNewInviteApplication,
        [user.notifyOnNewInviteApplication],
    )

    const [notifyOnNewInvite, setNotifyOnNewInvite] = useStateWithDeps(
        () => user.notifyOnNewInvite,
        [user.notifyOnNewInvite],
    )

    const [notifyOnNewTransfer, setNotifyOnNewTransfer] = useStateWithDeps(
        () => user.notifyOnNewTransfer,
        [user.notifyOnNewTransfer],
    )

    const [notifyOnNewUser, setNotifyOnNewUser] = useStateWithDeps(
        () => user.notifyOnNewUser,
        [user.notifyOnNewUser],
    )

    const [notifyOnTransferStatusChanged, setNotifyOnTransferStatusChanged] = useStateWithDeps(
        () => user.notifyOnTransferStatusChanged,
        [user.notifyOnTransferStatusChanged],
    )

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    // Render

    return <Form onSubmit={onSubmit}
                 loading={false}
                 height="100%">
        <Flex justify="space-between"
              align="start"
              height="100%">
            {renderFields()}
            {renderControls()}
        </Flex>
    </Form>

    function renderFields(): ReactNode {
        return <Flex align="start">
            <CheckBox label={t("notifications.types.new-chat-messages")}

                      onChange={setNotifyOnNewChatMessage}
                      checked={notifyOnNewChatMessage}

                      readonly={readonly}
                      loading={loading}/>

            <CheckBox label={t("notifications.types.new-invite-application")}

                      onChange={setNotifyOnNewInviteApplication}
                      checked={notifyOnNewInviteApplication}

                      readonly={readonly}
                      loading={loading}/>

            <CheckBox label={t("notifications.types.new-invite")}

                      onChange={setNotifyOnNewInvite}
                      checked={notifyOnNewInvite}

                      readonly={readonly}
                      loading={loading}/>

            <CheckBox label={t("notifications.types.new-transfer")}

                      onChange={setNotifyOnNewTransfer}
                      checked={notifyOnNewTransfer}

                      readonly={readonly}
                      loading={loading}/>

            <CheckBox label={t("notifications.types.new-user")}

                      onChange={setNotifyOnNewUser}
                      checked={notifyOnNewUser}

                      readonly={readonly}
                      loading={loading}/>

            <CheckBox label={t("notifications.types.transfer-status-changed")}

                      onChange={setNotifyOnTransferStatusChanged}
                      checked={notifyOnTransferStatusChanged}

                      readonly={readonly}
                      loading={loading}/>
        </Flex>
    }

    function renderControls(): ReactNode {
        const buttons: FormControlsButton[] = [
            {
                onClick() { navigate(-1) },
                text: t("misc.buttons.back"),
                buttonStyle: "outline",
                position: "right",
            }
        ]

        if (hasRightsChanged())
            buttons.push({
                text: t("misc.buttons.save"),
                position: "left",
                type: "submit",
                loading,
            })

        return <FormControls buttons={buttons}
                             error={error}
                             mobile={mobile}/>
    }

    // Events

    async function onSubmit() {
        setLoading(true)

        try {
            const response = await patchUserById(user.id, [
                {
                    op: "replace",
                    path: "/notifyOnNewChatMessage",
                    value: notifyOnNewChatMessage,
                },

                {
                    op: "replace",
                    path: "/notifyOnNewInviteApplication",
                    value: notifyOnNewInviteApplication,
                },

                {
                    op: "replace",
                    path: "/notifyOnNewInvite",
                    value: notifyOnNewInvite,
                },

                {
                    op: "replace",
                    path: "/notifyOnNewTransfer",
                    value: notifyOnNewTransfer,
                },

                {
                    op: "replace",
                    path: "/notifyOnNewUser",
                    value: notifyOnNewUser,
                },

                {
                    op: "replace",
                    path: "/notifyOnTransferStatusChanged",
                    value: notifyOnTransferStatusChanged,
                },
            ])

            if (response.status === "verification-needed") {
                await cancelMfa()
                throw new Error("MFA is required but shouldn't")
            }

            onUserChange?.(new User(response.body))
        } catch (error) {
            setError(error)
            resetRights()
        } finally {
            setLoading(false)
        }
    }

    // Util

    function resetRights() {
        setNotifyOnNewChatMessage(user.canManageTransfers)
        setNotifyOnNewInviteApplication(user.notifyOnNewInviteApplication)
        setNotifyOnNewInvite(user.notifyOnNewInvite)
        setNotifyOnNewTransfer(user.notifyOnNewTransfer)
        setNotifyOnNewUser(user.notifyOnNewUser)
        setNotifyOnTransferStatusChanged(user.notifyOnTransferStatusChanged)
    }

    function hasRightsChanged(): boolean {
        return notifyOnNewChatMessage !== user.notifyOnNewChatMessage
            || notifyOnNewInviteApplication !== user.notifyOnNewInviteApplication
            || notifyOnNewInvite !== user.notifyOnNewInvite
            || notifyOnNewTransfer !== user.notifyOnNewTransfer
            || notifyOnNewUser !== user.notifyOnNewUser
            || notifyOnTransferStatusChanged !== user.notifyOnTransferStatusChanged
    }
}
