import { z } from "zod"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"
import { AnonymousUserResponseSchema } from "../domain"

export const NewAnonymousChatMessagesNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal("new-chat-messages" satisfies NotificationTypeResponse),
    from: AnonymousUserResponseSchema,
    count: z.number().nonnegative().nullish(),
})

export type NewAnonymousChatMessagesNotificationResponse = z.infer<typeof NewAnonymousChatMessagesNotificationResponseSchema>
