import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { USER_ID_STORAGE_KEY } from "api"
import { createLoginPagePath } from "ui/page/LoginPage/path"
import { Link } from "ui/ui"
import BackToTheMainPageLink from "../BackToTheMainPageLink"

export interface ReloginLinkProps {
    hideOnMissing?: boolean
}

const ReloginLink = forwardRef((
    { hideOnMissing }: Readonly<ReloginLinkProps>,
    ref: ForwardedRef<HTMLAnchorElement | null>,
) => {
    const [t] = useTranslation()

    const userId = localStorage.getItem(USER_ID_STORAGE_KEY)

    const to = userId != null
        ? createLoginPagePath(userId)
        : null

    return to
        ? <Link text={t("misc.buttons.relogin")}
                to={to}
                ref={ref}/>

        : hideOnMissing
            ? null
            : <BackToTheMainPageLink ref={ref}/>
})

ReloginLink.displayName = "ReloginLink"

export default ReloginLink
