import { z } from "zod"
import { UuidSchema } from "my-util"
import { ExpirableModelObjectResponseSchema } from "../expirable-model-object"
import { UserRoleResponseSchema } from "./user"

export const InviteResponseSchema = ExpirableModelObjectResponseSchema.extend({
    messageTarget: z.string(),
    sentAt: z.coerce.date().nullish(),
    role: UserRoleResponseSchema,
    creatorId: UuidSchema.nullish(),
    name: z.string().nullish(),
    company: z.string().nullish(),
    reference: z.string().nullish(),
    text: z.string().nullish(),
    comment: z.string().nullish(),

    canManageTransfers: z.coerce.boolean(),
    canManageInvites: z.coerce.boolean(),
    canManageUsers: z.coerce.boolean(),
    canSeeAllUsers: z.coerce.boolean(),
    visibleUserIds: UuidSchema.array().nullish(),
})

export type InviteResponse = z.infer<typeof InviteResponseSchema>
