import { ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export interface ColorLegendProps {
    onClick?: (color: string | undefined, legend: string | undefined) => void

    color?: string
    legend?: string

    fontSize?: string
}

const ColorLegend = forwardRef((
    {
        onClick,
        color, legend,
        fontSize,
    }: Readonly<ColorLegendProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) =>
    <span className={onClick ? style.ClickableColorLegend : style.ColorLegend}
          onClick={() => onClick?.(color, legend)}
          ref={ref}>
        <span style={{ backgroundColor: color }}
              className={style.color}/>

        {legend &&
            <span className={style.legend}
                  style={{ fontSize }}>
                {legend}
            </span>
        }
    </span>
)

export default ColorLegend
