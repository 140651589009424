import { t } from "i18next"

type MessageTargetType =
    | "phone"
    | "email"

export default MessageTargetType

export function determineNullableMessageTargetType(s?: string | null): MessageTargetType | null {
    return s != null
        ? determineMessageTargetType(s)
        : null
}

export function determineMessageTargetType(s: string): MessageTargetType {
    return s.match(/[.@\p{L}]/ui)
         ? "email"
         : "phone"
}

export function messageTargetTypeToString(type: MessageTargetType): string {
    return t(`messageTargets.labels.${type}`)
}

export function isMessageTargetType(s: string): s is MessageTargetType {
    switch (s) {
        case "phone":
        case "email":
            s satisfies MessageTargetType
            return true

        default:
            return false
    }
}

export function messageTargetToObject(
    messageTarget: string,
    messageTargetType: MessageTargetType = determineMessageTargetType(messageTarget),
): MessageTargetObject {
    switch (messageTargetType) {
        case "phone":
            return { phone: messageTarget }

        case "email":
            return { email: messageTarget }
    }
}

export type MessageTargetObject =
    | { phone: string }
    | { email: string }
