import { t } from "i18next"
import { determineMessageTargetType, MessageTargetType } from "model/enums"
import { WithNullableCreatorId, WithOneMessageTarget, WithUserRights } from "model/interfaces"

import AbstractExpirableModelObject,
       { AbstractExpirableModelObjectCopyOptions,
         AbstractExpirableModelObjectCreationOptions } from "model/AbstractExpirableModelObject"

import { DeepReadonly, Duration, HOUR_MILLIS, map, millisToDuration,
         Nullish, ReadonlyDate, tryCopyDate, tryNormalizeUuid } from "my-util"

import { UserRole } from "./User"

// Comparator

export type InvitesComparator = (lhs: Invite, rhs: Invite) => number

// Status

export type InviteStatus =
    | "active"
    | "expires"
    | "expired"

export function inviteStatusToString(status: InviteStatus): string {
    return t(`domain.invites.statuses.${status}`)
}

export function isInviteStatus(s: string): s is InviteStatus {
    switch (s) {
        case "active":
        case "expires":
        case "expired":
            s satisfies InviteStatus
            return true

        default:
            return false
    }
}

// - Color

export interface InviteStatusColors {
    backgroundColor: string
    borderColor: string
    color: string
}

export const ACTIVE_STATUS_COLORS: Readonly<InviteStatusColors> = {
    backgroundColor: "#E6F4EA",
    borderColor: "#E6F4EA",
    color: "#08922E",
}

export const EXPIRES_STATUS_COLORS: Readonly<InviteStatusColors> = {
    backgroundColor: "#fffcf4",
    borderColor: "#cc5500",
    color: "#cc5500",
}

export const EXPIRED_STATUS_COLORS: Readonly<InviteStatusColors> = {
    backgroundColor: "#F2F2F2",
    borderColor: "#F2F2F2",
    color: "#333",
}

export function getInviteStatusColors(status: InviteStatus): InviteStatusColors {
    switch (status) {
        case "active":
            return { ...ACTIVE_STATUS_COLORS }

        case "expires":
            return { ...EXPIRES_STATUS_COLORS }

        case "expired":
            return { ...EXPIRED_STATUS_COLORS }
    }
}

// - Sorting

export type InviteByStatusSortingOrder = InviteStatus[]

export const ACTIVE_INVITE_BY_STATUS_SORTING_ORDER: Readonly<InviteByStatusSortingOrder> = [
    "active",
    "expires",
    "expired",
]

export const EXPIRES_INVITE_BY_STATUS_SORTING_ORDER: Readonly<InviteByStatusSortingOrder> = [
    "expires",
    "active",
    "expired",
]

export const EXPIRED_INVITE_BY_STATUS_SORTING_ORDER: Readonly<InviteByStatusSortingOrder> = [
    "expired",
    "active",
    "expires",
]

export function getInvitesByStatusSortingOrderComparator(
    statusOrOrder: InviteStatus | Readonly<InviteByStatusSortingOrder>,
): InvitesComparator {
    const order = typeof statusOrOrder === "string"
        ? getInvitesByStatusSortingOrder(statusOrOrder)
        : statusOrOrder

    return (lhs, rhs) => Math.sign(order.indexOf(lhs.status) - order.indexOf(rhs.status))
}

export function getInvitesByStatusSortingOrder(status: InviteStatus): InviteByStatusSortingOrder {
    switch (status) {
        case "active":
            return [...ACTIVE_INVITE_BY_STATUS_SORTING_ORDER]

        case "expires":
            return [...EXPIRES_INVITE_BY_STATUS_SORTING_ORDER]

        case "expired":
            return [...EXPIRED_INVITE_BY_STATUS_SORTING_ORDER]
    }
}

// Invite

export interface InviteCreationOptions
    extends
        AbstractExpirableModelObjectCreationOptions,
        Omit<Nullish<WithUserRights>, "visibleUserIds">
{
    messageTarget: string
    sentAt?: Date | null
    role?: UserRole | null
    creatorId?: string | null
    name?: string | null
    company?: string | null
    reference?: string | null
    text?: string | null
    comment?: string | null

    visibleUserIds?: Iterable<string> | null
}

export interface InviteCopyOptions
    extends
        AbstractExpirableModelObjectCopyOptions,
        Nullish<InviteCreationOptions>
{}

export default class Invite
    extends
        AbstractExpirableModelObject

    implements
        Readonly<WithNullableCreatorId>,
        Readonly<WithOneMessageTarget>,
        DeepReadonly<WithUserRights>
{
    static readonly EXPIRES_WHEN_LEFT_MILLIS = HOUR_MILLIS

    static createOrPass(arg: Invite | DeepReadonly<InviteCreationOptions>): Invite {
        return arg instanceof Invite
            ? arg
            : new Invite(arg)
    }

    readonly messageTarget: string
    readonly sentAt: ReadonlyDate | null
    readonly role: UserRole
    readonly name: string | null
    readonly company: string | null
    readonly reference: string | null
    readonly text: string | null
    readonly comment: string | null
    readonly creatorId: string | null

    readonly canManageTransfers: boolean
    readonly canManageInvites: boolean
    readonly canManageUsers: boolean
    readonly canSeeAllUsers: boolean
    readonly visibleUserIds: readonly string[]

    constructor(options: DeepReadonly<InviteCreationOptions>) {
        super(options)

        this.messageTarget = options.messageTarget
        this.sentAt = tryCopyDate(options.sentAt)
        this.role = options.role ?? "client"
        this.name = options.name ?? null
        this.company = options.company ?? null
        this.reference = options.reference ?? null
        this.text = options.text ?? null
        this.comment = options.comment ?? null

        this.creatorId = options.creatorId != null
            ? tryNormalizeUuid(options.creatorId)
            : null

        this.canManageTransfers = options.canManageTransfers ?? false
        this.canManageInvites = options.canManageInvites ?? false
        this.canManageUsers = options.canManageUsers ?? false
        this.canSeeAllUsers = options.canSeeAllUsers ?? false
        this.visibleUserIds = map(options.visibleUserIds ?? [], tryNormalizeUuid)
    }

    get status(): InviteStatus {
        const now = new Date()

        if (now >= this.expiresAt)
            return "expired"

        const millisLeft = this.expiresAt.getTime() - now.getTime()

        if (millisLeft <= Invite.EXPIRES_WHEN_LEFT_MILLIS)
            return "expires"

        return "active"
    }

    get duration(): Duration {
        return millisToDuration(this.durationMillis)
    }

    get durationMillis(): number {
        return this.expiresAt.getTime() - this.createdAt.getTime()
    }

    get messageTargetType(): MessageTargetType {
        return determineMessageTargetType(this.messageTarget)
    }

    getCreatorIdColor(opacity?: number): string {
        return AbstractExpirableModelObject.getIdColor(this.creatorId ?? "", opacity)
    }

    override copyOrPass(
        arg?: Invite
            | DeepReadonly<InviteCopyOptions>
            | null,
    ): Invite {
        if (arg == null)
            return this

        if (arg instanceof Invite)
            return arg

        return this.copy(arg)
    }

    override copy(options: DeepReadonly<InviteCopyOptions> = {}): Invite {
        return new Invite({
            messageTarget: options.messageTarget ?? this.messageTarget,

            sentAt: options.sentAt !== undefined
                ? options.sentAt
                : this.sentAt,

            role: options.role ?? this.role,

            name: options.name !== undefined
                ? options.name
                : this.name,

            company: options.company !== undefined
                ? options.company
                : this.company,

            reference: options.reference !== undefined
                ? options.reference
                : this.reference,

            text: options.text !== undefined
                ? options.text
                : this.text,

            comment: options.comment !== undefined
                ? options.comment
                : this.comment,

            creatorId: options.creatorId !== undefined
                ? options.creatorId
                : this.creatorId,

            canManageTransfers: options.canManageTransfers ?? this.canManageTransfers,
            canManageInvites: options.canManageInvites ?? this.canManageInvites,
            canManageUsers: options.canManageUsers ?? this.canManageUsers,
            canSeeAllUsers: options.canSeeAllUsers ?? this.canSeeAllUsers,
            visibleUserIds: options.visibleUserIds ?? this.visibleUserIds,

            // AbstractExpirableModelObject

            expiresAt: options.expiresAt ?? this.expiresAt,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
