import { RoutePointRequest } from "api"
import { RoutePoint, RoutePointType } from "model"
import { DeepReadonly } from "my-util"
import { copyUiDocument, FieldDocument } from "ui/component"

export default interface RoutePointFields {
    id?: string | null
    createdAt?: Date | null
    modifiedAt?: Date | null

    country: string
    type: RoutePointType
    documents: FieldDocument[]
}

export function copyRoutePointFields(fields: DeepReadonly<RoutePointFields>): RoutePointFields {
    return {
        id: fields.id,

        createdAt: fields.createdAt != null
            ? new Date(fields.createdAt.getTime())
            : null,

        modifiedAt: fields.modifiedAt != null
            ? new Date(fields.modifiedAt.getTime())
            : null,

        country: fields.country,
        type: fields.type,
        documents: fields.documents.map(copyUiDocument),
    }
}

export function routePointFromFields(fields: DeepReadonly<RoutePointFields>): RoutePoint {
    return new RoutePoint({
        id: fields.id,
        createdAt: fields.createdAt,
        modifiedAt: fields.modifiedAt,

        country: fields.country,
        type: fields.type,
        documentIds: fields.documents.map(({ document, id }) => document?.id ?? id),
    })
}

export function routePointToFields(routePoint: DeepReadonly<RoutePoint>): RoutePointFields {
    return {
        id: routePoint.id,
        createdAt: new Date(routePoint.createdAt.getTime()),
        modifiedAt: new Date(routePoint.modifiedAt.getTime()),

        country: routePoint.country,
        type: routePoint.type,

        documents: routePoint.documentIds.map(id => ({
            status: "loading",
            id,
        })),
    }
}

export function routePointFieldsToRequest(fields: DeepReadonly<RoutePointFields>): RoutePointRequest {
    return {
        country: fields.country,
        type: fields.type,
        documentIds: fields.documents.map(({ document, id }) => document?.id ?? id),
    }
}
