import z from "zod"
import { subpathWithoutFirstSep } from "my-util"
import { NetworkError, throwIfResponseNotOk } from "./error"

export const BASE_URL = `${window.location.origin}/api`

// Perform

export interface PerformOptions<T extends z.ZodType<any, any, any>> {
    method: Method
    subpath: string
    contentType?: string | null
    body?: any
    schema?: T | null
    signal?: AbortSignal | null
}

export type Method =
    | "get"
    | "post"
    | "delete"
    | "put"
    | "patch"

export default async function perform<T extends z.ZodType<any, any, any>>(
    {
        method,
        subpath,
        contentType,
        body,
        schema,
        signal,
    }: Readonly<PerformOptions<T>>,
): Promise<z.infer<T>> {
    const options: RequestInit = {
        method: method.toUpperCase(),
        signal,
    }

    if (body !== undefined) {
        options.body = JSON.stringify(body)
        options.headers = {
            "Content-Type": contentType ?? "application/json",
        }
    }

    const url = `${BASE_URL}/${subpathWithoutFirstSep(subpath)}`

    let response: Response

    try {
        response = await fetch(url, options)
    } catch (error) {
        throw new NetworkError("Network error", { cause: error })
    }

    await throwIfResponseNotOk(response)

    if (schema == null)
        return

    const json = await response.json()
    const schemaResponse = schema.parse(json)

    return schemaResponse
}

// Get

export interface GetOptions<T extends z.ZodType<any, any, any>> {
    subpath: string
    schema?: T | null,
    signal?: AbortSignal | null
}

export async function get<T extends z.ZodType<any, any, any>>(
    options: Readonly<GetOptions<T>>,
): Promise<z.infer<T>> {
    return perform({ ...options, method: "get" })
}

// Post

export interface PostOptions<T extends z.ZodType<any, any, any>> extends GetOptions<T> {
    contentType?: string | null
    body?: any
}

export async function post<T extends z.ZodType<any, any, any>>(
    options: Readonly<PostOptions<T>>,
): Promise<z.infer<T>> {
    return perform({ ...options, method: "post" })
}

// Delete

export interface DelOptions<T extends z.ZodType<any, any, any>> extends PostOptions<T> {}

export async function del<T extends z.ZodType<any, any, any>>(
    options: Readonly<DelOptions<T>>,
): Promise<z.infer<T>> {
    return perform({ ...options, method: "delete" })
}

// Put

export interface PutOptions<T extends z.ZodType<any, any, any>> extends PostOptions<T> {}

export async function put<T extends z.ZodType<any, any, any>>(
    options: Readonly<PutOptions<T>>,
): Promise<z.infer<T>> {
    return perform({ ...options, method: "put" })
}

// Patch

export interface PatchOptions<T extends z.ZodType<any, any, any>> extends PostOptions<T> {}

export async function patch<T extends z.ZodType<any, any, any>>(
    options: Readonly<PatchOptions<T>>,
): Promise<z.infer<T>> {
    return perform({ ...options, method: "patch" })
}
