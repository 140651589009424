import { useContext, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { Page, InvitesWidget, TransfersWidget, WidgetRef } from "ui/component"
import { Flex } from "ui/ui"

const DEFAULT_MAX_WIDGET_CONTENT_WIDTH = "300px"

export default function Private() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    const [maxWidgetContentWidth, setMaxWidgetContentWidth] = useState(DEFAULT_MAX_WIDGET_CONTENT_WIDTH)

    // Refs

    const invitesWidgetRef = useRef(null as WidgetRef | null)

    // Effects

    useLayoutEffect(() => {
        const invitesWidget = invitesWidgetRef.current

        if (invitesWidget == null)
            return

        const resizeObserver = new ResizeObserver(handleResize)

        resizeObserver.observe(invitesWidget.content.current)

        return () => resizeObserver.disconnect()

        function handleResize(entries: ResizeObserverEntry[]) {
            setMaxWidgetContentWidth(invitesWidget!.content.current.scrollWidth + "px")
        }
    })

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    const countType = localUser.isManager
        ? "all"
        : "my"

    return <Page title={t("datetime.labels.today") + ":"}
                 type="main">
        <Flex align="start">
            {localUser.isAgent && <>
                <InvitesWidget maxContentWidth={DEFAULT_MAX_WIDGET_CONTENT_WIDTH}
                               showApplicationCount={localUser.isManager}
                               sentCountType={countType}
                               ref={invitesWidgetRef}
                               showSentCount
                               width="100%"/>
            </>}

            <TransfersWidget maxContentWidth={maxWidgetContentWidth}
                             inWorkCountType={countType}
                             newCountType={countType}
                             width="100%"/>
        </Flex>
    </Page>
}
