import EventEmitter from "events"
import { NotificationResponse, NotificationResponseSchema } from "api/response"

import { Notification, NewChatMessagesNotification,
         NewInviteNotification, NewTransferNotification,
         NewInviteApplicationNotification, NewUserNotification,
         TransferStatusChangedNotification,
         NewAnonymousChatMessagesNotification} from "model"

import { DeepReadonly, UuidSchema } from "my-util"

export type NotificationSourceReadyState =
    | "closed"
    | "connecting"
    | "open"

export interface NotificationSourceCreationOptions {
    open?: boolean

    url: string | URL
    withCredentials?: boolean

    captureRejections?: boolean
}

export default class NotificationSource extends EventEmitter<{
    "old": [Notification[]],
    "new": [Notification],

    "delete": [string],
    "clear": [],

    "open": [],
    "error": [unknown],
    "close": [],
}> {
    readonly url: string | URL
    readonly withCredentials: boolean

    private eventSource: EventSource | null = null

    constructor(
        {
            open,
            url, withCredentials,
            captureRejections,
        }: Readonly<NotificationSourceCreationOptions>,
    ) {
        super({ captureRejections })

        this.url = url
        this.withCredentials = withCredentials ?? false

        if (open)
            this.open()
    }

    get readyState(): NotificationSourceReadyState {
        switch (this.eventSource?.readyState) {
            case EventSource.CLOSED:
            default:
                return "closed"

            case EventSource.CONNECTING:
                return "connecting"

            case EventSource.OPEN:
                return "open"
        }
    }

    open() {
        this.eventSource = new EventSource(this.url, { withCredentials: this.withCredentials })

        // Status events

        this.eventSource.addEventListener("open", () => this.emit("open"))
        this.eventSource.addEventListener("error", () => this.emit("error", undefined))

        // Notification receiving events

        this.eventSource.addEventListener("old", event => {
            if (this.listenerCount("old") === 0)
                return

            try {
                const json = JSON.parse(event.data)
                const parsedJson = NotificationResponseSchema.array().parse(json)
                const notifications = parsedJson.map(notificationResponseToNotification)

                this.emit("old", notifications)
            } catch (error) {
                this.emit("error", error)
            }
        })

        this.eventSource.addEventListener("new", event => {
            if (this.listenerCount("new") === 0)
                return

            try {
                const json = JSON.parse(event.data)
                const parsedJson = NotificationResponseSchema.parse(json)
                const notification = notificationResponseToNotification(parsedJson)

                this.emit("new", notification)
            } catch (error) {
                this.emit("error", error)
            }
        })

        // Notification deletion events

        this.eventSource.addEventListener("delete-all", () => this.emit("clear"))

        this.eventSource.addEventListener("delete", event => {
            if (this.listenerCount("delete") === 0)
                return

            try {
                const json = JSON.parse(event.data)
                const id = UuidSchema.parse(json)

                this.emit("delete", id)
            } catch (error) {
                this.emit("error", error)
            }
        })

        // Util

        function notificationResponseToNotification(
            response: DeepReadonly<NotificationResponse>,
        ): Notification {
            switch (response.type) {
                case "new-chat-messages":
                    return "fromId" in response
                        ? new NewChatMessagesNotification(response)
                        : new NewAnonymousChatMessagesNotification(response)

                case "new-invite-application":
                    return new NewInviteApplicationNotification(response)

                case "new-invite":
                    return new NewInviteNotification(response)

                case "new-transfer":
                    return new NewTransferNotification(response)

                case "new-user":
                    return new NewUserNotification(response)

                case "transfer-status-changed":
                    return new TransferStatusChangedNotification(response)
            }
        }
    }

    close() {
        if (this.eventSource == null)
            return

        this.eventSource.close()
        this.emit("close")

        this.eventSource = null
    }
}
