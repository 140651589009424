import { getInviteById } from "api"
import { Invite } from "model"

import createStorageHook,
       { StorageHook, StorageHookActions, StorageHookValues } from "./createStorageHook"

export interface InvitesHook extends StorageHook<Invite> {}
export interface InvitesHookValues extends StorageHookValues<Invite> {}
export interface InvitesHookActions extends StorageHookActions<Invite> {}

const useInvite = createStorageHook({
    loadById: getInviteById,
    // debug: true,
}) satisfies () => InvitesHook

export default useInvite
