import { ForwardedRef, forwardRef, ReactNode } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { Icon } from "ui/ui/icon"
import style from "./style.module.css"

export interface LinkProps {
    text?: string
    children?: ReactNode

    to: string
    target?: string
    download?: string

    whiteSpace?: string
    fontSize?: string
    color?: string

    stopClickPropagation?: boolean

    iconSrc?: string
    iconAlt?: string
    iconFilter?: string
}

const Link = forwardRef((
    {
        text, children,
        to, target, download,
        whiteSpace, fontSize, color,
        stopClickPropagation,
        iconSrc, iconAlt, iconFilter,
    }: Readonly<LinkProps>,
    ref: ForwardedRef<HTMLAnchorElement>,
) =>
    <ReactRouterLink className={style.Link}
                     style={{ whiteSpace, fontSize, color, textDecorationColor: color }}
                     onClick={event => { if (stopClickPropagation) event.stopPropagation() }}
                     to={to}
                     target={target}
                     download={download}
                     ref={ref}>
        {iconSrc &&
            <Icon src={iconSrc}
                  alt={iconAlt}
                  filter={iconFilter ?? "brightness(0) saturate(100%) invert(38%) sepia(0%) saturate(469%) hue-rotate(134deg) brightness(100%) contrast(84%)"}/>
        }

        {children ?? text}
    </ReactRouterLink>
)

Link.displayName = "Link"

export default Link
