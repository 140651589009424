import { ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { plusIconUrl } from "image"
import { deleteProviderById, getAllProviders } from "api"
import { Provider } from "model"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { Page, ProviderCreationModal, ProviderTable } from "ui/component"
import { ActionModal, Button, Center, ErrorDisplay, Flex, Loading } from "ui/ui"

export default function AllProvidersPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    const [providers, setProviders] = useState(new Array<Provider>())
    const [loading, setLoading] = useState(localUser?.isManager ?? false)
    const [error, setError] = useState(undefined as unknown)

    const [creating, setCreating] = useState(false)
    const [deletingId, setDeletingId] = useState(undefined as string | undefined)

    // Effects

    useEffect(() => {
        if (!loading)
            return

        const controller = new AbortController()

        getAllProviders(controller.signal)
            .then(setProviders)
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    }, [loading])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isManager)
        return <Error403Page/>

    return <Page compactTitle={renderCompactTitle()}
                 autoMoveCompactTitle
                 type="main">
        {renderContent()}
        {renderCreationModalIfCreating()}
        {renderDeletionModalIfDeleting()}
    </Page>

    function renderCompactTitle(): ReactNode {
        return <Flex direction="horizontal">
            <Button onClick={() => setCreating(true)}

                    iconSrc={plusIconUrl}
                    iconAlt="Plus icon"

                    width="fit-content"/>

            {t("sections.providers.header").toUpperCase()}
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error != null)
            return <ErrorDisplay error={error}/>

        if (providers.length === 0)
            return <Center>
                {t("sections.providers.messages.no")}
            </Center>

        return <ProviderTable onDelete={({ id }) => setDeletingId(id)}
                              providers={providers}/>
    }

    function renderCreationModalIfCreating(): ReactNode {
        if (!creating)
            return null

        return <ProviderCreationModal onCreated={newProvider => setProviders([...providers, newProvider])}
                                      onClose={() => setCreating(false)}
                                      width="calc(min(80vw, 900px))"
                                      closeOnCreated/>
    }

    function renderDeletionModalIfDeleting(): ReactNode {
        if (deletingId == null)
            return

        return <ActionModal onNo={() => setDeletingId(undefined)}
                            onYes={onDelete}
                            closeOnSuccess
                            critical>
            {t("domain.provider.messages.warnings.deletion")}
        </ActionModal>

        async function onDelete() {
            await deleteProviderById(deletingId!)
            setProviders(oldProviders => oldProviders.filter(({ id }) => id !== deletingId))
        }
    }
}
