import { useEffect, useImperativeHandle, useState, useRef,
         ForwardedRef, forwardRef, ReactNode, useLayoutEffect } from "react"

import { Padding } from "../layout"
import Hovering from "../Hovering"
import style from "./style.module.css"

// Consts

export const DEFAULT_TOOLTIP_GAP = 8
export const DEFAULT_WINDOW_PADDING = 16

// Props

export interface TooltipProps {
    onShow?: (shown: boolean) => void

    children?: ReactNode

    gap?: number
    windowPadding?: number
}

// Component

const Tooltip = forwardRef((
    {
        onShow,
        children,
        gap, windowPadding,
    }: Readonly<TooltipProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const innerGap = gap ?? DEFAULT_TOOLTIP_GAP
    const innerWindowPadding = windowPadding ?? DEFAULT_WINDOW_PADDING

    // State

    const [shown, setShown] = useState(false)

    // Refs

    const containerRef = useRef(null as HTMLDivElement | null)
    const contentRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => containerRef.current!, [])

    // Effects

    // - State propagation

    useEffect(
        () => { onShow?.(shown) },
        [onShow, shown],
    )

    // - Mouse move event handling

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove)

        return () => window.removeEventListener("mousemove", handleMouseMove)

        function handleMouseMove(event: MouseEvent) {
            const container = containerRef.current

            if (container == null)
                return

            const { x, y } = event

            const containerRect = container.getBoundingClientRect()

            const newVisible =
                x >= containerRect.left &&
                x <= containerRect.right &&
                y >= containerRect.top &&
                y <= containerRect.bottom

            if (newVisible)
                onMouseEnter()
            else
                onMouseLeave()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerGap, innerWindowPadding])

    // - Resize handling

    useLayoutEffect(() => {
        const content = contentRef.current

        if (content == null)
            return

        const observer = new ResizeObserver(updatePosition)

        observer.observe(content)

        return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerGap, innerWindowPadding])

    // Render

    const visibility = shown ? "visible" : "hidden"

    return <div className={style.container}
                style={{ visibility }}
                ref={containerRef}>
        <div className={style.content}
             ref={contentRef}>
            <Hovering>
                <Padding padding="8px">
                    {children}
                </Padding>
            </Hovering>
        </div>
    </div>

    // Events

    function onMouseEnter() {
        setShown(true)
        updatePosition()
    }

    function onMouseLeave() {
        setShown(false)
    }

    // Util

    function updatePosition() {
        const container = containerRef.current

        if (container == null)
            return

        const content = contentRef.current

        if (content == null)
            return

        const containerRect = container.getBoundingClientRect()
        const contentRect = content.getBoundingClientRect()

        let newContentLeft = containerRect.x + .5 * (containerRect.width - contentRect.width)

        if (newContentLeft < innerWindowPadding)
            newContentLeft = innerWindowPadding

        let newContentTop = containerRect.top - contentRect.height - innerGap

        if (newContentTop < innerWindowPadding)
            newContentTop = innerWindowPadding

        content.style.left = `${newContentLeft}px`
        content.style.top = `${newContentTop}px`
    }
})

Tooltip.displayName = "Tooltip"

export default Tooltip
