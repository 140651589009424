import { ForwardedRef, forwardRef, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Transfer, User } from "model"
import style from "./style.module.css"

export interface SmallTransferCardProps {
    onClick?: (transfer: Transfer) => void
    transfer: Transfer

    users?: Map<string, User> | Iterable<User>

    width?: string
}

const SmallTransferCard = forwardRef((
    {
        onClick, transfer,
        users,
        width,
    }: Readonly<SmallTransferCardProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const usersById = useMemo(() => User.groupByIdOrPassOrCreate(users), [users])

    const creator = usersById.get(transfer.creatorId)

    const applicant = creator != null
        ? creator.company.name ?? creator.name
        : null

    return <div onClick={() => onClick?.(transfer)}
                className={style.SmallTransferCard}
                style={{ width }}
                ref={ref}>
        {applicant ?? t("domain.transfers.labels.transfer")}
    </div>
})

SmallTransferCard.displayName = "SmallTransferCard"

export default SmallTransferCard
