import assert from "assert"
import { ReactNode, useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useNavigate } from "react-router-dom"
import { createTransfer, getTransfersConfig, TransfersConfigResponse } from "api"
import { TransferFields, transferFieldsToTransferRequest, transferToTransferFields } from "ui/fields"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { TransferFillInForm, Page, TransferViewForm } from "ui/component"
import { Loading, Pane, ErrorDisplay, PhaseIndicator, Padding } from "ui/ui"
import { createTransferPagePath } from "../TransferPage"

type State =
    | "loading-config"
    | "config-loading-failed"
    | "filling-in"
    | "verifying"
    | "creating"
    | "created"

export default function TransferCreationPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const navigate = useNavigate()

    // State

    const [state, setState] = useState("loading-config" satisfies State as State)
    const [error, setError] = useState(undefined as any)
    const [transfersConfig, setTransfersConfig] = useState(undefined as TransfersConfigResponse | undefined)

    const fieldsRef = useRef(undefined as TransferFields | undefined)

    // Config loading

    useEffect(() => {
        if (localUser == null || state !== "loading-config")
            return

        const controller = new AbortController()

        getTransfersConfig(controller.signal)
            .then(config => {
                setTransfersConfig(config)
                setState("filling-in")
            })
            .catch(error => {
                if (controller.signal.aborted)
                    return

                setError(error)
                setState("config-loading-failed")
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    // Creation

    useEffect(() => {
        if (localUser == null || state !== "creating")
            return

        const fields = fieldsRef.current

        if (fields == null)
            return

        const request = transferFieldsToTransferRequest(fields)
        const controller = new AbortController()

        createTransfer(request, controller.signal)
            .then(transfer => {
                fieldsRef.current = transferToTransferFields(transfer)
                setState("created")
            })
            .catch(error => {
                if (controller.signal.aborted)
                    return

                setError(error)
                setState("verifying")
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page title={t("sections.transfers.new.header").toUpperCase()}
                 type="main">
        {renderContent()}
    </Page>

    function renderContent() {
        if (state === "loading-config")
            return <Loading/>

        if (state === "config-loading-failed")
            return <ErrorDisplay error={error}/>

        if (state === "created")
            return <Navigate to={createTransferPagePath(fieldsRef.current!.id!)}
                             replace/>

        return renderPane()
    }

    function renderPane(): ReactNode {
        return <Pane>
            {renderPaddedPhaseIndicator()}
            {renderForm()}
        </Pane>
    }

    function renderPaddedPhaseIndicator(): ReactNode {
        return <Padding paddingBottom="16px">
            {renderPhaseIndicator()}
        </Padding>
    }

    function renderPhaseIndicator(): ReactNode {
        assert(
            state !== "loading-config" &&
            state !== "config-loading-failed" &&
            state !== "created"
        )

        let current: number
        let label: string
        let labelFontSize: string | undefined

        switch (state) {
            case "filling-in":
                current = 0
                label = t("misc.messages.correctlyFillInRequired")
                labelFontSize = undefined
                break

            case "verifying":
            case "creating":
                current = 1
                label = t("sections.transfers.new.messages.checkFilled")
                labelFontSize = "14px"
                break

            default:
                return state satisfies never
        }

        return <PhaseIndicator phases={[{}, {}]}
                               current={current}
                               label={label}
                               labelFontSize={labelFontSize}/>
    }

    function renderForm(): ReactNode {
        assert(
            state !== "loading-config" &&
            state !== "config-loading-failed" &&
            state !== "created"
        )

        switch (state) {
            case "filling-in":
                assert(transfersConfig != null)

                return <TransferFillInForm
                    fields={fieldsRef.current}

                    onSubmit={newFields => {
                        fieldsRef.current = newFields
                        setState("verifying")
                    }}

                    exportDisabled={!transfersConfig.isExportEnabled}
                    importDisabled={!transfersConfig.isImportEnabled}

                    buttons={[
                        {
                            onClick() { navigate(-1) },
                            text: t("misc.buttons.back"),
                            buttonStyle: "outline",
                            position: "right",
                        },
                        {
                            text: t("misc.buttons.save"),
                            type: "submit",
                            position: "left",
                        }
                    ]}
                />

            case "verifying":
            case "creating":
                assert(fieldsRef.current != null)

                const creating = state === "creating"

                return <TransferViewForm
                    fields={fieldsRef.current}
                    error={error}
                    buttons={[
                        {
                            text: t("misc.buttons.back"),
                            buttonStyle: "outline",
                            position: "right",
                            onClick() { setState("filling-in") },
                            disabled: creating,
                        },

                        {
                            text: t("misc.buttons.save"),
                            position: "left",
                            onClick() { setState("creating") },
                            loading: creating,
                        }
                    ]}
                />
        }
    }
}
