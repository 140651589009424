import { CSSProperties, ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export interface NotificationCountProps {
    count: number
    max?: number

    fontSize?: CSSProperties["fontSize"]

    backgroundColor?: CSSProperties["backgroundColor"]
    color?: CSSProperties["color"]
}

const NotificationCount = forwardRef((
    {
        count, max,
        fontSize,
        backgroundColor, color,
    }: Readonly<NotificationCountProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const text = max != null && count > max
        ? `${max}+`
        : String(count)

    const width = `calc(.5em + ${text.length}ch)`

    return <span style={{ fontSize, backgroundColor, color, width }}
                 className={style.NotificationCount}
                 ref={ref}>
        {text}
    </span>
})

NotificationCount.displayName = "NotificationCount"

export default NotificationCount
