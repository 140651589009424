import { ForwardedRef, forwardRef } from "react"
import { DeepReadonly } from "my-util"
import { None } from "ui/ui/layout"
import ErrorText, { ErrorTextProps } from "../ErrorText"
import style from "./style.module.css"

export interface ErrorBlockProps extends ErrorTextProps {
    width?: string
}

const ErrorBlock = forwardRef((
    props: DeepReadonly<ErrorBlockProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    props.error
        ? <div style={{ width: props.width }}
               className={style.ErrorBlock}
               ref={ref}>
            <ErrorText {...props}/>
        </div>

        : <None ref={ref}/>
)

ErrorBlock.displayName = "ErrorBlock"

export default ErrorBlock
