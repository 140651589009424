import { ForwardedRef, forwardRef, ReactNode } from "react"
import style from "./style.module.css"

export interface DimProps {
    onClick?: () => void
    children?: ReactNode
    opacity?: number
}

const Dim = forwardRef((
    {
        onClick,
        children,
        opacity,
    }: Readonly<DimProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const backgroundColor = `rgba(0, 0, 0, ${opacity ?? .5})`

    return <div style={{ backgroundColor }}
                className={style.Dim}
                onClick={onClick}
                ref={ref}>
        {children}
    </div>
})

Dim.displayName = "Dim"

export default Dim
