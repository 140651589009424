import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { TransferDirectionFilter } from "my-util"
import Radio from "ui/ui/Radio"

export interface TransferFilterDirectionRadioProps {
    onChange?: (type: TransferDirectionFilter) => void
    checked?: TransferDirectionFilter

    readonly?: boolean
    loading?: boolean
    disabled?: boolean

    name?: string

    allHidden?: boolean 
    allDisabled?: boolean

    importHidden?: boolean
    importDisabled?: boolean

    exportHidden?: boolean
    exportDisabled?: boolean
    
    hideButton?: boolean
}

const TransferFilterDirectionRadio = forwardRef((
    {
        onChange, checked,
        readonly, loading, disabled,
        name,
        allHidden, allDisabled,
        importHidden, importDisabled,
        exportHidden, exportDisabled,
        hideButton,
    }: Readonly<TransferFilterDirectionRadioProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Radio
        onChange={onChange as (value: string) => void}
        checked={checked ?? "all" satisfies TransferDirectionFilter}

        readonly={readonly}
        loading={loading}
        disabled={disabled}

        name={name}
        hideButton={hideButton}

        items={{
            ["all" satisfies TransferDirectionFilter]: {
                hidden: allHidden,
                disabled: allDisabled,

                label: t("misc.words.all"),
            },

            ["import" satisfies TransferDirectionFilter]: {
                hidden: importHidden,
                disabled: importDisabled,

                label: t("domain.transfers.directions.import"),
            },

            ["export" satisfies TransferDirectionFilter]: {
                hidden: exportHidden,
                disabled: exportDisabled,

                label: t("domain.transfers.directions.export"),
            },
        }}

        ref={ref}
    />
})

TransferFilterDirectionRadio.displayName = "TransferFilterDirectionRadio"

export default TransferFilterDirectionRadio
