import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import { Flex, FlexDirection } from "ui/ui/layout"
import { Icon } from "../icon"
import style from "./style.module.css"

// Consts

const DEFAULT_FALLBACK_PHASE_STATE: Readonly<Required<PhaseStyle>> = {
    backgroundColor: "white",
    borderColor: "#59778F",
    color: "#59778F",
    size: "normal",
    fontSize: "22px",
}

const DEFAULT_PASSED_PHASE_STATE: Readonly<Required<PhaseStyle>> = {
    backgroundColor: "#59778F",
    borderColor: "#59778F",
    color: "white",
    size: "normal",
    fontSize: "22px",
}

const DEFAULT_CURRENT_PHASE_STATE: Readonly<Required<PhaseStyle>> = {
    backgroundColor: "#D69E2E",
    borderColor: "#D69E2E",
    color: "white",
    size: "normal",
    fontSize: "22px",
}

const DEFAULT_FUTURE_PHASE_STATE: Readonly<Required<PhaseStyle>> = DEFAULT_FALLBACK_PHASE_STATE

// Types

export interface PhaseIndicatorProps {
    phases: Phase[]
    current?: number

    direction?: FlexDirection

    sepLength?: string

    label?: string
    labelFontSize?: string
}

export interface Phase {
    label?: string
    text?: string | ((type: PhaseType) => string)
    icon?: PhaseIcon | ((type: PhaseType) => PhaseIcon)
    style?: PhaseStateStyles
}

export interface PhaseStateStyles {
    fallback?: PhaseStyle
    passed?: PhaseStyle
    current?: PhaseStyle
    future?: PhaseStyle
}

export interface PhaseStyle {
    backgroundColor?: string
    borderColor?: string
    color?: string
    fontSize?: string
    size?: PhaseSize
}

export interface PhaseIcon {
    src?: string
    alt?: string
    filter?: string
}

export type PhaseType =
    | "passed"
    | "current"
    | "future"

export type PhaseSize =
    | "small"
    | "normal"
    | "big"

// Component

const PhaseIndicator = forwardRef((
    {
        phases, current,
        direction,
        sepLength,
        label, labelFontSize
    }: DeepReadonly<PhaseIndicatorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const innerCurrent = current ?? 0
    const innerDirection = direction ?? "horizontal"
    const horizontal = innerDirection === "horizontal"

    // Render

    return <div className={renderClassName()}
                ref={ref}>
            {renderAllPhases()}
            {renderAllSeparators()}
            {renderAllPhaseLabels()}
            {renderLabel()}
    </div>

    function renderClassName(): string {
        return horizontal
            ? style.horizontalLayout
            : style.verticalLayout
    }

    // --Phases

    function renderAllPhases(): ReactNode {
        return phases.map((phase, index) => {
            const key = `phase-${index}`
            const number = 2 * index + 1

            const [gridRow, gridColumn] = horizontal
                ? [1, number]
                : [number, 1]

            return <div style={{ gridRow, gridColumn }}
                        key={key}>
                {renderPhase(phase, index)}
            </div>
        })
    }

    function renderPhase(phase: DeepReadonly<Phase>, phaseIndex: number = 0): JSX.Element {
        const phaseType = getPhaseType(phaseIndex)

        const {
            color, backgroundColor, borderColor,
            fontSize, size,
        } = getPhaseStyle(phase, phaseType)

        const className = phaseSizeToPhaseClassName(size)

        const { text, icon } = phase

        const renderedText = typeof text === "function"
            ? text(phaseType)
            : text

        const effectiveText = renderedText ?? phaseIndex + 1

        const renderedIcon = typeof icon === "function"
            ? icon(phaseType)
            : icon

        return <div style={{ color, backgroundColor, borderColor, fontSize }}
                    className={className}>
            <div className={style.phaseContent}>
                <Flex direction="horizontal">
                    {renderedIcon?.src != null &&
                        renderPhaseIcon(renderedIcon, size)
                    }

                    {effectiveText}
                </Flex>
            </div>
        </div>
    }

    function renderPhaseIcon(
        { src, alt, filter }: Readonly<PhaseIcon>,
        phaseSize: PhaseSize = "normal",
    ): ReactNode {
        let size: string

        switch (phaseSize) {
            case "small":
                size = "14px"
                break

            case "normal":
                size = "16px"
                break

            case "big":
                size = "24px"
                break
        }

        return <Icon src={src}
                     alt={alt}
                     filter={filter}

                     width={size}
                     height={size}/>
    }

    // - Separators

    function renderAllSeparators(): ReactNode {
        return phases.map((_, index) => {
            if (index === phases.length - 1 && !label)
                return null

            const key = `sep-${index}`
            const number = 2 * index + 2

            const [gridRow, gridColumn] = horizontal
                ? [1, number]
                : [number, 1]

            return <div style={{ gridRow, gridColumn }}
                        key={key}>
                {renderSeparator(index)}
            </div>
        })
    }

    function renderSeparator(phaseIndex: number = 0): JSX.Element {
        const className = phaseIndex < innerCurrent
            ? style.finishedSeparator
            : style.separator

        const [width, height] = horizontal
            ? [sepLength, undefined]
            : [undefined, sepLength]

        return <div style={{ width, height }}
                    className={className}/>
    }

    // - Phase labels

    function renderAllPhaseLabels(): ReactNode {
        return phases.map(({ label }, index) => {
            if (!label)
                return null

            const key = `phase-label-${index}`
            const number = 2 * index + 1

            const [gridRow, gridColumn] = horizontal
                ? [2, number]
                : [number, 2]

            return <div className={style.phaseLabelWrapper}
                        style={{ gridRow, gridColumn }}
                        key={key}>
                {renderPhaseLabel(label)}
            </div>
        })
    }

    function renderPhaseLabel(label: string): ReactNode {
        return <div className={style.phaseLabel}>
            {label}
        </div>
    }

    // - Label

    function renderLabel(): ReactNode {
        if (!label)
            return null

        const position = 2 * phases.length + 1

        const [gridRow, gridColumn] = horizontal
            ? [1, position]
            : [position, 1]

        return <div style={{ gridRow, gridColumn, fontSize: labelFontSize }}
                    className={style.label}>
            {label}
        </div>
    }

    // Util

    function getPhaseType(phaseIndex: number): PhaseType {
        if (phaseIndex < innerCurrent)
            return "passed"

        if (phaseIndex === innerCurrent)
            return "current"

        return "future"
    }

    function getPhaseStyle(phase: DeepReadonly<Phase>, phaseType: PhaseType = "current"): Required<PhaseStyle> {
        const { fallback, passed, current, future } = phase?.style ?? {}

        switch (phaseType) {
            case "passed":
                return {
                    backgroundColor: passed?.backgroundColor ?? fallback?.backgroundColor ?? DEFAULT_PASSED_PHASE_STATE.backgroundColor,
                    borderColor: passed?.borderColor ?? fallback?.borderColor ?? DEFAULT_PASSED_PHASE_STATE.borderColor,
                    color: passed?.color ?? fallback?.color ?? DEFAULT_PASSED_PHASE_STATE.color,
                    fontSize: passed?.fontSize ?? fallback?.fontSize ?? DEFAULT_PASSED_PHASE_STATE.fontSize,
                    size: passed?.size ?? fallback?.size ?? DEFAULT_PASSED_PHASE_STATE.size,
                }

            case "current":
                return {
                    backgroundColor: current?.backgroundColor ?? fallback?.backgroundColor ?? DEFAULT_CURRENT_PHASE_STATE.backgroundColor,
                    borderColor: current?.borderColor ?? fallback?.borderColor ?? DEFAULT_CURRENT_PHASE_STATE.borderColor,
                    color: current?.color ?? fallback?.color ?? DEFAULT_CURRENT_PHASE_STATE.color,
                    fontSize: current?.fontSize ?? fallback?.fontSize ?? DEFAULT_CURRENT_PHASE_STATE.fontSize,
                    size: current?.size ?? fallback?.size ?? DEFAULT_CURRENT_PHASE_STATE.size,
                }

            case "future":
                return {
                    backgroundColor: future?.backgroundColor ?? fallback?.backgroundColor ?? DEFAULT_FUTURE_PHASE_STATE.backgroundColor,
                    borderColor: future?.borderColor ?? fallback?.borderColor ?? DEFAULT_FUTURE_PHASE_STATE.borderColor,
                    color: future?.color ?? fallback?.color ?? DEFAULT_FUTURE_PHASE_STATE.color,
                    fontSize: future?.fontSize ?? fallback?.fontSize ?? DEFAULT_FUTURE_PHASE_STATE.fontSize,
                    size: future?.size ?? fallback?.size ?? DEFAULT_FUTURE_PHASE_STATE.size,
                }

            default:
                return DEFAULT_FALLBACK_PHASE_STATE
        }
    }

    function phaseSizeToPhaseClassName(size?: PhaseSize): string {
        return style[`phase-${size ?? "normal"}`]
    }
})

PhaseIndicator.displayName = "PhaseIndicator"

export default PhaseIndicator
