import { z } from "zod"
import { UuidSchema } from "my-util"
import { NotificationResponseBaseSchema } from "./base"
import { NotificationTypeResponse } from "./type"

export const NewInviteNotificationResponseSchema = NotificationResponseBaseSchema.extend({
    type: z.literal("new-invite" satisfies NotificationTypeResponse),
    inviteId: UuidSchema,
})

export type NewInviteNotificationResponse = z.infer<typeof NewInviteNotificationResponseSchema>
