import assert from "assert"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { tickIconUrl } from "image"
import { completeRegistration, CompleteRegistrationRequest } from "api"
import { User } from "model"

import { capitalizedText, EN_NAME_INPUT_REGEX,
         generateRandomPassword, RU_NAME_INPUT_REGEX } from "my-util"

import { validateRuName, validateEnName } from "validation"

import { Button, CheckBox, ErrorText, Form, Pane,
         Icon, Flex, Limit, MessageTarget, DiLangInput } from "ui/ui"

export interface CompletePaneProps {
    onSuccess?: (user: User, password: string) => void

    messageTarget: string
}

export default function CompletePane(
    {
        onSuccess,
        messageTarget,
    }: Readonly<CompletePaneProps>,
) {
    const [t] = useTranslation()

    // State

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    const [name, setName] = useState({
        en: undefined as string | undefined,
        ru: undefined as string | undefined,
    })

    const ruNameInputInvalid = name.ru != null && validateRuName(name.ru) != null
    const ruNameInvalid = name.ru == null || ruNameInputInvalid

    const enNameInputInvalid = name.en != null && validateEnName(name.en) != null
    const enNameInvalid = name.en == null || enNameInputInvalid

    const [acceptedRules, setAcceptedRules] = useState(false)
    const [acceptedPersonalDataProcessing, setAcceptedPersonalDataProcessing] = useState(false)

    // Render

    const submitDisabled =
        ruNameInvalid ||
        enNameInvalid ||

        !acceptedRules ||
        !acceptedPersonalDataProcessing

    return <Pane header={t("auth.reg.headers.completion")}>
        <Form onSubmit={onSubmit}>
            <Flex align="start">
                <Flex direction="horizontal"
                      align="start">
                    <MessageTarget messageTarget={messageTarget}/>

                    <Icon src={tickIconUrl}
                          alt="Tick icon"
                          filter="invert(64%) sepia(96%) saturate(3701%) hue-rotate(88deg) brightness(113%) contrast(135%)"
                          width="16px"
                          height="16px"/>
                </Flex>

                <DiLangInput onChange={(en, ru) => setName({ en, ru })}
                             enValue={name.en}
                             ruValue={name.ru}

                             postProcess={capitalizedText}

                             placeholder={t("auth.reg.placeholders.name")}

                             loading={loading}

                             enInvalid={enNameInputInvalid}
                             ruInvalid={ruNameInputInvalid}

                             ruRegex={RU_NAME_INPUT_REGEX}
                             enRegex={EN_NAME_INPUT_REGEX}

                             maxWidth="400px"/>

                <CheckBox label={t("auth.reg.labels.agreeWithRules")}
                          onChange={setAcceptedRules}
                          loading={loading}
                          checked={acceptedRules}/>

                <CheckBox label={t("auth.reg.labels.agreeWithPersonalDataProcessing")}
                          onChange={setAcceptedPersonalDataProcessing}
                          loading={loading}
                          checked={acceptedPersonalDataProcessing}/>

                <Limit maxWidth="300px">
                    <Button text={t("misc.buttons.ok")}
                            disabled={submitDisabled}
                            loading={loading}
                            type="submit"/>
                </Limit>

                <ErrorText error={error}/>
            </Flex>
        </Form>
    </Pane>

    // Events

    async function onSubmit() {
        setLoading(true)

        try {
            const password = generateRandomPassword()

            assert(name.en != null && name.ru != null)

            const [enFirstname, enLastname, enPatronymic] = name.en
                .trim()
                .split(/\s+/)

            const [ruFirstname, ruLastname, ruPatronymic] = name.ru
                .trim()
                .split(/\s+/)

            const request: CompleteRegistrationRequest = {
                password,

                enFirstname,
                enLastname,
                enPatronymic,

                ruFirstname,
                ruLastname,
                ruPatronymic,
            }

            const user = await completeRegistration(request)

            onSuccess?.(user, password)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
}
