import Autolinker from "autolinker"
import { ForwardedRef, forwardRef, useMemo } from "react"
import style from "./style.module.css"

export interface LinkedTextProps {
    text: string
}

const LinkedText = forwardRef((
    { text }: Readonly<LinkedTextProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const linkedText = useMemo(
        () => Autolinker.link(text, { sanitizeHtml: true }),
        [text],
    )

    return <span dangerouslySetInnerHTML={{ __html: linkedText }}
                 className={style.LinkedText}
                 ref={ref}/>
})

LinkedText.displayName = "LinkedText"

export default LinkedText
