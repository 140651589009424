import { ForwardedRef, forwardRef } from "react"
import { determineMessageTargetType } from "model"
import { Phone } from "../phone"
import { Email } from "../email"

export interface MessageTargetProps {
    messageTarget: string

    noFormat?: boolean
    noLink?: boolean

    stopLinkClickPropagation?: boolean
}

const MessageTarget = forwardRef((
    {
        messageTarget,
        noFormat, noLink,
        stopLinkClickPropagation,
    }: Readonly<MessageTargetProps>,
    ref: ForwardedRef<HTMLElement>,
) => {
    switch (determineMessageTargetType(messageTarget)) {
        case "phone":
            return <Phone stopLinkClickPropagation={stopLinkClickPropagation}
                          phone={messageTarget}
                          noFormat={noFormat}
                          noLink={noLink}
                          ref={ref}/>

        case "email":
            return <Email stopLinkClickPropagation={stopLinkClickPropagation}
                          email={messageTarget}
                          noFormat={noFormat}
                          noLink={noLink}
                          ref={ref}/>
    }
})

MessageTarget.displayName = "MessageTarget"

export default MessageTarget
