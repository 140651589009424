import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"

export interface FlexItemProps {
    children?: ReactNode

    grow?: number
    shrink?: number

    width?: string
}

const FlexItem = forwardRef((
    {
        children,
        grow, shrink,
        width,
    }: DeepReadonly<FlexItemProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div
        style={{
            flexGrow: grow,
            flexShrink: shrink,
            width,
        }}
        ref={ref}
    >
        {children}
    </div>
)

FlexItem.displayName = "FlexItem"

export default FlexItem
