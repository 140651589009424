import { ForwardedRef, forwardRef, useMemo } from "react"
import { getLang } from "i18n"

import { getMonthWeeks, normalizeDayOrGetCurrent, weekToString,
         normalizeMonthIndexOrGetCurrent, normalizeYearOrGetCurrent } from "my-util"

import { useStateWithDeps } from "ui/hook"
import Carousel from "ui/ui/Carousel"

export interface WeekCarouselProps {
    onDayIndexSelect?: (dayIndex: number) => void
    dayIndex?: number
    monthIndex?: number
    year?: number

    maxShowWeeks?: number
    width?: string
}

const WeekCarousel = forwardRef((
    {
        onDayIndexSelect, dayIndex, monthIndex, year,
        maxShowWeeks, width,
    }: Readonly<WeekCarouselProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const today = useMemo(() => new Date(), [])

    // State

    const innerYear = useMemo(
        () => normalizeYearOrGetCurrent(year, today),
        [year, today],
    )

    const innerMonthIndex = useMemo(
        () => normalizeMonthIndexOrGetCurrent(monthIndex, today),
        [monthIndex, today],
    )

    const [innerDayIndex, setInnerDayIndex] = useStateWithDeps(
        () => normalizeDayOrGetCurrent(dayIndex, today),
        [dayIndex, today],
    )

    const monthWeeks = useMemo(
        () => getMonthWeeks(innerYear, innerMonthIndex),
        [innerYear, innerMonthIndex],
    )

    const stringMonthWeeks = useMemo(
        () => monthWeeks.map(week => weekToString(week)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [monthWeeks, getLang()],
    )

    const selectedWeekIndex = useMemo(
        () => {
            for (let i = 0; i < monthWeeks.length; ++i) {
                const { end } = monthWeeks[i]

                if (end.monthIndex === innerMonthIndex) {
                    if (innerDayIndex <= end.dayIndex)
                        return i
                } else
                    return monthWeeks.length - 1
            }

            return 0
        },
        [innerDayIndex, innerMonthIndex, monthWeeks],
    )

    // Render

    return <Carousel onSelect={onWeekSelect}
                     items={stringMonthWeeks}
                     selected={selectedWeekIndex}
                     maxShowItems={maxShowWeeks}
                     width={width}
                     ref={ref}/>

    // Events

    function onWeekSelect(weekIndex: number) {
        const { start } = monthWeeks[weekIndex]

        const newDayIndex = start.monthIndex === innerMonthIndex
            ? start.dayIndex
            : 0

        setInnerDayIndex(newDayIndex)
        onDayIndexSelect?.(newDayIndex)
    }
})

WeekCarousel.displayName = "WeekCarousel"

export default WeekCarousel
