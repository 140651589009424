import { z } from "zod"
import { JsonPatch } from "api/request"
import { CountResponseSchema, ExistsResponseSchema, MfaResponse, MfaResponseSchema } from "api/response"
import { DeepReadonly } from "my-util"
import { del, get, patch, post } from "./perform"
import { NotFoundApiError } from "./error"

export async function performMfaRequest(
    subpath: string,
    body?: any,
    signal?: AbortSignal | null,
): Promise<MfaResponse> {
    return post({
        schema: MfaResponseSchema,
        subpath,
        signal,
        body,
    })
}

export async function getEntityOrNull<T extends z.ZodType<any, any, any>>(
    subpath: string,
    schema: T,
    signal?: AbortSignal | null,
): Promise<z.infer<T> | null> {
    try {
        return await get({
            subpath,
            schema,
            signal,
        })
    } catch (error) {
        if (error instanceof NotFoundApiError)
            return null

        throw error
    }
}

export async function countEntities(subpath: string, signal?: AbortSignal | null): Promise<number> {
    return (await get({
        schema: CountResponseSchema,
        subpath,
        signal,
    })).count
}

export async function isEntityExists(subpath: string, signal?: AbortSignal | null): Promise<boolean> {
    return (await get({
        schema: ExistsResponseSchema,
        subpath,
        signal,
    })).exists
}

export async function tryDeleteEntity(subpath: string, signal?: AbortSignal | null): Promise<boolean> {
    try {
        await del({ subpath, signal })
        return true
    } catch (error) {
        if (error instanceof NotFoundApiError)
            return false

        throw error
    }
}

export interface PatchEntityOptions<T extends z.ZodType<any, any, any>> {
    subpath: string
    patches: DeepReadonly<JsonPatch[]>
    schema?: T
    signal?: AbortSignal | null
}

export async function patchEntity<T extends z.ZodType<any, any, any>>(
    {
        subpath,
        patches,
        schema,
        signal,
    }: PatchEntityOptions<T>
): Promise<z.infer<T>> {
    return patch({
        contentType: "application/json-patch+json",
        body: patches,
        subpath,
        schema,
        signal,
    })
}
