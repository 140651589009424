import { DeepReadonly, Nullish, tryNormalizeUuid } from "my-util"

import AbstractNotification,
       { NotificationType,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

export interface NewInviteApplicationNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">
{
    inviteApplicationId: string
}

export interface NewInviteApplicationNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewInviteApplicationNotificationCreationOptions>
{}

export default class NewInviteApplicationNotification
    extends
        AbstractNotification<"new-invite-application">
{
    static createOrPass(
        arg: NewInviteApplicationNotification
           | DeepReadonly<NewInviteApplicationNotificationCreationOptions>
    ): NewInviteApplicationNotification {
        return arg instanceof NewInviteApplicationNotification
            ? arg
            : new NewInviteApplicationNotification(arg)
    }

    readonly inviteApplicationId: string

    constructor(options: DeepReadonly<NewInviteApplicationNotificationCreationOptions>) {
        super({ ...options, type: "new-invite-application" })

        this.inviteApplicationId = tryNormalizeUuid(options.inviteApplicationId)
    }

    override copyOrPass(
        arg?: NewInviteApplicationNotification
            | DeepReadonly<NewInviteApplicationNotificationCopyOptions>
            | null
    ): NewInviteApplicationNotification {
        if (arg == null)
            return this

        if (arg instanceof NewInviteApplicationNotification)
            return arg
        
        return this.copy(arg)
    }

    override copy(
        options: DeepReadonly<NewInviteApplicationNotificationCopyOptions> = {},
    ): NewInviteApplicationNotification {
        return new NewInviteApplicationNotification({
            inviteApplicationId: options.inviteApplicationId ?? this.inviteApplicationId,

            // AbstractNotification

            recipientId: options.recipientId ?? this.recipientId,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
