export function downloadFile(file: File) {
    downloadBlob(file, file.name)
}

export function downloadBlob(blob: Blob, filename: string = "unknown") {
    const a = document.createElement("a")
    const url = URL.createObjectURL(blob)

    a.href = url
    a.download = filename

    a.click()
    a.remove()
}
