import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react"
import { v4 as generateRandomUuid } from "uuid"
import { DeepReadonly } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Icon } from "ui/ui/icon"
import { Flex } from "ui/ui/layout"
import Label from "ui/ui/Label"
import style from "./style.module.css"

export interface RadioProps {
    onChange?: (value: string) => void
    checked?: string

    readonly?: boolean
    loading?: boolean
    disabled?: boolean

    items?: RadioItems
    name?: string

    hideButton?: boolean

    width?: string
}

export interface RadioItems {
    [key: string]: RadioItemValue | undefined
}

export interface RadioItemValue {
    label?: string

    disabled?: boolean
    hidden?: boolean

    iconSrc?: string
    iconAlt?: string
    iconFilter?: string
}

const Radio = forwardRef((
    {
        onChange, checked,
        readonly, loading, disabled,
        items, name,
        hideButton,
        width
    }: DeepReadonly<RadioProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const itemsEntries = items != null
        ? Object.entries(items)
        : []

    const [innerChecked, setInnerChecked] = useStateWithDeps(
        () => checked
            ?? (
                itemsEntries.length > 0
                    ? itemsEntries[0][0]
                    : undefined
            ),
        [checked],
    )

    const innerName = useMemo(() => name ?? generateRandomUuid(), [name])

    // Render

    const inputStyle = hideButton
        ? { display: "none" } as const
        : undefined

    const allDisabled =
        disabled ||
        readonly ||
        loading

    return <Flex direction="horizontal"
                 width={width}
                 align="center"
                 gap="32px"
                 ref={ref}>
        {itemsEntries.map(renderItemEntry)}
    </Flex>

    function renderItemEntry(
        [itemKey, itemValue]: [string, Readonly<RadioItemValue> | undefined],
        itemIndex: number = 0,
    ): ReactNode {
        if (itemValue == null || itemValue.hidden)
            return null

        const {
            label,
            disabled,
            iconSrc, iconAlt, iconFilter,
        } = itemValue

        return <label className={renderLabelClassName()}
                      key={itemIndex}>
            <input className={style.htmlInput}
                   style={inputStyle}
                   disabled={allDisabled || disabled}
                   type="radio"
                   name={innerName}
                   checked={itemKey === innerChecked}
                   onChange={() => onInnerChange(itemKey)}/>

            <span className={style.input}
                  style={inputStyle}/>

            {iconSrc &&
                <Icon src={iconSrc}
                      alt={iconAlt}
                      filter={iconFilter ?? renderDefaultIconFilter()}/>
            }

            <Label text={label}/>
        </label>
    }

    function renderLabelClassName(): string {
        if (readonly)
            return style.readonlyLabel

        if (loading)
            return style.loadingLabel

        return style.label
    }

    function renderDefaultIconFilter(): string {
        return readonly || loading || disabled
            ? "brightness(0) invert(50%)"
            : "brightness(0) invert(6%)"
    }

    // Events

    function onInnerChange(itemKey: string) {
        setInnerChecked(itemKey)
        onChange?.(itemKey)
    }
})

Radio.displayName = "Radio"

export default Radio
