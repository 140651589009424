import { ReactElement, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import Required from "../Required"

export interface OptionallyRequiredProps {
    children?: ReactNode

    required?: boolean
}

export default function OptionallyRequired(
    {
        children,
        required,
    }: DeepReadonly<OptionallyRequiredProps>,
): ReactElement {
    return required
        ? <Required>
            {children}
        </Required>

    : <>{children}</>
}
