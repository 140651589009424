import Decimal from "decimal.js"
import { TransferRequest } from "api"
import { Money, Transfer, TransferDirection, Product, TransferStatus } from "model"
import { DeepReadonly } from "my-util"
import { copyUiDocument, FieldDocument } from "ui/component/document"

import CurrencyRateFields,
       { copyCurrencyRateFields, currencyRateFromFields,
         currencyRateToFields, currencyRateFieldsToMessage } from "./CurrencyRateFields"

import RoutePointFields,
       { copyRoutePointFields, routePointFromFields,
         routePointFieldsToRequest, routePointToFields } from "./RoutePointFields"

import LegalDocumentFields,
       { copyLegalDocumentFields, legalDocumentToFields,
         legalDocumentFromFields, legalDocumentFieldsToMessage } from "./LegalDocumentFields"

export default interface TransferFields {
    id?: string | null
    createdAt?: Date | null
    modifiedAt?: Date | null

    creatorId: string
    direction: TransferDirection
    country: string
    recipient: {
        name: string
        itn: string | null
        paymentAccount: string | null
        correspondentAccount: string | null
        iban: string | null
        bank: {
            name: string
            address: string
            swift: string
        }
    }

    money: Money
    currencyRate: CurrencyRateFields | null

    invoice: LegalDocumentFields
    contract: LegalDocumentFields

    expenses: Decimal | null
    agentPercent: Decimal | null
    attorneyFee: Money | null

    comment: string | null
    adminComment: string | null

    moment: Date | null

    status: TransferStatus

    documents: FieldDocument[]
    products: Product[]
    routePoints: RoutePointFields[]
}

export function copyTransferFields(fields: DeepReadonly<TransferFields>): TransferFields {
    return {
        // Model object

        id: fields.id,

        createdAt: fields.createdAt != null
            ? new Date(fields.createdAt.getTime())
            : null,

        modifiedAt: fields.modifiedAt != null
            ? new Date(fields.modifiedAt.getTime())
            : null,

        // Common

        creatorId: fields.creatorId,
        direction: fields.direction,
        country: fields.country,

        recipient: {
            name: fields.recipient.name,
            itn: fields.recipient.itn,
            paymentAccount: fields.recipient.paymentAccount,
            correspondentAccount: fields.recipient.correspondentAccount,
            iban: fields.recipient.iban,

            bank: {
                name: fields.recipient.bank.name,
                address: fields.recipient.bank.address,
                swift: fields.recipient.bank.swift,
            },
        },

        // Money

        money: fields.money.copy(),

        currencyRate: fields.currencyRate != null
            ? copyCurrencyRateFields(fields.currencyRate)
            : null,

        // Special documents

        invoice: copyLegalDocumentFields(fields.invoice),
        contract: copyLegalDocumentFields(fields.contract),

        // Payments

        expenses: fields.expenses,
        agentPercent: fields.agentPercent,
        attorneyFee: fields.attorneyFee?.copy() ?? null,

        // Comments

        comment: fields.comment,
        adminComment: fields.adminComment,

        // Moment

        moment: fields.moment != null
            ? new Date(fields.moment.getTime())
            : null,

        // Status

        status: fields.status,

        // Collections

        documents: fields.documents.map(copyUiDocument),
        products: fields.products.map(product => product.copy()),
        routePoints: fields.routePoints.map(copyRoutePointFields),
    }
}

export function transferFieldsToTransfer(fields: DeepReadonly<TransferFields>): Transfer {
    return new Transfer({
        // Model object

        id: fields.id,

        createdAt: fields.createdAt,
        modifiedAt: fields.modifiedAt,

        // Common

        creatorId: fields.creatorId,
        direction: fields.direction,
        country: fields.country,
        recipient: fields.recipient,

        // Money

        money: fields.money,

        currencyRate: fields.currencyRate != null
            ? currencyRateFromFields(fields.currencyRate)
            : null,

        // Special documents

        invoice: legalDocumentFromFields(fields.invoice),
        contract: legalDocumentFromFields(fields.contract),

        // Payments

        expenses: fields.expenses,
        agentPercent: fields.agentPercent,
        attorneyFee: fields.attorneyFee ?? null,

        // Comments

        comment: fields.comment,
        adminComment: fields.adminComment,

        // Moment

        moment: fields.moment,

        // Status

        status: fields.status,

        // Collections

        documentIds: fields.documents.map(({ id, document }) => document?.id ?? id),
        products: fields.products,
        routePoints: fields.routePoints.map(routePointFromFields),
    })
}

export function transferToTransferFields(transfer: Transfer): TransferFields {
    return {
        // Model object

        id: transfer.id,

        createdAt: transfer.createdAt != null
            ? new Date(transfer.createdAt.getTime())
            : null,

        modifiedAt: transfer.modifiedAt != null
            ? new Date(transfer.modifiedAt.getTime())
            : null,

        // Common

        creatorId: transfer.creatorId,
        direction: transfer.direction,
        country: transfer.country,

        recipient: {
            name: transfer.recipient.name,
            itn: transfer.recipient.itn,
            paymentAccount: transfer.recipient.paymentAccount,
            correspondentAccount: transfer.recipient.correspondentAccount,
            iban: transfer.recipient.iban,

            bank: {
                name: transfer.recipient.bank.name,
                address: transfer.recipient.bank.address,
                swift: transfer.recipient.bank.swift,
            },
        },

        // Money

        money: transfer.money,

        currencyRate: transfer.currencyRate != null
            ? currencyRateToFields(transfer.currencyRate)
            : null,

        // Special documents

        invoice: legalDocumentToFields(transfer.invoice),
        contract: legalDocumentToFields(transfer.contract),

        // Payments

        expenses: transfer.expenses,
        agentPercent: transfer.agentPercent,
        attorneyFee: transfer.attorneyFee ?? null,

        // Comments

        comment: transfer.comment,
        adminComment: transfer.adminComment,

        // Moment

        moment: transfer.moment != null
            ? new Date(transfer.moment.getTime())
            : null,

        // Status

        status: transfer.status,

        // Collections

        documents: transfer.documentIds.map(id => ({ id, status: "loading" })),
        products: [...transfer.products],
        routePoints: transfer.routePoints.map(routePointToFields),
    }
}

export function transferFieldsToTransferRequest(fields: DeepReadonly<TransferFields>): TransferRequest {
    return {
        // Common

        id: fields.id,
        direction: fields.direction,
        country: fields.country,
        recipient: fields.recipient,

        // Money

        money: {
            currency: fields.money.currency,
            amount: fields.money.amount.toString(),
        },

        currencyRate: fields.currencyRate != null
            ? currencyRateFieldsToMessage(fields.currencyRate)
            : null,

        // Special documents

        invoice: legalDocumentFieldsToMessage(fields.invoice),
        contract: legalDocumentFieldsToMessage(fields.contract),

        // Payments

        expenses: fields.expenses?.toString(),
        agentPercent: fields.agentPercent?.toString(),

        attorneyFee: fields.attorneyFee != null
            ? {
                currency: fields.attorneyFee.currency,
                amount: fields.attorneyFee.amount.toString(),
            }
            : null,

        // Comments

        comment: fields.comment,
        adminComment: fields.adminComment,

        // Moment

        moment: fields.moment?.toISOString(),

        // Status

        status: fields.status,

        // Collections

        documentIds: fields.documents.map(({ id, document }) => document?.id ?? id),

        products: fields.products.map(({ name, cnfeaCodes}) => ({
            cnfeaCodes: [...cnfeaCodes],
            name,
        })),

        routePoints: fields.routePoints.map(routePointFieldsToRequest),
    }
}
