export interface WindowSize {
    width: number
    height: number
}

export function getWindowSize(): WindowSize {
    const html = document.documentElement

    return {
        width: html.clientWidth,
        height: html.clientHeight,
    }
}
