import { get, post } from "api/http/util"
import { CompletePasswordResetRequest, StartPasswordResetRequest } from "api/request"

import { MfaResponse, PasswordResetConfigResponse,
         PasswordResetConfigResponseSchema, MfaResponseSchema } from "api/response"

import { joinSubpaths } from "my-util"
import { PASSWORDS_SUBPATH } from "./subpath"

export const PASSWORD_RESET_SUBPATH = joinSubpaths(PASSWORDS_SUBPATH, "reset")

export async function cancelPasswordReset(signal?: AbortSignal | null): Promise<void> {
    return post({
        subpath: `${PASSWORD_RESET_SUBPATH}/cancel`,
        signal,
    })
}

export async function completePasswordReset(
    password: string,
    signal?: AbortSignal | null,
): Promise<void> {
    return post({
        subpath: `${PASSWORD_RESET_SUBPATH}/complete`,
        body: { password } satisfies CompletePasswordResetRequest,
        signal,
    })
}

export async function getPasswordResetConfig(
    signal?: AbortSignal | null,
): Promise<PasswordResetConfigResponse> {
    return get({
        subpath: `${PASSWORD_RESET_SUBPATH}/config`,
        schema: PasswordResetConfigResponseSchema,
        signal,
    })
}

export async function startPasswordReset(
    request: Readonly<StartPasswordResetRequest>,
    signal?: AbortSignal | null,
): Promise<MfaResponse> {
    return post({
        subpath: `${PASSWORD_RESET_SUBPATH}/start`,
        schema: MfaResponseSchema,
        body: request,
        signal,
    })
}
