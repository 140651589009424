import ViolationType from "./ViolationType"

export const PAYMENT_ACCOUNT_LENGTH = 20

export function validatePaymentAccount(account: string): ViolationType | undefined {
    if (account.length < PAYMENT_ACCOUNT_LENGTH)
        return "too-short"

    if (account.length > PAYMENT_ACCOUNT_LENGTH)
        return "too-long"

    if (account.match(/^\w*$/g) == null)
        return "bad-syntax"

    return undefined
}
