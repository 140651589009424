import { ForwardedRef, forwardRef, Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { trashCanIconUrl } from "image"
import { Provider } from "model"
import { DeepReadonly } from "my-util"
import { Button } from "ui/ui"
import style from "./style.module.css"

export interface ProviderTableProps {
    onDelete?: (provider: Provider) => void

    providers: Iterable<Provider>

    width?: string
}

const ProviderTable = forwardRef((
    {
        onDelete,
        providers,
        width,
    }: DeepReadonly<ProviderTableProps>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const [t] = useTranslation()

    // State

    const innerProviders = useMemo(
        () => [...providers],
        [providers]
    )

    // Render

    return <table className={style.table}
                  width={width}
                  ref={ref}>
        <tbody>
            {innerProviders.map(provider =>
                <Fragment key={provider.id}>
                    {/* Company */}

                    <tr>
                        <td className={style.provider}>
                            {provider.anyCompany ?? t("domain.company.messages.notSet")}
                        </td>

                        <td>
                            <Button onClick={() => onDelete?.(provider)}
                                    iconSrc={trashCanIconUrl}
                                    iconAlt="Trash can icon"
                                    width="fit-content"
                                    buttonStyle="text"
                                    critical/>
                        </td>
                    </tr>

                    {/* Name */}

                    <tr>
                        <td className={style.provider}>
                            {provider.name}
                        </td>
                    </tr>

                    {/* Phone */}

                    <tr>
                        <td className={style.provider}>
                            {provider.phone ?? t("messageTargets.messages.phoneNotSet")}
                        </td>
                    </tr>

                    {/* Email */}

                    <tr>
                        <td className={style.provider}>
                            {provider.email ?? t("messageTargets.messages.emailNotSet")}
                        </td>
                    </tr>

                    {/* Gap */}

                    <tr className={style.gap}/>
                </Fragment>
            )}
        </tbody>
    </table>
})

ProviderTable.displayName = "ProviderTable"

export default ProviderTable
