import { forwardRef, ForwardedRef } from "react"
import { userStatusToString, getUserStatusColors } from "model"
import { UserStatus } from "model"
import ColorLegend from "ui/ui/ColorLegend"

export interface UserStatusColorLegendProps {
    onClick?: (status: UserStatus) => void
    status: UserStatus

    showLegend?: boolean

    fontSize?: string
}

export const UserStatusColorLegend = forwardRef((
    {
        onClick, status,
        showLegend,
        fontSize
    }: Readonly<UserStatusColorLegendProps>,
    ref: ForwardedRef<HTMLSpanElement>
) => {
    const onInnerClick = onClick != null
        ? () => onClick(status)
        : undefined

    const legend = showLegend
        ? userStatusToString(status)
        : undefined

    const { color } = getUserStatusColors(status)

    return <ColorLegend onClick={onInnerClick}
                        fontSize={fontSize}
                        legend={legend}
                        color={color}
                        ref={ref}/>
});

UserStatusColorLegend.displayName = "UserStatusColorLegend"

export default UserStatusColorLegend
