import { DeepReadonly, Nullish, tryNormalizeUuid } from "my-util"

import AbstractNotification,
       { NotificationType,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

export interface NewUserNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">
{
    userId: string
}

export interface NewUserNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewUserNotificationCreationOptions>
{}

export default class NewUserNotification
    extends
        AbstractNotification<"new-user">
{
    static createOrPass(
        arg: NewUserNotification
           | DeepReadonly<NewUserNotificationCreationOptions>
    ): NewUserNotification {
        return arg instanceof NewUserNotification
            ? arg
            : new NewUserNotification(arg)
    }

    readonly userId: string

    constructor(options: DeepReadonly<NewUserNotificationCreationOptions>) {
        super({ ...options, type: "new-user" })

        this.userId = tryNormalizeUuid(options.userId)
    }

    override get userIds(): string[] {
        return [this.recipientId, this.userId]
    }

    override copyOrPass(
        arg?: NewUserNotification
            | DeepReadonly<NewUserNotificationCopyOptions>
            | null
    ): NewUserNotification {
        if (arg == null)
            return this

        if (arg instanceof NewUserNotification)
            return arg
        
        return this.copy(arg)
    }

    override copy(
        options: DeepReadonly<NewUserNotificationCopyOptions> = {},
    ): NewUserNotification {
        return new NewUserNotification({
            userId: options.userId ?? this.userId,

            // AbstractNotification

            recipientId: options.recipientId ?? this.recipientId,

            // AbstractModelObject

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
