import { User } from "model"

export interface UserStatusPresence {
    activePresent: boolean
    blockedPresent: boolean
    unverifiedPresent: boolean
}

export function checkUserStatusPresence(users: readonly User[]): UserStatusPresence {
    return {
        activePresent: users.some(({ status }) => status === "active"),
        blockedPresent: users.some(({ status }) => status === "blocked"),
        unverifiedPresent: users.some(({ status }) => status === "unverified"),
    }
}
