export function isLeapYear(year: number): boolean {
    year = normalizeYear(year)

    if (year % 400 === 0)
        return true

    if (year % 100 === 0)
        return false

    if (year % 4 === 0)
        return true

    return false
}

export function normalizeYearOrGetCurrent(year?: number | null, date: Date = new Date()): number {
    return year != null
        ? normalizeYear(year)
        : date.getFullYear()
}

export function normalizeYear(year: number): number {
    return year | 0
}
