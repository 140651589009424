import { ForwardedRef, forwardRef, useContext, useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { fullBlueLogoUrl, miniBlueLogoUrl } from "image"
import { UserContext } from "ui/context"
import { MAIN_PAGE_PATH } from "ui/page/sections/MainPage/path"
import style from "./style.module.css"

export interface LogoProps {
    full?: boolean

    refetchUserOnClick?: boolean

    width?: string
    height?: string
}

const Logo = forwardRef((
    {
        full,
        refetchUserOnClick,
        width, height,
    }: Readonly<LogoProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const location = useLocation()
    const [,, refetchUser] = useContext(UserContext)
    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        return () => {
            if (refetchUserOnClick && clicked)
                refetchUser()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchUserOnClick, clicked])

    const src = full
        ? fullBlueLogoUrl
        : miniBlueLogoUrl

    return <div className={style.Logo}
                onClick={onClick}
                ref={ref}>
        <img style={{ width, height }}
             className={style.image}
             src={src}
             alt="Logo"/>

        {clicked &&
            <Navigate to={MAIN_PAGE_PATH}/>
        }
    </div>

    function onClick() {
        if (location.pathname !== MAIN_PAGE_PATH)
            setClicked(true)
    }
})

Logo.displayName = "Logo"

export default Logo
