import { ForwardedRef, forwardRef } from "react"
import { DeepReadonly } from "my-util"
import { None, Center, CenterType } from "ui/ui/layout"
import ErrorText, { ErrorTextProps } from "../ErrorText"

export interface ErrorDisplayProps extends ErrorTextProps {
    centerType?: CenterType
}

const ErrorDisplay = forwardRef((
    props: DeepReadonly<ErrorDisplayProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    props.error
        ? <Center type={props.centerType ?? "absolute-fill"}
                  ref={ref}>
            <ErrorText {...props}/>
        </Center>

        : <None ref={ref}/>
)

ErrorDisplay.displayName = "ErrorDisplay"

export default ErrorDisplay
