import { z } from "zod"
import { UuidSchema } from "my-util"
import { ModelObjectResponseSchema } from "../model-object"
import { AnonymousUserResponseSchema } from "./anonymous-user"

export const ProviderResponseSchema = ModelObjectResponseSchema.extend({
    creatorId: UuidSchema.nullish(),

    phone: z.string().nullish(),
    email: z.string().nullish(),

    enCompany: z.string().nullish(),
    ruCompany: z.string().nullish(),
}).merge(AnonymousUserResponseSchema)

export type ProviderResponse = z.infer<typeof ProviderResponseSchema>
