export function makeName(firstname: string, lastname: string, patronymic: string | null): string {
    return patronymic != null
        ? `${firstname} ${lastname} ${patronymic}`
        : `${firstname} ${lastname}`
}

export function makeFormalName(firstname: string, patronymic: string | null): string {
    return patronymic != null
        ? `${firstname} ${patronymic}`
        : firstname
}
