import { ForwardedRef, forwardRef, useEffect, useRef,
         useImperativeHandle, useLayoutEffect, useState } from "react"

import { bellIconUrl } from "image"
import { Notification } from "model"
import { GlobalNotificationList } from "ui/component/notification"
import { Clickable, Hovering, Icon, NotificationCount } from "ui/ui"
import style from "./style.module.css"

const WINDOW_PADDING = 8
const LIST_OFFSET = 8

const NotificationsButton = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
    // State

    const [notifications, setNotifications] = useState(new Array<Notification>())
    const [showNotifications, setShowNotifications] = useState(false)

    // Refs

    const componentRef = useRef(null as HTMLDivElement | null)
    const listRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => componentRef.current!, [])

    // Effects

    useEffect(() => {
        document.addEventListener("mouseup", handleClick)

        return () => document.removeEventListener("mouseup", handleClick)

        function handleClick(event: MouseEvent) {
            if (!showNotifications)
                return

            const component = componentRef.current

            if (component == null)
                return

            if (event.target instanceof Node)
                setShowNotifications(component.contains(event.target))
        }
    }, [setShowNotifications, showNotifications])

    // - Notification list positioning

    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver(
            () => window.requestAnimationFrame(updateListPosition),
        )

        if (listRef.current)
            resizeObserver.observe(listRef.current)

        window.addEventListener("resize", updateListPosition)
        window.addEventListener("scroll", updateListPosition)

        updateListPosition()

        return () => {
            resizeObserver.disconnect()

            window.removeEventListener("resize", updateListPosition)
            window.removeEventListener("scroll", updateListPosition)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showNotifications])

    // Render

    return <div className={style.Notifications}
                ref={componentRef}>
        <Clickable onClick={onInnerToggleShowNotifications}
                   cursor="pointer">
            <Icon src={bellIconUrl}
                  alt="Bell icon"
                  filter="brightness(0) saturate(100%) invert(29%) sepia(37%) saturate(574%) hue-rotate(164deg) brightness(99%) contrast(98%)"/>

            {notifications.length > 0 &&
                <div className={style.count}>
                    <NotificationCount count={notifications.length}
                                       fontSize="10px"
                                       max={99}/>
                </div>
            }
        </Clickable>

        <div style={{ visibility: showNotifications ? undefined : "hidden" }}
             className={style.list}
             ref={listRef}>
            <Hovering>
                <GlobalNotificationList onChange={setNotifications}
                                        height="80vh"/>
            </Hovering>
        </div>
    </div>

    // Events

    function onInnerToggleShowNotifications() {
        setShowNotifications(oldInnerShowNotifications => !oldInnerShowNotifications)
    }

    // Util

    function updateListPosition() {
        const component = componentRef.current
        const list = listRef.current

        if (component == null || list == null)
            return

        const componentRect = component.getBoundingClientRect()

        const newListLeft = Math.max(
            componentRect.left - list.scrollWidth + componentRect.width,
            WINDOW_PADDING,
        )

        const newListTop = componentRect.bottom + LIST_OFFSET

        list.style.left = newListLeft + "px"
        list.style.top = newListTop + "px"
    }
})

NotificationsButton.displayName = "NotificationsButton"

export default NotificationsButton
