import { ForwardedRef, forwardRef } from "react"
import { v4 as generateRandomUuid } from "uuid"
import { Button, ButtonProps } from "ui/ui"
import { UploadingUiDocument } from "../UiDocument"

export interface AddDocumentsButtonProps extends Omit<ButtonProps, "onClick"> {
    onClick?: (documents: UploadingUiDocument[]) => void

    multiple?: boolean
}

const AddDocumentsButton = forwardRef((
    props: Readonly<AddDocumentsButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>,
) => {
    const { multiple, onClick } = props

    return <Button {...props}
                   onClick={onInnerClick}
                   ref={ref}/>

    function onInnerClick() {
        if (onClick == null)
            return

        const input = document.createElement("input")

        input.type = "file"
        input.multiple = multiple ?? false

        input.onchange = () => {
            try {
                if (onClick == null || input.files === null || input.files.length === 0)
                    return

                const documents = [...input.files].map((file): UploadingUiDocument => ({
                    id: generateRandomUuid(),
                    status: "uploading",
                    file,
                }))

                onClick(documents)
            } finally {
                input.remove()
            }
        }

        input.click()
    }
})

AddDocumentsButton.displayName = "AddDocumentsButton"

export default AddDocumentsButton
